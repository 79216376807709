import { fetchRecentOrder } from '../util/recent_order_util'
import { checkAndSetAutoOut } from './auth'

export const FETCH_RECENT_ORDERS_SUCCESS = 'FETCH_RECENT_ORDERS_SUCCESS'
export const FETCH_RECENT_ORDERS_ERROR = 'FETCH_RECENT_ORDERS_ERROR'
export const HANDLE_RECENT_ORDERS_PROCESSING = 'HANDLE_RECENT_ORDERS_PROCESSING'
export const REMOVE_RECENT_ORDER = 'REMOVE_RECENT_ORDER'

const fetchRecentOrderSuccess = res => ({
  type: FETCH_RECENT_ORDERS_SUCCESS,
  payload: {
    isProcessing: false,
    recent_order: res.data
  }
})

const fetchRecentOrderError = () => ({
  type: FETCH_RECENT_ORDERS_ERROR,
  payload: {
    isProcessing: false,
    recent_order: {}
  }
})

const handleRecentOrderProcessing = status => ({
  type: HANDLE_RECENT_ORDERS_PROCESSING,
  payload: {
    isProcessing: status
  }
})

export const ResetRecentOrderData = status => ({
  type: REMOVE_RECENT_ORDER
})

export const getRecentOrderInfo = () => async (dispatch) => {
  try {
    dispatch(handleRecentOrderProcessing(true))
    const resp = await fetchRecentOrder()
    // Adding scroll to Top
    // window.scrollTo(0, 0)
    if (!!resp && !!resp.data) {
      dispatch(fetchRecentOrderSuccess(resp))
      return resp
    } else {
      dispatch(checkAndSetAutoOut(resp))
      const errorBag = !!resp && !!resp.error ? resp.error : { otherError: resp.message }
      dispatch(fetchRecentOrderError(errorBag))
      return false
    }
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }

    // return empty object if error
    dispatch(fetchRecentOrderError())
    return false
  }
}
