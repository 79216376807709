import React, { Fragment, useEffect, useState } from 'react'
import { DotSpinLoading, helpersFn } from '@eargo/eargo-components'
import { Switch, Route, useHistory, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import PropTypes from 'prop-types'

import SupportNotification from './SupportSection/SupportNotification'
import SupportSection from './SupportSection'
import MyInformation from './MyInformation'
import Dashboard from './DashboardSection'
import OrdersList from './MyOrder/NewDesign/Orders'
import { getActiveSupportCase, getSupportCaseAccountInfo } from '../../actions/support'
import Header from '../common/Header'
import { colorCodes } from '../../constant/colors'
import FooterWrapper from '../common/Footer.js'
import OrderDetails from './MyOrder/NewDesign/OrderDetails'
import Documents from './DocumentsSection'
import { addressUrl, checkLeadIdforDocument, SERVER_URL } from '../../constant'
import { checkLeadId } from '../../util/document'
import Sidebar from './Sidebar'
import './index.scss'
import { fetchAuth } from 'util/auth'

const { GREY_1, WHITE } = colorCodes

const customStyles = {
  control: styles => ({ ...styles, backgroundColor: WHITE, color: GREY_1 }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  option: styles => ({
    ...styles
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: GREY_1,
    backgroundColor: 'none',
    height: '40px',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
    transition: '250ms',
    cursor: 'pointer',
    ':hover': {
      color: GREY_1
    }
  })
}

const MyInfo = () => {
  return (
    <MyInfoRoutesWrapper>
       <Sidebar />
        <Switch>
          <Route exact path='/my-info' component={MyInformation} />
          <Route exact path='/my-info/order' component={OrdersList} />
          <Route exact path='/my-info/order/:id' component={OrderDetails} />
          <Route exact path='/my-info/support' component={SupportSection} />
          <Route exact path='/my-info/dashboard' component={Dashboard} />
          <Route exact path='/my-info/documents' component={Documents} />
          <Route render={() => (<Redirect to='/my-info/dashboard'/>)} />
        </Switch>
      </MyInfoRoutesWrapper>
  )
}

export default MyInfo

export const MyInfoRoutesWrapper = ({ children }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [notfication, setNotification] = useState(false)
  const isAllowed = useSelector(state => state.support.isAllowed)
  const active = useSelector(state => state.support.active)
  const [isLoading, setIsLoading] = useState(true)
  // const isLoading = useSelector(state => state.support.isLoading)
  // const autoOut = useSelector(state => state.global.autoOut)
  // const userInfo = useSelector(state => state.global.user)

  const { pathname } = location
  const [selectBoxOption, setSelectBoxOption] = useState(checkLeadIdforDocument())

  useEffect(() => {
    async function checkLeadData () {
      try {
        const response = await checkLeadId()
        const data = checkLeadIdforDocument(response)
        setSelectBoxOption(data)
      } catch (error) {
        console.log('Error in check lead', error)
      }
    }
    checkLeadData()
  }, [])

  // Redirect to Login page if not logged in
  useEffect(() => {
    async function fetchUser () {
      const res = await fetchAuth()

      if (res?.data?.id) {
        setIsLoading(false)
      }

      if (!res?.data?.id) {
        window.location = '/login'
      }
    }

    fetchUser()
  }, [])

  const pageTitle = {
    '/my-info': 'My Info',
    '/my-info/order': 'My Orders',
    '/my-info/support': 'My Support',
    '/my-info/support/form': 'My Support Form',
    '/my-info/dashboard': 'My Dashboard'

  }
  const handleGtm = () => {
    const url = location.pathname.toLowerCase()
    let urlDescription = pageTitle[url]
    if (!urlDescription) {
      urlDescription = /\/my-info\/order\/./.test(url) ? 'My Orders Detail' : url
    }
    const gtmOptions = {
      event: 'VirtualPageView',
      virtualPageURL: url,
      virtualPageTitle: urlDescription
    }
    helpersFn.gtmSend(gtmOptions)
  }
  // NOTE - Never used
  const supPath = history.location.pathname === '/my-info/support'
  // NOTE - Never used
  useEffect(() => {
    // Adding scrolling to Top
    window.scrollTo(0, 0)
    dispatch(getActiveSupportCase())
    dispatch(getSupportCaseAccountInfo())
    setNotification(true)
  }, [])

  const getValue = () => {
    return selectBoxOption.find((opt) =>
      typeof opt.to === 'string'
        ? history.location.pathname.includes('order')
          ? opt.to === history.location.pathname.slice(0, 14)
          : opt.to === history.location.pathname
        : history.location.pathname.includes('support')
          ? opt.to.includes(history.location.pathname.slice(0, 16))
          : opt.to === history.location.pathname
    )
  }
  useEffect(() => {
    handleGtm()
  }, [pathname])

  const handleSelectChange = async (e) => {
    if (e.action === 'logout') {
      const response = await fetch(`${SERVER_URL}/v2/api/auth/logout`, { credentials: 'include' })
      if (response) {
        window.location = addressUrl
      }
    } else if (e.action === 'link') {
      if (Array.isArray(e.to) && e.to[0] === '/my-info/support') {
        history.push(e.to[0])
      } else {
        history.push(e.to)
      }
    }
  }
  return (
    <Fragment>

   {isLoading && <DotSpinLoading />}
      <Header history={history} />
   {!!isAllowed && !!notfication && active > 0 && !supPath && <SupportNotification returnNotification={notfication => setNotification(notfication)} activeNumber = {active} />}
      <div className="dashboard_section_header myInfo" data-testid='heading-testid'>
       <div className='dashboard_select_box'>
         <div className='typographyV2 body dashboard_select_option'>
           <Select
             isSearchable={false}
             styles={customStyles}
             options={selectBoxOption.filter((i) => !!i.show)}
             value={getValue()}
             onChange={e => handleSelectChange(e)}
             />
         </div>
      </div>
      </div>
      <div className='dashboard_section_container' data-testid='routes-testid'>
        {children}
      </div>
      <FooterWrapper history={history} />
    </Fragment>
  )
}

MyInfoRoutesWrapper.propTypes = {
  children: PropTypes.any
}
