import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'

import { DATE_FORMAT } from '../../../constant'
import './SupportRequestLayout.scss'

function SupportRequestLayout ({ caseDetail }) {
  const history = useHistory()

  return (
      <Fragment>
          {
              caseDetail.map((data, i) => (
                  <Fragment key={data.number}>
                      <div className='support_request_title' data-testid='support-case-detail-list'>
                          <div className="support-subject-heading">
                              <div className={`typographyV2 append_icon support_request_status ${data.status === 'ACTIVE' ? 'isActive' : ''}`}>{data.status}</div>
                              <span className="typographyV2 body_bold">Subject: </span>
                              <span className='typographyV2 body'>{data.subject}</span>
                              <div className='support_request_one_line'>
                                  <div className='typographyV2 body support_request_created_date'> Created: {moment(data.case_created_date).format(DATE_FORMAT)}</div>
                                  <div className='typographyV2 body'>#{data.number ? data.number : 'To Be Assigned'}</div>
                              </div>
                          </div>
                          <img className='support_request_next_arrow' src='https://assets.eargo.com/account/support/next.svg' alt="Next Svg" onClick={() => history.push(`/my-info/support/:${data.sf_case_id}`)} />
                      </div>
                      <div className='support_request_holder'>
                      </div>
                      {(i !== caseDetail.length - 1) &&
                          <div className={`support_request_dim_line ${data.status === 'active' ? 'isActive' : ''}`} />}
                  </Fragment>
              ))
          }
      </Fragment>
  )
}

SupportRequestLayout.propTypes = {
  caseDetail: PropTypes.array
}

export default React.memo(SupportRequestLayout)
