import { handleAjax } from '../common/helpers'

export const fetchCart = query => handleAjax('GET', 'api/orders', query)

export const verifyOrder = query => handleAjax('GET', 'api/orders/verifyOrder', query)

export const createCart = item => handleAjax('POST', 'api/orders', item)

export const updateCart = order_info => handleAjax('PATCH', 'api/orders/', order_info)

export const productsForInsurance = (info, signal) => handleAjax('POST', 'api/products_for_insurance', info, false, false, signal)

export const updateInsuranceCart = order_info => handleAjax('PATCH', 'api/insurance_order', order_info)

export const chooseSelectedProd = order_info => handleAjax('PATCH', 'api/insurance_order/lineitems', order_info)

export const removeLineItem = (orderId, line_item_id) => handleAjax('DELETE', 'api/orders/', { orderId, line_item_id })

export const checkoutUpdate = order_info => handleAjax('PATCH', 'api/checkout', JSON.stringify(order_info), 'json', 'application/json')

export const applyCoupon = request => handleAjax('POST', 'api/orders/apply_coupon', request)

export const removeCoupon = orderId => handleAjax('POST', 'api/orders/deactivate_coupon', { orderId })

export const completeOrder = order => handleAjax('POST', 'api/checkout/complete', JSON.stringify(order), 'json', 'application/json')

export const reviseCartOrder = orderId => handleAjax('POST', 'api/revise-order', { orderId })

export const breadCompleteOrder = order => handleAjax('POST', 'api/bread/complete_order', order)

export const federalCompleteOrder = order => handleAjax('POST', 'api/insurance_order/complete', order)

export const updateTaxApi = shipAddress => handleAjax('PATCH', 'api/orders/tax', JSON.stringify(shipAddress), 'json', 'application/json')

export const checkSession = info => handleAjax('GET', 'api/sent-to-sf', info)

export const attachPaymentMethod = info => handleAjax('PATCH', 'api/orders/change_payment_method', info)
