import React from 'react'
import PropTypes from 'prop-types'
import { colorCodes } from '../../constant/colors'

const UpArrow = ({
  style = {},
  fill = colorCodes.DARK_BLUE_1,
  width = 16,
  height = 11,
  className = '',
  onClick,
  viewBox = '0 0  16 11'
}) => (
    <svg
      fill="none"
      style={style}
      height={height}
      viewBox={viewBox}
      width={width}
      onClick={() => !!onClick && onClick}
      className={className}
      data-testid='jest-up-arrow-icon-id'
    >
      <path d="M13.3687 10.1313L7.63123 4.40629L1.89373 10.1313L0.131226 8.36879L7.63123 0.868787L15.1312 8.36879L13.3687 10.1313Z" fill={fill} />
    </svg>
)

UpArrow.propTypes = {
  style: PropTypes.any,
  fill: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  viewBox: PropTypes.string
}

export default UpArrow
