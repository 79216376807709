import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import EditForm from './EditForm'
import CommanHeading from '../CommanHeading'
import { useDispatch, useSelector } from 'react-redux'
import { disabledOtherSection } from '../../../actions/global'
import './index.scss'

const AccountSection = ({ user }) => {
  const [isEdit, setIsEdit] = useState(false)
  const dispatch = useDispatch()
  const isDisabled = useSelector(state => state.global.isDisabled)

  // This method is use to make smooth scroll behaviour
  const scrollToMyAccount = () => {
    setTimeout(() => {
      const element = document.getElementById('eargo-my-account-container')
      !!element && element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
    }, 100)
  }

  // HINT: If user is authenticated, the username and email must be there.
  return (
    <div className='account_section_container' id="eargo-my-account-container">
      {
        !!user && Object.keys(user).length > 0 &&
        <>
          <CommanHeading className='my-account-line' heading='Account Info' />
          <div className='account_section_sub_container' id='myAccount'>
            {!isEdit
              ? <Fragment>
                {!!user && Object.keys(user).length > 0
                  ? <div className='account_details' data-testid='AccountDetail-testid'>
                    <div className='account_holder_details'>
                      <label className="typographyV2 body eargo-capitalize account_label" style={{ color: '#666666' }}><span className='regular' style={{ color: '#333333' }} >Name:</span> {`${user.firstName || 'N/A'} ${user.lastName || ''}`}</label>
                      <label className='typographyV2 body account_label' style={{ color: '#666666' }}><span style={{ color: '#333333' }} >Email:</span> {user.email}</label>
                      <label className='typographyV2 body account_label' style={{ color: '#666666' }}><span style={{ color: '#333333' }} >Phone:</span> {user.phone || 'N/A'}</label>
                    </div>
                    <div className='account_edit_section' data-testid='EditSection-testid'>
                      <div className={`typographyV2 body account_edit_button ${isDisabled ? 'isDisabled' : ''}`} data-testid='editBtn-testid' onClick={() => {
                        dispatch(disabledOtherSection(true))
                        setIsEdit(true)
                        scrollToMyAccount()
                      }}>Edit</div>
                    </div>
                  </div>
                  : <div className='typographyV2 body account_no_info'>You haven&#39;t entered your account information yet</div>}
              </Fragment>
              : <div className='account_edit'>
                <EditForm onCancel={() => {
                  setIsEdit(false)
                  scrollToMyAccount()
                }}
                />
              </div>
            }
          </div>
        </>
      }
    </div>
  )
}

AccountSection.propTypes = {
  user: PropTypes.any
}

export default React.memo(AccountSection)
