import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { PrimaryInput, helpersFn } from '@eargo/eargo-components'

import { devices } from '../../../constant/devices'

const { capitalizeOnBlur, formatPhoneNumber } = helpersFn
const ReduxHolder = styled.div`
margin-bottom: 10px;
${props => props.oneline
? `
width: 50%;
&.error_border {
    span {
        @media ${devices.mobile} {
            font-size: 16px;
        }
    }
}
@media ${devices.mobile} {
    margin: 12px 0;
    width: auto;
  }
`
: ''}
`

const ReduxPrimaryInput = ({
  input, id, type, disabled, label, handleOnFocus, handleOnBlur, className, showPassword,
  meta: { asyncValidating, touched, error, active, visited }, errResp, oneline = false, capitalize = false
}) => {
  return (
    <ReduxHolder oneline={oneline}>
      <PrimaryInput
        type={type}
        id={id}
        disabled={disabled}
        className={className}
        active={active}
        showPassword={showPassword}
        errClass={(((!active && (visited || touched)) && error) || errResp) ? 'error_border' : ''}
        label={(((!active && (visited || touched)) && error) || errResp) ? (asyncValidating ? 'Validating...' : (error || errResp)) : (asyncValidating ? 'Validating...' : label)}
        handleOnChange={param => {
          const val = param.target.value
          input.onChange((input.name === 'patient_phone' || input.name === 'payer_phone')
            ? formatPhoneNumber(val)
            : val)
        }}
        handleOnFocus={e => {
          input.onFocus(e)
          !!handleOnFocus && handleOnFocus(e)
        }}
        handleOnBlur={e => {
          const trimmedValue = (e.target.value).trim()
          input.onChange(capitalize ? capitalizeOnBlur(trimmedValue) : trimmedValue)

          // HINT: capitalizeOnBlur function taking some time in calculation that's why we added setTimeOut
          capitalize ? setTimeout(() => input.onBlur(), 50) : input.onBlur(e)
          !!handleOnBlur && handleOnBlur(e)
        }}
        {...input}
      />
    </ReduxHolder>
  )
}

ReduxPrimaryInput.propTypes = {
  input: PropTypes.any,
  id: PropTypes.any,
  type: PropTypes.any,
  disabled: PropTypes.any,
  label: PropTypes.any,
  handleOnFocus: PropTypes.func,
  handleOnBlur: PropTypes.func,
  className: PropTypes.string,
  showPassword: PropTypes.any,
  meta: PropTypes.object,
  errResp: PropTypes.any,
  oneline: PropTypes.bool,
  capitalize: PropTypes.bool
}

export default ReduxPrimaryInput
