import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import './DownloadApp.scss'
import CommonHeaderSection from '../SupportHeader/SupportHeader'
import { downloadSupportHeading, downloadSupportSubHeading, MobileViewSubHeading, neoAppStore, neoPlayStore, eargoAppStore, eargoPlayStore } from '../../../../constant'

const DownloadSupportApp = ({ Products }) => {
  const [ProductsAppsInfo, setProductsAppsInfo] = useState([])
  const [setProducts] = useState(Products)
  let [neoItems, setNeoItems] = useState('')
  let [eargoItems, setEargoItems] = useState('')
  const [flag, setFlag] = useState(false)
  const eargoNeo = ['EARGO NEO HIFI', 'EARGO NEO']
  const eargo = ['EARGO 5', 'EARGO 6', 'EARGO 7', 'EARGO SE', 'LINK BY EARGO']

  const RemoveComma = (eargoItem) => {
    const str = eargoItem.split('')
    str.splice(0, 1)
    return str.join('')
  }

  useEffect(() => {
    setProducts.forEach((item) => {
      if (eargoNeo.includes(item)) {
        setNeoItems(neoItems += `, ${item}`)
      } else if (eargo.includes(item)) {
        setEargoItems(eargoItems += `, ${item}`)
      }
    })
    setFlag(true)
  }, [setProducts])

  useEffect(() => {
    if (flag) {
      if (eargoItems?.length) {
        setProductsAppsInfo(ProductsAppsInfo => [...ProductsAppsInfo, { title: RemoveComma(eargoItems), links: { appstore: eargoAppStore, playstore: eargoPlayStore }, image: false }])
      }
      if (neoItems?.length) {
        setProductsAppsInfo(ProductsAppsInfo => [...ProductsAppsInfo, { title: RemoveComma(neoItems), links: { appstore: neoAppStore, playstore: neoPlayStore }, image: 'https://assets.eargo.com/new_account/eargo_neo_app.png' }])
      }
    }
  }, [flag])

  return (
    <div>
      {
        ProductsAppsInfo?.map((item, i) => {
          return (<div key={i}>
            <CommonHeaderSection heading={downloadSupportHeading} subHeading={window.innerWidth < 650 ? MobileViewSubHeading : downloadSupportSubHeading} isBorderBottom={true} isImage={true} appHeading={item.title} isBorderTop={true} eargoHifiImage={item.image} />
            <div className='App_links'>
              <div className='appStore' ><a href={item.links.appstore} target="_blank" rel="noreferrer" ><img src='https://assets.eargo.com/new_account/app_store_app.png' /></a></div>
              <div className='playStore' ><a href={item.links.playstore} target="_blank" rel="noreferrer" ><img src='https://assets.eargo.com/new_account/play_store_app.png' /></a></div>
            </div>
          </div>
          )
        })
      }
    </div>
  )
}

DownloadSupportApp.propTypes = {
  Products: PropTypes.array
}

export default DownloadSupportApp
