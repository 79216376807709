import {
  SET_AEM_HEADER_DATA,
  SET_AEM_FOOTER_DATA
} from '../actions/aem_data'

const defaultState = {
  isLoading: true,
  headerData: {}
}
const defaultFooterState = {
  isLoading: true,
  footerData: {}
}

export const headerJson = (state = defaultState, action) => {
  switch (action.type) {
    case SET_AEM_HEADER_DATA:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export const footerJson = (state = defaultFooterState, action) => {
  switch (action.type) {
    case SET_AEM_FOOTER_DATA:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
