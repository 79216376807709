import * as CartApiUtil from '../util/insurance_cart_api_util'

export const ORDER_LOADING = 'ORDER_LOADING'

const setLoading = status => ({
  type: ORDER_LOADING,
  payload: { isLoading: status }
})

export const createInsCart = (cart, history) => async (dispatch) => {
  console.log('running--->')
  try {
    dispatch(setLoading(true))
    const order = await CartApiUtil.createInsuranceCart(cart)
    if (!!order && !!order.state && order.state === 'address') {
      history.push('/insurance-checkout')
      return true
    } else {
      dispatch(setLoading(false))
      console.log('Order state not updated: ', order)
      return false
    }
  } catch (error) {
    dispatch(setLoading(false))
    console.log('CreateCart Catch: ', error)
    return false
  }
}
