import React from 'react'
import PropTypes from 'prop-types'

import { Footer } from '@eargo/eargo-components'

import { addressUrl, SERVER_URL } from '../../constant/index.js'
import { useSelector } from 'react-redux'

const FooterWrapper = (props) => {
  const { history } = props
  const footerJson = useSelector((state) => state.footerJson)
  return (
    footerJson.isLoading
      ? <div>...loading</div>
      : <Footer history={history} footerData = {footerJson.footerData} corpDomain={addressUrl} serverUrl={SERVER_URL} />
  )
}
FooterWrapper.propTypes = {
  history: PropTypes.any,
  footerData: PropTypes.object
}

export default FooterWrapper
