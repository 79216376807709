import {
  FETCH_RECENT_ORDERS_SUCCESS,
  FETCH_RECENT_ORDERS_ERROR,
  HANDLE_RECENT_ORDERS_PROCESSING,
  REMOVE_RECENT_ORDER
} from '../actions/recent_order_action'

const defaultState = {
  recent_order: {},
  isProcessing: false,
  error: {}
}

const recentOrder = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_RECENT_ORDERS_SUCCESS:
    case FETCH_RECENT_ORDERS_ERROR:
    case HANDLE_RECENT_ORDERS_PROCESSING:
      return {
        ...state,
        ...action.payload
      }
    case REMOVE_RECENT_ORDER:
      return {
        ...state,
        recent_order: {}
      }
    default:
      return state
  }
}

export default recentOrder
