import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field, reduxForm } from 'redux-form'
import { useDispatch } from 'react-redux'
import { EargoButtonV2, DotSpinLoading } from '@eargo/eargo-components'

import { addNewAddress, addressesError, removeFieldError, setAddressListPopUp, validateZipCode } from '../../../actions/user_address'
import { requiredField, onlyOneSpecialCharDash, noOnlyNumber, number, minLength, maxLength, doubleSpace } from '../../common/redux-field/validation'
import { ReduxCheckboxInput } from '../../common/redux-field/ReduxCheckboxInput'
import ReduxPrimaryInput from '../../common/redux-field/ReduxPrimaryInput'
import ReduxSelectBox from '../../common/redux-field/ReduxSelectBox'
import { submitFailure } from '../../../common/helpers'
import { colorCodes } from '../../../constant/colors'
import { devices } from '../../../constant/devices'
import './AddNewAddress.scss'

const FORM_NAME = 'AddNewAddress'
const { GREY_6, WHITE, GREY_10, ORANGE_5, BRIGHT_GREY } = colorCodes

const { mobile } = devices

const fNameRequire = requiredField('First Name')
const lNameRequire = requiredField('Last Name')
const address1Require = requiredField('Street Address')
const addressMin = minLength(4)
const cityRequire = requiredField('City')
const stateRequire = requiredField('State')
const zipcodeRequire = requiredField('Zipcode')
const minChar5 = minLength(5, 'Too Short')
const maxChar5 = maxLength(5)

const EditButton = styled.div`
  justify-content: center;
    ${props => props.isEdit
? `
    display: inline-flex;
    padding: 14px 0 41px 0;
    `
: `
    display: flex;
    padding-top: 20px;`}
    @media ${mobile} {
        padding-top: 24px;
    }
`

const stateSelectStyle = {
  container: (provided, { isDisabled }) => ({
    ...provided,
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    width: '100%',
    height: '100%',
    background: isDisabled ? BRIGHT_GREY : 'transparent'
  }),
  indicatorsContainer: () => ({
    marginRight: 7
  }),
  dropdownIndicator: () => ({
    color: ORANGE_5
  }),
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    color: colorCodes.GREY_1
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 16,
    paddingTop: 12,
    overflow: 'visible',
    [`@media ${mobile}`]: {
      paddingLeft: 13
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'none'
  }),
  menu: () => ({
    flexDirection: 'column',
    maxHeight: 300,
    minHeight: 300,
    overflow: 'hidden',
    zIndex: 2,
    width: '100%',
    position: 'absolute',
    top: 75,
    left: 0,
    minWidth: 200,
    borderRadius: 3,
    [`@media ${mobile}`]: {
      top: 60
    }
  }),
  menuList: () => ({
    minHeight: 290,
    maxHeight: 290,
    overflowY: 'auto',
    background: WHITE,
    border: `1px solid ${GREY_6}`,
    position: 'relative'
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    cursor: 'pointer',
    background: (isFocused && !isSelected) && GREY_10,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 24,
    fontSize: '1.125rem',
    fontFamily: 'Inter, sans-serif'
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'none'
  })
}

const StateSelectBox = ({
  input, meta: { active, visited, touched, error },
  handleOnFocus, handleOnBlur, errResp, removeFieldError, stateList, disabled = false
}) => {
  const [searchableData, setSearchableData] = useState('')
  let statePlaceHolder = input.value

  if ((!!stateList.length && input.value) ||
    (!!input.value && (typeof input.value === 'number'))) {
    const record = stateList.find(el => el.id === parseInt(input.value))
    if (!!record && !!record.abbr) {
      statePlaceHolder = record.abbr
    }
  }
  return (
    <label className={`${((touched && error) || !!errResp) ? 'error_border state_error_border' : ''} ip_holder_2`}>
      {<h1 className={`typographyV2 state_label ${(!!active || !!input.value) ? 'floating_label' : 'label'}`}>
        {(((!active && (visited || touched)) && error) || !!errResp) ? (error || errResp) : 'State'}
      </h1>}
      {!!input.value && <span className='state_span'>{!searchableData && statePlaceHolder}</span>}
      <ReduxSelectBox
        selectBoxStyle={stateSelectStyle}
        input={input}
        options={stateList.map((option) => ({
          ...option,
          id: option.id,
          label: option.name,
          value: option.id
        }))}
        isSearchable={true}
        handleOnFocus={handleOnFocus}
        handleOnBlur={handleOnBlur}
        errResp={errResp}
        disabled={disabled}
        onMenuScrollToBottom={e => e.target.scrollIntoView()}
        removeFieldError={removeFieldError}
        onInputChange={value => setSearchableData(value)}
      />
    </label>
  )
}

StateSelectBox.propTypes = {
  input: PropTypes.any,
  meta: PropTypes.object,
  handleOnFocus: PropTypes.any,
  handleOnBlur: PropTypes.any,
  errResp: PropTypes.any,
  removeFieldError: PropTypes.func,
  stateList: PropTypes.any,
  disabled: PropTypes.bool
}

const AddNewAddress = ({ isLoading, submitting, errMsg, states, handleSubmit, closePopUp, addressCount }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setAddressListPopUp(false))
    // eslint-disable-next-line
  }, [])

  // Handle Unmount logic
  useEffect(() => () => dispatch(addressesError(null)), [addressesError])
  useEffect(() => () => {
    if (!!addressCount && addressCount > 1) {
      dispatch(setAddressListPopUp(true))
    }
  }, [setAddressListPopUp, addressCount])

  const submitForm = async info => {
    info.default = !!info.default
    if (states && states.length) {
      info.country_id = states[0].country_id
      if (info.state_id) {
        const stateId = states.find(st => st.abbr === info.state_id)
        if (stateId) {
          info.state_id = stateId.id
        }
      }
    }

    const isValid = await dispatch(validateZipCode(info, FORM_NAME))

    if (isValid) {
      const resp = await dispatch(addNewAddress(info))
      if (resp) {
        // HINT: state update functionality taking some time
        setTimeout(() => closePopUp(), 200)
      }
    }
  }

  const handleOnFocus = e => {
    const fieldName = e.target.name
    if (!!errMsg && errMsg[fieldName]) dispatch(removeFieldError(fieldName))
  }

  return (
    <div className='add_new_address_container'>
      {!!isLoading && <DotSpinLoading />}
      <h5 className="typographyV2 heading">Add New Address</h5>
      <form onSubmit={handleSubmit(submitForm)} id='eargo-add-new-add-form' className='address_form'>
        <div className='one_line'>
          <Field
            id="firstname"
            name="firstname"
            label="First Name"
            capitalize={true}
            onFocus={handleOnFocus}
            component={ReduxPrimaryInput}
            validate={[fNameRequire, doubleSpace, onlyOneSpecialCharDash, noOnlyNumber]}
            errResp={!!errMsg && !!errMsg.firstname ? errMsg.firstname : false}
          />
          <Field
            id="lastname"
            name="lastname"
            label="Last Name"
            capitalize={true}
            onFocus={handleOnFocus}
            component={ReduxPrimaryInput}
            validate={[lNameRequire, doubleSpace, onlyOneSpecialCharDash, noOnlyNumber]}
            errResp={!!errMsg && !!errMsg.lastname ? errMsg.lastname : false}
          />
        </div>
        <Field
          id="address1"
          name="address1"
          label="Street Address"
          onFocus={handleOnFocus}
          component={ReduxPrimaryInput}
          validate={[address1Require, addressMin]}
          errResp={!!errMsg && !!errMsg.address1 ? errMsg.address1 : false}
        />
        <Field
          id="address2"
          name="address2"
          label="Apt/Suite (Optional)"
          onFocus={handleOnFocus}
          component={ReduxPrimaryInput}
          errResp={!!errMsg && !!errMsg.address2 ? errMsg.address2 : false}
        />
        <Field
          id="city"
          name="city"
          label="City"
          onFocus={handleOnFocus}
          validate={[cityRequire]}
          component={ReduxPrimaryInput}
          errResp={!!errMsg && !!errMsg.city ? errMsg.city : false}
          />
        <div className='two_line_section'>
          <Field
            id="state_id"
            name="state_id"
            label="State"
            stateList={states}
            component={StateSelectBox}
            validate={[stateRequire]}
            errResp={!!errMsg && !!errMsg.state_id ? errMsg.state_id : false}
          />
          <div className='zip_field'>
            <Field
              id="zipcode"
              name="zipcode"
              label="Zipcode"
              onFocus={handleOnFocus}
              component={ReduxPrimaryInput}
              validate={[zipcodeRequire, number, minChar5, maxChar5]}
              errResp={!!errMsg && !!errMsg.zipcode ? errMsg.zipcode : false}
            />
            </div>
          </div>
        <div className='checkbox_holder'>
          <Field
            name="default"
            label="Make default address"
            component={ReduxCheckboxInput}
          />
        </div>
        {!!errMsg && !!errMsg.otherError && <div className='err_regular typographyV2 error_message'>{errMsg.otherError}</div>}
        <EditButton>
          <EargoButtonV2
            className="primary stretch"
            disabled={submitting || !!isLoading}
            type="submit"
            label={isLoading ? 'Loading...' : 'Save'}
            />
        </EditButton>
      </form>
    </div>
  )
}

AddNewAddress.propTypes = {
  validateZipCode: PropTypes.func,
  setAddressListPopUp: PropTypes.func,
  isLoading: PropTypes.bool,
  submitting: PropTypes.bool,
  errMsg: PropTypes.object,
  states: PropTypes.any,
  handleSubmit: PropTypes.func,
  closePopUp: PropTypes.func,
  addressCount: PropTypes.any,
  removeFieldError: PropTypes.func,
  removeAllError: PropTypes.func,
  addNewAddress: PropTypes.func
}

export default (reduxForm({
  form: FORM_NAME,
  onSubmitFail: () => submitFailure()
})(AddNewAddress))
