import React, { useEffect, Suspense } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { devices } from '../../constant/devices'
import { colorCodes } from '../../constant/colors'
import { fetchAuthInfo } from '../../actions/global'
import { CLEMENTAINEIFRAMEURL } from '../../constant/index.js'
import CheckoutHeader from '../order_checkout_forms/CheckoutHeader'
import LoaderPlaceholder from '../common/LoaderPlaceholder'
// import { fetchCart } from '../../actions/cart_actions'
import FooterWrapper from '../common/Footer.js'

const { mobile, tablet } = devices
const { WHITE, GREY_12, DIM_GREY7, GREY_1 } = colorCodes

const HearingContainer = styled.div`
  background: ${DIM_GREY7};
  .container-fluid {
    padding: 0px; 
    width: 100%;
  }
  #clementineFrame {
    width: 100%; 
    min-height: 940px;
  }
`

const HearingSize = styled.div`
  min-height: 700px;
`

const HearingMain = styled.div`
  min-height: 100vh;
  background: ${WHITE};
  padding-bottom: 50px;
  .row {
    .container-fluid {
      #clementineFrame {
        border: none;
      }
    }
  }
`

const HearingBanner = styled.div`
  padding: 50px 100px;
  @media ${tablet} {
    padding: 50px 40px;
  }
  @media ${mobile} {
    padding: 50px 30px;
  }
`

const HearingContent = styled.h5.attrs({ className: 'typographyV2' })`
  background: ${GREY_12};
  padding-top: 47px;
  padding-bottom: 47px;
  border-radius: 25px;
  color: ${GREY_1};
  padding-left: 49px;
  @media ${mobile} {
    padding-left: 0px;
    text-align: center;
  }
`

const InsuranceHearing = (props) => {
  const { fetchAuthInfo, isLoggedIn, history, userInfo } = props
  useEffect(() => {
    !isLoggedIn && fetchAuthInfo()
    if (document.getElementById('clementineFrame')) {
      const clementineFrame = document.getElementById('clementineFrame').contentWindow
      window.addEventListener('message', function (e) {
        let passed_data = event.data
        if (typeof passed_data === 'string') {
          passed_data = JSON.parse(event.data)
        }
        if (passed_data.type === 'scroll') {
          const top = document.getElementById('clementineFrame_parent').offsetTop - 100
          window.scrollTo({ top: top, left: 500, behavior: 'smooth' })
        }
        if (passed_data.type === 'getparenturl') {
          const pass_data = {
            type: 'passparenturl',
            name: 'passparenturl',
            value: document.URL
          }
          clementineFrame.postMessage(pass_data, '*')
        }
      })
    }
    window.top.scrollTo(0, 0)
  }, [])

  return (
    <Suspense fallback={<LoaderPlaceholder />}>
      <HearingContainer>
      <CheckoutHeader userInfo={userInfo} />
      <HearingSize>
        <HearingMain>
            <HearingBanner>
              <HearingContent>
                    Let’s start your hearing journey below!
              </HearingContent>
            </HearingBanner>
            <div className = "row">
              <div className="container-fluid" id="clementineFrame_parent">
                <iframe id="clementineFrame" src={`${CLEMENTAINEIFRAMEURL}`} >
                </iframe>
              </div>
            </div>
          </HearingMain>
      {
        // !orderFound && <OrderNotFound history={history} />
      }
      </HearingSize>
      <FooterWrapper history={history} />
    </HearingContainer>
    </Suspense>
  )
}

InsuranceHearing.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  match: PropTypes.any,
  userInfo: PropTypes.any,
  cart: PropTypes.any,
  fetchAuthInfo: PropTypes.func,
  isLoggedIn: PropTypes.any
}

const mapStateToProps = state => {
  // TODO: I'll optimize it later
  const userInfo = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    passwordConfirmation: ''
  }

  if (state.currentUser) {
    userInfo.firstName = state.currentUser.firstName || ''
    userInfo.lastName = state.currentUser.lastName || ''
    userInfo.email = state.currentUser.email || ''
    userInfo.phone = state.currentUser.phone || ''
  }
  return {
    userInfo,
    isLoggedIn: state.global.isLoggedIn,
    showHelpDesk: state.feedback.showHelpDesk,
    cart: state.cart
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAuthInfo: () => dispatch(fetchAuthInfo())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InsuranceHearing))
