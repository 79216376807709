import { helpersFn } from '@eargo/eargo-components'
import { SERVER_URL } from '../constant'

export const fetchOrderTracking = orderNumber => {
  const reqUrl = `${SERVER_URL}v2/api/users/order-tracking?order_number=` + orderNumber

  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('GET', reqUrl)
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}
