import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { DotSpinLoading } from '@eargo/eargo-components'
import moment from 'moment'

import { DATE_FORMAT } from '../../../constant'
import { getCaseListDetail } from '../../../actions/support'
import './SupportRequestDetail.scss'

const SupportRequestDetail = () => {
  // single case detail return a single support details
  // In useEffect we are fetching that particular support details were passed in the params
  const dispatch = useDispatch()
  const history = useHistory()
  const [singleCaseDetail, isLoading] = useSelector((state) => [
    state.support.singleCaseDetail,
    state.support.isLoading
  ])
  useEffect(() => {
    const path = history.location.pathname.split(':')[1]
    dispatch(getCaseListDetail({ sf_case_id: path }))
  }, [])

  return (
    <div className='support-request-detail-container' data-testid='support-request-detail'>
      {!!isLoading && <DotSpinLoading />}
      <div className='support-detail-wrapper'>
        <img className='support-detail-prevarrow' src='https://assets.eargo.com/account/support/prev.svg' alt="Prev Svg" onClick={() => history.push('/my-info/support')} />
        <div className='support-detail-holder'>
          <div className='typographyV2 body support-detail-heading'> Support Request</div>
          <h7 className='typographyV2 support-detail-number'>#{singleCaseDetail.number ? singleCaseDetail.number : 'To Be Assigned' }</h7>
        </div>
      </div>
      <div className='support-details-holder'>
        <div className='typographyV2 body support-detail-label'>Subject: <div className='support-detail-data'>{singleCaseDetail.subject}</div></div>
        <div className='typographyV2 body support-detail-label'>Status: <div className='support-detail-data'>{!!singleCaseDetail && !!singleCaseDetail.status && singleCaseDetail.status[0].toUpperCase() + singleCaseDetail.status.slice(1).toLowerCase()}</div></div>
        <div className='typographyV2 body support-detail-label'>Creation Date: <div className='support-detail-data'>{!!singleCaseDetail.case_created_date && moment(singleCaseDetail.case_created_date).format(DATE_FORMAT)}</div></div>
      </div>
      <div className='support-help-wrapper'>
        <div className='typographyV2 append_icon support-help-heading'>HOW CAN WE HELP? </div>
        <div className='typographyV2 body support-subtext'>{!isLoading ? !!singleCaseDetail && singleCaseDetail.description ? singleCaseDetail.description : 'Thank you for contacting us. We have submitted your request and will notify you when your request is complete.' : ''}</div>
      </div>
    </div>
  )
}

export default React.memo(SupportRequestDetail)
