import React, { useEffect } from 'react'
import styled from 'styled-components'
import { withRouter, Switch, Route } from 'react-router-dom'
import Header from '../common/Header.jsx'
import { colorCodes } from '../../constant/colors'
import ActivateAccount from '../activate-my-account/ActivateAccount'
import AccountActivationCode from '../activate-my-account/AccountActivationCode'
import ForgotPassword from '../forgot-password'
import ActivationCode from '../forgot-password/ActivationCode'
import CompletedMessage from '../common/CompletedMessage'
import TrackOrder from '../track_order'
import FooterWrapper from '../common/Footer.js'
import { helpersFn } from '@eargo/eargo-components'
import verifyLoginOtp from '../login-option/verifyLoginOtp.jsx'
import ResendLoginOtp from '../login-option/ResendLoginOtp.jsx'
import CodeRequest from '../login-option/CodeRequest.jsx'
import ResetPasswordMain from '../forms/reset_password/ResetPassword'

const Container = styled.div`
min-height: 100vh;
background: ${colorCodes.WHITE};
`

const RouteWrapper = () => {
  const { pathname } = location
  const pageTitle = {
    '/activate-account': 'Activate Account',
    '/forgot-password': 'Forgot Password',
    '/verify-otp': 'Verify OTP',
    '/track-order': 'Track Order'

  }
  const handleGtm = () => {
    const url = location.pathname.toLowerCase()
    let urlDescription = pageTitle[url]
    if (!urlDescription) {
      urlDescription = /\/track-order\/./.test(url) ? 'Track Order' : /\/activate-account\/./.test(url) ? 'Activate Account' : url
    }
    const gtmOptions = {
      event: 'VirtualPageView',
      virtualPageURL: url,
      virtualPageTitle: urlDescription
    }
    helpersFn.gtmSend(gtmOptions)
  }
  useEffect(() => {
    handleGtm()
  }, [pathname])
  return (
    <>
      <Header history={history} />
      <Container>
        <Switch>
        <Route exact path='/activate-account' component={ActivateAccount} />
        <Route exact path='/activate-account/:id' component={AccountActivationCode} />
        <Route exact path='/forgot-password' component={ForgotPassword} />
        <Route exact path='/verify-otp' component={ActivationCode} />
        <Route exact path='/completed' component={CompletedMessage} />
        <Route path='/track-order' component={TrackOrder} />
        <Route exact path='/login-option' component={CodeRequest} />
        <Route exact path='/verify-login-otp/:id' component={verifyLoginOtp} />
        <Route exact path='/Resend-login-otp' component={ResendLoginOtp} />
        <Route exact path='/change-password' component={ResetPasswordMain} />

        </Switch>
      </Container>
      <FooterWrapper history={history} />
    </>
  )
}

export default withRouter(RouteWrapper)
