import React from 'react'
import PropTypes from 'prop-types'
import { EargoButtonV2 } from '@eargo/eargo-components'

import './create_new_support.scss'
import { createSupportHeading, createSupportSubHeading } from '../../../../constant'
import CommonHeaderSection from '../../SupportSection/SupportHeader/SupportHeader'

const CreateNewSupportTicket = ({ history }) => {
  return (
    <div className='create_new_support_section'>
      <CommonHeaderSection heading={createSupportHeading} subHeading={createSupportSubHeading}
        className='dashboard_support'
      />
      <EargoButtonV2
        className='support_section primary'
        label="Create support request"
        handleOnClick={() => history.push('/my-info/support/form') }
      />
    </div>
  )
}

CreateNewSupportTicket.propTypes = {
  history: PropTypes.Object
}

export default CreateNewSupportTicket
