import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { DotSpinLoading } from '@eargo/eargo-components'

import { checkAndSetAutoOut } from '../../../../actions/auth'
import { isWebPSupported } from '../../../../common/helpers'
import { SET_WIDTH_FOR_TABLET } from '../../../../constant'
import { fetchAuth } from '../../../../util/auth'
import { downloadInvoice } from '../../../../util/orders'

function Invoice ({ sfId }) {
  const dispatch = useDispatch()
  const [invoiceProcess, setInvoiceProcess] = useState(false)
  const [downloadFailed, setDownloadFailed] = useState(false)

  useEffect(() => {
    setDownloadFailed(false)
    setInvoiceProcess(false)
    // eslint-disable-next-line
  }, [])

  const downloadFn = (fileURL, sfOrderId, title) => {
    const link = document.createElement('a')
    link.href = fileURL
    link.id = `super-bill-${sfOrderId}`
    link.setAttribute('download', `${title}.pdf`)
    document.body.appendChild(link)
    link.click()
    setTimeout(() => {
      const element = document.getElementById(`super-bill-${sfOrderId}`)
      !!element && element.parentNode.removeChild(element)
    }, 500)
    // title === 'invoice' ? setInvoiceProcess(false) : setSuperbillProcess(false)
    if (title === 'invoice') setInvoiceProcess(false)
  }

  const downloadInvoiceFn = async (sfOrderId, downloadFile = false) => {
    if (invoiceProcess === sfOrderId) {
      return false
    }
    try {
      setDownloadFailed(false)
      setInvoiceProcess(sfOrderId)
      const response = await fetchAuth()
      if (!!response && response.success && !!response.data && !!response.data.id) {
        const res = await downloadInvoice(sfOrderId)
        if (res) {
          // Create a Blob from the PDF Stream
          const file = new Blob([res], { type: 'application/pdf' })
          const fileURL = URL.createObjectURL(file)

          if (downloadFile) {
            downloadFn(fileURL, sfOrderId, 'invoice')
          } else if ((window.innerWidth > SET_WIDTH_FOR_TABLET) || !!isWebPSupported()) {
            window.open(fileURL, '_blank')
          }
        } else {
          setDownloadFailed(true)
          dispatch(checkAndSetAutoOut(res))
        }
      } else {
        setDownloadFailed(true)
        dispatch(checkAndSetAutoOut({
          success: false,
          code: 'unauthenticated'
        }))
      }
      setInvoiceProcess(false)
    } catch (error) {
      console.log('Invoice Download Error ', error)
      setDownloadFailed(true)
      dispatch(checkAndSetAutoOut(error.response))
      setInvoiceProcess(false)
    }
  }

  return (
    <div className='invoice_container'>
      <a className='invoice interFontBold' target='_self' rel='noopener noreferrer' onClick={() => { downloadInvoiceFn(sfId, true) }}>
        {invoiceProcess && <DotSpinLoading />}
        <img className='invoice_image' src='https://assets.eargo.com/pdp/my_orders/invoice-file.svg' />
        INVOICE
      </a>
      { downloadFailed && <div className='invoice_error'>Download Failed</div> }
    </div>
  )
}

Invoice.propTypes = {
  sfId: PropTypes.string
}

export default Invoice
