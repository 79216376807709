import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { createActiveSupportHeading } from '../../../../constant'
import CommonHeaderSection from '../../SupportSection/SupportHeader/SupportHeader'
import SupportRequestLayout from '../../SupportSection/SupportRequestLayout'

const LatestSupportTicket = ({ caseDetail, history }) => {
  const isLoading = useSelector(state => state.support.isLoading)

  const showSupportTickets = (caseDetail) => {
    if (!caseDetail || !caseDetail.length) {
      return (
        <div className='typographyV2 body center-flex full_height'>No current requests</div>
      )
    }
    return (
      <>
        <SupportRequestLayout caseDetail={[caseDetail[0]]} />
        <div className='typographyV2 body_bold all_request' onClick={() => history.push('/my-info/support') }>All requests</div>
      </>
    )
  }

  return (
    <div className='full_height'>
      <CommonHeaderSection heading={createActiveSupportHeading} isSubHeading={false} isBorderBottom={true}
        className='dashboard_support latest_support_active'
      />
      {!isLoading && showSupportTickets(caseDetail)}
    </div>
  )
}

LatestSupportTicket.propTypes = {
  caseDetail: PropTypes.arrayOf(Object),
  history: PropTypes.Object
}

export default LatestSupportTicket
