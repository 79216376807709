import * as ShipmentApiUtil from '../util/shipment_api_util'

export const RECEIVE_SHIPMENT_TYPES = 'RECEIVE_SHIPMENT_TYPES'

export const receiveShipmentTypes = shipment_types => {
  return {
    type: RECEIVE_SHIPMENT_TYPES,
    shipment_types
  }
}

export const fetchShippingTypes = order_id => dispatch => (
  ShipmentApiUtil.getShipmentTypes(order_id).then(res => dispatch(receiveShipmentTypes(res)))
)
