import {
  DELETE_PAYMENT_SUCCESS,
  EDIT_PAYMENT_SUCCESS,
  FETCH_PAYMENTS_ERROR,
  FETCH_PAYMENTS_SUCCESS,
  HANDLE_PAYMENT_PROCESSING,
  SET_DEFAULT_PAYMENT_ID,
  REMOVE_PAYMENT_ERROR_MSG,
  SET_CYBERSOURCE_KEY,
  SET_CYBERSOURCE_KEY_STATUS,
  SET_IS_CBS,
  SET_LOADED_METHOD
} from '../actions/info_payment_method'

const defaultState = {
  isLoading: false,
  cyberSourceKey: false,
  cyberSourceKeyStatus: false,
  methods: [],
  methodLoaded: false,
  isCBS: false,
  total: 0,
  errMsg: {}
}

const PaymentMethod = (state = defaultState, action) => {
  switch (action.type) {
    case HANDLE_PAYMENT_PROCESSING:
    case FETCH_PAYMENTS_SUCCESS:
    case FETCH_PAYMENTS_ERROR:
    case SET_CYBERSOURCE_KEY:
    case SET_CYBERSOURCE_KEY_STATUS:
    case SET_IS_CBS:
    case SET_LOADED_METHOD:
      return {
        ...state,
        ...action.payload
      }
    // HINT: For now not in use
    case EDIT_PAYMENT_SUCCESS: {
      let methods = state.methods
      const { paymentId, method } = action.payload
      methods = methods.map(a => {
        if (a.id === paymentId) {
          a = method
        }
        return a
      })
      return {
        ...state,
        methods: methods
      }
    }
    case REMOVE_PAYMENT_ERROR_MSG: {
      const errMessages = state.errMsg
      if (!!errMessages && !!errMessages.otherError) {
        delete errMessages.otherError
      }
      if (!!action.fieldName && !!errMessages && !!errMessages[action.fieldName]) {
        delete errMessages[action.fieldName]
      }
      return {
        ...state,
        ...errMessages
      }
    }
    case DELETE_PAYMENT_SUCCESS:
      return {
        ...state,
        methods: state.methods.filter(a => a.id !== action.id)
      }
    case SET_DEFAULT_PAYMENT_ID: {
      const methodArray = state.methods
      const updatedMethod = !!methodArray && !!methodArray.length && methodArray.map(value => value.id === action.defaultId ? { ...value, default: true } : { ...value, default: false })
      return {
        ...state,
        methods: updatedMethod
      }
    }
    default:
      return state
  }
}

export default PaymentMethod
