import React from 'react'
import PropTypes from 'prop-types'
// import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { cardExpiryFormat } from '../../../common/helpers'
import './ReduxNumberInput.scss'

const ElementWrapper = ({ label, children, labelClass, isValue }) => (
  <label className={`card-containers inputContainer cardholder-name ${labelClass}`}>
    <h1 className={`typographyV2 ${isValue ? 'floating_label' : 'label'}`}>
      {label}
    </h1>
    {children}
  </label>
)

ElementWrapper.propTypes = {
  label: PropTypes.any,
  children: PropTypes.any,
  labelClass: PropTypes.any,
  isValue: PropTypes.any
}

const ReduxNumberInput = ({
  input, label, errResp, expError,
  meta: { touched, error, active, visited }
}) => (
  <ElementWrapper label={(((!active && (visited || touched)) && error) || (!!errResp || !!expError)) ? (error || (errResp || expError)) : label}
    isValue={active || (!!input.value)}
    labelClass={`primary-input-label redux-number-input ${(((!active && (visited || touched)) && error) || (!!errResp || !!expError)) ? 'error_border' : ''}`}
  >
    <NumberFormat
      className="redux_number_format_input"
      format={cardExpiryFormat}
      {...input}
    />
  </ElementWrapper>
)

ReduxNumberInput.propTypes = {
  input: PropTypes.any,
  label: PropTypes.any,
  errResp: PropTypes.any,
  expError: PropTypes.any,
  meta: PropTypes.any
}

export default ReduxNumberInput
