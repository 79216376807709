import React from 'react'
import PropTypes from 'prop-types'
import { colorCodes } from '../../constant/colors'

const Feedback = ({
  style = {},
  fill = colorCodes.BLACK,
  width = 35,
  height = 35,
  className = '',
  onClick,
  viewBox = '-51 0 512 512'
}) => (
    <svg
      fill={fill}
      style={style}
      height={height}
      viewBox={viewBox}
      width={width}
      onClick={() => !!onClick && onClick}
      className={className}
      data-testid='jest-feedback-id'
    >
      <path d="m401.5 124.066406c4.144531 0 7.5-3.359375 7.5-7.5v-71.066406c0-14.335938-11.664062-26-26-26h-98.074219c-2.078125-11.085938-11.828125-19.5-23.507812-19.5h-113.835938c-11.679687 0-21.429687 8.414062-23.507812 19.5h-98.074219c-14.335938 0-26 11.664062-26 26v440.5c0 14.335938 11.664062 26 26 26h357c14.335938 0 26-11.664062 26-26v-341.433594c0-4.144531-3.355469-7.5-7.5-7.5s-7.5 3.355469-7.5 7.5v341.433594c0 6.066406-4.933594 11-11 11h-357c-6.066406 0-11-4.933594-11-11v-440.5c0-6.066406 4.933594-11 11-11h97.667969c.5625 7.449219-1.867188 15.214844 4.132812 24.023438h-81.300781c-4.144531 0-7.5 3.359374-7.5 7.5v194.726562c0 4.140625 3.355469 7.5 7.5 7.5s7.5-3.359375 7.5-7.5v-187.226562h112.683594c3.101562 22.480468 24.796875 37.003906 46.433594 31.996093 15.289062-3.542969 27.007812-16.109375 29.199218-31.996093h112.683594v384.453124h-301v-169.226562c0-4.140625-3.355469-7.5-7.5-7.5s-7.5 3.359375-7.5 7.5v176.726562c0 4.140626 3.355469 7.5 7.5 7.5h316c4.144531 0 7.5-3.359374 7.5-7.5v-399.453124c0-4.140626-3.355469-7.5-7.5-7.5h-81.300781c5.984375-8.789063 3.578125-16.667969 4.132812-24.023438h97.667969c6.066406 0 11 4.933594 11 11v71.066406c0 4.140625 3.355469 7.5 7.5 7.5zm-220.167969-55.734375v-6.820312c0-4.140625-3.355469-7.5-7.5-7.5h-26.25c-4.914062 0-8.914062-4-8.914062-8.914063v-21.183594c0-4.914062 4-8.914062 8.914062-8.914062h113.835938c4.914062 0 8.914062 4 8.914062 8.914062v21.183594c0 4.914063-4 8.914063-8.914062 8.914063h-26.25c-4.144531 0-7.5 3.359375-7.5 7.5v6.820312c0 12.773438-10.394531 23.167969-23.167969 23.167969s-23.167969-10.394531-23.167969-23.167969zm0 0" />
      <path d="m105.953125 200.261719h33.761719c11.976562 0 21.71875-9.742188 21.71875-21.714844v-33.765625c0-11.972656-9.742188-21.714844-21.71875-21.714844h-33.761719c-11.976563 0-21.71875 9.742188-21.71875 21.714844v33.765625c0 11.972656 9.742187 21.714844 21.71875 21.714844zm-6.71875-55.480469c0-3.703125 3.011719-6.714844 6.71875-6.714844h33.761719c3.703125 0 6.71875 3.011719 6.71875 6.714844v33.765625c0 3.703125-3.015625 6.714844-6.71875 6.714844h-33.761719c-3.707031 0-6.71875-3.011719-6.71875-6.714844zm0 0" />
      <path d="m187.265625 169.164062h130c4.140625 0 7.5-3.359374 7.5-7.5 0-4.140624-3.359375-7.5-7.5-7.5h-130c-4.144531 0-7.5 3.359376-7.5 7.5 0 4.140626 3.355469 7.5 7.5 7.5zm0 0" />
      <path d="m84.234375 282.632812c0 11.972657 9.742187 21.714844 21.71875 21.714844h33.761719c11.976562 0 21.71875-9.742187 21.71875-21.714844v-33.765624c0-11.972657-9.742188-21.714844-21.71875-21.714844h-33.761719c-11.976563 0-21.71875 9.742187-21.71875 21.714844zm15-33.765624c0-3.703126 3.011719-6.714844 6.71875-6.714844h33.761719c3.703125 0 6.71875 3.011718 6.71875 6.714844v33.765624c0 3.703126-3.015625 6.714844-6.71875 6.714844h-33.761719c-3.707031 0-6.71875-3.011718-6.71875-6.714844zm0 0" />
      <path d="m187.265625 273.25h130c4.140625 0 7.5-3.359375 7.5-7.5s-3.359375-7.5-7.5-7.5h-130c-4.144531 0-7.5 3.359375-7.5 7.5s3.355469 7.5 7.5 7.5zm0 0" />
      <path d="m84.234375 386.71875c0 11.972656 9.742187 21.714844 21.71875 21.714844h33.761719c11.976562 0 21.71875-9.742188 21.71875-21.714844v-33.765625c0-11.972656-9.742188-21.714844-21.71875-21.714844h-33.761719c-11.976563 0-21.71875 9.742188-21.71875 21.714844zm15-33.765625c0-3.703125 3.011719-6.714844 6.71875-6.714844h33.761719c3.703125 0 6.71875 3.011719 6.71875 6.714844v33.765625c0 3.703125-3.015625 6.714844-6.71875 6.714844h-33.761719c-3.707031 0-6.71875-3.011719-6.71875-6.714844zm0 0" />
      <path d="m187.265625 377.335938h130c4.140625 0 7.5-3.359376 7.5-7.5 0-4.140626-3.359375-7.5-7.5-7.5h-130c-4.144531 0-7.5 3.359374-7.5 7.5 0 4.140624 3.355469 7.5 7.5 7.5zm0 0" />
    </svg>
)

Feedback.propTypes = {
  style: PropTypes.any,
  fill: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  viewBox: PropTypes.string
}

export default Feedback
