import React from 'react'
import PropTypes from 'prop-types'

import { EargoButtonV2 } from '@eargo/eargo-components'
import { useHistory } from 'react-router-dom'

import PopUp from '../PopUp'
import './ThankYouPopup.scss'

const ThankYouPopup = ({ isContact, setResp }) => {
  const history = useHistory()

  return (
    <div className='thankyou-popup-container' data-testid='support-thankyou-popup'>
      <PopUp topDeskPadding={true} handleClose={() => setResp(false)} height={window.innerWidth > 599 ? 350 : 420}>
        <h7 className='typographyV2 thankyou-popup-heading'>THANK YOU</h7>
        <div className='typographyV2 body thankyou-popup-bodytext'>Thanks for submitting your support request.  Your message has been received and will be answered by a Customer Care Specialist as soon as possible.</div>
        <div className='typographyV2 body thankyou-popup-responsetext'>You can expect a response by: </div>
        <div className='typographyV2 body_light thankyou-popup-contactname'><img className={`thankyou-popup-contacticon ${isContact === 'Phone' ? 'phone' : ''}`} src={isContact === 'Phone' ? 'https://assets.eargo.com/account/support/contact.svg' : 'https://assets.eargo.com/account/support/email.svg'} alt="contact image" /> {isContact === 'Phone' ? 'Phone' : 'Email'} </div>
        <div className='thankyou-popup-buttonholder'>
          <EargoButtonV2
            className='primary bold stretch thankyou-popup-okbutton'
            label="OK"
            handleOnClick={() => history.push('/my-info/support')}
          />
        </div>
      </PopUp>
    </div>
  )
}

ThankYouPopup.propTypes = {
  isContact: PropTypes.any,
  setResp: PropTypes.func
}

export default ThankYouPopup
