
import React, { useState, useEffect } from 'react'
import DashboardBanner from '../DashboardBanner'
import { fetchOrientationBannerData } from '../../../../util/orders.js'
import moment from 'moment'
import momentTimeZone from 'moment-timezone'
const OrientationBanner = () => {
  const [orientationCallData, setOrientationCallData] = useState({})

  const getOrientationBannerDetails = async () => {
    try {
      const resp = await fetchOrientationBannerData()
      if (!!resp && !!resp.data) {
        resp.data.isWelcomeCallEligible ? setOrientationCallData(resp.data) : setOrientationCallData({})
      } else {
        setOrientationCallData({})
      }
    } catch (error) {
      console.log('Failure get Banner', error)
      setOrientationCallData({})
    }
  }
  const convertTimeZone = (time) => {
    return moment(time, 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'UTC').tz(momentTimeZone.tz.guess()).format('h:mm A z')
  }
  const formatDate = (date) => {
    return moment(date).format('MMMM D, YYYY')
  }
  useEffect(() => {
    getOrientationBannerDetails()
  }, [])

  if (Object.keys(orientationCallData).length) {
    if (orientationCallData.scheduler_url) {
      return <DashboardBanner action='link' link= {orientationCallData.scheduler_url} icon='calendar' copy={`Congrats on your new ${orientationCallData.productName} purchase! <br />Schedule your orientation call with Eargo Support.`} buttonLabel='Schedule your call' openInNewTab = {true} customCss = 'limit-width'/>
    } else if (orientationCallData.scheduled_datetime) {
      return <DashboardBanner action='call' icon='calendar' copy={`Your orientation call has been booked for ${formatDate(orientationCallData.scheduled_datetime)} - ${convertTimeZone(orientationCallData.scheduled_datetime)}`} phoneNumber = '1 (800) 903-6883' />
    } else return null
  } else {
    return null
  }
}

export default OrientationBanner
