import
{ YOTPO_KEY } from './constant'

export const loadYotpo = (yotpoKey = YOTPO_KEY) => {
  if (window.yotpo) {
    return true
  }

  const e = document.createElement('script')
  e.type = 'text/javascript'
  e.defer = true
  e.id = 'yotpo-js'
  e.async = !0
  // e.crossOrigin = 1;
  e.src = `//staticw2.yotpo.com/${yotpoKey}/widget.js`
  const t = document.getElementsByTagName('script')[0]
  t.parentNode.insertBefore(e, t)
}

// export const loadNsgSeal = () => {
//   const body = document.getElementsByTagName('body')[0]
//   const e = document.createElement('script')
//   e.type = 'text/javascript'
//   e.defer = true
//   e.async = !0
//   // e.crossOrigin = 1;
//   e.src = '//nsg.symantec.com/Web/Seal/gjs.aspx?SN=962895532'
//   body.appendChild(e)
// }

// export const loadNsgGurantee = (ordernumber, shipmentTotal, email) => {
//   const body = document.getElementsByTagName('body')[0]
//   const script1 = document.createElement('script')
//   script1.type = 'text/javascript'
//   // script1.defer = true;
//   script1.async = !0
//   // script1.crossOrigin = 1
//   script1.src = '//nsg.symantec.com/Web/Seal/gjs.aspx?SN=962895532'

//   const script2 = document.createElement('script')
//   script2.type = 'text/javascript'
//   script2.rel = 'preload'
//   // script2.crossOrigin = 1;
//   script2.defer = true
//   const text = `if (window._GUARANTEE && _GUARANTEE.Loaded) {
//                 _GUARANTEE.Guarantee.order    = '${ordernumber}';
//                 _GUARANTEE.Guarantee.subtotal = '${shipmentTotal}';
//                 _GUARANTEE.Guarantee.currency = 'USD';
//                 _GUARANTEE.Guarantee.email    = '${email}';
//                 _GUARANTEE.WriteGuarantee();
//                }`
//   const textNode = document.createTextNode(text)
//   script2.appendChild(textNode)
//   body.appendChild(script1)
//   body.appendChild(script2)
// }

/**
 * Set Tracking cookie
 */
export const setTrackingCookie = () => {
  const regTrackingUrl = /Track_URL=/ig
  const today = new Date()
  const numberOfDaysToAdd = 30
  today.setDate(today.getDate() + numberOfDaysToAdd)

  if (!regTrackingUrl.test(document.cookie)) {
    document.cookie = 'online_track_url=' + escape(window.location.href) + '; expires=' + today + '; path=/' + '; domain=.eargo.com'
  }
}

export const convertStringToAmount = string => parseFloat(string, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
