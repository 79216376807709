import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { parse } from 'query-string'
import { EargoButtonV2, DotSpinLoading, HeaderSection, helpersFn, AuthWrapper, Otp, AdditionalInfoSection, CommonUnderline } from '@eargo/eargo-components'
import usePrevious from '../../common/usePrevious'
import MessageContainer from '../common/MessageContainer'
import { fetchAuth, fetchForgotPassword, resendOTPRequest } from '../../util/auth'
import { SERVER_URL } from '../../constant'

import './index.scss'

/**
 * Validate OTP
 * @param {*} reqObj
 */
const validateOTP = reqObj => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('POST', `${SERVER_URL}v2/api/password/validate-otp`, JSON.stringify(reqObj))
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

const ActivationCode = ({ location, history }) => {
  const [otp, setOtp] = useState('')
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({})
  const [expireMsg, setExpireMsg] = useState(false)
  const [successMsg, setSuccessMsg] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const preventSubmit = !!Object.keys(errors).length || !otp || otp.length < 4
  const otherErrWindow = !!errors && !!errors.otherError
  const queryParams = parse(window.location.search)
  const isExpired = !!queryParams && !!queryParams.passwordExpired
  const titleVal = isExpired ? 'Your Password Has Expired!' : 'Verify Code'
  const prevSearch = usePrevious(location.search)

  useEffect(() => {
    checkIfPageValid()
  }, [])

  useEffect(() => {
    const { search } = location
    if (prevSearch !== search) {
      const queryParams = parse(search)
      if (queryParams.req_id) {
        fetchForgotPasswordInfo(queryParams.req_id)
      }
    }
  }, [prevSearch])

  const checkIfPageValid = () => {
    const queryParams = parse(window.location.search)
    if (!queryParams.req_id) {
      (!!queryParams && !!queryParams.redirect_uri)
        ? history.push('/forgot-password?redirect_uri=' + queryParams.redirect_uri)
        : history.push('/forgot-password')
    } else {
      fetchAuthInfo()
    }
  }

  const fetchForgotPasswordInfo = async (id) => {
    let email = false
    let expireMsg = 'You\'ll be redirect now, your request expired. Please make a new one.'
    try {
      const response = await fetchForgotPassword(id)
      const queryParams = parse(window.location.search)
      if (!!response && !!response.success && !!response.email) {
        email = response.email
        expireMsg = false
      } else {
        setTimeout(() => {
          (!!queryParams && !!queryParams.redirect_uri)
            ? history.push('/forgot-password?redirect_uri=' + queryParams.redirect_uri)
            : history.push('/')
        }, 2000)
      }
    } catch (error) {
      console.log('Fetch ForgotPasswordInfo Error: ', error)
    }
    setEmail(email)
    setExpireMsg(expireMsg)
  }

  const fetchAuthInfo = async () => {
    try {
      const response = await fetchAuth()
      const queryParams = parse(window.location.search)
      if (!!response && !!response.data && !!response.data.id) {
        if (!!queryParams && !!queryParams.redirect_uri) {
          window.location.href = queryParams.redirect_uri
        } else {
          history.push('/')
        }
      } else {
        !!queryParams.req_id && fetchForgotPasswordInfo(queryParams.req_id)
        setEmail(queryParams.email ? queryParams.email : false)
      }
    } catch (error) {
      console.log('Fetch Auth Error: ', error)
    }
  }

  const validateForm = () => {
    const errors = {}
    let formIsValid = true
    if (!otp) {
      formIsValid = false
      errors.otp = 'Please enter your code'
    }
    setErrors(errors)
    setSuccessMsg(successMsg)
    return formIsValid
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const errors = {}
    const { search } = location
    setIsProcessing(true)
    const queryParams = parse(window.location.search)
    const req_id = !!queryParams && !!queryParams.req_id ? queryParams.req_id : false
    if (validateForm()) {
      try {
        const resp = await validateOTP({ email, otp, req_id })
        if (!!resp && !!resp.success) {
          history.push({
            pathname: '/change-password',
            search,
            state: { email, otp }
          })
        } else {
          if (!!resp && !!resp.errorCode && resp.errorCode === 'request_expired') {
            history.push('/forgot-password?redirect_uri=' + queryParams.redirect_uri + '&email=' + encodeURI(email))
          } else {
            errors.otherError = resp.error ? resp.error : 'Verification failed. Please try again'
          }
        }
      } catch (error) {
        const errorBag = !!error.responseJSON && !!error.responseJSON.error
          ? error.responseJSON.error
          : (
              !!error.responseJSON && !!error.responseJSON[0] ? error.responseJSON[0] : 'Something went wrong, Please try again.'
            )
        errors.otherError = errorBag
      }
    }
    setErrors(errors)
    setIsProcessing(false)
    setSuccessMsg(false)
  }

  const resendOTP = async () => {
    setIsProcessing(true)
    setOtp('')
    const errors = {}
    let successMsg = false
    try {
      const queryParams = parse(window.location.search)
      const req_id = !!queryParams && !!queryParams.req_id ? queryParams.req_id : false
      const resp = await resendOTPRequest({ email, req_id })
      if (!!resp && !!resp.type && resp.type === 'success') {
        successMsg = 'Activation code sent successfully'
      } else if (!!resp && !!resp.errorCode && resp.errorCode === 'request_expired') {
        errors.otherError = 'You\'ll be redirect now, your request expired. Please make a new one.'
        setTimeout(() => {
          (!!queryParams && !!queryParams.redirect_uri)
            ? history.push('/forgot-password?redirect_uri=' + queryParams.redirect_uri)
            : history.push('/')
        }, 2000)
      } else {
        const errorBag = !!resp && !!resp.error ? resp.error : (!!resp && !!resp[0] ? resp[0] : resp.message)
        errors.otherError = errorBag
      }
    } catch (error) {
      const errorBag = !!error.responseJSON && !!error.responseJSON.error
        ? error.responseJSON.error
        : (
            !!error.responseJSON && !!error.responseJSON[0] ? error.responseJSON[0] : 'Something went wrong, Please try again.'
          )
      errors.otherError = errorBag
    }
    setErrors(errors)
    setIsProcessing(false)
    setSuccessMsg(successMsg)
  }

  return (
    <AuthWrapper>
      <div className="center-flex activation_code_container" id="eargo-activation-code-container">
        <HeaderSection heading={titleVal} label='Please enter the code we have sent to:' email={email}/>
        {
          expireMsg && <div>{expireMsg}</div>
        }
        {!!isProcessing && <DotSpinLoading />}
        <form className='form_container' onSubmit={handleSubmit}>
          <Otp setOtp={(value) => { setOtp(value); setErrors({}) }} otp={otp} />
          {!!otherErrWindow && <div className='message_container'><MessageContainer className="pt-12" messageList={errors.otherError} /></div>}
          {!!successMsg && <div className='message_container'><MessageContainer messageList={successMsg} isError={false} /></div>}
          <div>
            <EargoButtonV2
              label={isProcessing ? 'Loading...' : 'Reset Password'}
              type="submit"
              disabled={preventSubmit}
              className={`primary ${preventSubmit ? 'disabled' : ''}`} />
          </div>
          <AdditionalInfoSection heading='Didn’t receive a code?' subHeading='Please allow 3 minutes to receive the code' label='Resend the code' handleOnClick={resendOTP} />
          <CommonUnderline />
          <div className='body_bold typographyV2 back_to_login_tag' onClick={() => history.push('/login')}>
            Back to login
          </div>
        </form>
      </div>
      </AuthWrapper>
  )
}

ActivationCode.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
}

export default withRouter(ActivationCode)
