import * as VariantApiUtil from '../util/variant_api_util'

export const RECIEVE_CATEGORIES = 'RECIEVE_CATEGORIES'
export const RECEIVE_VARIANTS = 'RECEIVE_VARIANTS'
export const RECEIVE_VARIANT = 'RECEIVE_VARIANT'
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const MAKE_PRODUCTS_NULL = 'MAKE_PRODUCTS_NULL'

export const recieveCategory = categories => {
  return {
    type: RECIEVE_CATEGORIES,
    payload: {
      categories
    }
  }
}

export const receiveVariant = variant => {
  return {
    type: RECEIVE_VARIANT,
    payload: {
      variant
    }
  }
}

export const receiveVariants = variants => {
  return {
    type: RECEIVE_VARIANTS,
    payload: {
      variants
    }
  }
}

export const getProducts = id => {
  return {
    type: GET_PRODUCTS,
    id
  }
}

export const makeVariantNull = () => {
  return {
    type: MAKE_PRODUCTS_NULL
  }
}

export const fetchCategories = () => dispatch => {
  VariantApiUtil.fetchCategories().then(res => {
    dispatch(recieveCategory(res.data))
  }
  ).catch(err => console.log('Error in fetchVariant', err))
}

export const fetchVariant = (id) => dispatch => (
  VariantApiUtil.fetchVariantNew(id)
    .then(res => {
      dispatch(receiveVariant(res))
      return res
    })
    .catch(err => console.log(err))
)

export const fetchVariants = (id = '', heading) => dispatch => (
  VariantApiUtil.fetchVariants(id, heading)
    .then(res => {
      let newArray = Array.isArray(res.data) ? res.data : [res.data]
      newArray = newArray.map(i => {
        return {
          ...i, childCategories: !!i && !!i.childCategories ? i.childCategories.filter(j => { return j.products.length > 0 && j }) : []
        }
      })
      dispatch(receiveVariants(newArray))
      return res
    })
    .catch(err => console.log(err))
)
