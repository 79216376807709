import React from 'react'
import PropTypes from 'prop-types'
import './MessageContainer.scss'

const MessageContainer = ({ messageList, className = '', isError = true }) => (
  <div className= {`err_regular typographyV2 msg_container ${className} ${isError ? 'error' : 'success'}`}>
    {typeof messageList === 'string'
      ? messageList
      : !!messageList && messageList.length > 0 && messageList.map((message, index) => <p key={index}>{message}</p>)}
  </div>
)

MessageContainer.propTypes = {
  className: PropTypes.string,
  messageList: PropTypes.any,
  isError: PropTypes.bool
}

export default MessageContainer
