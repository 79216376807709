import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { RoundRadioInput } from '@eargo/eargo-components'

import EditAddressForm from '../EditAddressForm'
import { setDefaultAddress } from '../../../actions/user_address'
import { getStateAbbr } from '../../../common/helpers'
import { disabledOtherSection } from '../../../actions/global'
import './RenderAddresses.scss'

const RenderAddresses = ({ showAllAddress, showAddresses, setAnotherEdit, setDeleteAddress, setEditId, editAddress, setEditAddress }) => {
  const dispatch = useDispatch()
  const states = useSelector(state => state.address.states)
  const addresses = useSelector(state => state.address.addresses)
  const isDisabled = useSelector(state => state.global.isDisabled)
  // This method is use to make smooth scroll behaviour
  const scrollToAddresses = (editAddressId) => {
    setTimeout(() => {
      const element = !!editAddressId && document.getElementById('eargo-address-detail-' + editAddressId)
      !!element && element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
    }, 100)
  }
  // Here set no. of addresses in addresses array
  const addressCount = addresses.length
  return <div>
    {showAddresses.map((add, i) => (
      <div className='render_address_wrapper' key={i}>
      {editAddress !== add.id
        ? <div className={`render_address_container ${!!showAllAddress && i !== 0 ? 'isCheck' : ''}`} id={'eargo-address-detail-' + add.id}>
          <div className='render_address_details_holder'>
            <label className="typographyV2 body render_address_label eargo-capitalize">{add.firstname + ' ' + add.lastname}</label >
            {!!add && !!add.address1 && <label className='typographyV2 body render_address_label'>{add.address1}</label >}
            {!!add && !!add.address2 && <label className='typographyV2 body render_address_label'>{add.address2}</label >}
            <label className='typographyV2 body render_address_label'>{!!add.city && [(add.city)].concat(', ')} {[getStateAbbr(states, add.state_id), add.zipcode].join(', ')}</label>
            <div className={`render_address_default_field ${addressCount > 1 ? 'isVisible' : ''}`}>
              {(!!addressCount && (addressCount > 1)) && <RoundRadioInput
              className=''
              label={add.default ? 'Default' : 'Select as default'}
              value={i}
              checked={!!add.default}
              id='my-shipping'
              name='my-shipping'
              handleOnChange={() => dispatch(setDefaultAddress(add.id))}
              />}
            </div>
          </div>
          <div className={`render_address_edit_section_line ${showAllAddress ? 'isCheck' : ''}`}>
            <div className='render_address_edit_section'>
              <div className={`typographyV2 body render_address_edit_delete_button ${isDisabled ? 'isDisabled' : ''}`} onClick={() => {
                dispatch(disabledOtherSection(true))
                setAnotherEdit(add.id)
              }}>Edit</div>
              {(!!addressCount && (addressCount > 1)) && <div className={`typographyV2 body render_address_edit_delete_button ${isDisabled ? 'isDisabled' : ''}`} onClick={() => setDeleteAddress(add.id)}>Delete</div>}
            </div>
          </div>
        </div>
        : <div className='render_address_edit_account_section'>
            <EditAddressForm addressId={editAddress}
              handleOnCancel={() => {
                setEditAddress(false)
                setEditId(false)
                scrollToAddresses(editAddress)
              }} />
        </div>
      }
      </div>
    ))
  }
  </div>
}

RenderAddresses.propTypes = {
  showAllAddress: PropTypes.bool,
  showAddresses: PropTypes.array || PropTypes.object,
  setAnotherEdit: PropTypes.func,
  setDeleteAddress: PropTypes.func,
  setEditId: PropTypes.func,
  editAddress: PropTypes.any,
  setEditAddress: PropTypes.func
}

export default RenderAddresses
