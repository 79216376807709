export const RECEIVE_REVIEW_CREDIT_CARD_ERROR = 'RECEIVE_REVIEW_CREDIT_CARD_ERROR'
export const REMOVE_ALL_REVIEW_ERRORS = 'REMOVE_ALL_REVIEW_ERRORS'

export const receiveReviewCreditCardError = error => ({
  type: RECEIVE_REVIEW_CREDIT_CARD_ERROR,
  error
})

export const removeAllReviewErrors = () => ({
  type: REMOVE_ALL_REVIEW_ERRORS
})
