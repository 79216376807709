import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import './support-tabs.scss'
import { useSelector } from 'react-redux'

const SupportTabs = ({ setSelectedOptions }) => {
  const isAllowed = useSelector((state) => state.support.isAllowed)
  const lineItem = useSelector(state => state.support.lineItems)
  const [isEargoMax, setIsEargoMax] = useState(false)
  const [currentTab, setCurrentTab] = useState(1)

  useEffect(() => {
    if (isAllowed) {
      setCurrentTab(0)
    }
  }, [isAllowed])

  useEffect(() => {
    if (lineItem.length === 1 && lineItem[0].product_name === 'Eargo Max') {
      setIsEargoMax(true)
    }
  }, [lineItem])
  const supportTabList = [
    { title: 'Support requests', show: isAllowed },
    { title: 'Video guides', show: true },
    { title: 'Download app', show: isAllowed, isEargoMax: isEargoMax },
    { title: 'User guides', show: isAllowed }
  ]

  return (
    <div className='support_navbar'>
      {supportTabList.map((list, j) => (
        list.show && !list?.isEargoMax && <div className='support_all_link' key={j} onClick={() => {
          setCurrentTab(j)
          setSelectedOptions(list.title)
        }}>
          <div className={`typographyV2 append_icon support_heading ${currentTab === j ? 'navbar_border_bottom' : ''}`}>{list.title}</div>
        </div>
      ))}
    </div>
  )
}

SupportTabs.propTypes = {
  setSelectedOptions: PropTypes.func
}

export default SupportTabs
