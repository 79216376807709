import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { devices } from '../../../../../constant/devices'
import { getSkuImage } from '../../../../../common/helpers'
import { colorCodes } from '../../../../../constant/colors'
import { convertStringToAmount } from '../../../../../general_methods'

const { tablet, mobile } = devices
const { GREY_1, GREY_2 } = colorCodes

const Container = styled.div`
  margin-bottom: 43px;
  display: flex;
  align-items: center;
  position: relative;
  @media ${tablet} {
    slign-items: unset;
  }
`

const ThreeSection = styled.div`
  display: flex;
  width: 80%;
  @media ${tablet} {
    width: 64%;
  }
  @media ${mobile} {
    width: ${(props) => props.mobileWidth < 380 ? '100%' : '70%'}
  }
`

const TowSection = styled.div`
  width: 100%;
  margin-left: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${tablet} {
    align-items: unset;
    flex-direction: column;
  }
`

const SingleSection = styled.p`
  min-width: 110px;
  margin-left: 30px;
  color: ${GREY_2};
  @media ${tablet} {
    margin-left: 0px;
    margin-top: 8px;
    display: contents;
  }
`

const ProductImage = styled.img`
  width: 90px;
  height: 90px;
  @media ${tablet} {
    height: 50px;
    width: 50px;
  }
  @media ${mobile} {
    height: 89px;
    width: 89px;
  }
`

const NameQty = styled.div`
  display: flex;
  flex-direction: column;
  div {
    &:last-child {
      color: ${GREY_2};
      font-weight: 400;
      font-size: 12px;
      line-height: 176%;
    }
  }
`

const PriceSection = styled.div`
  position: absolute;
  right: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 148%;
  color: ${GREY_1};
  @media ${tablet} {
    top: 0;
    font-size: 16px;
    line-height: 152%;
  }
`

const ProductName = styled.p`
  color: ${GREY_1};
  margin-bottom: ${(props) => props.mobileWidth < 380 ? '0px' : '3px'};
  line-height: 148%;
  @media ${mobile} {
    font-size: 16px;
  }
`

const MobileViewProductPrice = styled.p`
line-height: 148%;
color: ${GREY_1};
@media ${tablet} {
  font-size: 16px;
  line-height: 152%;
}
`

const ProductQty = styled.p``

const ProductList = ({ lineItems, order_channel }) => {
  const [mobileWidth, setMobileWidth] = useState(window.innerWidth)

  window.onresize = function () {
    setMobileWidth(window.document.body.clientWidth)
  }

  return (
    <div className="products RoundedCorner interFontRegular">
      {lineItems &&
        lineItems.map((data, index) => {
          const { quantity, name, price, sku } = data
          return (
            <Container key={index}>
              <ThreeSection mobileWidth={mobileWidth}>
                <ProductImage className="product_icon" src={getSkuImage(sku)} />
                <TowSection>
                  <NameQty>
                    <ProductName mobileWidth={mobileWidth} className="typographyV2 body_bold">{name}</ProductName>
                    { (order_channel === 'Eargo' && mobileWidth < 380) &&
                    <MobileViewProductPrice className="typographyV2 body_bold tesst2">
                    $
                    {convertStringToAmount(
                      Math.abs((quantity * price), 10).toFixed(2)
                    )}
                    </MobileViewProductPrice>
                     }
                    <ProductQty className="typographyV2 caption quentity_container">
                      Qty: {quantity}
                      {order_channel === 'Eargo' && (<>X ${convertStringToAmount(Math.abs(parseFloat(price, 10)).toFixed(2))}</>)}
                    </ProductQty>
                  </NameQty>
                  <SingleSection className='typographyV2 body'>
                    Item {index + 1} of {lineItems.length}
                  </SingleSection>
                </TowSection>
              </ThreeSection>
              { (order_channel === 'Eargo' && mobileWidth > 380) &&
              <PriceSection className="interFontBold">
                $
                {convertStringToAmount(
                  Math.abs((quantity * price), 10).toFixed(2)
                )}
              </PriceSection>
              }
            </Container>
          )
        })}
    </div>
  )
}

ProductList.propTypes = {
  lineItems: PropTypes.array,
  order_channel: PropTypes.string
}

export default ProductList
