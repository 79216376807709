import React, { Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import { ROUTES, TOASTER_AUTOCLOSE, addressUrl } from '../constant'
import { detectIsMobile, setActivePopUpStatus, checkForUser } from '../actions/global'
import { handleAjax, isJ2Supported, handlecallRail, removeElement } from '../common/helpers'
import { getPaymentMethods } from '../actions/payment_methods_actions'
import { fetchCart } from '../actions/cart_actions'
import { removeCouponError } from '../actions/error_actions'
import Wrapper from './wrapper'
import LoaderPlaceholder from './common/LoaderPlaceholder'
import MyInfo from '../components/my_info'
import RouteWrapper from './RouteWrapper'
import LoginNew from './login/login_new'
import InsuranceHearing from './confirmation/InsuranceHeraing'
import { fetchHeaderJson, fetchFooterJson } from '../actions/aem_data.js'

const {
  EARGO_MAX, EARGO_NEO_HIFI, EARGO_PLUS, EARGO_5, EARGO_6, EARGO_7,
  ADDRESS, REVIEW, RESET_PASSWORD, INSURANCE_CHECKOUT,
  ORDER_CONFIRMATION, INSURANCE_FORM, SUPPORT_FORM,
  SUPPORT_ID, ACTIVATE_ACCOUNT, ACTIVATE_ACCOUNT_ID, TRACK_ORDER,
  COMPLETED, FORGOT_PASSWORD, VERIFY_OTP, MY_INFO, LOGIN, SIGNUP,
  INSURANCE_HEARING_SCREEN, LOGIN_OPTION, VERIFY_LOGIN_OTP, CHANGE_PASSWORD, LOGIN_DEVICEID
} = ROUTES

const OrderCheckoutFormsContainer = React.lazy(() => import('./order_checkout_forms/order_checkout_forms_container'))
// const AccessoriesContainer = React.lazy(() => import('./accessories/accessories_container'))
// const Accessories = React.lazy(() => import('./accessories/accessories'))
const ConfirmationContainer = React.lazy(() => import('./confirmation/confirmation'))
const NotFound = React.lazy(() => import('./common/NotFound'))
const ResetPasswordFormContainer = React.lazy(() => import('./forms/reset_password/reset_password_container'))
const InsuranceCheckoutContainer = React.lazy(() => import('./insurance_checkout_forms/insurance_checkout_forms_container'))
const InsuranceForm = React.lazy(() => import('./federal-get-started'))

let actionVal = false
let pathVal = false

const handleDTrum = location => {
  pathVal = location
  const { dtrum } = window
  if (dtrum) {
    !!actionVal && dtrum.leaveAction(actionVal)
    actionVal = dtrum.enterAction(location.pathname, 'load')
  }
  // NOTE - We don't want this api to get called in these routes.
  const myInfoRoutes = ['/my-info', '/my-info/order', '/my-info/support', '/login', '/signup', '/activate-account', '/my-info/dashboard']
  const dynamicOrderRoute = location.pathname.includes('/my-info/order/')
  if (!!location.pathname && (location.pathname !== '/' && !myInfoRoutes.includes(location.pathname)) && !dynamicOrderRoute) {
    // NOTE: This is done to avoid console.err
    handleAjax('GET', `api${location.pathname}`)
      .then(res => res)
      .catch(err => console.log('error --> ', err))
  }
}

const App = ({ history, location: { pathname } }) => {
  const dispatch = useDispatch()
  const { autoOut, isLoggedIn } = useSelector(state => state.global)
  const searchTerm = location?.search

  useEffect(() => {
    handleDTrum(location)
    history.listen((location, action) => {
      if (action === 'PUSH' &&
        pathVal.pathname !== pathname) {
        handleDTrum(location)
      }
    })
    isJ2Supported()
    dispatch(fetchHeaderJson())
    dispatch(fetchFooterJson())
    dispatch(detectIsMobile())
    dispatch(getPaymentMethods())
    handlecallRail()
    window.addEventListener('resize', dispatch(detectIsMobile()))
    return () => {
      window.removeEventListener('resize', dispatch(detectIsMobile()))
      removeElement('eargo-bread-js')
    }
  }, [])

  useEffect(() => {
    if (pathname) {
      dispatch(removeCouponError())
      dispatch(getPaymentMethods())
      dispatch(checkForUser())
    }
  }, [pathname])

  useEffect(() => {
    if ((isLoggedIn) &&
      !(pathname).includes('/orders/') &&
      !(pathname).includes('/insurance-checkout')) {
      dispatch(fetchCart({}, true))
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (autoOut) {
      dispatch(setActivePopUpStatus(false))
    }
  }, [autoOut])

  return (
      <Suspense fallback={<LoaderPlaceholder />}>
        <ToastContainer autoClose={TOASTER_AUTOCLOSE} />

        <Switch>

          <Route exact path='/' render={() => (
            <>
              <Redirect to={`${EARGO_7}${searchTerm}`} />

              <Helmet>
                <link href={`https://shop.eargo.com${EARGO_7}`} rel="canonical" />
              </Helmet>

            </>
          )} />

          <Route exact path={[EARGO_5, EARGO_6, EARGO_7, SUPPORT_FORM, SUPPORT_ID]} component={Wrapper} />

          <Route strict path={INSURANCE_FORM} component={InsuranceForm} />
          <Route strict path={RESET_PASSWORD} component={ResetPasswordFormContainer} />
          <Route path={ORDER_CONFIRMATION} component={ConfirmationContainer} />
          <Route exact strict path={[EARGO_NEO_HIFI, EARGO_MAX, EARGO_PLUS]} render={() => { window.location.href = `${addressUrl}products-hearing-aids`; return null }} />
          {/* <Route exact strict path={ACCESSORIES} component={Accessories} /> */}
          {/* <Route exact strict path={ACCESSORIES_DESCRIPTION} component={AccessoriesContainer} /> */}

          <Route strict path={`${EARGO_6}/`} render={() => (<Redirect to={`${EARGO_6}${searchTerm}`} />)} />
          <Route strict path={`${EARGO_7}/`} render={() => (<Redirect to={`${EARGO_7}${searchTerm}`} />)} />
          <Route strict path={`${EARGO_NEO_HIFI}/`} render={() => (<Redirect to={`${EARGO_NEO_HIFI}${searchTerm}`} />)} />
          <Route strict path={`${EARGO_MAX}/`} render={() => (<Redirect to={`${EARGO_MAX}${searchTerm}`} />)} />
          <Route strict path={`${EARGO_PLUS}/`} render={() => (<Redirect to={`${EARGO_PLUS}${searchTerm}`} />)} />
          <Route strict path={`${INSURANCE_CHECKOUT}/`} render={() => (<Redirect to={`${INSURANCE_CHECKOUT}${searchTerm}`} />)} />

          <Route exact strict path={ADDRESS} component={OrderCheckoutFormsContainer} />
          <Route exact strict path={INSURANCE_CHECKOUT} component={InsuranceCheckoutContainer} />
          <Route exact strict path={REVIEW} component={OrderCheckoutFormsContainer} />
          <Route strict path={`${ADDRESS}/`} render={() => (<Redirect to={`${ADDRESS}${searchTerm}`} />)} />
          <Route strict path={`${REVIEW}/`} render={() => (<Redirect to={`${REVIEW}${searchTerm}`} />)} />
          <Route path={MY_INFO} component={MyInfo} />
          {/* <Route path={DASHBOARD} component={MyInfo} /> */}
          <Route exact path={[ACTIVATE_ACCOUNT, ACTIVATE_ACCOUNT_ID, FORGOT_PASSWORD, VERIFY_OTP, COMPLETED, TRACK_ORDER, LOGIN_OPTION, VERIFY_LOGIN_OTP, CHANGE_PASSWORD]} component={RouteWrapper} />
          <Route exact path={[LOGIN, LOGIN_DEVICEID]} component={LoginNew} />
          <Route exact path={SIGNUP} render={() => { window.location.href = `${addressUrl}register`; return null }} />
          <Route exact path={INSURANCE_HEARING_SCREEN} component={InsuranceHearing} />
          <Route component={NotFound} />
        </Switch>

      </Suspense>
  )
}

App.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  detectIsMobile: PropTypes.any,
  removeCouponError: PropTypes.func,
  fetchCart: PropTypes.func,
  setActivePopUpStatus: PropTypes.func,
  fetchHeaderJson: PropTypes.func,
  fetchFooterJson: PropTypes.func
}

export default withRouter(App)
