import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { AuthPopUp } from '@eargo/eargo-components'
import { addressUrl, ROUTES, SERVER_URL, BASE_URL } from '../../constant'
import { setAuthState } from '../../actions/global'
import { fetchCart } from '../../actions/cart_actions'
import './CheckoutHeader.scss'
import { handleLogin } from '../../util/auth0_api'

const CheckoutHeader = ({
  autoOut, currentUser, history, setAuthState,
  location, userInfo, fetchCart, match
}) => {
  const [compState, setCompState] = useState({
    showLogin: false,
    showSignUp: false
  })

  const handleCompState = newState => {
    setCompState((prevState) => {
      return ({
        ...prevState,
        ...newState
      })
    })
  }

  const closePopUp = (resp = {}) => {
    handleCompState({
      showLogin: false,
      showSignUp: false
    })
    if (!!resp && !!resp.id) {
      if (match.params.order_number) {
        fetchCart({ orderNumber: match.params.order_number })
      } else if (location.search) {
        const search = location.search
        const id = new URLSearchParams(search).get('SFID')
        fetchCart({ orderNumber: id })
      }
    }
    setAuthState(userInfo)
    if (autoOut) {
      if (!userInfo || !userInfo.id) {
        history.push('/')
      } else if (!!userInfo && !!userInfo.id &&
        (location.pathname === ROUTES.REVIEW)) {
        history.push(ROUTES.ADDRESS)
      }
    }
  }

  const { showLogin, showSignUp } = compState
  const prevEmail = !!userInfo && !!userInfo.email ? userInfo.email : false
  return (
    <div data-testid='jest-checkoutheader-testid-1' className='checkout_header_container'>
      <div className='header'>
        <div className="nav-logo_container logo_container">
          <Link title="Eargo" to="/">
            <span className="visuallyhidden" />
          </Link>
        </div>
        <div className='photo_container'>
          <a href="tel:1-844-383-3911"><h7 className='typographyV2'>1 (844) 383-3911</h7></a>
        </div>
      </div>
      {
        (location.pathname).includes('/orders/') &&
        <Fragment>
          {(!!autoOut) &&
            <AuthPopUp
              history={history}
              corpDomain={addressUrl}
              shopDomain={BASE_URL}
              serverUrl={SERVER_URL}
              showLogin={showLogin}
              showSignUp={showSignUp}
              userInfo={userInfo}
              prevEmail={prevEmail}
              autoOut={autoOut}
              closePopUp={closePopUp}
              showSignUpPage={() => handleCompState({ showSignUp: true, showLogin: false })}
              showLoginPage={() => handleCompState({ showSignUp: false, showLogin: true })}
              handleLogin= { handleLogin}
            />}
        </Fragment>
      }
    </div>
  )
}

CheckoutHeader.propTypes = {
  autoOut: PropTypes.any,
  currentUser: PropTypes.any,
  history: PropTypes.any,
  setAuthState: PropTypes.func,
  location: PropTypes.any,
  userInfo: PropTypes.any,
  fetchCart: PropTypes.func,
  match: PropTypes.any
}

const mapStateToProps = state => ({
  autoOut: state.global.autoOut,
  currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
  fetchCart: query => dispatch(fetchCart(query)),
  setAuthState: resp => dispatch(setAuthState(resp))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckoutHeader))
