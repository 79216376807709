import {
  RECEIVE_INSURANCE_PREQUAL,
  INSURANCE_PREQUAL_LOADING,
  INSURANCE_PREQUAL_ERROR_MSG,
  REMOVE_INSURANCE_PREQUAL_ERROR_MSG
} from '../actions/insurance_prequal_action'

const defaultState = {
  isLoading: false,
  errMsg: {},
  applicantId: false
}

const InsurancePrequalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case INSURANCE_PREQUAL_LOADING:
    case RECEIVE_INSURANCE_PREQUAL:
    case INSURANCE_PREQUAL_ERROR_MSG:
      return {
        ...state,
        ...action.payload
      }
    case REMOVE_INSURANCE_PREQUAL_ERROR_MSG: {
      const errMessages = state.errMsg
      if (!!errMessages && !!errMessages.otherError) {
        delete errMessages.otherError
      }
      if (!!action.fieldName && !!errMessages && !!errMessages[action.fieldName]) {
        delete errMessages[action.fieldName]
      }
      if (!!action.fieldName && (action.fieldName === 'patient_email') && !!errMessages && !!errMessages.email) {
        delete errMessages.email
      }
      const newState = { ...state, errMsg: errMessages }
      return {
        newState
      }
    }
    default:
      return state
  }
}

export default InsurancePrequalReducer
