import PhoneNumber from 'awesome-phonenumber'
import * as EmailValidator from 'email-validator'

const COUNTRY = 'US'
const required = (value) => value ? undefined : 'Required'
const requiredRead = (value) => value ? undefined : 'Please read required terms and conditions'
const requiredField = name => value => value ? undefined : `${name} is required`
const number = value => value && isNaN(Number(value)) ? 'Only number allowed' : undefined
const regexPattern = value => value && !/^[A-Za-z\s.-]+$/i.test(value) ? 'Numbers & Special Chars not allowed' : undefined
const noSpecialChar = value => value && /['-/`~!#*$@_%+=.,^&(){}[\]|;:"<>?\\]/g.test(value) ? 'No special chars' : undefined
const confirmPasswordsMatch = (value, allValues) => value !== allValues.password ? 'Passwords do not match' : undefined
const oldPasswordAndConfirmPassword = (value, allValues) => value === allValues.old_password ? 'Cannot use a recent password.' : undefined

const onlyOneSpecialCharDash = value => value && /['/`~!#*$@_%+=,^&(){}[\]|;:"<>?\\]/g.test(value) ? 'No special chars' : undefined

const medicareNoSpecialChar = value => value && /['/`~!#*$@_%+=.,^&(){}[\]|;:"<>?\\]/g.test(value) ? 'Special Chars not allowed' : undefined
const groupNumberPattern = value => value && !/^[0-9-]*$/i.test(value) ? 'Only number allowed' : undefined
const noNumber = value => value && /[0-9]/i.test(value) ? 'Numbers not allowed' : undefined
const noOnlyNumber = value => value && /^\d+$/.test(value) ? 'Only Numbers not allowed' : undefined
const doubleSpace = value => !!value && !/^([A-Z]|\d| (?! )|(?!\s\s).)*$/i.test(value) ? 'Double space not allowed' : undefined
const noSpace = value => !!value && /\s/.test(value) ? 'Space not allowed' : undefined
const email = value => value && !EmailValidator.validate(value) ? 'Enter a valid Email' : undefined
const phoneMax = max => value => (!!value && (value.split('')).filter(char => !isNaN(parseInt(char))).length > max) ? `Must be ${max} characters` : undefined
const phoneMax10 = phoneMax(10)

const phoneMin = min => value => (!!value && (value.split('')).filter(char => !isNaN(char)).length < min) ? `Must be ${min} characters ` : undefined
const phoneMin10 = phoneMin(10)

const USPhone = value => (!!value && !(new PhoneNumber((value).replace(/\D/g, ''), COUNTRY)).isValid()) ? 'Invalid US phone no ' : undefined

const minLength = (min, message) => value => value.length < min ? (message || `Must be ${min} characters `) : undefined
const maxLength = (max, message) => value => value.length > max ? (message || `Must be ${max} characters `) : undefined

const whiteSpace = value => !/\s/.test(value) ? 'Last name required' : undefined
const zipNotZero = value => !!value && (value === '00000') ? 'Please enter valid zipcode' : undefined

export {
  required,
  requiredRead,
  requiredField,
  whiteSpace,
  doubleSpace,
  noSpace,
  number,
  regexPattern,
  noSpecialChar,
  confirmPasswordsMatch,
  groupNumberPattern,
  medicareNoSpecialChar,
  noNumber,
  noOnlyNumber,
  email,
  phoneMax10,
  phoneMin10,
  USPhone,
  minLength,
  maxLength,
  zipNotZero,
  oldPasswordAndConfirmPassword,
  onlyOneSpecialCharDash
}
