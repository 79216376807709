import { handleAjax } from '../common/helpers'

export const getActiveSupportCaseApi = () => handleAjax('GET', 'api/support_cases/activeCaseCount')

export const getSupportCaseAccountInfoApi = () => handleAjax('GET', 'api/support_cases/accountinfo')

export const addSupportApi = info => handleAjax('POST', 'api/support_cases/', info)

export const getCaseListApi = (query) => handleAjax('GET', 'api/support_cases', query)

export const getCaseListDetailApi = sf_case_id => handleAjax('GET', `api/support_cases/${sf_case_id}`)

export const getProductDetailsApi = () => handleAjax('GET', 'api/users/latest_device_order')
