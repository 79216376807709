import React from 'react'
import styled from 'styled-components'
import { EargoButtonV2 } from '@eargo/eargo-components'

import './empty_order.scss'
import { addressUrl } from '../../../constant'
const EmptyOrder_container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
.empty_order {
    width: auto;
    max-width: 173px;
}
.content {
    max-width: 314px;
    color: #666666;
    margin-top: 20px;
    text-align: center;
}
    
`

const EmptyOrder = () => {
  const redirectHandler = () => {
    window.location.href = `${addressUrl}products-hearing-aids`
  }

  return (
    <EmptyOrder_container>
        <h5 className='typographyV2'>Recent Orders</h5>
        <img src='https://assets.eargo.com/account/emptyOrder.jpg' alt='Empty order' className='empty_order'/>
        <span className='typographyV2 body content'>Your purchased orders, returns, and exchanges will show up here.</span>
        <div className='button_container'>
          <EargoButtonV2
            className='primary stretch'
            label="Shop Now"
            handleOnClick={redirectHandler}
            style={{ minWidth: 'max-content' }}
          />
        </div>
      </EmptyOrder_container>
  )
}

export default EmptyOrder
