import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { DotSpinLoading } from '@eargo/eargo-components'

import './index.scss'
import SupportRequest from './SupportRequest'
import { SUPPORT_CONTENT } from '../../../constant'
import SupportTabs from './SupportTabs/SupportTabs'
import DeviceVideoContent from './DeviceVideoContent'
import { getCaseList, clearCaseListData, getProductDetails, getSupportCaseAccountInfo } from '../../../actions/support'
import DownloadSupportApp from './DownloadSupportApp/DownloadSupportApp'
import UserGuides from './UserGuides/UserGuides'

const SupportSection = () => {
  // predefined methods imported form packages
  const history = useHistory()
  const dispatch = useDispatch()

  // activePage is used for pagination
  // isLoading is used for page load or not
  // notFound is used for check support data or not
  const [activePage, setActivePage] = useState(1)
  const isAllowed = useSelector((state) => state.support.isAllowed)
  const [openSelectedTab, setOpenSelectedTab] = useState('Video guides')
  const [productsName, setProductName] = useState([])
  const lineItem = useSelector(state => state.support.lineItems)
  const [awsLoading, setAwsLoading] = useState(false)
  const [isLoading, notFound] = useSelector((state) => [
    state.support.isLoading,
    state.support.notFound
  ])
  const checkedProductsName = []

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }, 2200)
    dispatch(getSupportCaseAccountInfo())
  }, [])

  useEffect(() => {
    if (isAllowed) {
      setOpenSelectedTab('Support requests')
    }
  }, [isAllowed])

  useEffect(() => {
    // HINT: Need to clear previous data on mount, as pagination works differently here
    dispatch(clearCaseListData())
    dispatch(getCaseList())
    window.scrollTo(0, 0)
  }, [history])

  useEffect(() => {
    setAwsLoading(true)
    dispatch(getProductDetails())
    setAwsLoading(false)
  }, [])

  useEffect(() => {
    if (lineItem.length) {
      let productNameList = []
      lineItem.forEach((item) => {
        if (typeof item === 'string' && item?.includes('eargo_7')) {
          productNameList = ['eargo_7']
        } else if (productNameList.indexOf(item.product_name.toLowerCase().replace(/ /g, '_')) === -1) {
          productNameList = [...productNameList, item.product_name.toLowerCase().replace(/ /g, '_')]
        }
      })
      setProductName(productNameList)
    }
  }, [lineItem])

  const setOtherCheckedProducts = (Item) => {
    checkedProductsName.push(Item.product_class.toUpperCase().replace(/_/g, ' '))
  }

  // const OriginalArray = []
  const videoContent = SUPPORT_CONTENT.filter(Item => {
    if (productsName.includes(Item.product_class)) {
      setOtherCheckedProducts(Item)
      // OriginalArray.push({
      //   content: [{
      //     ...Item.content[0],
      //     class1: Item.content[0].class1,
      //     class2: Item.content[0].class2,
      //     class3: Item.content[0].class3,
      //     info: Item.content[0].info.slice(0, 3),
      //     note: Item.content[0].note,
      //     title: Item.content[0].title,
      //     type: Item.content[0].type
      //   }],
      //   device: Item.device,
      //   product_class: Item.product_class,
      //   title: Item.title
      // })
      Item.content[0].info = Item.content[0].info.slice(0, 3)
    } else {
      return false
    }
    return Item
  })

  // local method use for update page list
  const handleLoadMoreTickets = async () => {
    window.scrollTo(0, 0)
    const resp = await dispatch(getCaseList({ page: activePage + 1 }))
    if (resp) {
      setActivePage(activePage + 1)
    }
  }

  const setSelectedOptions = option => setOpenSelectedTab(option)
  return (
    <div className='support_wrapper_container' data-testid='support-container-test'>
      <div className='child_container'>
      {(isLoading || awsLoading) && <DotSpinLoading />}
      {(!isLoading && !awsLoading) && <div className='support_sub_container RoundedCorner'>
        <SupportTabs setSelectedOptions={setSelectedOptions} />
        {openSelectedTab === 'Support requests' && <SupportRequest notFound={notFound} handleLoadMoreTickets={handleLoadMoreTickets} />}
        {openSelectedTab === 'Video guides' && videoContent.map((deviceContent, i) =>
          <DeviceVideoContent key={i} productCount={i.toString()} deviceContent={deviceContent} Products={checkedProductsName} totalProductCount={videoContent.length.toString() - 1} />
        )}
        {openSelectedTab === 'Download app' && <DownloadSupportApp Products={checkedProductsName} />}
        {openSelectedTab === 'User guides' && lineItem.length && lineItem.map((itemList, i) => {
          return <UserGuides itemList={itemList} key={i} />
        })}
      </div>}
      </div>
      </div>
  )
}

export default SupportSection
