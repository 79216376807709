import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { Field } from 'redux-form'
import { EargoButtonV2 } from '@eargo/eargo-components'
import ReduxPrimaryInput from '../../common/redux-form/ReduxPrimaryInput'
import { removeFieldError, addressesError } from '../../../actions/address'
import { noOnlyNumber, onlyOneSpecialCharDash, requiredField, doubleSpace } from '../../common/redux-form/validation'
import CommonAddressField from './CommonAddressField'
import { ReduxCheckboxField } from '../../common/redux-form/ReduxCheckboxInput'
import { disabledOtherSection } from '../../../actions/global'
import './AddressForm.scss'

const fNameRequire = requiredField('First Name')
const lNameRequire = requiredField('Last Name')

const AddressForm = ({
  handleSubmit, states, errMsg, handleOnCancel, isDisable = false,
  removeFieldError, removeAllError, isEdit = false, addressCount
}) => {
  const dispatch = useDispatch()
  // Handle Unmount logic
  const scrollToTop = () => {
    setTimeout(() => {
      if (isEdit) {
        const element = document.getElementById('eargo-address-form')
        !!element && element.scrollIntoView({ behavior: 'smooth' })
      }
    }, 100)
  }

  useEffect(() => {
    removeAllError()
    scrollToTop()
    return () => {
      removeFieldError()
    }
    // eslint-disable-next-line
  }, [removeAllError]
  )

  const handleOnFocus = e => {
    const fieldName = e.target.name
    if (!!errMsg && errMsg[fieldName]) removeFieldError(fieldName)
    if (!!errMsg && !!errMsg.otherError) removeFieldError('otherError')
  }

  return (
    <form className='edit_address_form_container' onSubmit={handleSubmit} id="eargo-address-form">
      <div className='edit_address_form_one_line'>
        <Field
          id="firstname"
          name="firstname"
          label="First Name"
          capitalize={true}
          component={ReduxPrimaryInput}
          onFocus={handleOnFocus}
          validate={[fNameRequire, doubleSpace, onlyOneSpecialCharDash, noOnlyNumber]}
          errResp={!!errMsg && !!errMsg.firstname ? errMsg.firstname : false}
        />
        <Field
          id="lastname"
          name="lastname"
          label="Last Name"
          capitalize={true}
          component={ReduxPrimaryInput}
          onFocus={handleOnFocus}
          validate={[lNameRequire, doubleSpace, onlyOneSpecialCharDash, noOnlyNumber]}
          errResp={!!errMsg && !!errMsg.lastname ? errMsg.lastname : false}
        />
      </div>
      <CommonAddressField
        isEdit={isEdit}
        errMsg={errMsg}
        states={states}
        handleOnFocus={handleOnFocus} />
      {!isEdit && <div className='edit_address_checkbox_holder'>
        {(
          (isEdit && (addressCount > 1)) ||
          (!isEdit && (!!addressCount))
        ) && <Field
            name="default"
            label="Make default address"
            component={ReduxCheckboxField}
          />}
      </div>
      }
      {!!errMsg && !!errMsg.otherError && <div className='typographyV2 err_regular edit_address_error_message'>{errMsg.otherError}</div>}
      <div className={`edit_address_buttons ${isEdit ? 'isEdit' : ''}`}>
        <EargoButtonV2 disabled={isDisable} className={`primary ${isEdit ? '' : 'whole_width'}`} type="submit" label="Save" />
        {!!isEdit && <span className='typographyV2 body edit_address_cancel_button' onClick={() => {
          dispatch(disabledOtherSection(false))
          !!handleOnCancel && handleOnCancel()
        }}>Cancel</span>}
      </div>
    </form>
  )
}

AddressForm.propTypes = {
  handleSubmit: PropTypes.func,
  states: PropTypes.any,
  errMsg: PropTypes.any,
  handleOnCancel: PropTypes.func,
  isDisable: PropTypes.bool,
  removeFieldError: PropTypes.func,
  removeAllError: PropTypes.func,
  isEdit: PropTypes.bool,
  addressCount: PropTypes.any
}

const mapStateToProps = state => ({
  ...state,
  states: state.address.states,
  addressCount: state.address.total,
  errMsg: state.address.errMsg
})

const mapDispatchToProps = dispatch => ({
  removeAllError: () => dispatch(addressesError(null)),
  removeFieldError: fieldName => dispatch(removeFieldError(fieldName))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm)
