import {
  HANDLE_ACTIVE_SUPPORT_CASE,
  HANDLE_SUPPORT_PROCESSING,
  POST_SUPPORT_SUCCESS,
  HANDLE_SUPPORT_ERROR,
  GET_CASE_LIST_SUCCESS,
  GET_CASE_LIST_DETAIL_SUCCESS,
  HANDLE_SERVER_DOWN,
  GET_SUPPORT_CASE_ACCOUNT,
  CLEAR_CASE_LIST_DATA,
  GET_PRODUCT_DETAILS_SUCCESS,
  CLEAR_SUPPORT_REQUEST_DETAILS
} from '../actions/support'

const defaultState = {
  active: 0,
  isLoading: false,
  total: 0,
  caseDetail: [],
  singleCaseDetail: {},
  notFound: false,
  isAllowed: false,
  lineItems: []
}

const support = (state = defaultState, action) => {
  switch (action.type) {
    case HANDLE_SUPPORT_PROCESSING:
    case POST_SUPPORT_SUCCESS:
    case HANDLE_SUPPORT_ERROR:
    case HANDLE_ACTIVE_SUPPORT_CASE:
    case HANDLE_SERVER_DOWN:
    case GET_SUPPORT_CASE_ACCOUNT:
    case CLEAR_CASE_LIST_DATA:
      return {
        ...state,
        ...action.payload
      }

    case GET_CASE_LIST_DETAIL_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }
    case GET_CASE_LIST_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        caseDetail: state.caseDetail.concat([...action.payload.caseDetail])
      }
    }
    case GET_PRODUCT_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        lineItems: action.payload.lineItems
      }
    }
    case CLEAR_SUPPORT_REQUEST_DETAILS:
      return {
        ...state,
        lineItems: []
      }
    default:
      return {
        ...state
      }
  }
}

export default support
