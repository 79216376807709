import * as LeadProcessApiUtil from '../util/lead_process_util'

export const RECEIVE_LEAD_PROCESS = 'RECEIVE_LEAD_PROCESS'
export const LEAD_PROCESS_LOADING = 'LEAD_PROCESS_LOADING'
export const LEAD_PROCESS_ERROR_MSG = 'LEAD_PROCESS_ERROR_MSG'
export const REMOVE_LEAD_PROCESS_ERROR_MSG = 'REMOVE_LEAD_PROCESS_ERROR_MSG'

const setLoadingStatus = isLoading => ({
  type: LEAD_PROCESS_LOADING,
  payload: {
    isLoading
  }
})

const receiveLeadProcess = applicantInfo => ({
  type: RECEIVE_LEAD_PROCESS,
  payload: {
    applicantInfo
  }
})

const setErrorMessage = errMsg => ({
  type: LEAD_PROCESS_ERROR_MSG,
  payload: {
    errMsg
  }
})

export const removeFieldError = fieldName => ({
  type: REMOVE_LEAD_PROCESS_ERROR_MSG,
  fieldName
})

export const submitLeadProcess = info => async (dispatch) => {
  dispatch(setLoadingStatus(true))
  dispatch(setErrorMessage(null))
  try {
    const resp = await LeadProcessApiUtil.submitLeadProcess(info)
    dispatch(setLoadingStatus(false))
    dispatch(receiveLeadProcess(resp))
    return resp.status === 'OK'
  } catch (error) {
    dispatch(setLoadingStatus(false))
    dispatch(setErrorMessage(!!error.responseJSON && !!error.responseJSON.error ? error.responseJSON.error : {}))
    console.log('submitLeadProcess Error: ', error)
    return false
  }
}

export const submitLeadAPI = info => async (dispatch) => {
  dispatch(setLoadingStatus(true))
  dispatch(setErrorMessage(null))
  try {
    const resp = await LeadProcessApiUtil.submitLead(info)
    dispatch(setLoadingStatus(false))
    dispatch(receiveLeadProcess(resp))
    return resp
  } catch (error) {
    dispatch(setLoadingStatus(false))
    dispatch(setErrorMessage(!!error.responseJSON && !!error.responseJSON ? error.responseJSON : {}))
    return false
  }
}
