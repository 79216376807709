import {
  FETCH_USER_DOCUMETS,
  UPDATE_TOTAL_DOCUMENTS,
  HANDLE_ID_LOADING,
  HANDLE_UPLOADING
} from '../actions/documents'

const defaultState = {
  isLoading: false,
  totalDocuments: [],
  uploadedDocuments: [],
  uploadInProgress: false
}

const documents = (state = defaultState, action) => {
  switch (action.type) {
    case HANDLE_ID_LOADING: {
      return {
        ...state,
        ...action.payload
      }
    }
    case HANDLE_UPLOADING: {
      return {
        ...state,
        ...action.payload
      }
    }
    case UPDATE_TOTAL_DOCUMENTS:
      return {
        ...state,
        totalDocuments: action.payload.total
      }
    case FETCH_USER_DOCUMETS: {
      return {
        ...state,
        uploadedDocuments: action.payload.documents
      }
    }
    default:
      return {
        ...state
      }
  }
}
export default documents
