import React, { useEffect } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DotSpinLoading, EargoButtonV2 } from '@eargo/eargo-components'

import './OrderDetails.scss'
import { devices } from '../../../../constant/devices'
import ProductList from './OrderProductList/ProductList'
import { colorCodes } from '../../../../constant/colors'
import RelatedOrders from './RelatedOrders/RelatedOrders'
import { DATE_FORMAT_1 } from '../../../../constant'
import { fetchViewDetailsBySFId } from '../../../../actions/orders'
import { convertStringToAmount } from '../../../../general_methods'
import Invoice from './Invoice'

const { tablet, mobile } = devices
const { GREY_1, GREY_2, DARK_BLUE_1, GREY_3 } = colorCodes

const DeliveredDate = styled.div`
  width: 215px;
  font-size: 18px;
  line-height: 26px;
  @media ${tablet} {
    padding: unset;
  }
`

const HolderInfo = styled.p`
  padding-top: 6px;
  color: ${GREY_2};
`

const DeliveredTitle = styled.p`
  color: ${GREY_1};
`

const TrackOrder = styled.div`
  display: flex;
  margin-top: 19px;
  flex-direction: column;
  img {
    width: 18.33px;
    margin-right: 5px;
  }
  span {
    font-size: 13px;
    line-height: 22px;
    color: ${DARK_BLUE_1};
  }
`

const Holder = styled.div`
  display: flex;
  margin: 13px 0px;
  width: 100%;
  justify-content: space-between;
  @media ${tablet} {
    width: 100%;
    justify-content: space-between;
  }
  span {
    font-size: 12px;
    color: ${GREY_3};
  }
  .discount-code-span {
    @media ${mobile} {
      display: flex;
      justify-content: flex-end;
    }
  }
`

const TrackWrapper = styled.div`
  display: flex;
  cursor: pointer;
`
const TrackingNumber = styled.p`
  color: ${GREY_2};
`

const OrderLabel = styled.p`
  color: ${GREY_1};
  @media ${mobile} {
    font-size: 16px;
    line-height: 152%;
  }
`

const OrderData = styled.p`
  text-align: end;
  color: ${GREY_2};
`

const OrderDetails = () => {
  const history = useHistory()
  const state = useSelector((state) => state.orders)
  const { isProcessing, orderDetail } = state
  const { id } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchViewDetailsBySFId(id, false))
  }, [])

  const handleOnClick = () => {
    history.goBack()
  }

  const {
    additionalTaxTotal,
    total,
    lineItems,
    createdAt,
    number,
    invoice,
    status,
    firstName,
    lastName,
    shipAddress,
    shipmentTotal,
    deliveryTime,
    estDeliveryTime,
    estDelivery,
    trackingNumber,
    rmaOrders,
    itemTotal,
    promoTotal,
    order_channel
  } = orderDetail

  const showRelatedOrders = () => {
    return rmaOrders.map(data => {
      const { number, order_created_date, status, sf_order_id } = data
      return <RelatedOrders number={number} createdDate={order_created_date}
          sfOrderrId={sf_order_id} total={total} status={status} key={number}
          rmaData={data}
        />
    })
  }

  return (
    <div className="order_details_main interFontRegular">
      <>{isProcessing
        ? <DotSpinLoading />
        : <div className="order_details_container">
        <div className="details">
          <p className="typographyV2 body_bold back_button" onClick={handleOnClick}>
            <img
              className="arrow_icon"
              src="https://assets.eargo.com/new_account/arrow_back.png"
            />
            All Orders
          </p>
          <div className="status_detail">
            <h5 className="typographyV2 order_date">
              {moment(createdAt).format(DATE_FORMAT_1)}
              <h5 className="typographyV2 order_number">Order #{number}</h5>
            </h5>
            <p className="typographyV2 body_bold order_status">
              {status}
              {invoice && <Invoice sfId={id} /> }
            </p>
          </div>
          <div className="order_details">
            <div className="common_container">
              <ProductList lineItems={lineItems} order_channel = {order_channel}/>
              {rmaOrders && !!rmaOrders.length && <div className='show_in_desktop'><h5 className='typographyV2 related_orders'>Related Orders</h5><span>{showRelatedOrders()}</span></div>}
            </div>
            <div className='order_information'>
            <div className="common_container_second">
              <div className="delivery_details RoundedCorner">
                <div className="checkout_details_to">
                  <div className="address">
                    <p className="typographyV2 body_bold">{status === 'Delivered' ? 'Delivered to:' : 'Will be delivered to:'}</p>
                    {(!!firstName || !!lastName) && (
                      <p className="typographyV2 body address_detail eargo-capitalize">
                        {firstName || ''} {lastName || ''}
                      </p>
                    )}
                    {!!shipAddress?.address1 && (
                      <p
                        className="typographyV2 body address_detail"
                        id="jest-test-billingAddress-id"
                      >
                        {shipAddress.address1}
                        {!!shipAddress.address2 && `, ${shipAddress.address2}`}
                      </p>
                    )}
                    <p className="typographyV2 body address_detail">
                      {[
                        shipAddress?.city && shipAddress.city + ',',
                        shipAddress?.state + ',',
                        shipAddress?.zipcode
                      ].join(' ')}
                    </p>
                  </div>
                </div>
                <div className="deliverydate_trackorder_conatiner">
                  {(!!deliveryTime || !!estDeliveryTime || !!estDelivery) && (
                    <DeliveredDate>
                      <DeliveredTitle className="typographyV2 body_bold">
                        {deliveryTime ? 'Delivered on' : 'Estimated delivery:'}
                      </DeliveredTitle>
                      <HolderInfo
                        id="jest-test-deliverytime-id" className="typographyV2 body"
                      >
                        {deliveryTime
                          ? moment(deliveryTime).format(DATE_FORMAT_1)
                          : estDeliveryTime
                            ? moment(estDeliveryTime).format(DATE_FORMAT_1)
                            : estDelivery || 'N/A'}
                      </HolderInfo>
                      {!!trackingNumber && (
                        <TrackOrder
                          className="pt-2"
                          data-testid="TrackOrder-testid"
                        >
                        <TrackWrapper onClick={() => history.push(`/track-order/${number}`)}>
                          <img
                            src="https://assets.eargo.com/account/track_order_logo.svg"
                            alt="track order logo"
                          />
                          <p className="typographyV2 body_bold" id="jest-test-trackOrder-id">
                            TRACK ORDER
                          </p>
                          </TrackWrapper>
                          <TrackingNumber className='typographyV2 caption'>Tracking ID: {trackingNumber}</TrackingNumber>
                        </TrackOrder>
                      )}
                    </DeliveredDate>
                  )}
                </div>
                {/* <div className="payment_method">
                  Payment method
                  <div className="card_details">
                    <img
                      className="card_image"
                      src="https://assets.eargo.com/pdp/new_images/visa.png"
                    />
                    Ending in 5678
                  </div>
                </div> */}
                {order_channel === 'Eargo' && (
                <>
                  <div>
                    <Holder data-testid="Holder-testid">
                      <OrderLabel className="typographyV2 body_bold">
                        Subtotal
                      </OrderLabel>
                      <OrderData className='typographyV2 body'>
                        $
                        {convertStringToAmount(
                          Math.abs(parseFloat(itemTotal, 10)).toFixed(2)
                        )}
                      </OrderData>
                    </Holder>
                    <Holder>
                      <OrderLabel className="typographyV2 body_bold">
                        Shipping
                      </OrderLabel>
                      <OrderData className='typographyV2 body'>
                        ${shipmentTotal ? convertStringToAmount(Math.abs(parseFloat(shipmentTotal, 10)).toFixed(2)) : '0.00' }
                      </OrderData>
                    </Holder>
                    <Holder>
                      <OrderLabel className="typographyV2 body_bold">
                        Taxes
                      </OrderLabel>
                      <OrderData className="typographyV2 body">
                        $
                        {convertStringToAmount(
                          Math.abs(parseFloat(additionalTaxTotal, 10)).toFixed(2)
                        )}
                      </OrderData>
                    </Holder>
                    <Holder>
                      <OrderLabel className="typographyV2 body_bold">
                        Discount
                      </OrderLabel>
                      <OrderData className="typographyV2 body">
                        ${promoTotal ? convertStringToAmount(Math.abs(parseFloat(promoTotal, 10)).toFixed(2)) : '0.00'}
                      </OrderData>
                    </Holder>
                  </div>
                  <Holder className="pt-20 total_price">
                    <OrderLabel className="typographyV2 body_bold">
                      Total
                    </OrderLabel>
                    <OrderData className='typographyV2 body_bold show_total_price'>
                      $
                      {convertStringToAmount(
                        Math.abs(parseFloat(total, 10)).toFixed(2)
                      )}
                    </OrderData>
                  </Holder>
                </>
                )}
              </div>
              {
                <EargoButtonV2 className="start_return ghost black interFontBold" onClick={() => history.push({
                  pathname: '/my-info/support/form',
                  search: `?type=return&order_id=${number}`
                })}>
                  Need help?
                </EargoButtonV2>
              }
            </div>
              {rmaOrders && !!rmaOrders.length && <div className='show_in_tablet'><h5 className='typographyV2 related_orders'>Related Orders</h5><span>{showRelatedOrders()}</span></div>}
            </div>
          </div>
        </div>
      </div>
    }</>
    </div>
  )
}

export default OrderDetails
