import { helpersFn } from '@eargo/eargo-components'
import {
  addPaymentMethod, fetchAllPaymentMethods,
  generateCBPublicKey, setPaymentAsDefault, initPayerAuthSetup
} from '../util/payment_api_util'
import { receiveCartErrors, receiveCreditCardError, receiveSplitCardError } from '../actions/error_actions'
import { checkAndSetAutoOut } from './global'
import { fetchCart, refreshCartForExpire } from './cart_actions'

const { handleErrOtherKey } = helpersFn

export const HANDLE_PAYMENT_PROCESSING = 'HANDLE_PAYMENT_PROCESSING'
export const UP_METHODS_PROCESSING = 'UP_METHODS_PROCESSING'
export const USER_PAYMENTS_SUCCESS = 'USER_PAYMENTS_SUCCESS'
export const USER_PAYMENTS_ERROR = 'USER_PAYMENTS_ERROR'
export const REMOVE_PAYMENT_ERROR_MSG = 'REMOVE_PAYMENT_ERROR_MSG'
export const FETCH_PAYMENTS_ERROR = 'FETCH_PAYMENTS_ERROR'
export const SET_SAVED_METHOD_ID = 'SET_SAVED_METHOD_ID'
export const SET_DEFAULT_PAYMENT_ID = 'SET_DEFAULT_PAYMENT_ID'
export const SET_PAYMENT_LIST_POPUP = 'SET_PAYMENT_LIST_POPUP'
export const SET_SPLIT_CYBERSOURCE_KEY = 'SET_SPLIT_CYBERSOURCE_KEY'
export const SET_SPLIT_CYBERSOURCE_KEY_STATUS = 'SET_SPLIT_CYBERSOURCE_KEY_STATUS'
export const SET_ADD_PAYMENT_POPUP = 'SET_ADD_PAYMENT_POPUP'
export const ADD_UP_METHOD_SUCCESS = 'ADD_UP_METHOD_SUCCESS'
export const SET_CYBERSOURCE_KEY = 'SET_CYBERSOURCE_KEY'
export const SET_CYBERSOURCE_KEY_STATUS = 'SET_CYBERSOURCE_KEY_STATUS'
export const SET_SAVED_PAYMENT_ID = 'SET_SAVED_PAYMENT_ID'
export const SET_ALL_PAYMENT_METHODS = 'SET_ALL_PAYMENT_METHODS'
export const ERR_MSG = 'Your specified card is not found, please use a different one.'

export const setPaymentListPopUp = paymentListPopUp => ({
  type: SET_PAYMENT_LIST_POPUP,
  payload: {
    paymentListPopUp
  }
})

export const setAddPaymentPopUp = addPaymentPopUp => ({
  type: SET_ADD_PAYMENT_POPUP,
  payload: {
    addPaymentPopUp
  }
})

export const handleUPMethodsProcessing = status => ({
  type: UP_METHODS_PROCESSING,
  payload: {
    isLoading: status
  }
})

export const setCyberSourceKey = cyberSourceKey => ({
  type: SET_CYBERSOURCE_KEY,
  payload: {
    cyberSourceKey
  }
})

export const setSplitCyberSourceKey = splitCyberSourceKey => ({
  type: SET_SPLIT_CYBERSOURCE_KEY,
  payload: {
    splitCyberSourceKey
  }
})

const setCyberSourceKeyStatus = cyberSourceKeyStatus => ({
  type: SET_CYBERSOURCE_KEY_STATUS,
  payload: {
    cyberSourceKeyStatus
  }
})

const setSplitCyberSourceKeyStatus = splitCyberSourceKeyStatus => ({
  type: SET_SPLIT_CYBERSOURCE_KEY_STATUS,
  payload: {
    splitCyberSourceKeyStatus
  }
})

export const setSavedMethodId = savedMethodId => ({
  type: SET_SAVED_METHOD_ID,
  payload: {
    savedMethodId
  }
})

const fetchUPMethodsSuccess = res => ({
  type: USER_PAYMENTS_SUCCESS,
  payload: {
    isLoading: false,
    methods: (res.data).sort((a, b) => (a.default === b.default) ? 0 : a.default ? -1 : 1)
  }
})

// HINT: For now, not in use
export const addUPMethodSuccess = method => ({
  type: ADD_UP_METHOD_SUCCESS,
  method
})

export const paymentsError = errMsg => ({
  type: USER_PAYMENTS_ERROR,
  payload: {
    isLoading: false,
    errMsg
  }
})

export const removeFieldError = fieldName => ({
  type: REMOVE_PAYMENT_ERROR_MSG,
  fieldName
})

export const setDefaultMethodId = defaultId => ({
  type: SET_DEFAULT_PAYMENT_ID,
  defaultId
})

export const changeSavedId = id => ({
  type: SET_SAVED_PAYMENT_ID,
  id
})

export const updateAllSaveId = ids => ({
  type: SET_ALL_PAYMENT_METHODS,
  ids
})

/**
 * Fetch Payment Methods
 * for logged in user
 */
export const fetchUPMethods = () => async (dispatch) => {
  try {
    dispatch(handleUPMethodsProcessing(true))
    const resp = await fetchAllPaymentMethods()
    if (!!resp && !!resp.success) {
      dispatch(fetchUPMethodsSuccess(resp))
      return resp
    } else {
      dispatch(checkAndSetAutoOut(resp))
      const errorBag = !!resp && !!resp.error ? resp.error : { otherError: resp.message }
      dispatch(paymentsError(errorBag))
      return errorBag
    }
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (
          !!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {}
        )
    dispatch(paymentsError(errorBag))
    return error
  }
}

/**
 * Set Default Address
 * @param {Number} id
 */
export const setDefaultPayment = id => async (dispatch) => {
  dispatch(handleUPMethodsProcessing(true))
  try {
    const response = await setPaymentAsDefault(id)
    let returnMsg = false
    if (!!response && !!response.success) {
      dispatch(setDefaultMethodId(id))
      returnMsg = true
    } else {
      dispatch(checkAndSetAutoOut(response))
      const errorBag = !!response && !!response.error ? response.error : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(paymentsError(errorBag))
      returnMsg = false
    }
    dispatch(handleUPMethodsProcessing(false))
    return returnMsg
  } catch (error) {
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (
          !!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {}
        )
    dispatch(handleUPMethodsProcessing(false))
    dispatch(paymentsError(errorBag))
    return false
  }
}

/**
 * Add New Payment Method
 * @param {Object} info
 */
export const addNewPayment = (info) => async (dispatch, getState) => {
  dispatch(handleUPMethodsProcessing(true))
  try {
    const response = await addPaymentMethod(info)
    let returnMsg = false
    if (!!response && !!response.success) {
      dispatch(fetchUPMethods())
      dispatch(setSavedMethodId([response.data.id]))
      returnMsg = true
    } else {
      dispatch(checkAndSetAutoOut(response))
      const errorBag = !!response && !!response.error ? handleErrOtherKey(response.error) : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(paymentsError(errorBag))
      returnMsg = false
    }
    dispatch(handleUPMethodsProcessing(false))
    return returnMsg
  } catch (error) {
    const errJson = error.responseJSON
    if (errJson) {
      dispatch(checkAndSetAutoOut(errJson))
    }
    const errorBag = !!errJson && !!errJson.error
      ? handleErrOtherKey(errJson.error)
      : (!!errJson && !!errJson[0] ? { otherError: errJson[0] } : {})

    const isCBS = getState().global.isCBS
    if (!!isCBS && !!errorBag && !errorBag.otherError &&
      (!!errorBag.last_digits || !!errorBag.stripe_payment_method_id)) {
      errorBag.otherError = 'Something went wrong, Please refresh the page and try again.'
    } else if (!isCBS && !!errorBag && !errorBag.otherError &&
      !!errorBag.transient_token) {
      errorBag.otherError = 'Something went wrong, Please refresh the page and try again.'
    }

    dispatch(handleUPMethodsProcessing(false))
    dispatch(paymentsError(errorBag))
    return false
  }
}

/**
 * Generate CyberSource
 * Public Key
 */
export const generateCyberSourceKey = (split = false) => async (dispatch, getState) => {
  dispatch(setCyberSourceKeyStatus('LOADING'))
  dispatch(setSplitCyberSourceKeyStatus('LOADING'))
  try {
    const resp = await generateCBPublicKey()
    if (!!resp && !!resp.success && !!resp.data && !!resp.data.keyId) {
      if (split) {
        dispatch(setSplitCyberSourceKey(resp.data.keyId))
        dispatch(setSplitCyberSourceKeyStatus('SUCCESS'))
      } else {
        dispatch(setCyberSourceKey(resp.data.keyId))
        dispatch(setCyberSourceKeyStatus('SUCCESS'))
      }
      return resp.data.keyId
    }
    return false
  } catch (e) {
    dispatch(setCyberSourceKeyStatus('FAIL'))
    console.log('fetchKeyInfo Error: ', e)
    return false
  }
}

/**
 * Apply Payer
 * Auth Setup
 */
export const applyPayerAuthSetup = info => async dispatch => {
  try {
    return await initPayerAuthSetup(info)
  } catch (e) {
    const errJson = e.responseJSON
    dispatch(refreshCartForExpire(errJson))
    if (!!errJson && typeof errJson.error === 'string') {
      dispatch(fetchCart())
      dispatch(receiveCartErrors([errJson.error]))
      return false
    } else if (!!errJson && errJson.error) {
      dispatch(fetchCart())
      dispatch(receiveCartErrors([errJson.error[Object.keys(errJson.error)[0]]]))
      return false
    } else if (!!errJson && errJson.data && errJson.data.length) {
      if (errJson.data.length === 1 && errJson.data[0].error === ERR_MSG) {
        dispatch(receiveCreditCardError([ERR_MSG]))
        return false
      } else if (errJson.data.length > 1 && (errJson.data[0].error === ERR_MSG || errJson.data[1].error === ERR_MSG)) {
        dispatch(receiveCreditCardError([ERR_MSG]))
        return false
      } else if (!!errJson && errJson.data && errJson.data.length) {
        dispatch(receiveSplitCardError(errJson.data))
        return false
      }
    }
    console.log('applyPayerAuthSetup Error: ', e)
    return false
  }
}
