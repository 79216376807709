import {
  FETCH_ORDERS_TRACK_SUCCESS,
  HANDLE_ORDERS_PROCESSING,
  RESET_ORDERS_TRACK_DATA
} from '../actions/order_track'

const defaultState = {
  data: [],
  isProcessing: false
}

const OrderTrack = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ORDERS_TRACK_SUCCESS:
    case RESET_ORDERS_TRACK_DATA:
    case HANDLE_ORDERS_PROCESSING:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default OrderTrack
