import { ORDER_LOADING } from '../actions/insurance_cart_action'

const defaultState = {
  isLoading: false
}

const InsCart = (state = defaultState, action) => {
  switch (action.type) {
    case ORDER_LOADING:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default InsCart
