import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Field, reduxForm } from 'redux-form'
import { EargoButtonV2, PasswordStrength, DotSpinLoading } from '@eargo/eargo-components'
import { colorCodes } from '../../../constant/colors'
import { devices } from '../../../constant/devices'
import ReduxPrimaryInput from '../../common/redux-form/ReduxPrimaryInput'
import { oldPasswordAndConfirmPassword, confirmPasswordsMatch, requiredField } from '../../common/redux-form/validation'
import { changePassword, changePasswordError, removeFieldError } from '../../../actions/change_password'
import { submitFailure } from '../../../common/helpers'
import { disabledOtherSection } from '../../../actions/global'

const { ORANGE_1, ERROR_RED } = colorCodes
const FORM_NAME = 'changePasswordForm'

const oldRequired = requiredField('Old Password')
const newRequired = requiredField('New Password')
const confirmRequired = requiredField('Confirm Password')

const Form = styled.form`
display: block;
.redux-primary-input-holder {
    margin-bottom: 10px;
    label.primary-input-label {
        box-sizing: border-box;
        &.error_border {
            width: calc(100% - 2px);
            margin: 0 auto;
        }
    }
}
.epsc {
    margin: -4px 0px 20px 0px;
}
`

const ButtonHolder = styled.div`
display: inline-flex;
margin-top: 35px;
`

const SaveButton = styled(EargoButtonV2)`
:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}
`

const StrengthContainer = styled(PasswordStrength)`
margin-bottom: 23px;
margin-top: -4px;
`

const CancelButton = styled.span`
// font-size: 18px;
color: ${ORANGE_1};
cursor: pointer;
padding: 8px 23px;
margin: auto;
`

const ErrorMessage = styled.div`
color: ${ERROR_RED};
font-size: 14px;
font-weight: 400;
margin-bottom: 12px;
`

const SuccessMsg = styled.div`
font-size: 18px;
 min-height: 300px;
 @media ${devices.mobile} {
    min-height: 250px;
  }
`

const ResetPasswordForm = ({ handleSubmit, onCancel }) => {
  const dispatch = useDispatch()
  const [passwordScore, setPasswordScore] = useState(0)
  const [successMsg, setSuccessMsg] = useState(false)
  const [passwordConfirmed, setPasswordConfirmed] = useState(false)

  const password_confirmation = useSelector((state) => state.form?.changePasswordForm?.values?.password_confirmation)
  const password = useSelector((state) => state.form?.changePasswordForm?.values?.password)
  const isLoading = useSelector(state => state.changePassword.isLoading)
  const errMsg = useSelector(state => state.changePassword.errMsg)
  const isProcessing = useSelector(state => state.changePassword.isProcessing)

  // Handle Unmount logic
  useEffect(() => () => dispatch(changePasswordError(null)), [changePasswordError])

  const handleChange = e => {
    const fieldName = e.target.name
    const value = e.target.value
    if (fieldName === 'password_confirmation' && !!value) {
      if (password !== value) {
        setPasswordConfirmed(false)
        dispatch(changePasswordError({ password_confirmation: 'Password do not match.' }))
      } else if (password === value) {
        setPasswordConfirmed(true)
      }
    }
  }

  const handleBlur = e => {
    if ((e.target.name === 'password' || e.target.name === 'password_confirmation') || (e.target.value)) {
      if (password !== password_confirmation) {
        dispatch(changePasswordError({ password_confirmation: 'Password do not match.' }))
        setPasswordConfirmed(false)
      } else if (password === password_confirmation && password && password_confirmation) {
        setPasswordConfirmed(true)
      }
    }
  }

  const handleOnFocus = e => {
    const fieldName = e.target.name
    dispatch(changePasswordError(null)) // HINT: Added Temporary
    if (!!errMsg && !!errMsg.otherError) dispatch(removeFieldError('otherError'))
    if (!!errMsg && errMsg[fieldName]) dispatch(removeFieldError(fieldName))
  }

  const submitForm = async data => {
    if (passwordScore < 4) return false
    const resp = await dispatch(changePassword(data))
    if (resp) {
      dispatch(disabledOtherSection(false))
      setSuccessMsg(true)
      setTimeout(() => {
        onCancel()
      }, 2000)
    }
  }

  return (
    <Form onSubmit={handleSubmit(submitForm)} data-testid='RestForm-testid'>
      {!successMsg
        ? <Fragment>
        {!!isLoading && <DotSpinLoading />}
        <Field
          id="old_password"
          name="old_password"
          type="password"
          label="Old Password"
          errResp={!!errMsg && !!errMsg.old_password ? errMsg.old_password : false}
          autoComplete={false}
          component={ReduxPrimaryInput}
          showPassword={true}
          validate={[oldRequired]}
          handleOnFocus={handleOnFocus}
        />
        <div>
          <Field
            id="password"
            name="password"
            type="password"
            label="New Password"
            errResp={!!errMsg && !!errMsg.password ? errMsg.password : false}
            autoComplete={false}
            component={ReduxPrimaryInput}
            showPassword={true}
            validate={[newRequired, oldPasswordAndConfirmPassword]}
            handleOnFocus={handleOnFocus}
            handleOnChange={handleChange}
            handleOnBlur={handleBlur}
          />

          {!!password && <StrengthContainer
            returnScore={score => setPasswordScore(score)}
            password={password} />}
        </div>
        <Field
          id="password_confirmation"
          name="password_confirmation"
          type="password"
          label={passwordConfirmed ? 'Password Match' : !!errMsg && !!errMsg.password_confirmation ? errMsg.password_confirmation : 'Confirm New Password'}
          errResp={passwordConfirmed ? 'confirm_check' : !!errMsg && !!errMsg.password_confirmation}
          passwordConfirmed={passwordConfirmed}
          autoComplete={false}
          component={ReduxPrimaryInput}
          showPassword={true}
          validate={[confirmRequired, confirmPasswordsMatch]}
          handleOnFocus={handleOnFocus}
          handleOnChange={handleChange}
          handleOnBlur={handleBlur}
        />
        {!!errMsg && !!errMsg.otherError && <ErrorMessage>{errMsg.otherError}</ErrorMessage>}
        <ButtonHolder data-testid='Form-button-tesid'>
          <SaveButton label={isProcessing ? 'Loading...' : 'Save'}
            disabled={!!isProcessing || (passwordScore < 4)} className="primary" type="submit" />
          <CancelButton className='typographyV2 body' onClick={() => {
            dispatch(disabledOtherSection(false))
            onCancel()
          }}>Cancel</CancelButton>
        </ButtonHolder>
      </Fragment>
        : <SuccessMsg>Your New Password Has Been Set!</SuccessMsg>
      }
    </Form>
  )
}

ResetPasswordForm.propTypes = {
  errMsg: PropTypes.any,
  handleSubmit: PropTypes.func,
  isProcessing: PropTypes.bool,
  onCancel: PropTypes.func,
  password: PropTypes.any,
  changePassword: PropTypes.func,
  removeFieldError: PropTypes.func,
  isLoading: PropTypes.any,
  removeAllError: PropTypes.func,
  changePasswordError: PropTypes.func,
  password_confirmation: PropTypes.any
}

const ResetPasswordReduxForm = reduxForm({
  form: FORM_NAME,
  onSubmitFail: () => submitFailure()
})(ResetPasswordForm)

export default ResetPasswordReduxForm
