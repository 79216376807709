import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const CardFormContext = createContext()

export const CardFormProvider = ({ children }) => {
  const [cardState, setCardState] = useState({
    numberFocus: false,
    numberError: false,
    numberEmpty: true,
    numberMessage: true,
    expFocus: false,
    expError: false,
    expMessage: true,
    expEmpty: true,
    cvcFocus: false,
    cvcError: false,
    cvcEmpty: true,
    cvcMessage: true
  })

  const [splitCardState1, setSplitCardState1] = useState({
    numberFocus: false,
    numberError: false,
    numberEmpty: true,
    numberMessage: true,
    expFocus: false,
    expError: false,
    expMessage: true,
    expEmpty: true,
    cvcFocus: false,
    cvcError: false,
    cvcEmpty: true,
    cvcMessage: true
  })

  const [splitCardState2, setSplitCardState2] = useState(
    {
      numberFocus: false,
      numberError: false,
      numberEmpty: true,
      numberMessage: true,
      expFocus: false,
      expError: false,
      expMessage: true,
      expEmpty: true,
      cvcFocus: false,
      cvcError: false,
      cvcEmpty: true,
      cvcMessage: true
    }
  )

  return (
        <CardFormContext.Provider value={[cardState, setCardState, splitCardState1, setSplitCardState1, splitCardState2, setSplitCardState2]}>
            {children}
        </CardFormContext.Provider>
  )
}

CardFormProvider.propTypes = {
  children: PropTypes.any
}
