import { SET_FORGOT_PASSWORD_MESSAGE, SET_RESET_PASSWORD_MESSAGE } from '../actions/password_actions'

const defaultState = {
  forgotMessage: null,
  resetMessage: null
}

const PasswordReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_FORGOT_PASSWORD_MESSAGE:
      return Object.assign({}, state, {
        forgotMessage: action.forgotMessage
      })
    case SET_RESET_PASSWORD_MESSAGE:
      return Object.assign({}, state, {
        resetMessage: action.resetMessage
      })
    default:
      return state
  }
}

export default PasswordReducer
