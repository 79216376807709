import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'

const LoaderPlaceholder = ({ placeholder = '...', className = '' }) =>
    <div className={className} data-testid='jest-loadplaceholder-id'>{placeholder}</div>

LoaderPlaceholder.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string
}

export default LoaderPlaceholder
