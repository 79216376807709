import { helpersFn } from '@eargo/eargo-components'
import { handleAjax } from '../common/helpers'

export const fetchAllPaymentMethods = (queryParams = {}) => {
  let reqUrl = 'api/users/payment-methods'
  if (Object.keys(queryParams).length) {
    reqUrl = reqUrl + '?' + helpersFn.createQueryParams(queryParams)
  }
  return handleAjax('GET', reqUrl)
}

/**
 * Add New Payment Method
 * @param {Object} info
 */
export const addPaymentMethod = info => handleAjax('POST', 'api/users/payment-methods', info)

/**
 * Set Payment as default
 * @param {Integer} id
 */
export const setPaymentAsDefault = id => handleAjax('PATCH', `api/users/payment-methods/set-default/${id}`)

/**
 * Generate CyberSource
 * Public Key
 */
export const generateCBPublicKey = () => handleAjax('GET', 'api/cybersource/generate-public-key')

/**
 * Initiate CyberSource
 * Payer Auth Setup
 * Process
 */
export const initPayerAuthSetup = info => handleAjax('POST', 'api/cybersource/payer-auth-setup', info)
