import {
  DETECT_IS_MOBILE,
  PAYPAL_IS_LOADING,
  SET_BREAD_MODAL,
  SET_CART_STATUS,
  SET_OPEN_REVIEW,
  SET_COUPON_RESPONSE_MESSAGE,
  SET_ORDER_FOUND,
  SET_LOGIN_FORM,
  LOGIN_AUTH_SUCCESS,
  LOGIN_AUTH_FALSE,
  SET_AUTH_RESP,
  SET_AUTO_OUT,
  CLEAR_ON_AUTO_OUT,
  SET_IS_CBS,
  OPEN_MY_INFO_COLLAPSE,
  SET_LOADED_METHOD,
  DISABLED_OTHER_SECTION
} from '../actions/global'

const defaultState = {
  user: {},
  autoOut: false,
  isLoggedIn: null,
  isMobile: false,
  isLoading: false,
  showLogin: false,
  openCart: false,
  openReview: false,
  orderFound: true,
  methodLoaded: false,
  isCBS: false,
  openBreadModal: false,
  couponRespMessage: {
    error: false,
    message: false
  },
  collapseIsOpen: false,
  isDisabled: false,
  windowWidth: ''
}

const Global = (state = defaultState, action) => {
  switch (action.type) {
    case DETECT_IS_MOBILE:
    case PAYPAL_IS_LOADING:
    case SET_LOGIN_FORM:
    case SET_ORDER_FOUND:
    case SET_COUPON_RESPONSE_MESSAGE:
    case SET_CART_STATUS:
    case SET_BREAD_MODAL:
    case SET_OPEN_REVIEW:
    case LOGIN_AUTH_SUCCESS:
    case LOGIN_AUTH_FALSE:
    case SET_AUTH_RESP:
    case SET_AUTO_OUT:
    case CLEAR_ON_AUTO_OUT:
    case SET_IS_CBS:
    case SET_LOADED_METHOD:
      return Object.assign({}, state, action.payload)
    case OPEN_MY_INFO_COLLAPSE:
      return {
        ...state,
        collapseIsOpen: action.payload
      }
    case DISABLED_OTHER_SECTION:
      return {
        ...state,
        isDisabled: action.payload
      }
    default:
      return state
  }
}

export default Global
