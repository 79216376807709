import { handleAjax } from '../common/helpers'

// Fetch All Payment Methods
export const fetchAllPaymentMethods = () => handleAjax('GET', 'api/users/payment-methods')

/**
 * Add New Payment Method
 * @param {Object} info
 */
export const addPaymentMethod = info => handleAjax('POST', 'api/users/payment-methods', info)

/**
 * Edit Payment Method Info
 * @param {Integer} id
 * @param {Object} info
 */
export const editPaymentMethod = (id, info) => handleAjax('PATCH', `api/users/payment-methods/${id}`, info)

/**
 * Set Payment as default
 * @param {Integer} id
 */
export const setMethodAsDefault = id => handleAjax('PATCH', `api/users/payment-methods/set-default/${id}`)

/**
 * Delete Payment Method
 * @param {Integer} id
 */
export const deletePaymentMethod = id => handleAjax('DELETE', `api/users/payment-methods/${id}`)

/**
 * Generate CyberSource
 * Public Key
 */
export const generateCBPublicKey = () => handleAjax('GET', 'api/cybersource/generate-public-key')

/**
 * Get Active Payment
 * Method list
 * @returns
 */
export const getActivePaymentMethods = () => handleAjax('GET', 'api/payment_methods')
