import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { ReactSelectBox } from '@eargo/eargo-components'

const selectBoxTitle = 'Choose an option'

const ReduxSelectBox = ({
  input, options, removeFieldError, placeholder, disabled = false, onInputChange,
  selectBoxStyle, handleOnChange, handleOnFocus, handleOnBlur, isSearchable = false,
  onMenuScrollToBottom = false, openMenuOnFocus = true
}) => {
  const dispatch = useDispatch()
  return <ReactSelectBox
    placeholder={placeholder || (input.value ? input.value : selectBoxTitle)}
    selectBoxStyle={selectBoxStyle}
    value={options.filter(o => o.value === input.value)}
    handleOnChange={(e) => {
      input.onChange(e.value)
      !!handleOnChange && handleOnChange(e.value)
      !!removeFieldError && dispatch(removeFieldError(input.name))
    }}
    openMenuOnFocus={openMenuOnFocus}
    handleOnFocus={e => {
      input.onFocus(e)
      !!removeFieldError && dispatch(removeFieldError(input.name))
      !!handleOnFocus && handleOnFocus(e)
    }}
    handleOnBlur={e => {
      input.onBlur()
      !!handleOnBlur && handleOnBlur(e)
    }}
    disabled={disabled}
    isSearchable={isSearchable}
    options={options}
    blurInputOnSelect={false}
    onMenuScrollToBottom={onMenuScrollToBottom}
    onInputChange={onInputChange}
  />
}

ReduxSelectBox.propTypes = {
  input: PropTypes.any,
  options: PropTypes.any,
  removeFieldError: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onInputChange: PropTypes.any,
  selectBoxStyle: PropTypes.any,
  handleOnChange: PropTypes.any,
  handleOnFocus: PropTypes.func,
  handleOnBlur: PropTypes.func,
  isSearchable: PropTypes.bool,
  onMenuScrollToBottom: PropTypes.any,
  openMenuOnFocus: PropTypes.bool
}

export default ReduxSelectBox
