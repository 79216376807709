import { ZIP_SERVER_MSG } from '../constant'
import { submitPrequalInfo } from '../util/insurance_prequal_util'
import { productsForInsurance, submitLead, submitSFLeadId } from '../util/lead_process_util'

export const RECEIVE_INSURANCE_PREQUAL = 'RECEIVE_INSURANCE_PREQUAL'
export const INSURANCE_PREQUAL_LOADING = 'INSURANCE_PREQUAL_LOADING'
export const INSURANCE_PREQUAL_ERROR_MSG = 'INSURANCE_PREQUAL_ERROR_MSG'
export const REMOVE_INSURANCE_PREQUAL_ERROR_MSG = 'REMOVE_INSURANCE_PREQUAL_ERROR_MSG'

const setLoadingStatus = isLoading => ({
  type: INSURANCE_PREQUAL_LOADING,
  payload: {
    isLoading
  }
})

const receiveInsurancePrequal = applicantId => ({
  type: RECEIVE_INSURANCE_PREQUAL,
  payload: {
    applicantId
  }
})

const setErrorMessage = errMsg => ({
  type: INSURANCE_PREQUAL_ERROR_MSG,
  payload: {
    errMsg
  }
})

export const removeFieldError = fieldName => ({
  type: REMOVE_INSURANCE_PREQUAL_ERROR_MSG,
  fieldName
})

export const submitInsurancePrequal = info => async (dispatch) => {
  dispatch(setLoadingStatus(true))
  dispatch(setErrorMessage(null))
  try {
    const resp = await submitPrequalInfo(info)
    dispatch(setLoadingStatus(false))
    if (resp.status === 'OK') {
      dispatch(receiveInsurancePrequal(resp.microservices_id))
      return true
    }
    return false
  } catch (error) {
    dispatch(setLoadingStatus(false))
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})
    dispatch(setErrorMessage(errorBag))
    console.log('submitInsurancePrequal Error: ', error)
    return false
  }
}

export const updateLeadAPI = info => async (dispatch) => {
  dispatch(setLoadingStatus(true))
  dispatch(setErrorMessage(null))
  try {
    const resp = await submitLead(info)
    dispatch(setLoadingStatus(false))
    return resp
  } catch (error) {
    dispatch(setLoadingStatus(false))
    const errorBag = !!error && !!error.responseJSON
      ? error.responseJSON
      : (!!error.responseJSON && !!error.responseJSON[0] ? {} : {})
    dispatch(setErrorMessage(errorBag))
    console.log('updateLeadAPI Error: ', error)
    return false
  }
}

export const sendSFLeadId = info => async (dispatch) => {
  dispatch(setLoadingStatus(true))
  try {
    const resp = await submitSFLeadId(info)
    dispatch(setLoadingStatus(false))
    return resp
  } catch (error) {
    dispatch(setLoadingStatus(false))
    return false
  }
}

/**
 * Get Products list
 * @param {*} info
 * @param {*} signal
 * @returns
 */
export const productForIns = (info, signal) => async (dispatch) => {
  dispatch(setErrorMessage(null))
  try {
    const resp = await productsForInsurance(info, signal)
    if (!!resp && !!resp.success && !!resp.products) {
      return resp.products
    }
    return false
  } catch (error) {
    console.log('productForIns Error: ', error)

    const errJson = error.responseJSON

    if (!!errJson && !!errJson.error &&
      (errJson.error === ZIP_SERVER_MSG)) {
      dispatch(setErrorMessage({ shipping_zipcode: 'Zipcode combo is incorrect' }))
      return false
    }

    const errorBag = !!errJson && !!errJson.error ? errJson.error : (!!errJson && !!errJson[0] ? {} : {})
    dispatch(setErrorMessage(errorBag))
    return false
  }
}
