import React from 'react'
import PropTypes from 'prop-types'

const Documents = ({
  style = {},
  fill = '#333333',
  width = 24,
  height = 24,
  className = '',
  onClick,
  viewBox = '0 0 24 24'
}) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      className={className}
      onClick={() => !!onClick && onClick()}
      fill="none"
    >
      <path stroke={fill} strokeLinecap="round" strokeWidth="1.5" d="m6 6 12 .02M6 14l12 .02M6 10l12 .02M6 18l12 .02"/><rect width="18.5" height="20.5" x="2.75" y="1.75" stroke={fill} strokeWidth="1.5" rx=".75"/>
    </svg>
)

Documents.propTypes = {
  style: PropTypes.any,
  fill: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  viewBox: PropTypes.string
}

export default Documents
