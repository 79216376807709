import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import './index.scss'
import { openMyInfoCollapse } from '../../../actions/global'
import { colorCodes } from '../../../constant/colors'
import { addressUrl, checkLeadIdforDocumentUpload, SERVER_URL } from '../../../constant'
import { checkLeadId } from '../../../util/document'

const { ORANGE_1 } = colorCodes

const SidebarLabel = styled.label`
  ${(props) =>
    props.isActive
      ? `
  color: ${ORANGE_1} !important;
  ::before {
      content: url(${require('./active.svg').default});
      position: absolute;
      left: 0px;
  }
  line-height: 18px;
`
      : `
  line-height: 26px;
`}
`

const Sidebar = () => {
  // isAllowed is used for checking weather user allowed to create a support or not
  const dispatch = useDispatch()
  const history = useHistory()
  const collapseIsOpen = useSelector((state) => state.global.collapseIsOpen)
  const [sidebarMenu, setSidebarMenus] = useState(checkLeadIdforDocumentUpload())

  useEffect(() => {
    async function checkLeadData () {
      try {
        const response = await checkLeadId()
        const data = checkLeadIdforDocumentUpload(response)
        setSidebarMenus(data)
      } catch (error) {
        console.log('Error in check lead', error)
      }
    }
    checkLeadData()
  }, [])

  // It navigates user to their respective components
  const onChangeHandler = (to) => {
    history.location.pathname !== to && history.push(to)
  }

  const logoutHandler = async () => {
    const response = await fetch(`${SERVER_URL}/v2/api/auth/logout`, { credentials: 'include' })
    if (response) {
      window.location = addressUrl
    }
  }

  const checkActive = (to, i) => {
    if (location.pathname.substring(0, 16) === to) return true
    return history.location.pathname === to || (i === 2 && history.location.pathname.includes('order'))
  }

  return (
    <div
      className={`${
        collapseIsOpen ? 'close_collapse' : 'open_collapse'
      } my_info_side_bar`}
      data-testid='sidebar-container-testid'
    >
      <div className='collapse_option'>
        <p className={`typographyV2 caption collapse_text ${collapseIsOpen ? 'hide_collaspe' : ''}`}>
          Collapse
        </p>{' '}
        <div
          className='collapse_wrapper'
          onClick={() => dispatch(openMyInfoCollapse(!collapseIsOpen))}
        >
          <img
            className={`collapse_arrow ${collapseIsOpen && 'reverse_arrow'}`}
            src='https://assets.eargo.com/new_account/collapse.png'
            alt='collapse_arrow'
          />
        </div>
      </div>
      {sidebarMenu && sidebarMenu.length && sidebarMenu.map(
        ({ image, to, title, show, action }, i) =>
          show && (

            <SidebarLabel
              collapseIsOpen={collapseIsOpen}
              isActive={checkActive(to, i)}
              key={i}
              className={`typographyV2 sidebar_label ${checkActive(to, i) ? 'body_bold active_route' : 'body'}`}
            >
          {action === 'link' &&
              <div
                className='my_info_sidebar'
                onClick={() => onChangeHandler(to)}
              >
                <img className='collaspe_image' src={image} alt='logo' />
                <span className={`${collapseIsOpen ? 'hide_sidebar_label' : 'show_sidebar_label'}`}>{title}</span>
              </div>
          }
          {action === 'logout' &&
              <div
                className='my_info_sidebar'
                onClick={() => logoutHandler()}
              >
                <img className='collaspe_image' src={image} alt='logo' />
                <span className={`${collapseIsOpen ? 'hide_sidebar_label' : 'show_sidebar_label'}`}>{title}</span>
              </div>
          }
            </SidebarLabel>

          )
      )}
    </div>
  )
}

export default Sidebar
