/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { NavBar, DotSpinLoading } from '@eargo/eargo-components'
import { ProductNavigation } from '@eargo/ecomm-component-library'

import { withRouter } from 'react-router'

import { YOTPO_KEY, BASE_URL, addressUrl } from '../../constant'
import { getCartCount } from '../../common/helpers'
import { fetchCart } from '../../actions/cart_actions'
import { CartContainer } from '../lazyComponents'
import {
  setAuthState,
  setOpenReview,
  setCartStatus,
  setBreadModal
} from '../../actions/global'

import './Header.scss'

class Header extends Component {
  componentDidMount () {
    const { fetchCart } = this.props
    fetchCart()
  }

  handleReviews = (e) => {
    const { openReview, setOpenReview } = this.props
    setOpenReview(e.target.tagName === 'A' ? true : !openReview)
  }

  updateState = (e, field, value) => {
    e.preventDefault()
    if (field === 'openCart') {
      this.props.setCartStatus(value)
    }
  }

  handleOpenBreadModal = (e) => {
    const { setBreadModal } = this.props
    setBreadModal()
  }

  render () {
    const RESTRICTED_PATHS = []
    const {
      openCart,
      history,
      location,
      cart,
      userInfo,
      setAuthState,
      autoOut,
      variant,
      addToCart,
      headerJson
    } = this.props
    const shopAllURL = `${addressUrl}products-hearing-aids`
    const pathNameVal = location.pathname.toLowerCase()
    const displayPromoBanner = RESTRICTED_PATHS.includes(pathNameVal)
    return (
      <>
        {
          headerJson.isLoading ? <DotSpinLoading /> : <ProductNavigation id='header' showBanner={false} />
        }

        {openCart && (
          <div className="header_pdp_cart_container">
            <CartContainer open={openCart} updateState={this.updateState} />
          </div>
        )}
      </>
    )
  }
}

Header.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  promoBanner: PropTypes.any,
  fetchCart: PropTypes.func,
  cart: PropTypes.any,
  setAuthState: PropTypes.func,
  autoOut: PropTypes.any,
  userInfo: PropTypes.any,
  setOpenReview: PropTypes.func,
  openReview: PropTypes.any,
  addToCart: PropTypes.func,
  variant: PropTypes.any,
  headerJson: PropTypes.object,
  updateState: PropTypes.func,
  openCart: PropTypes.any,
  setCartStatus: PropTypes.func,
  setBreadModal: PropTypes.func
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  userInfo: state.global.user,
  autoOut: state.global.autoOut,
  promoBanner: state.promoBanner,
  variant: state.variant.variant,
  openReview: state.global.openReview,
  headerJson: state.headerJson,
  openCart: state.global.openCart
})

const mapDispatchToProps = (dispatch) => ({
  fetchCart: () => dispatch(fetchCart()),
  setAuthState: (resp) => dispatch(setAuthState(resp)),
  setOpenReview: (status) => dispatch(setOpenReview(status)),
  setCartStatus: (status) => dispatch(setCartStatus(status)),
  setBreadModal: () => {
    dispatch(setBreadModal(true))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))
