import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { colorCodes } from '../../../../constant/colors'
import Icon from '../../../icons'
import './index.scss'

const { GREY_6, ORANGE_1 } = colorCodes

const Pagination = ({ total, perPage, activePage, handlePageChange, pageInterval = 10 }) => {
  const [moblieWidth, setMobileWidth] = useState(window.innerWidth)
  let switchingPages = 5
  let calculatePages = 4

  window.onresize = function () {
    setMobileWidth(window.document.body.clientWidth)
  }

  if (moblieWidth < 800) {
    switchingPages = 2
    calculatePages = 1
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activePage])

  const numberOfPages = Math.ceil(total / perPage)
  const pageLimit = switchingPages

  let startPage, endPage
  if (numberOfPages <= pageLimit) {
    startPage = 1
    endPage = numberOfPages
  } else {
    if (activePage <= pageLimit) {
      startPage = 1
      endPage = pageLimit
    } else if (activePage + 1 >= numberOfPages) {
      startPage = activePage - (activePage % pageLimit) + 1
      endPage = numberOfPages
    } else {
      startPage = activePage - (activePage % pageLimit !== 0 ? activePage % pageLimit : pageLimit) + 1
      endPage = (startPage + calculatePages) > numberOfPages ? numberOfPages : startPage + calculatePages
    }
  }

  if (!!total === false || numberOfPages <= 1) return null

  const pageGroup = () => {
    const pageArr = []
    for (let n = startPage; n <= endPage; n++) {
      pageArr.push(n)
    }
    if ((pageArr.length < pageLimit) && (numberOfPages >= pageLimit)) {
      const el = pageArr[pageArr.length - 1]
      const len = pageArr.length
      for (let i = calculatePages; i >= len; i--) {
        pageArr.push(el - i)
      }
      pageArr.sort((a, b) => a - b)
    }
    return pageArr
  }

  return (
    <div className="pagination-container" role='jest-pagination-test-role'>
      <p className='typographyV2 body pagination-summary'>
        {activePage * pageInterval - (pageInterval - 1)}-{activePage * pageInterval > total ? total : activePage * pageInterval}  of {total}
      </p>
      <div className="d-flex">
        <div className={`pagination-prev-button center-flex ${activePage === 1 ? 'disable-cls' : ''}`} onClick={() => activePage > 1 && handlePageChange(activePage - 1)}>
          <Icon name="arrow-left" fill={activePage === 1 ? GREY_6 : ORANGE_1} />
          <label className={activePage === 1 ? 'disable-label' : ''}>PREV</label>
        </div>
        <div className='pagination-numbring-holder center-flex' >
          {pageGroup().map((page, index) => (
            <div key={index} onClick={() => handlePageChange(page)}
              className={`pagination-digit-container ${page > 9 ? 'pagination-not-single-digit' : ''} ${activePage === page ? 'active-page' : ''}`}>
              {page}
            </div>
          ))}
        </div>
        <div className={`pagination-next-button center-flex ${activePage === numberOfPages ? 'disable-cls' : ''}`} onClick={() => activePage !== numberOfPages && handlePageChange(activePage + 1)} >
          <label className={`${activePage === numberOfPages ? 'disable-label' : 'nextBtn'}`} >NEXT</label>
          <Icon name="arrow-right" fill={activePage === numberOfPages ? GREY_6 : ORANGE_1} />
        </div>
      </div>
    </div>
  )
}

Pagination.propTypes = {
  total: PropTypes.number,
  perPage: PropTypes.number,
  activePage: PropTypes.number,
  pageInterval: PropTypes.number,
  handlePageChange: PropTypes.func
}

export default Pagination
