import { fetchAuth, loginAttemptsApi, updateUser, uploadDocumentsApi, deleteDocumentApi } from '../util/auth'
import { handleUploadInProgress } from './documents'

export const HANDLE_UPDATE_USER_PROCESSING = 'HANDLE_UPDATE_USER_PROCESSING'
export const LOGIN_AUTH_SUCCESS = 'LOGIN_AUTH_SUCCESS'
export const LOGIN_AUTH_FALSE = 'LOGIN_AUTH_FALSE'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
export const REMOVE_UPDATE_USER_ERROR_MSG = 'REMOVE_UPDATE_USER_ERROR_MSG'
export const SET_AUTH_RESP = 'SET_AUTH_RESP'
export const SET_AUTO_OUT = 'SET_AUTO_OUT'

const handleProcessing = status => ({
  type: HANDLE_UPDATE_USER_PROCESSING,
  payload: {
    isLoading: status
  }
})

const loginAuthSuccess = user => ({
  type: LOGIN_AUTH_SUCCESS,
  payload: {
    loginAuthSuccess: true,
    autoOut: false,
    user
  }
})

const loginAuthFalse = () => ({
  type: LOGIN_AUTH_FALSE,
  payload: {
    loginAuthSuccess: false,
    autoOut: false,
    user: {}
  }
})

export const setAuthResp = payload => ({
  type: SET_AUTH_RESP,
  payload
})

const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: {
    isLoading: false,
    user
  }
})

export const updateUserError = errMsg => ({
  type: UPDATE_USER_ERROR,
  payload: {
    isLoading: false,
    errMsg
  }
})

export const removeFieldError = fieldName => ({
  type: REMOVE_UPDATE_USER_ERROR_MSG,
  fieldName
})

/**
 * Fetch logged in
 * user's info
 * @param {*} history
 */
export const fetchAuthInfo = history => async (dispatch) => {
  try {
    const response = await fetchAuth()
    // Adding scroll to Top
    window.scrollTo(0, 0)
    if (!!response && response.success && !!response.data && !!response.data.id) {
      dispatch(loginAuthSuccess(response.data))
    } else {
      dispatch(loginAuthFalse())
      // NOTE - Popup will display instead of redirecting.
    }
  } catch (error) {
    console.log('Fetch Auth Error: ', error)
  }
}

/**
 * check logged in for route
 * user's info
 * @param {*} history
 */
export const checkLoggedIn = history => async (dispatch) => {
  dispatch(handleProcessing(true))
  try {
    const response = await fetchAuth()
    if (!!response && response.success && !!response.data && !!response.data.id) {
      history.push('/my-info/dashboard')
    }
  } catch (error) {
    console.log('Fetch Auth Error: ', error)
  }
  dispatch(handleProcessing(false))
}

/**
 * Update logged in
 * user's info
 * @param {Object} info
 */
export const updateUserInfo = info => async (dispatch) => {
  try {
    dispatch(handleProcessing(true))
    const resp = await updateUser(info)
    if (!!resp && !!resp.id) {
      dispatch(updateUserSuccess(resp))
      return true
    }
    dispatch(checkAndSetAutoOut(resp))

    // HINT: It will be update after API endpoint change - For now it is temporary
    dispatch(updateUserError(!!resp && !!resp[0] ? { otherError: resp[0] } : {}))
    return false
  } catch (error) {
    console.log('Update userInfo Error: ', error)
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})
    dispatch(updateUserError(errorBag))
    return false
  }
}

export const loginAtempts = async (info, abortController) => {
  const errors = {}
  try {
    abortController.abort()
    abortController = new AbortController()
    const signal = abortController.signal
    // onBlur a call will fire for fetch the failedAttempt count
    const resp = await loginAttemptsApi(info, signal)
    // In case of success response, setState with failedAttempt
    if (!!resp && !!resp.success && !!resp.data &&
              typeof resp.data.attempts !== 'undefined') {
      return { failedAttempt: resp.data.attempts }
    } else {
      const errorBag = !!resp && !!resp.error ? resp.error : resp.message
      errors.otherError = errorBag
    }
  } catch (error) {
    if (!!error && !!error.name && (error.name === 'AbortError')) {
      console.log('Previous call Aborted!')
    } else {
      const errorBag = !!error.responseJSON && !!error.responseJSON.error
        ? error.responseJSON.error
        : (!!error.responseJSON && !!error.responseJSON[0] ? error.responseJSON[0] : 'Something went wrong, Please try again.')
      errors.otherError = errorBag
    }
    return error
  }
}

export const uploadDocuments = async (info, setDownloadProgress, dispatch) => {
  const errors = {}
  try {
    dispatch(handleUploadInProgress(true))
    const resp = await uploadDocumentsApi(info, setDownloadProgress)
    dispatch(handleUploadInProgress(false))
    return resp
  } catch (error) {
    dispatch(handleUploadInProgress(false))
    if (!!error && !!error.name && (error.name === 'AbortError')) {
      console.log('Previous call Aborted!')
    } else {
      const errorBag = !!error.responseJSON && !!error.responseJSON.error
        ? error.responseJSON.error
        : (!!error.responseJSON && !!error.responseJSON[0] ? error.responseJSON[0] : 'Something went wrong, Please try again.')
      errors.otherError = errorBag
    }
    return error
  }
}

export const deleteDocument = async (info) => {
  const errors = {}
  try {
    const resp = await deleteDocumentApi(info)
    return resp
  } catch (error) {
    if (!!error && !!error.name && (error.name === 'AbortError')) {
      console.log('Previous call Aborted!')
    } else {
      const errorBag = !!error.responseJSON && !!error.responseJSON.error
        ? error.responseJSON.error
        : (!!error.responseJSON && !!error.responseJSON[0] ? error.responseJSON[0] : 'Something went wrong, Please try again.')
      errors.otherError = errorBag
    }
    return error
  }
}

const setAutoOut = autoOut => ({
  type: SET_AUTO_OUT,
  payload: { autoOut }
})

export const checkAndSetAutoOut = res => dispatch => {
  if (
    (!!res && !res.success && !!res.code && ((res.code === 'unauthenticated') || (res.code === 'unauthorized_access'))) ||
    (!!res && !res.success && !!res.statusText && res.statusText === 'Unauthorized') // axios response format
  ) {
    dispatch(setAutoOut(true))
  }
}
