import { RECEIVE_SHIPMENT_TYPES } from '../actions/shipment_type_actions'

const ShipmentTypesReducer = (state = {}, action) => {
  Object.freeze(state)
  switch (action.type) {
    case RECEIVE_SHIPMENT_TYPES:
      return action.shipment_types
    default:
      return state
  }
}

export default ShipmentTypesReducer
