import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { EargoButtonV2 } from '@eargo/eargo-components'
import SupportHeader from './SupportHeader/SupportHeader'
import { createSupportHeading, createSupportSubHeading } from '../../../constant'
import './CreateSupportRequest.scss'

const CreateSupportRequest = ({ isAllowed }) => {
  const history = useHistory()

  return (
    <>
      <SupportHeader heading={createSupportHeading} subHeading={createSupportSubHeading} />
      <EargoButtonV2
        className={`primary stretch support-button ${!isAllowed && 'disabled'}`}
        label="Create Support Request"
        disabled={!isAllowed}
        handleOnClick={() => !!isAllowed && history.push('/my-info/support/form')}
        style={{ minWidth: 'fit-content' }}
      />
    </>
  )
}

CreateSupportRequest.propTypes = {
  isAllowed: PropTypes.bool
}

export default React.memo(CreateSupportRequest)
