import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { parse } from 'query-string'
import { EargoButtonV2, DotSpinLoading, AuthWrapper, HeaderSection, AdditionalInfoSection, CommonUnderline, Otp } from '@eargo/eargo-components'
import MessageContainer from '../common/MessageContainer'
import { resendActivateAccount, validateOTP, getCurrentUserEmail, fetchAuth } from '../../util/auth'
import { addressUrl } from '../../constant'
import './index.scss'

const AccountActivationCode = ({ history, location, match }) => {
  const [otp, setOtp] = useState('')
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({})
  const [isProcessing, setIsProcessing] = useState(false)
  const [expireMsg, setExpireMsg] = useState(false)
  const [successMsg, setSuccessMsg] = useState(false)
  useEffect(() => {
    async function fetchData () {
      console.log(email, expireMsg)
      const response = await fetchAuth()
      if (!!response && !!response.data && !!response.data.id) {
        history.push('/')
      } else {
        let email = false
        let expireMsg = 'You\'ll be redirect now, your request expired. Please make a new one.'
        const resp = await getCurrentUserEmail(match.params.id)
        if (!!resp && !!resp.success && !!resp.email) {
          email = resp.email
          expireMsg = false
        } else if (!!resp && !!resp.errorCode &&
          (resp.errorCode === 'request_expired')) {
          // NOTE - return is added to avoid error ( state can't be updated for an unmounted component )
          return history.push('/activate-account')
        }
        setEmail(email)
        setExpireMsg(expireMsg)
      }
    }

    fetchData()
    // eslint-disable-next-line
  }, [])

  const validateForm = () => {
    const error = {}
    let formIsValid = true
    if (!otp) {
      formIsValid = false
      error.otp = 'Please enter your code'
    }

    setErrors(error)
    setSuccessMsg(false)
    return formIsValid
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (validateForm()) {
      setIsProcessing(true)
      const error = {}
      try {
        const resp = await validateOTP(otp, match.params.id)
        if (!!resp && !!resp.success) {
          resp.data?.userType && resp.data?.userType === 'audiologist'
            ? (window.location = `${addressUrl}/professional-partner`)
            : (window.location = '/my-info/dashboard')
        } else {
          if (!!resp && !!resp.code && resp.code === 'request_expired') {
            setOtp('')
            history.push('/activate-account')
          } else {
            error.otherError = (!!resp && !!resp.error) ? resp.error : 'Verification failed. Please try again'
          }
        }
      } catch (error) {
        const errorBag = !!error.responseJSON && !!error.responseJSON.error
          ? error.responseJSON.error
          : (!!error.responseJSON && !!error.responseJSON[0] ? error.responseJSON[0] : 'Something went wrong, Please try again.')
        error.otherError = errorBag
      }
      setErrors(error)
      setSuccessMsg(false)
      setIsProcessing(false)
    }
  }

  const resendCode = async () => {
    setOtp('')
    setIsProcessing(true)
    const error = {}
    let successMsg = false
    try {
      const queryParams = parse(window.location.search)
      if (!!match && !!match.params && !!match.params.id) {
        const resp = await resendActivateAccount(match.params.id, false)
        if (!!resp && !!resp.type && resp.type === 'success') {
          successMsg = 'Activation code sent successfully'
        } else if (!!resp && !!resp.code && resp.code === 'request_expired') {
          error.otherError = 'You\'ll be redirect now, your request expired. Please make a new one.'
          setTimeout(() => {
            (!!queryParams && !!queryParams.redirect_uri)
              ? history.push('/activate-account?redirect_uri=' + queryParams.redirect_uri)
              : history.push('/activate-account')
          }, 2000)
        } else {
          const errorBag = !!resp && !!resp.error && !!resp.message ? resp.message : ''
          error.otherError = errorBag
        }
      }
    } catch (error) {
      const errorBag = !!error.responseJSON && !!error.responseJSON.error
        ? error.responseJSON.error
        : (!!error.responseJSON && !!error.responseJSON[0] ? error.responseJSON[0] : 'Something went wrong, Please try again.')
      error.otherError = errorBag
    }
    setErrors(error)
    setIsProcessing(false)
    setSuccessMsg(successMsg)
  }

  const otherErrWindow = !!errors && !!errors.otherError
  // const isResend = !!location && !!location.state && !!location.state.resendCode
  return (
    <AuthWrapper>
      <div className="center-flex interFontRegular activate_account_container" id="eargo-activation-code-container">
        <HeaderSection heading='Verify Code' label='Please enter the code we have sent to:' email={email}/>
        {!!isProcessing && <DotSpinLoading />}
        <form className='form_container' onSubmit={handleSubmit} >
          <Otp setOtp={(value) => { setOtp(value) }} otp={otp} />
          {!!otherErrWindow && <div className='message_container'><MessageContainer messageList={errors.otherError} /></div>}
          {!!successMsg && <div className='message_container'><MessageContainer messageList={successMsg} isError={false} /></div>}
          <div>
            <EargoButtonV2
              label={isProcessing ? 'Loading...' : 'Activate Account'}
              type="submit"
              disabled={!otp}
              className={`${(!otp || otp.length < 4) ? 'disabled' : ''} primary`}
            />
          </div>
          <AdditionalInfoSection heading='Didn’t receive a code?' subHeading='Please allow 3 minutes to receive the code' label='Resend the code' handleOnClick={resendCode} />
          <CommonUnderline />
          <div className='body_bold typographyV2 back_to_login_tag' onClick={() => history.push('/login')}>
            Back to login
          </div>
        </form>
      </div>
    </AuthWrapper>
  )
}

AccountActivationCode.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  match: PropTypes.any
}

export default withRouter(AccountActivationCode)
