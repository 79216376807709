import { helpersFn } from '@eargo/eargo-components'
import { SERVER_URL } from '../constant'
import axios from 'axios'

/**
 * Fetch logged in
 * auth info
 */
export const fetchAuth = async () => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('GET', `${SERVER_URL}v2/api/auth`)
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

/**
 * Fetch logged in
 * auth info
 */
export const getUserInfoApi = async (id) => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('GET', `${SERVER_URL}v2/api/auth/login-account-detail/${id}`)
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

/**
 * Update Logged in
 * user's info
 * @param {*} info
 */
export const updateUser = async (info) => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('PATCH', `${SERVER_URL}v2/api/users`, JSON.stringify(info))
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

/**
 * Login Attempt
 * user's info
 * @param {*} info
 */
export const loginAttemptsApi = async (info, signal) => {
  return new Promise((resolve, reject) => {
    fetch(`${SERVER_URL}v2/api/auth/attempts`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(info),
      signal
    })
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

/**
 * upload documents
 * document info
 * @param {*} info
 */
export const uploadDocumentsApi = async (info, setDownloadProgress) => {
  return new Promise((resolve, reject) => {
    axios.request({
      method: 'POST',
      url: `${SERVER_URL}v2/api/document-upload/${info.get('type')}`,
      withCredentials: true,
      data: info,
      onUploadProgress: (data) => {
        const loaded = Math.round(data.loaded / 1000, 1)
        const total = Math.round(data.total / 1000, 1)
        setDownloadProgress(loaded + 'kb / ' + total + 'kb')
      }
    })
      .then(result => resolve(result.data))
      .catch(error => reject(error))
  })
}

/**
 * Delete Document
 * by id
 * @param {*} id
 */
export const deleteDocumentApi = async (info) => {
  return new Promise((resolve, reject) => {
    fetch(`${SERVER_URL}v2/api/insurance-documents/`, {
      method: 'DELETE',
      credentials: 'include',
      body: JSON.stringify(info),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

/**
 * Request Forgot Password
 * @param {*} email
 */
export const requestForgotPassword = email => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('POST', `${SERVER_URL}v2/api/password/forgot`, JSON.stringify({ email }))
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

/**
 * Resend OTP Request
 * @param {*} reqObj
 */
export const resendOTPRequest = reqObj => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('POST', `${SERVER_URL}v2/api/password/resend`, JSON.stringify(reqObj))
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

/**
 * Fetch Forgot Password
 * @param {*} id
 */
export const fetchForgotPassword = id => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('GET', `${SERVER_URL}v2/api/user-email?req_id=${id}`)
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

/**
 * Change Password for
 * logged in user
 * @param {Object} info
 */
export const requestChangePassword = info => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('POST', `${SERVER_URL}v2/api/password/change-password`, JSON.stringify(info))
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

/**
 * Request To Check The
 * Activate Account OTP
 * @param {*} opt
 */
export const validateOTP = (activationCode, requestId) => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('POST', `${SERVER_URL}v2/api/auth/activate-account`, JSON.stringify({
      activation_code: activationCode,
      activation_code_request_id: requestId
    }))
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

/**
 * Request Activation Code
 * again after expire
 * @param {*} email
 * @param {*} newCode
 * @returns
 */
export const resendActivationCode = (email, newCode) => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('POST', `${SERVER_URL}v2/api/auth/resend-activation-code`, JSON.stringify({
      email,
      new_activation_code: newCode
    }))
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

/**
 * Get Email For SignUp User
 * @param {*} id
 */
export const getCurrentUserEmail = id => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('GET', `${SERVER_URL}v2/api/user-email?activation_code_request_id=${id}`)
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

/**
 * Request Activation Code
 * Resend after expire
 * @param {*} requestId
 * @param {*} code
 * @returns
 */
export const resendActivateAccount = (requestId, code) => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('POST', `${SERVER_URL}v2/api/auth/resend-activation-code`, JSON.stringify({
      activation_code_request_id: requestId,
      new_activation_code: code
    }))
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

export const fetchLogin = (email, password) => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('POST', `${SERVER_URL}v2/api/auth/login`, JSON.stringify({
      email,
      password
    })).then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

export const sendOtp = (email) => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('POST', `${SERVER_URL}v2/api/auth/send-otp`, JSON.stringify({
      email
    })).then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

export const verifyOTP = (otp, email) => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('POST', `${SERVER_URL}v2/api/auth/verify-otp`, JSON.stringify({
      login_otp: otp,
      email: email
    }))
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

export const resendLoginOtp = (email, code) => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('POST', `${SERVER_URL}v2/api/auth/resend-login-otp`, JSON.stringify({
      email: email,
      new_login_code: code
    }))
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

export const checkIfExist = (email) => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('POST', `${SERVER_URL}v2/api/auth/fetch-user`, JSON.stringify({
      email
    })).then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

export const requestSetPassword = info => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('POST', `${SERVER_URL}v2/api/password/set-password`, JSON.stringify(info))
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}
