import { helpersFn } from '@eargo/eargo-components'

export const breadContent = {
  BREAD_36: [
    {
      sectiontitle: 'Affordable Monthly Plans',
      sectioncontent: 'Pay for your order over time at competitive rates with Bread Pay™. 0% APR financing is available for 12, 24, and 36-month loan terms*.',
      alt: 'Piggie',
      img: 'https://assets.eargo.com/pdp/breadModal/CalendarIcon.svg'
    },
    {
      sectiontitle: 'No Prepayment Penalties',
      sectioncontent: 'You pay back the loan in monthly payments and can prepay at any time without penalty.',
      alt: 'Heart',
      img: 'https://assets.eargo.com/pdp/breadModal/MoneyIcon.svg'
    },
    {
      sectiontitle: 'Easy Application',
      sectioncontent: 'Get a decision in seconds with no obligation to buy. Checking your rate won\'t affect your credit score. Your data is safe and encrypted.',
      alt: 'Snap',
      img: 'https://assets.eargo.com/pdp/breadModal/ApplicationIcon.svg'
    }
  ],
  BREAD_24: [
    {
      sectiontitle: 'Affordable Monthly Plans',
      sectioncontent: 'Pay for your order over time at competitive rates with Bread Pay™. 0% APR financing is available for 12 and 24-month loan terms*.',
      alt: 'Piggie',
      img: 'https://assets.eargo.com/pdp/breadModal/CalendarIcon.svg'
    },
    {
      sectiontitle: 'No Prepayment Penalties',
      sectioncontent: ' You pay back the loan in monthly payments and can prepay at any time without penalty.',
      alt: 'Heart',
      img: 'https://assets.eargo.com/pdp/breadModal/MoneyIcon.svg'
    },
    {
      sectiontitle: 'Easy Application',
      sectioncontent: "Get a decision in seconds with no obligation to buy. Checking your rate won't affect your credit score. Your data is safe and encrypted.",
      alt: 'Snap',
      img: 'https://assets.eargo.com/pdp/breadModal/ApplicationIcon.svg'
    }
  ],
  logo: 'https://assets.eargo.com/pdp/breadModal/Eargo.svg',
  closeLogo: 'https://assets.eargo.com/pdp/breadModal/CloseLogo.png',
  title: 'Get Eargo Today, Pay Over Time',
  subtitleP1: 'Powered by Bread Pay™. Bread Pay™ is not affiliated with Eargo. Any information you provide here will be received by Bread Financial™ and its affiliates, and the Bread Privacy Policy will govern the use of this information.',
  // subtitleP2: 'privacy policy',
  // subtitleP3: 'will govern the use of this information.',
  breadbutton: 'Check your rate',
  m1: 'Please note, your financial institution may charge you one or more non-sufficient funds or overdraft fees if any loan payment exceeds your account’s available balance. If you pay with a credit card, your credit card issuer may charge interest and/or fees.'
  // m2: "There's no obligation to purchase when you prequalify for financing.",
  // m3: '*Subject to approval of credit application. Rates range from 0% to 9.99% APR, resulting in, for example, 36 monthly payments of $31.80 at 9.99% APR, per $1,000 borrowed. APRs will vary depending on credit qualifications, loan amount, and term. Some financing terms will vary based on purchase price and/or product. Bread',
  // m4: 'pay-over-time plans are loans made by Comenity Capital Bank.'
}

export const breadFinanceText = (price, finance_price, discount, months = 36, aprValue = '9.99%') => {
  return `*Monthly payment of $${finance_price}/month is based on the listed product price of $${helpersFn.getDecimalPriceVal(price - (discount || 0))} assuming a ${months}-month term and a ${aprValue} APR. Subject to approval of credit application. Rates range from 0% to 29.99% APR. APRs will vary depending on credit qualifications, loan amount, and term. Bread Pay™ loans are made by Comenity Capital Bank, a Bread Financial™ company.`
}
