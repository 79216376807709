import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { EargoButtonV2, ReactCheckBox, RoundRadioInput, DotSpinLoading } from '@eargo/eargo-components'

import SectionTitle from './SectionTitle'
import { fetchUserAddresses, setAddressPopUp, setDefaultAddress, setSavedAddressId } from '../../actions/user_address'
import { getStateAbbr } from '../../common/helpers'
import './AllShipAddress.scss'

const AllShipAddress = ({
  cart, setSelectedAddress, updateShipAddress,
  addresses, isLoading, setAddressPopUp, fetchUserAddresses,
  toggleAddressList, addressPayload, states, setDefaultAddress, defaultAddrId
}) => {
  const [selected, setSelected] = useState(defaultAddrId)
  const [localLoading, setLocalLoading] = useState(false)

  useEffect(() => { setSelected(defaultAddrId) }, [defaultAddrId])

  useEffect(() => {
    fetchUserAddresses()
    // eslint-disable-next-line
  }, [])

  // Handle Unmount logic
  useEffect(() => {
    return () => {
      if (!!cart && !!cart.shipAddress && !!cart.shipAddress.id) {
        setSelectedAddress(cart.shipAddress.id)
      }
      setLocalLoading(false)
    }
  }, [cart, setSelectedAddress])

  const shipWithThisAddress = async () => {
    try {
      setLocalLoading(true)
      await updateShipAddress(addressPayload)
      toggleAddressList(false)
    } catch (e) {
      setLocalLoading(false)
      console.log('Error in shipWithThisAddress ', e)
    }
  }

  return (
    <div>
      {(!!isLoading || !!localLoading) && <DotSpinLoading />}
      <SectionTitle title="Ship to" />
      {addresses.map((obj, index) => (
        <div key={index} className='all_ship_address_container'>
          <div className="d-flex mb-4">
            <RoundRadioInput
              label={!!obj.id}
              value={obj.id}
              checked={obj.id === selected}
              id='my-shipping-address'
              name='my-shipping-address'
              handleOnChange={() => {
                setSelected(obj.id)
                setSelectedAddress(obj.id)
              }}
            />
            <div>
              <span className="body typographyV2 eargo-capitalize shipping_details_span">{(obj.firstname ? obj.firstname : '') + ' ' + (obj.lastname ? obj.lastname : '')}</span>
              <span className="body typographyV2 shipping_details_span">{[obj.address1 ? [obj.address1].concat('') : '', obj.address2 ? [obj.address2].concat('') : '', obj.city ? [obj.city].concat('') : '', getStateAbbr(states, obj.state_id) + ',', obj.zipcode].join(' ')}</span>
            </div>
          </div>
          <div className="pl-30">{obj.default
            ? <span className='caption typographyV2 shipping_details_span'>Your default shipping address</span>
            : <span className='shipping_details_span' onClick={() => setDefaultAddress(obj.id)}>
              <ReactCheckBox label='Make default address' />
            </span>}
          </div>
        </div>
      )
      )}
      <div className='shipping_footer'>
        <div onClick={() => setAddressPopUp(true)} id='eargo-add-new-add-link' className='label_holder'>
          <img src='https://assets.eargo.com/account/insuranceform/add.svg' alt="add-svg" />
          <span className="body typographyV2 ml-5">Add a new address</span>
        </div>
        <div className='button_section' onClick={() => shipWithThisAddress()}>
          <EargoButtonV2
            className='primary whole_width'
            label={`${(!!localLoading || !!isLoading) ? 'Loading...' : 'Ship To This Address'}`}
          />
        </div>
      </div>
    </div>
  )
}

AllShipAddress.propTypes = {
  cart: PropTypes.any,
  setSelectedAddress: PropTypes.any,
  updateShipAddress: PropTypes.any,
  addresses: PropTypes.any,
  isLoading: PropTypes.any,
  setAddressPopUp: PropTypes.any,
  fetchUserAddresses: PropTypes.any,
  toggleAddressList: PropTypes.any,
  addressPayload: PropTypes.any,
  states: PropTypes.any,
  setDefaultAddress: PropTypes.func,
  defaultAddrId: PropTypes.any
}

const mapStateToProps = state => ({
  isLoading: state.userAddresses.isLoading
})

const mapDispatchToProps = dispatch => ({
  setSelectedAddress: id => dispatch(setSavedAddressId(id)),
  setDefaultAddress: id => dispatch(setDefaultAddress(id)),
  fetchUserAddresses: () => dispatch(fetchUserAddresses()),
  setAddressPopUp: status => dispatch(setAddressPopUp(status))
})

export default connect(mapStateToProps, mapDispatchToProps)(AllShipAddress)
