import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import * as EmailValidator from 'email-validator'
import { PrimaryInput, DotSpinLoading, EargoButtonV2 } from '@eargo/eargo-components'
import { resendActivationCode, fetchAuth } from '../../util/auth'
import { colorCodes } from '../../constant/colors'
import { devices } from '../../constant/devices'
import MessageContainer from '../common/MessageContainer'

const { mobile } = devices
const { GREY_1, WHITE, DIM_GREY } = colorCodes

const EargoButtonV2_style = styled(EargoButtonV2)`
  align-self: center;
`

const Container = styled.div`
    margin: 0 auto;
    padding: 52px 0px;
    background: ${WHITE};
    flex-direction: column;
`

const Title = styled.div`
    height: 52px;
    font-size: 36px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 4px;
    color: ${GREY_1};
    @media ${mobile} {
        height: unset;
        line-height: unset;
    }
`

const Description = styled.div`
    width: 624px;
    height: 54px;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: ${GREY_1};
    @media ${mobile} {
        padding: 0 20px;
        height: unset;
        width: unset;
    }
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    @media ${mobile} {
        max-width: 483px;
        width: 100%;
    }
    .primary-input-label {
        height: 64px;
        width: 460px;
        box-sizing: border-box;
        margin: ${props => props.otherError ? '34px 24px 24px 24px' : '34px 24px 48px 24px'};
        @media ${mobile} {
            width: 90%;
            margin: ${props => props.otherError ? '34px auto 24px auto' : '34px auto 48px auto'};
        }
    }
    span {
        font-size: 18px;
        line-height: 26px;
        color: ${DIM_GREY} !important;
    }
    .error_border span {
        font-size: 14px;
    }
`

// const Button = styled(EargoButton)`
//     text-transform: uppercase;
//     width: ${props => props.resend ? 300 : 270}px;
//     height: 56px;
//     font-size: 18px;
//     &.disabled {
//         background-color: ${ORANGE_4};
//     }
// `

const MessageHolder = styled(MessageContainer)`
    width: 480px;
    margin: 0px 0px 12px 30px;
    @media ${mobile} {
        width: 90%;
        margin: 0px auto 12px auto;
    }
`

const ActivateAccount = ({ history }) => {
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({})
  const [isProcessing, setIsProcessing] = useState(false)

  useEffect(() => {
    async function fetchData () {
      const response = await fetchAuth()
      if (!!response && !!response.data && !!response.data.id) {
        history.push('/')
      }
    }
    fetchData()
    // eslint-disable-next-line
  }, [])

  const handleFocus = e => {
    const { name } = e.target
    const error = errors
    if (error) {
      !!error[name] && delete error[name]
      !!error.otherError && delete error.otherError
    }
    setErrors(error)
  }

  const requestActivateAccount = async (error) => {
    try {
      const resp = await resendActivationCode(email, true)
      if (!!resp && !!resp.success) {
        setErrors(resp.error)
        setIsProcessing(false)
        return resp
      } else {
        const errorBag = !!resp && !!resp.error && !!resp.message
          ? resp.message
          : (!!resp && !!resp.error ? resp.error : '')
        error.otherError = errorBag
        setErrors(error)
        setIsProcessing(false)
        return false
      }
    } catch (error) {
      const errorBag = !!error.responseJSON && !!error.responseJSON.error
        ? error.responseJSON.error
        : (!!error.responseJSON && !!error.responseJSON[0] ? error.responseJSON[0] : 'Something went wrong, Please try again.')
      error.otherError = errorBag
      setErrors(error)
      setIsProcessing(false)
      return false
    }
  }

  const validateForm = () => {
    const error = {}
    let formIsValid = true

    if (!email) {
      formIsValid = false
      error.email = 'Please enter your email'
    } else {
      if (!EmailValidator.validate(email)) {
        formIsValid = false
        error.email = 'Please enter valid email'
      }
    }
    setErrors(error)
    return formIsValid
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const error = {}
    setIsProcessing(true)
    if (validateForm()) {
      const resp = await requestActivateAccount(error)
      if (resp.success) {
        history.push({
          pathname: '/activate-account/' + resp.activation_code_request_id,
          state: { resendCode: true }
        })
      }
    }
    setErrors(error)
    setIsProcessing(false)
  }

  const preventSubmit = !!Object.keys(errors).length || !email
  const errorList = errors.otherError
  return (
    <Container className="center-flex" id="eargo-activate-account-container">
      <Title className="super"> Your Activation Code Has Expired! </Title>
      <Description>Please enter the email address you used to create the account, and we’ll send you an activation code to reset your password.</Description>
      {!!isProcessing && <DotSpinLoading />}
      <Form onSubmit={handleSubmit} otherError={!!errors && !!errors.otherError}>
        <PrimaryInput
          name="email"
          value={email || ''}
          handleOnBlur={validateForm}
          handleOnFocus={handleFocus}
          handleOnChange={e => {
            setEmail(e.target.value.trim())
            setErrors({})
          }}
          errClass={!!errors && !!errors.email ? 'error_border' : ''}
          label={!!errors && !!errors.email ? errors.email : 'Email'}
        />
        {!!errors && !!errors.otherError && <MessageHolder messageList={errorList} />}
        <EargoButtonV2_style
          label={isProcessing ? 'Loading...' : 'Send Activation Code'}
          type="submit"
          disabled={preventSubmit}
          className={`primary stretch ${preventSubmit ? 'disabled' : ''} bold`} />
      </Form>
    </Container>
  )
}

ActivateAccount.propTypes = {
  history: PropTypes.any
}

export default withRouter(ActivateAccount)
