import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field } from 'redux-form'
import { EargoButtonV2 } from '@eargo/eargo-components'
import ReduxPrimaryInput from '../../common/redux-form/ReduxPrimaryInput'
import CommonAddressField from '../AddressSection/CommonAddressField'
import { paymentsError, removeFieldError, generateCyberSourceKey, getPaymentMethods } from '../../../actions/info_payment_method'
import { handleFullName, minLength, noOnlyNumber, onlyOneSpecialCharDash, requiredField, whiteSpace, doubleSpace } from '../../common/redux-form/validation'
import { fetchAllAddresses } from '../../../util/address_api_util'
import { ReduxCheckboxField } from '../../common/redux-form/ReduxCheckboxInput'
import AddressSelectBox from './AddressSelectBox'
import CyberSourceForm from './CyberSourceForm'
import { scrollIntoView } from '../../../common/helpers'
import { checkAndSetAutoOut } from '../../../actions/auth'
import { disabledOtherSection } from '../../../actions/global'
import './CardForm.scss'

const fullNameRequire = requiredField('Name')
const minChar4 = minLength(4, 'Too Short')

const ElementWrapperLabel = styled.label`
overflow-y: hidden;
&.error_border span{
    font-size: 14px !important;
}
`

const ElementWrapper = ({ label, children, labelClass, isValue }) => (
  <ElementWrapperLabel className={`card-containers inputContainer cardholder-name padding-left-0 ${labelClass}`}>
    <span className={`body1_light ${isValue ? 'floating_label padding-left-24' : 'label'}`}>
      {label}
    </span>
    {children}
  </ElementWrapperLabel>
)

ElementWrapper.propTypes = {
  label: PropTypes.any,
  children: PropTypes.any,
  labelClass: PropTypes.string,
  isValue: PropTypes.any
}

const CardForm = ({
  isCBS, errMsg, onSubmit, isProcessing, isEdit = false, isDisable = false, checkAndSetAutoOut, disabledOtherSection,
  handleMicroformIns, microform, expiration, setCardBrand, change, cyberSourceKey, generateCyberSourceKey, getPaymentMethods,
  handleOnIdChange, onCancel, states, removeFieldError, setPaymentErr, methodCount, initialValues = {}
}) => {
  useEffect(() => {
    getPaymentMethods()

    // eslint-disable-next-line
  }, [])

  const [cardCode, setCardCode] = useState(false)

  const [cbsState, setCBSState] = useState({
    numberFocus: false,
    numberError: false,
    numberEmpty: true,
    numberMessage: true,
    expFocus: false,
    expError: false,
    expMessage: true,
    expEmpty: true,
    cvcFocus: false,
    cvcError: false,
    cvcEmpty: true,
    cvcMessage: true
  })

  const [addresses, setAddresses] = useState([])

  const fetchAddress = async () => {
    try {
      const resp = await fetchAllAddresses()
      if (!!resp && !!resp.success) {
        setAddresses(resp.rows || [])
      }
    } catch (error) {
      if (error.responseJSON) {
        checkAndSetAutoOut(error.responseJSON)
      }
    }
  }

  useEffect(() => {
    fetchAddress()

    if (!cyberSourceKey && !!isCBS) {
      generateCyberSourceKey()
    }
    // eslint-disable-next-line
  }, [isCBS])

  const scrollToTop = () => {
    setTimeout(() => {
      if (isEdit) {
        const element = document.getElementById('eargo-payment-card-form')
        !!element && element.scrollIntoView({ behavior: 'smooth' })
      }
    }, 100)
  }

  useEffect(() => {
    scrollToTop()
    setPaymentErr()
    // eslint-disable-next-line
  }, [setPaymentErr])

  const handleOnFocus = e => {
    const fieldName = e.target.name
    setPaymentErr() // HINT: Added Temporary
    if (!!errMsg && !!errMsg.otherError) removeFieldError('otherError')
    if (!!errMsg && errMsg[fieldName]) removeFieldError(fieldName)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (!!isCBS && !!microform) {
      const { fields: { number, securityCode } } = microform
      if (!number || !securityCode) {
        return false
      }

      const expirationVal = !!expiration && expiration.split('/')
      microform.createToken({
        expirationMonth: expirationVal[0],
        expirationYear: `20${expirationVal[1]}`,
        type: cardCode
      }, async function (err, token) {
        if (err) {
          onSubmit()
          if (!!err && !!err.reason && !err.details && (err.reason === 'CREATE_TOKEN_VALIDATION_SERVERSIDE')) {
            handleMicroformIns(false)
            generateCyberSourceKey()
            setPaymentErr({
              ...errMsg,
              otherError: 'Card session time out, Please enter again.'
            })
          }
          if (!!err && !!err.reason && (
            (err.reason === 'CREATE_TOKEN_UNABLE_TO_START') ||
            (err.reason === 'FIELD_LOAD_CONTAINER_SELECTOR') ||
            (err.reason === 'CREATE_TOKEN_CAPTURE_CONTEXT_USED_TOO_MANY_TIMES'))) {
            handleMicroformIns(false)
            generateCyberSourceKey()
            setPaymentErr({
              ...errMsg,
              otherError: err.message
            })
          }
          if (!!err && !!err.details && !!err.details.length) {
            (err.details).map(({ location, message }) => {
              if (!!location && location === 'number') {
                const numberMsgVal = ((message === 'Validation error') ||
                  (message === 'Invalid card number format'))
                  ? 'is invalid'
                  : message
                setCBSState((prevState) => {
                  return ({
                    ...prevState,
                    [location + 'Error']: true,
                    [location + 'Message']: numberMsgVal
                  })
                })
                scrollIntoView('number-container')
              }

              if (!!location && ((location === 'expirationMonth') ||
                (location === 'expirationYear') ||
                (location === 'captureData.expirationYear') ||
                (location === 'captureData.expirationMonth'))) {
                setCBSState((prevState) => {
                  return ({
                    ...prevState,
                    expError: true,
                    expMessage: message
                  })
                })
              }

              if (!!location && location === 'securityCode') {
                const cvcMsgVal = (message === 'Validation error') ? 'is invalid' : message
                setCBSState((prevState) => {
                  return ({
                    ...prevState,
                    cvcError: true,
                    cvcMessage: cvcMsgVal
                  })
                })
                scrollIntoView('securityCode-container')
              }
              return true
            })
          }
          return false
        } else {
          change('transient_token', token)
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(onSubmit()), 500))
          return true
        }
      })
    }
  }

  return (
    <form className='payment_section_card_form_container' onSubmit={handleSubmit} id="eargo-payment-card-form">
      <div className='payment_section_card_form_element_container'>
        <Field
          id="name"
          name="name"
          label="Name"
          capitalize={true}
          errResp={!!errMsg && !!errMsg.name ? errMsg.name : false}
          autoComplete={false}
          validate={[fullNameRequire, doubleSpace, onlyOneSpecialCharDash, noOnlyNumber, handleFullName, whiteSpace, minChar4]}
          component={ReduxPrimaryInput}
          handleOnFocus={handleOnFocus}
        />
        <Field
          id="address_id"
          name="address_id"
          label="Choose an Address (optional)"
          addressList={addresses}
          isEdit={isEdit}
          states={states}
          component={AddressSelectBox}
          fetchMore={fetchAddress}
          handleOnIdChange={handleOnIdChange}
          removeFieldError={removeFieldError}
          errResp={!!errMsg && !!errMsg.address_id ? errMsg.address_id : false}
        />
        <CommonAddressField
          isEdit={isEdit}
          errMsg={errMsg}
          states={states}
          handleOnFocus={handleOnFocus} />
        {
         <CyberSourceForm
         setCardState={setCBSState}
         cardState={cbsState}
         setCardBrand={setCardBrand}
         setCardCode={setCardCode}
         handleMicroformIns={handleMicroformIns}
         isEdit={isEdit}
         initialValues={initialValues} />
        }
      </div>
      {(
        (isEdit && (methodCount > 1)) ||
        (!isEdit && (!!methodCount))
      ) && <div className='payment_section_checkbox_holder'>
          <Field
            name="default"
            label="Make default credit card"
            component={ReduxCheckboxField}
          />
        </div>}
      {!!errMsg && !!errMsg.otherError && <div className='typographyV2 err_regular payment_section_error_message'>{errMsg.otherError}</div>}
      <div className={`payment_section_button_holder ${isEdit ? 'isEdit' : ''}`}>
        <EargoButtonV2 label={isProcessing ? 'Loading...' : 'Save'}
          disabled={!!isProcessing || !!isDisable} className={`payment_section_save_button primary ${isEdit ? '' : 'whole_width'}`} type="submit" />
        {!!isEdit && <span className='typographyV2 body payment_section_cancel_button' onClick={() => {
          disabledOtherSection(false)
          !!onCancel && onCancel()
        }}>Cancel</span>}
      </div>
    </form>
  )
}

CardForm.propTypes = {
  isCBS: PropTypes.any,
  errMsg: PropTypes.any,
  onSubmit: PropTypes.func,
  isProcessing: PropTypes.any,
  isEdit: PropTypes.bool,
  isDisable: PropTypes.bool,
  checkAndSetAutoOut: PropTypes.func,
  handleMicroformIns: PropTypes.func,
  microform: PropTypes.any,
  expiration: PropTypes.any,
  setCardBrand: PropTypes.any,
  change: PropTypes.func,
  cyberSourceKey: PropTypes.any,
  generateCyberSourceKey: PropTypes.func,
  getPaymentMethods: PropTypes.func,
  handleOnIdChange: PropTypes.any,
  onCancel: PropTypes.any,
  states: PropTypes.any,
  disabledOtherSection: PropTypes.func,
  removeFieldError: PropTypes.func,
  setPaymentErr: PropTypes.func,
  methodCount: PropTypes.any,
  initialValues: PropTypes.any
}

const mapStateToProps = state => ({
  isCBS: state.myInfoPaymentMethod.isCBS,
  states: state.address.states,
  errMsg: state.myInfoPaymentMethod.errMsg,
  methodCount: state.myInfoPaymentMethod.total,
  isProcessing: state.myInfoPaymentMethod.isLoading,
  cyberSourceKey: state.myInfoPaymentMethod.cyberSourceKey
})

const mapDispatchToProps = dispatch => ({
  getPaymentMethods: () => dispatch(getPaymentMethods()),
  checkAndSetAutoOut: info => dispatch(checkAndSetAutoOut(info)),
  removeFieldError: fieldName => dispatch(removeFieldError(fieldName)),
  disabledOtherSection: status => dispatch(disabledOtherSection(status)),
  generateCyberSourceKey: () => dispatch(generateCyberSourceKey()),
  setPaymentErr: err => dispatch(paymentsError(err))
})

export default connect(mapStateToProps, mapDispatchToProps)(CardForm)
