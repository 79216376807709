import { SET_HELP_DESK, RECEIVE_QUESTIONS, ANSWER_SUBMITTED } from '../actions/feedback'

const defaultState = {
  showHelpDesk: true,
  feedbackQuestions: [],
  answerSubmitted: false
}

const Feedback = (state = defaultState, action) => {
  switch (action.type) {
    case SET_HELP_DESK:
      return Object.assign({}, state, action.payload)
    case RECEIVE_QUESTIONS:
      return Object.assign({}, state, {
        feedbackQuestions: action.payload
      })
    case ANSWER_SUBMITTED:
      return Object.assign({}, state, {
        answerSubmitted: action.payload
      })
    default:
      return state
  }
}

export default Feedback
