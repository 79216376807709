import React from 'react'
import PropTypes from 'prop-types'

const Cross = ({
  style = {},
  fill = '#333333',
  width = 16,
  height = 16,
  className = '',
  onClick,
  viewBox = '0 0 16 16'
}) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      className={className}
      onClick={() => !!onClick && onClick()}
      data-testid='jest-cross-id'
    >
      <path fill={fill} fillRule="nonzero" d="M8.707 8l3.147 3.146a.5.5 0 0 1-.708.708L8 8.707l-3.146 3.147a.5.5 0 0 1-.708-.708L7.293 8 4.146 4.854a.5.5 0 1 1 .708-.708L8 7.293l3.146-3.147a.5.5 0 0 1 .708.708L8.707 8z" />
    </svg>
)

Cross.propTypes = {
  style: PropTypes.any,
  fill: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  viewBox: PropTypes.string
}

export default Cross
