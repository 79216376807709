import React from 'react'

import './PieSpinLoader.scss'

function PieSpinLoader () {
  return (
      <div className="wrapper">
          <div className="leftHalf"></div>
          <div className="spinner"></div>
          <div className="rightHalf"></div>
      </div>
  )
}

export default PieSpinLoader
