import React from 'react'
import { ReactCheckBox } from '@eargo/eargo-components'
import PropTypes from 'prop-types'

export const ReduxCheckboxField = ({
  input, label, className = ''
}) => (
  <ReactCheckBox
    label={label}
    className={className}
    checked={input.value}
    handleOnChange={e => input.onChange(e)}
    {...input}
  />
)

ReduxCheckboxField.propTypes = {
  input: PropTypes.any,
  label: PropTypes.any,
  className: PropTypes.string
}
