import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams, withRouter } from 'react-router-dom'
import { EargoButtonV2, DotSpinLoading, AuthWrapper, HeaderSection, AdditionalInfoSection, CommonUnderline, Otp } from '@eargo/eargo-components'
import MessageContainer from '../common/MessageContainer'
import { verifyOTP, resendLoginOtp, getUserInfoApi, fetchAuth } from '../../util/auth'
import './index.scss'

const verifyLoginOtp = ({ history, location, match }) => {
  const [otp, setOtp] = useState('')
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({})
  const [isProcessing, setIsProcessing] = useState(false)
  const [successMsg, setSuccessMsg] = useState(false)
  const path = history.location?.state?.redirect_to
  const userEmail = history.location?.state?.email ? history.location?.state?.email : ''
  const params = useParams()

  useEffect(() => {
    async function fetchData () {
      const response = await fetchAuth()
      if (!!response && !!response.data && !!response.data.id) {
        history.push('/')
      }
    }
    async function getUserInfo () {
      setIsProcessing(true)
      const response = await getUserInfoApi(params.id)
      if (!!response && !!response.success) {
        setEmail(response.data.email)
      } else {
        setEmail(userEmail)
      }
      setIsProcessing(false)
    }
    fetchData()
    getUserInfo()
  }, [])

  const validateForm = () => {
    const error = {}
    let formIsValid = true
    if (!otp) {
      formIsValid = false
      error.otp = 'Please enter your code'
    }

    setErrors(error)
    setSuccessMsg(false)
    return formIsValid
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (validateForm()) {
      setIsProcessing(true)
      const error = {}
      try {
        const resp = await verifyOTP(otp, email)
        if (!!resp && !!resp.success) {
          if (path) {
            path.includes('/login') ? history.push('/my-info/dashboard') : history.push(path)
            return
          }
          history.push('/my-info/dashboard')
          return
        } else {
          if (!!resp && !!resp.code && resp.code === 'request_expired') {
            setOtp('')
            error.otherError = 'You\'ll be redirect now, your request expired. Please make a new one.'
            setTimeout(() => {
              history.push('/login')
            }, 2000)
          } else {
            error.otherError = 'Incorrect code. Please try again or request to resend code.'
          }
        }
      } catch (error) {
        const errorBag = !!error.responseJSON && !!error.responseJSON.error
          ? error.responseJSON.error
          : (!!error.responseJSON && !!error.responseJSON[0] ? error.responseJSON[0] : 'Something went wrong, Please try again.')
        error.otherError = errorBag
      }
      setErrors(error)
      setSuccessMsg(false)
      setIsProcessing(false)
    }
  }

  const resendCode = async () => {
    setOtp('')
    setIsProcessing(true)
    const error = {}
    let successMsg = false
    try {
      const resp = await resendLoginOtp(email, true)
      if (!!resp && !!resp.type && resp.type === 'success') {
        successMsg = 'Login Code sent successfully'
      } else if (!!resp && !!resp.code && resp.code === 'request_expired') {
        error.otherError = 'You\'ll be redirect now, your request expired. Please make a new one.'
        setTimeout(() => {
          history.push('/login')
        }, 2000)
      } else {
        const errorBag = !!resp && !!resp.error && !!resp.message ? resp.message : ''
        error.otherError = errorBag
      }
    } catch (error) {
      const errorBag = !!error.responseJSON && !!error.responseJSON.error
        ? error.responseJSON.error
        : (!!error.responseJSON && !!error.responseJSON[0] ? error.responseJSON[0] : 'Something went wrong, Please try again.')
      error.otherError = errorBag
    }
    setErrors(error)
    setIsProcessing(false)
    setSuccessMsg(successMsg)
  }

  const otherErrWindow = !!errors && !!errors.otherError
  // const isResend = !!location && !!location.state && !!location.state.resendCode
  return (
    <AuthWrapper>
      <div className="center-flex interFontRegular verify_otp_container" id="eargo-activation-code-container">
        {!!isProcessing && <DotSpinLoading />}
        <HeaderSection heading='Verify Code' label='Please enter the code we have sent to:' email={email} />
        <form className='form_container' onSubmit={handleSubmit}>
          <Otp setOtp={(value) => { setOtp(value) }} otp={otp} />
          {!!otherErrWindow && <div className='message_container'><MessageContainer messageList={errors.otherError} /></div>}
          {!!successMsg && <div className='message_container'><MessageContainer messageList={successMsg} isError={false} /></div>}
          <div>
            <EargoButtonV2
              label={isProcessing ? 'Loading...' : 'Login'}
              type="submit"
              disabled={!otp}
              className={`${(!otp || otp.length < 4) ? 'disabled' : ''} primary`}
            />
          </div>
          <AdditionalInfoSection heading='Didn’t receive a code?' subHeading='Please allow 3 minutes to receive the code' label='Resend the code' handleOnClick={resendCode} />
          <CommonUnderline />
          <div className='body_bold typographyV2 back_to_login_tag' onClick={() => history.push('/login')}>
            Back to login
          </div>
        </form>
      </div>
    </AuthWrapper>
  )
}

verifyLoginOtp.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  match: PropTypes.any
}

export default withRouter(verifyLoginOtp)
