// Imported modules
import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { EargoButtonV2, DotSpinLoading } from '@eargo/eargo-components'

import DeletePopup from './../DeletePopup'
import CommanHeading from '../CommanHeading'
import usePrevious from '../../../common/usePrevious'
import PaymentInformation from './PaymentInformation'
import AddNewCreditCard from '../../common/AddNewCreditCard'
import { deletePaymentInfo, addNewPayment, fetchPayments, generateCyberSourceKey } from '../../../actions/info_payment_method'
import './index.scss'

const ExpandArrow = styled.img`
  width: 8px;
  margin-left: 10px;
  transform: rotate(270deg);
  display: inline-block;
  ${props => props.showAll && `
  transform: rotate(90deg);
  `}
`

const PaymentSectionWrapper = ({ anchorClassName }) => {
  const dispatch = useDispatch()
  const [showAll, setShowAll] = useState(false)
  const [addNew, setAddNew] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteMethod, setDeleteMethod] = useState(false)
  const [isEditId, setIsEditId] = useState(false)
  const autoOut = useSelector(state => state.auth.autoOut)
  const [checkIsPopUp, setCheckIsPopUp] = useState(false)
  const { isLoading, errMsg, methods } = useSelector(state => state.myInfoPaymentMethod)
  const methodCount = useSelector(state => state.myInfoPaymentMethod.total)
  const isDisabled = useSelector(state => state.global.isDisabled)
  const prevAutoOut = usePrevious(autoOut)

  // This variable is use to set initial paymentMethod When user come on my info page
  const defaultPaymentMethod = !!methods && methods.filter(obj => !!obj.default)

  // This useEffect is fetch PaymentMethods on mount component and get all PaymentMethods through useSelector
  useEffect(() => {
    dispatch(fetchPayments())
  }, [])
  useEffect(() => {
    if (!isEdit && !!isEditId) {
      setIsEdit(isEditId)
      setIsEditId(false)
    }
  }, [isEditId])
  // Check user session is end or not where autoOut and prevAutoOut give a bool value to check
  useEffect(() => {
    if ((prevAutoOut !== autoOut) && !!autoOut) {
      setAddNew(false)
      setDeleteMethod(false)
    }
  }, [prevAutoOut, autoOut])

  // This useEffect is use to edit PaymentMethods according to address id
  const setAnotherEdit = (id) => {
    if (isEdit) {
      setIsEdit(false)
      setIsEditId(id)
    } else {
      setIsEdit(id)
    }
  }
  const deleteMethodInfo = async (id) => {
    const resp = await dispatch(deletePaymentInfo(id))
    !!resp && setDeleteMethod(false)
  }
  const setEditAndShowAll = () => {
    setShowAll(!showAll)
    setIsEdit(false)
  }
  const closeEditAndAddNew = () => {
    dispatch(generateCyberSourceKey())
    setIsEdit(false)
    setCheckIsPopUp(true)
  }
  // This is use to enable to PopUp form (Add New Payment Method) after clicked on (Add New PaymentMethod) Button
  useEffect(() => {
    if (checkIsPopUp) {
      setAddNew(true)
      setCheckIsPopUp(false)
    }
  }, [checkIsPopUp])

  if (!methodCount && !!isLoading) return <DotSpinLoading />
  return (
    <Fragment>
      <div className='payment_section_component' id={anchorClassName}>
      {(!isEdit && !!isLoading) && <DotSpinLoading />}
      <div className='payment_account_heading_section'>
        <CommanHeading className='' heading='Payment Info' />
        {!!methodCount && (methodCount > 1) && <div className={`payment_section_show_section ${isDisabled ? 'isDisabled' : ''} ${isEdit ? 'isActive' : ''}`} onClick={() => !isEdit && setEditAndShowAll()}>
          {showAll
            ? <label className={`typographyV2 caption payment_section_show_label ${isEdit ? 'isActive' : ''} ${isDisabled ? 'isDisabled' : ''}`}>Show less <ExpandArrow showAll={showAll} src='https://assets.eargo.com/new_account/arrow_back.png' /></label>
            : (!!methodCount && <label className={`typographyV2 caption payment_section_show_label ${isDisabled ? 'isDisabled' : ''} ${isEdit ? 'isActive' : ''}`}>Show all ({methodCount}) <ExpandArrow showAll={showAll} src='https://assets.eargo.com/new_account/arrow_back.png' /></label>)
          }
        </div>}
      </div>
      <div className='payment_section_details_container'>
        <div id='eargo-my-acc-payment-container'>
          {methodCount
            ? <PaymentInformation
            PaymentMethods={(!showAll ? (!!defaultPaymentMethod && !!defaultPaymentMethod[0] ? [defaultPaymentMethod[0]] : [methods[0]]) : methods)}
            isEdit={isEdit}
            showAll={showAll}
            setAnotherEdit={setAnotherEdit}
            setDeleteMethod={setDeleteMethod}
            setIsEdit={setIsEdit}
            setIsEditId={setIsEditId}
            />
            : <div className='typographyV2 body payment_section_no_info'>You don&#39;t have any payment info yet</div>
          }
          {
            !!methodCount &&
            !methods.some(a => !!a.default) &&
            <div className='typographyV2 err_regular payment_section_warning_info'>Please select a payment method as the Default.</div>}
          <div className='payment_section_add_button'>
            <EargoButtonV2
              disabled={isDisabled}
              className='primary'
              label="Add New Card"
              handleOnClick={closeEditAndAddNew} />
          </div>
        </div>
        {!!addNew && <AddNewCreditCard
            addNewPayment={addNewPayment}
            onCancel={() => setAddNew(false)}
          />}
        {!!deleteMethod && <DeletePopup
          isLoading={isLoading}
          onCancel={() => setDeleteMethod(false)}
          onDelete={() => deleteMethodInfo(deleteMethod)}
          errMsg={(!!errMsg && !!errMsg.otherError) ? errMsg.otherError : false}
          title="Delete Payment Method?"
          subTitle="Are you sure you want to delete this payment method? This action cannot be reverted."
        />}
      </div>
      </div>
    </Fragment>
  )
}

const PaymentSection = props => (
  <PaymentSectionWrapper {...props}/>
)
PaymentSectionWrapper.propTypes = {
  anchorClassName: PropTypes.string
}
export default React.memo(PaymentSection)
