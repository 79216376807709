import color from '../assets/scss/common.module.scss'

export const colorCodes = {
  WHITE: color.white,
  BLACK: color.black,
  BLACK_1: color.blue1,
  RED: color.red,
  DIM_GREY: color.dimGrey,
  DIM_GREY2: color.dimGrey2,
  DIM_GREY3: color.dimGrey3,
  DIM_GREY4: color.dimGrey4,
  DIM_GREY5: color.dimGrey5,
  DIM_GREY6: color.dimGrey6,
  DIM_GREY7: color.dimGrey7,
  DIM_GREY8: color.dimGrey8,
  DIM_GREY9: color.dimGrey9,
  DIM_GREY10: color.dimGrey10,
  WHITE_SMOKE: color.whiteSmoke,
  ORANGE_1: color.orange1,
  ORANGE_2: color.orange2,
  ORANGE_3: color.orange3,
  ORANGE_4: color.orange4,
  ORANGE_5: color.orange5,
  ORANGE_6: color.ORANGE_6,
  BLUE_1: color.blue1,
  BLUE_2: color.blue2,
  DARK_BLUE_1: color.darkBlue1,
  DARK_BLUE_3: color.darkBlue3,
  DARK_BLUE_4: color.darkBlue4,
  LIGHT_BLUE: color.lightBlue,
  LIGHT_BLUE1: color.lightBlue1,
  GREEN_1: color.green1,
  GREEN_2: color.green2,
  GREEN_3: color.green3,
  GREY_1: color.grey1,
  GREY_2: color.grey2,
  GREY_3: color.grey3,
  GREY_4: color.grey4,
  GREY_5: color.grey5,
  GREY_6: color.grey6,
  GREY_7: color.grey7,
  GREY_9: color.grey9,
  GREY_10: color.grey10,
  GREY_11: color.grey11,
  GREY_12: color.grey12,
  GREEN_11: color.grey11,
  GREY_14: color.grey14,
  GREY_15: color.grey15,
  GREY_16: color.grey16,
  GREY_17: color.grey17,
  GREY18: color.grey18,
  GREY_19: color.grey19,
  GREY_20: color.grey20,
  GREY_21: color.grey21,
  GREY_22: color.grey22,
  GREY_23: color.grey23,
  GREY_24: color.grey24,
  GREY_25: color.grey25,
  GREY_26: color.grey26,
  GREY_27: color.grey27,
  GREY_28: color.grey28,
  DUSTY_GREY: color.dustyGrey,
  DUSTY_GREY_2: color.dustyGrey2,
  BRIGHT_GREY: color.brightGrey,
  BRIGHT_GREY1: color.brightGrey1,
  BRIGHT_GREY2: color.brightGrey2,
  ERROR_RED: color.errorRed,
  AQUA_SQUEEZE: color.aquaSqueeze,
  GHOST_WHITE: color.ghostWhite
}
