import { ajax } from 'jquery'
import { helpersFn } from '@eargo/eargo-components'
import {
  CARD_BRANDS, CART_STATES, PAYMENT_METHODS, ROUTES, SERVER_URL, SKU
} from '../constant'
import configureStore from '../store/store'
import { checkAndSetAutoOut } from '../actions/global'
import { receiveCreditCardError } from '../actions/error_actions'

const { JCB, DINNER_CLUB, DINERS, AMERICAN_EXPRESS, AMEX, DISCOVER, MASTER_CARD, UNION_PAY, CU_PAY, VISA } = CARD_BRANDS
const { EARGO_NEO, EARGO_MAX, EARGO_NEO_HIFI, ACCESSORIES } = ROUTES
const { createQueryParams } = helpersFn

export const storeOptionsList = locationSearch => {
  return [
    {
      address: EARGO_NEO_HIFI + locationSearch,
      optionImage: 'https://assets.eargo.com/brand/header/NeoHiFi.png',
      titleImage: true,
      optionTitle: 'https://assets.eargo.com/Rebrand2019/EargoNEOHiFi_300x81-02.svg'
    },
    {
      address: EARGO_NEO + locationSearch,
      optionImage: 'https://assets.eargo.com/brand/header/neo.png',
      titleImage: true,
      optionTitle: 'https://assets.eargo.com/Rebrand2019/neo.svg'
    },
    {
      address: EARGO_MAX + locationSearch,
      optionImage: 'https://assets.eargo.com/brand/header/max.png',
      titleImage: true,
      optionTitle: 'https://assets.eargo.com/Rebrand2019/max.svg'
    },
    {
      address: ACCESSORIES + locationSearch,
      optionImage: 'https://assets.eargo.com/brand/header/accessories.svg',
      titleImage: false,
      optionTitle: 'ACCESSORIES'
    }
  ]
}

export const removeElement = elementId => {
  const element = document.getElementById(elementId)
  !!element && element.parentNode.removeChild(element)
}

/**
 * Remove element bind
 * with this elementIds
 * @param {*} elementIds
 */
export const removeElements = elementIds => {
  if (!!elementIds && elementIds.length > 0) {
    elementIds.map(elementId =>
      removeElement(elementId))
  }
}

export const styleElement = (selector, style) => {
  const element = document.querySelector(selector)
  !!element && element.setAttribute('style', style)
}

/**
 * Set title value
 * @param {*} text
 */
export const setTitle = text => {
  document.title = text
}

/**
 * Add meta tags
 * @param {*} metaTags
 */
export const addMetaTags = metaTags => {
  removeMetaTags()
  const head = document.querySelector('head')
  if (!!metaTags === false || metaTags.length <= 0) return false

  metaTags.map(tag => {
    const keysName = Object.keys(tag)
    if (tag[keysName[0]] === 'title') {
      setTitle(tag[keysName[1]])
    }
    const metaTag = document.createElement('meta')
    metaTag.setAttribute(keysName[0], tag[keysName[0]])
    metaTag.setAttribute(keysName[1], tag[keysName[1]])
    metaTag.setAttribute('class', 'eargo-product-meta-tags')
    return head.appendChild(metaTag)
  })
}

/**
 * Remove all meta tags
 */
export const removeMetaTags = () => {
  document.querySelectorAll('meta.eargo-product-meta-tags')
    .forEach(e => e.parentNode.removeChild(e))
  setTitle('Eargo Store | Order Hearing Aids')
}

/**
 * Validate email
 * @param {*} email
 */
export const validateEmail = email => {
  /* eslint-disable */
  const regexEmail = /^(([^<>()[\]\\.,;:+\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regexEmail.test(email)
}

/**
 * Scroll into view
 * on elementId
 * @param {*} elementId
 */
export const scrollIntoView = elementId => {
  const element = document.getElementById(elementId)
  !!element && element.scrollIntoView()
}

/**
 * Check if value
 * exist in array
 * @param {*} array
 * @param {*} value
 */
export const inArray = (array, value) => array.includes(value)


/**
 * Add product base
 * meta json in head
 * @param {*} variant
 */
export const productBaseMetaJson = variant => {
  const { product, price } = variant
  removeElement('product-base-script')
  const script = document.createElement('script')
  script.type = 'application/ld+json'
  script.id = 'product-base-script'
  const text = `{
    "@context":"http://schema.org/",
    "@type":"Product",
    "description": "${product.description}",
    "name": "${product.name}",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "${product.avgRating}",
      "worstRating": "1",
      "bestRating": "5",
      "reviewCount": "${product.reviewsCount}",
      "itemReviewed": "${product.name}"
    },
    "offers": {
      "@type":"Offer",
      "priceCurrency":"USD",
      "price": "${price}",
      "availability":"http://schema.org/InStock"
    }
  }`
  const textNode = document.createTextNode(text)
  script.appendChild(textNode)
  document.head.appendChild(script)
}


/**
 * Get only digit
 * @param {*} str
 */
export const onlyDigit = str => str.replace(/\D/g, '')

/**
 * Get Product type
 * @param {*} skuVal
 */
export const getProductSubType = skuVal => {
  if (!!skuVal === false) return null

  const { FLEXI_DOMES_LARGE, FLEXI_FIBERS_LARGE, FLEXI_PALMS_LARGE, FLEXI_TETRAPALMS_LARGE,
    FLEXI_DOMES_REGULAR, FLEXI_FIBERS_REGULAR, FLEXI_PALMS_REGULAR, FLEXI_TETRAPALMS_REGULAR,
    CHARGER_MAX, CHARGER_NEO, CHARGER_CABLE_MAX, FLEXI_PALMS_SMALL, PETALS_FOR_EARGO_5, CLOSE_PETAL_5, PETALS_FOR_EARGO, CLOSE_PETAL } = SKU
  const largeProducts = [FLEXI_DOMES_LARGE, FLEXI_FIBERS_LARGE, FLEXI_PALMS_LARGE, FLEXI_TETRAPALMS_LARGE, PETALS_FOR_EARGO, CLOSE_PETAL]
  const mediumProducts = [PETALS_FOR_EARGO_5, CLOSE_PETAL_5]
  const regularProducts = [FLEXI_DOMES_REGULAR, FLEXI_FIBERS_REGULAR, FLEXI_PALMS_REGULAR, FLEXI_TETRAPALMS_REGULAR]
  const smallProducts = [FLEXI_PALMS_SMALL]
  let productType = null
  if (inArray(largeProducts, skuVal)) {
    productType = "Large";
  } else if (inArray(regularProducts, skuVal)) {
    productType = "Regular";
  } else if (inArray(mediumProducts, skuVal)) {
    productType = "Medium";
  } else if (inArray(smallProducts, skuVal)) {
    productType = "Small";
  } else if ((skuVal === CHARGER_MAX) || (skuVal === CHARGER_CABLE_MAX)) {
    productType = "Max";
  } else if (skuVal === CHARGER_NEO) {
    productType = "Neo";
  }

  return productType
}


/**
 * Add Script
 * @param {*} src
 * @param {*} id
 * @param {*} async
 * @param {*} selector
 */
export const addScript = (src, id, async = true, selector = false) => {
  document.querySelectorAll(`script#${id}`)
    .forEach(e => e.parentNode.removeChild(e))
  const script = document.createElement('script')
  script.src = src
  script.id = id
  script.async = async
  const element = !!selector ? document.getElementById(selector) : false
  !!element ?
    element.parentNode.insertBefore(script, element.nextSibling) :
    document.head.appendChild(script)
}


/**
 * Add script content
 * @param {*} content
 * @param {*} id
 * @param {*} selector
 */
export const addScriptContent = (content, id, selector = false) => {
  document.querySelectorAll(`script#${id}`)
    .forEach(e => e.parentNode.removeChild(e))
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.id = id
  const textNode = document.createTextNode(content)
  script.appendChild(textNode)

  const element = !!selector ? document.getElementById(selector) : false
  !!element ?
    element.parentNode.insertBefore(script, element.nextSibling) :
    document.head.appendChild(script)
}


export const urlencode = str => {
  return encodeURIComponent(str)
    .replace(' ', '%20')
    .replace('!', '%21')
    .replace('#', '%23')
    .replace('$', '%24')
    .replace('&', '%26')
    .replace('@', '%40')
}


export const waitForScript = (name, timeout = 3) => {
  return new Promise(resolve => {
    let waited = 0
    function wait(interval) {
      setTimeout(() => {
        waited += interval
        if (window[name] !== undefined)
          return resolve(true)
        else if (waited >= timeout * 1000)
          return resolve(false)
        wait(interval * 2)
      }, interval)
    }
    return wait(500)
  })
}

export const prepareRequest = (method, url, data, dataType, contentType, signal, blobResponse = false) => {
  const serverUrl = `${SERVER_URL}v2/`
  return {
    ...(!!signal && {
      beforeSend: function (jqXhr) {
        signal.addEventListener("abort", () => {
          jqXhr.abort()
        });
      }
    }),
    'method': method,
    'url': serverUrl + url,
    'xhrFields': {
      'withCredentials': true,
      ...(!!blobResponse && { 'responseType': 'blob' }),
    },
    ...(data && { 'data': data }),
    ...(dataType && { 'dataType': dataType }),
    ...(contentType && { 'contentType': contentType })
  }
}

/**
 * Get Cart Count
 * @param {*} cart
 */
export const getCartCount = (cart) => {
  let cartCount = 0
  if (cart &&
    (cart.state !== CART_STATES.COMPLETE) &&
    (cart.state !== CART_STATES.PENDING) &&
    (cart.state !== CART_STATES.PENDING_APPROVAL)) {
    if (cart.lineItems) {
      cartCount = Object.keys(cart.lineItems).reduce(
        (acc, index) => acc + cart.lineItems[index].quantity,
        cartCount
      )
    }
  }
  return cartCount
}

export const isWebPSupported = () => {
  const { Modernizr } = window
  if (!!Modernizr &&
    typeof Modernizr !== 'undefined' &&
    typeof Modernizr !== undefined) {
    return !!Modernizr.webp
  }
  return false
}

/**
 * Handle CallRail
 */
export const handlecallRail = () => {
  removeElement('eargo-call-rail');
  const body = document.getElementsByTagName('body')[0];
  var e = document.createElement("script");
  e.type = "text/javascript";
  // e.crossOrigin = 1;
  e.id = 'eargo-call-rail';
  e.async = !0;
  e.src = "//cdn.callrail.com/companies/699543035/01f1c5cbdbdee3a715cd/12/swap.js";
  body.appendChild(e);
}


/**
 * Handle Ajax request
 * @param {*} method
 * @param {*} url
 * @param {*} info
 * @param {*} dataType
 * @param {*} contentType
 * @param {*} signal // We'll pass signal for AbortController
 * @param {*} blobResponse
 * @returns
 */
export const handleAjax = async (method, url, info, dataType, contentType, signal, blobResponse) =>
  ajax(prepareRequest(method, url, info, dataType, contentType, signal, blobResponse))


/**
 * Handle Auth Request
 * @param {*} method
 * @param {*} url
 * @param {*} info
 * @param {*} dataType
 * @param {*} contentType
 */
export const handleRequest = async (method, url, info, dataType, contentType) => {
  const serverUrl = `${SERVER_URL}v2/` + url
  const store = configureStore()
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest(method, serverUrl, info, dataType, contentType)
      .then(res => res.json())
      .then(result => {
        store.dispatch(checkAndSetAutoOut(result))
        return resolve(result)
      })
      .catch(error => {
        store.dispatch(checkAndSetAutoOut(error))
        return reject(error)
      })
  })
}

/**
 * Check browser name
 * and version and decide
 * j2 format supported or not
 * @param {*} withAllInfo
 * @returns
 */
 export const isJ2Supported = (withAllInfo = false) => {
  let supported = false
  var nAgt = navigator.userAgent;
  var browserName = navigator.appName;
  var fullVersion = '' + parseFloat(navigator.appVersion);
  var verOffset;

  // In Opera, the true version is after "Opera" or after "Version"
  if (((verOffset = nAgt.indexOf("Opera")) != -1) ||
  (verOffset = nAgt.indexOf("OPR")) != -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 6)
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
      fullVersion = nAgt.substring(verOffset + 8);

    supported = true
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }

  return withAllInfo ? {
    [browserName]: {
      supported,
      fullVersion
    }
  } : supported
}


/**
 * Update search params
 * @param {*} search
 * @param {*} deleteParam
 */
export const updateSearchParams = (search, deleteParam) => {
  delete search[deleteParam]
  if (!!search && Object.keys(search).length > 0) {
    let allParams = helpersFn.createQueryParams(search);
    return "?" + allParams;
  }
}

/**
 * Get State Abbr value
 * @param {Array} states
 * @param {String} value
 */
export const getStateAbbr = (states, value) => {
  const record = states.find(el => el.id === parseInt(value))
  if (!!record && !!record.abbr) {
    return record.abbr
  }
  return value
}


/**
 * Create address line
 * @param {Array} states
 * @param {Object} address
 */
export const createAddressLine = (states, address) => {
  const addressLine = !!address.address2 ? [address.address1 + ',', address.address2 + ','] : [address.address1 + ',']
  return [...addressLine, !!address.city ? address.city + ',' : '', getStateAbbr(states, address.state_id) + ',', address.zipcode].join(' ')
}


/**
 * Scroll On error
 * in case of failure
 * @param {*} selector
 * @param {*} timeOut
 */
export const submitFailure = (selector = '.error_border, .err-txt', timeOut = 100) => {
  setTimeout(() => {
    const elements = document.querySelectorAll(selector)
    if (!!elements && !!elements[0]) {
      (elements[0]).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  }, timeOut)
}

/**
 * Convert String abount
 * into float value
 * @param {String} val
 */
 export const convertStringToAmount = val => parseFloat(val, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

/**
 * Get Card Image
 * according to brand
 * @param {*} brand
 */
export const getCardImage = (brand = VISA) => {
  const isWebp = isWebPSupported()
  const isJp2 = isJ2Supported()

  switch (brand.toLowerCase()) {
    case JCB.toLowerCase():
      return !!isWebp ? 'https://assets.eargo.com/pdp/new_images/webp/jcb.webp' :
        (!!isJp2 ? 'https://assets.eargo.com/pdp/new_images/jp2/jcb.jp2' :
          'https://assets.eargo.com/pdp/new_images/jcb.png')
    case DINNER_CLUB.toLowerCase():
    case DINERS.toLowerCase():
      return !!isWebp ? 'https://assets.eargo.com/pdp/new_images/webp/dinner_club.webp' :
        (!!isJp2 ? 'https://assets.eargo.com/pdp/new_images/jp2/dinner_club.jp2' :
          'https://assets.eargo.com/pdp/new_images/dinner_club.png')
    case AMEX.toLowerCase():
    case AMERICAN_EXPRESS.toLowerCase():
      return !!isWebp ? 'https://assets.eargo.com/pdp/new_images/webp/american-express.webp' :
        (!!isJp2 ? 'https://assets.eargo.com/pdp/new_images/jp2/american-express.jp2' :
          'https://assets.eargo.com/pdp/new_images/american-express.png')
    case DISCOVER.toLowerCase():
      return !!isWebp ? 'https://assets.eargo.com/pdp/new_images/webp/discover.webp' :
        (!!isJp2 ? 'https://assets.eargo.com/pdp/new_images/jp2/discover.jp2' :
          'https://assets.eargo.com/pdp/new_images/discover.png')
    case MASTER_CARD.toLowerCase():
      return !!isWebp ? 'https://assets.eargo.com/pdp/new_images/webp/master-card.webp' :
        (!!isJp2 ? 'https://assets.eargo.com/pdp/new_images/jp2/master-card.jp2' :
          'https://assets.eargo.com/pdp/new_images/master-card.png')
    case UNION_PAY.toLowerCase():
    case CU_PAY.toLowerCase():
      return !!isWebp ? 'https://assets.eargo.com/pdp/new_images/webp/unionpay.webp' :
        (!!isJp2 ? 'https://assets.eargo.com/pdp/new_images/jp2/unionpay.jp2' :
          'https://assets.eargo.com/pdp/new_images/unionpay.png')
    case VISA.toLowerCase():
      return !!isWebp ? 'https://assets.eargo.com/pdp/new_images/webp/visa.webp' :
        (!!isJp2 ? 'https://assets.eargo.com/pdp/new_images/jp2/visa.jp2' :
          'https://assets.eargo.com/pdp/new_images/visa.png')
    default:
      return !!isWebp ? 'https://assets.eargo.com/pdp/new_images/webp/credit_card.webp' :
        (!!isJp2 ? 'https://assets.eargo.com/pdp/new_images/jp2/credit_card.jp2' :
          'https://assets.eargo.com/pdp/new_images/credit_card.png')
  }
}


/**
 * Convert Value in
 * lowerCase format
 * @param {*} value
 */
export const convertInLower = value => {
  if (!value) return value

  return value.toLowerCase()
}


/**
 * Handle Card Expiry
 * input format
 * @param {*} val
 */
export const cardExpiryFormat = (val) => {
  let newval = val.substring(0, 2)
  const max = '12'
  if (newval.length === 1 && newval[0] > max[0]) {
    newval = `0${newval}`;
  }
  if (newval.length === 2) {
    if (Number(newval) === 0) {
      newval = '01';
    } else if (newval > max) {
      newval = max;
    }
  }
  const month = `${newval}/`;
  const year = val.substring(2, 4);
  return month + (year.length ? year : '');
}


export const generateMicroFormToken = async (instance, options) => {
  return await new Promise((resolve, reject) => {
    let errorObj = {}
    instance.createToken(options, async (err, token) => {
      if (err) {
        console.log("generateMicroFormToken Error ", err)
        if (!!err && !!err.reason) {
          if (((err.reason === 'CREATE_TOKEN_UNABLE_TO_START') ||
            (err.reason === 'CREATE_TOKEN_CAPTURE_CONTEXT_USED_TOO_MANY_TIMES'))) {
            errorObj['reGenerateKey'] = true
            store.dispatch(receiveCreditCardError(['Please fill your card details and try again.']))
          }

          if (err.reason === 'CREATE_TOKEN_VALIDATION_SERVERSIDE') {
            if (!!err && !!err.details && !!err.details.length) {
              (err.details).map(({ message }) => {
                if (message === 'Invalid card number format') {
                  store.dispatch(receiveCreditCardError(['Invalid card details, Please try again.']))
                }
              })
            } else {
              store.dispatch(receiveCreditCardError(['Please fill your card details and try again.']))
              errorObj['reGenerateKey'] = true
            }
          }

          if (err.reason === 'FIELD_LOAD_CONTAINER_SELECTOR') {
            console.log("ERROR FIELD_LOAD_CONTAINER_SELECTOR: ", err.message)
          }
        }

        if (!!err && !!err.details && !!err.details.length) {
          (err.details).map(({ location, message }) => {
            const numberMsgVal = ((message === 'Validation error') ||
              (message === 'Invalid card number format')) ? 'is invalid' : message
            if (!!location && location === 'number') {
              errorObj['numberError'] = true
              errorObj['numberMessage'] = numberMsgVal
            }

            if (!!location && ((location === 'expirationMonth') ||
              (location === 'expirationYear') ||
              (location === 'captureData.expirationYear') ||
              (location === 'captureData.expirationMonth'))) {
              errorObj['expError'] = message
              errorObj['expMessage'] = message
              errorObj['expiration'] = message
            }

            if (!!location && location === 'securityCode') {
              const cvcMsgVal = (message === 'Validation error') ? 'is invalid' : message
              errorObj['cvcError'] = cvcMsgVal
              errorObj['cvcMessage'] = cvcMsgVal
            }
            return true
          })
        }
        return reject({
          success: false,
          errors: errorObj
        })
      } else {
        return resolve({
          success: true,
          token
        })
      }
    })
  })
}


/**
 * Return CBS payment method
 * @param {Array} allMethods
 */
export const isCBSMethod = allMethods => {
  if (!!allMethods && !!allMethods.length) {
    return allMethods.find(el =>
    (el.description === PAYMENT_METHODS.CBS))
  } else
    return false
}

/**
 * Number with comma
 * as thousands separators
 */
 export const numberWithCommas = value => value.toLocaleString()

 /**
  *  @param {object} ? required
  */
export const isSupportRoute = (location) => {
  const url = location.pathname
  const id = url.substring(url.lastIndexOf('/') + 1)
  if ((id && url.includes('/support')) || url === '/my-info/support/form') return true
  return false
}

export const isAccessoriesRoute = (location) => {
  const url = location.pathname
  const id = url.substring(url.lastIndexOf('/') + 1)
  if ((id && url.includes('/accessories'))) return true
  return false
}

export const rockerboxPixel = (email, phone_number, name) => {
  const script = document.createElement('script')
  const text = `(function(d,RB) {
    if (!window.RB) {
      window.RB=RB;
      RB.queue=RB.queue||[];
      RB.track=RB.track||function(){
        RB.queue.push(Array.prototype.slice.call(arguments))
      };
      RB.initialize=function(s){
        RB.source=s};
        var a = d.createElement("script");
        a.type="text/javascript";
        a.async=!0;
        a.src="https://rb8qgdcr.eargo.com/assets/wxyz.rb.js";
        f=d.getElementsByTagName("script")[0];
        f.parentNode.insertBefore(a,f);
      }
    }
    )(document,window.RB || {});
    RB.disablePushState=true;
    RB.initialize("ZWFyZ298dmlld3xwdXJjaGFzZXxzaWdudXB8bG9naW4=");
    RB.track("identify", {
    "external_id" : '',
    "email": '${email}',
    "phone_number": '${phone_number}',
    "name": '${name}'
});`
  const textNode = document.createTextNode(text)
  script.id = 'eargo-rockerbox-pixel-content'
  script.appendChild(textNode)

  // Append script under head
  document.head.appendChild(script)

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'email': email,
    'phoneNumber': phone_number,
    'name': name,
  })
}

/**
 * Handle Ajax request
 * @param {*} queryParams
 * @param {*} method
 * @param {*} url
 * @param {*} info
 * @param {*} dataType
 * @param {*} contentType
 * @param {*} signal // We'll pass signal for AbortController
 * @param {*} blobResponse
 * @returns
 */
export const handleAjaxWithQuery = (queryParams, method, url, info, dataType, contentType, signal, blobResponse) => {
  let reqUrl = url
  if (!!queryParams && Object.keys(queryParams).length > 0) {
    reqUrl = reqUrl + '?' + createQueryParams(queryParams)
  }
  return handleAjax(method, reqUrl, info, dataType, contentType, signal, blobResponse)
}

export const hero_module_new = {
  title1: 'Snap on, snap off',
  description: 'Choose between two Petal types for Eargo 5, 6, and 7 and swap them easily on and off.',
  URL: ROUTES.EARGO_5,
  buttonTitle: 'Shop Petals for Eargo 5, 6, and 7'
}
export const accessories_sale_banner = {
  title: 'Save on accessories',
  description: 'For a limited time only, get 25% off accessories* for your Eargo hearing aids.',
  caption:"Chargers not included.",
  buttonTitle: 'Shop Petals for Eargo 5, 6, and 7'
}

export const getSkuImage = (variant) => {
  const isWebp = isWebPSupported()
  const isJp2 = isJ2Supported()
  switch(variant) {
        case SKU.EARGO_7: {
          return isWebp
          ? 'https://assets.eargo.com/pdp/eargo7/Eargo+7+Charger+at+angle+with+Devies+on+it.png'
          : (isJp2 ? 'https://assets.eargo.com/pdp/eargo7/Eargo+7+Charger+at+angle+with+Devies+on+it.png' : 'https://assets.eargo.com/pdp/eargo7/Eargo+7+Charger+at+angle+with+Devies+on+it.png')
        }
        case SKU.EARGO_6: {
          return isWebp
          ? 'https://assets.eargo.com/pdp/eargo6/eargo6_cart.png'
          : (isJp2 ? 'https://assets.eargo.com/pdp/eargo6/eargo6_cart.png' : 'https://assets.eargo.com/pdp/eargo6/eargo6_cart.png')
        }
        case SKU.EARGO_5: {
          return isWebp
          ? 'https://assets.eargo.com/pdp/eargo5/eargo5_cart.webp'
          : (isJp2 ? 'https://assets.eargo.com/pdp/eargo5/eargo5_cart.jp2' : 'https://assets.eargo.com/pdp/eargo5/eargo5_cart.png')
        }
        case SKU.EARGO_NEO: {
          return isWebp
          ? 'https://assets.eargo.com/pdp/new_images/webp/neo.webp'
          : (isJp2 ? 'https://assets.eargo.com/pdp/new_images/jp2/neo.jp2' : 'https://assets.eargo.com/pdp/new_images/neo.png')
        }
        case SKU.EARGO_MAX: {
          return isWebp
          ? 'https://assets.eargo.com/pdp/new_images/webp/max_image.webp'
          : (isJp2 ? 'https://assets.eargo.com/pdp/new_images/jp2/max_image.jp2' : 'https://assets.eargo.com/pdp/new_images/max_image.png')
        }
        case SKU.EARGO_NEO_HIFI: {
          return isWebp
          ? 'https://assets.eargo.com/pdp/new_images/webp/neoHiFi.webp'
          : (isJp2 ? 'https://assets.eargo.com/pdp/new_images/jp2/neoHiFi.jp2' : 'https://assets.eargo.com/pdp/new_images/neoHiFi.png')
        }
        case SKU.FLEXI_FIBERS_LARGE:
        case SKU.FLEXI_FIBERS_5:
        case SKU.FLEXI_FIBERS_REGULAR:
        case SKU.FLEXI_FIBERS_LARGE: {
          return isWebp
          ? 'https://assets.eargo.com/accessories/newdesign/Flexi_Fibers.webp'
          : (isJp2 ? 'https://assets.eargo.com/accessories/newdesign/Flexi_Fibers.jp2' : 'https://assets.eargo.com/accessories/newdesign/Flexi_Fibers.png')
        }
        case SKU.FLEXI_DOMES_REGULAR:
        case SKU.FLEXI_DOMES_5:
        case SKU.FLEXI_DOMES_LARGE: {
          return isWebp
          ? 'https://assets.eargo.com/accessories/newdesign/Flexi_Fiber_Domes.webp'
          : (isJp2 ? 'https://assets.eargo.com/accessories/newdesign/Flexi_Fiber_Domes.jp2' : 'https://assets.eargo.com/accessories/newdesign/Flexi_Fiber_Domes.png')
        }
        case SKU.FLEXI_PALMS_REGULAR:
        case SKU.FLEXI_PALMS_5:
        case SKU.FLEXI_PALMS_SMALL:
        case SKU.FLEXI_PALMS_LARGE: {
          return isWebp
          ? 'https://assets.eargo.com/accessories/newdesign/Flexi_Palm.webp'
          : (isJp2 ? 'https://assets.eargo.com/accessories/newdesign/Flexi_Palm.jp2' : 'https://assets.eargo.com/accessories/newdesign/Flexi_Palm.png')
        }
        case SKU.FLEXI_TETRAPALMS_LARGE:
        case SKU.FLEXI_TETRAPALMS_REGULAR: {
          return isWebp
          ? 'https://assets.eargo.com/accessories/newdesign/Flexi_Tetrapalm_Trimmed.webp'
          : (isJp2 ? 'https://assets.eargo.com/accessories/newdesign/Flexi_Tetrapalm_Trimmed.jp2' : 'https://assets.eargo.com/accessories/newdesign/Flexi_Tetrapalm_Trimmed.png')
        }
        case SKU.CLOSE_PETAL_5:
        case SKU.CLOSE_PETAL:{
          return isWebp
          ? 'https://assets.eargo.com/accessories/closed_petals.png'
          : (isJp2 ? 'https://assets.eargo.com/accessories/closed_petals.png' : 'https://assets.eargo.com/accessories/closed_petals.png')
        }
        case SKU.MIC_CAPS: {
          return isWebp
          ? 'https://assets.eargo.com/accessories/mic_cap.png'
          : (isJp2 ? 'https://assets.eargo.com/accessories/mic_cap.png' : 'https://assets.eargo.com/accessories/mic_cap.png')
        }
        case SKU.PETALS_FOR_EARGO_5:
        case SKU.PETALS_FOR_EARGO: {
          return isWebp
          ? 'https://assets.eargo.com/accessories/open_petals.png'
          : (isJp2 ? 'https://assets.eargo.com/accessories/open_petals.png' : 'https://assets.eargo.com/accessories/open_petals.png')
        }
        case SKU.ADDITIONAL_CHARGER: {
          return isWebp
          ? 'https://assets.eargo.com/accessories/charger_cables.png'
          : (isJp2 ? 'https://assets.eargo.com/accessories/charger_cables.png' : 'https://assets.eargo.com/accessories/charger_cables.png')
        }
        case SKU.USB_C_CORD: {
          return isWebp
          ? 'https://assets.eargo.com/accessories/cable_adapter.png'
          : (isJp2 ? 'https://assets.eargo.com/accessories/cable_adapter.png' : 'https://assets.eargo.com/accessories/cable_adapter.png')
        }
        case SKU.WAX_GUARD: {
          return isWebp
          ? 'https://assets.eargo.com/pdp/new_images/webp/D_Wax_Guard.webp'
          : (isJp2 ? 'https://assets.eargo.com/pdp/new_images/jp2/D_Wax_Guard.jp2' : 'https://assets.eargo.com/pdp/new_images/D_Wax_Guard.png')
        }
        case SKU.CHARGER_CABLE_ADAPTER: {
          return isWebp
          ? 'https://assets.eargo.com/accessories/charger_cables.png'
          : (isJp2 ? 'https://assets.eargo.com/accessories/charger_cables.png' : 'https://assets.eargo.com/accessories/charger_cables.png')
        }
        case SKU.CABLE_ADAPTER: {
          return isWebp
          ? 'https://assets.eargo.com/accessories/cable_adapter.png'
          : (isJp2 ? 'https://assets.eargo.com/accessories/cable_adapter.png' : 'https://assets.eargo.com/accessories/cable_adapter.png')
        }
        case SKU.DEEP_CLEANING_KIT: {
          return 'https://assets.eargo.com/product-cart-images/deep-cleaning-kit.png'
        }
        case SKU.EARGO_SE: {
          return 'https://assets.eargo.com/product-cart-images/eargo-se-cart.png'
        }
        case SKU.LINK_BY_EARGO: {
          return 'https://assets.eargo.com/product-cart-images/link-cart.png'
        }
        case SKU.EARGO_SE_SMALL_TRUMPETS:
        case SKU.EARGO_SE_MEDIUM_TRUMPETS:
        case SKU.EARGO_SE_LARGE_TRUMPETS: {
          return 'https://assets.eargo.com/product-cart-images/se_trumpets.png'
        }
        case SKU.LINK_BY_EARGO_SMALL_EAR_TIPS:
        case SKU.LINK_BY_EARGO_MEDIUM_EAR_TIPS:
        case SKU.LINK_BY_EARGO_LARGE_EAR_TIPS: {
          return 'https://assets.eargo.com/product-cart-images/link_by_eargo_ear_tips.png'
        }
      }
}

// HINT: For adjustment of split-pay
export const adjustTotalAmount = (amount1, amount2, estimatedTotal, prevTotal) => {
  // amount1, amount2
  if (prevTotal > estimatedTotal) {
    const newAmount = prevTotal - estimatedTotal
    if (amount1 > amount2) {
      return [amount1 - newAmount, amount2]
    } else {
      return [amount1, amount2 - newAmount]
    }
  } else {
    const newAmount = estimatedTotal - prevTotal
    if (amount1 > amount2) {
      return [amount1 + newAmount, amount2]
    } else {
      return [amount1, amount2 + newAmount]
    }
  }
}

export const maskedEmail=(email)=>{
const userEmail = email && email.split('@')
const starCase = '*****'
const emailStart = !!userEmail[0] && userEmail[0].slice(-4)
const emailEnd = !!userEmail[1] && userEmail[1].toString()
const addEamil =  '@' + emailEnd
const finalEmail = !!emailStart && starCase.concat(emailStart, addEamil)
return finalEmail
}

export const totalDocuments = [{
  id: 'AudiogramHearingTestDoc',
  heading: 'Initial Audiogram',
  subHeading: 'Please upload your document.',
}, {
  id: 'AdditionalDocumentation1',
  heading: 'Additional Documentation (1)',
  subHeading: 'Please upload your document.',
  altId: 'PrimaryInsuranceCardDoc'
}, {
  id: 'AdditionalDocumentation2',
  heading: 'Additional Documentation (2)',
  subHeading: 'Please upload your document.',
  altId: 'SecondaryInsuranceCardDoc'
}, {
  id: 'AdditionalDocumentation3',
  heading: 'Additional Documentation (3)',
  subHeading: 'Please upload your document.',
  altId: 'OtherHealthcareProviderDoc'
}, {
  id: 'AdditionalDocumentation4',
  heading: 'Additional Documentation (4)',
  subHeading: 'Please upload your document.'
}, {
  id: 'AdditionalDocumentation5',
  heading: 'Additional Documentation (5)',
  subHeading: 'Please upload your document.',
}]
