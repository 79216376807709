import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colorCodes } from '../../constant/colors'
import { devices } from '../../constant/devices'
import './PopUp.scss'

const { WHITE } = colorCodes
const { tablet, mobile } = devices

const SubContainer = styled.div`
    width: ${props => props.width && props.width}px;
    max-width: 80%;
    margin: 40px auto;
    border-radius: 40px;
    background-color: ${WHITE};
    ${props => props.setHeight
? 'height:fit-content;'
    : ''}
    @media ${mobile} {
      max-width: 90%;
    }
`

const Wrapper = styled.div`
    ::-webkit-scrollbar {
      display: none;
    }
    ${props => props.setHeight
? ''
    : ` max-height: 90vh;overflow-y: scroll;
    height: ${props.height && props.height}px;`}
    
    ${props => props.checkOutPopUp
? `
        padding-top: 51px;
        ::-webkit-scrollbar {
            display: none;
        } `
: `
        padding: 94px 50px 36px 50px;
    `}
    position: relative;
    @media ${tablet} {
        padding-bottom: 59px;
        max-height: ${props => props.checkOutPopUp && 100}vh;
    }
    @media ${mobile} {
            padding: 64px 15px 40px 15px;
        ${props => props.checkOutPopUp
? `
    padding: 64px 15px 40px 15px;
`
: `
padding: 0px; `
}
    }

    ${props => props.hideScroll
? `
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
    `
: ''}
${props => props.paddingTop
  ? ''
  : `
  padding-top: 0px;
    `}
`

const PopUp = ({
  height = 400, width = 600, handleClose, className = '',
  closeOnEsc = false, closeOnOutClick = true, unsetHeight = false,
  children, hideScroll = false, paddingTop = false, checkOutPopUp = false
}) => {
  const wrapperRef = useRef()

  const handleClickOutside = event => {
    if (!!wrapperRef &&
      !!closeOnOutClick &&
      (wrapperRef.current !== null) &&
      !wrapperRef.current.contains(event.target)) {
      handleClose()
    }
  }

  const handleKeyDown = e => {
    if (((e.keyCode === 27) ||
      (e.key === 'Escape') ||
      (e.key === 'Esc')) && !!closeOnEsc) {
      handleClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleKeyDown)
    document.body.classList.add('modal-open')
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleKeyDown)
      document.body.classList.remove('modal-open')
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className={`pop_up_container ${className} ${unsetHeight ? 'set_height' : ''} `}>
      <SubContainer className="popup-content-holder" width={width} setHeight={unsetHeight}>
        <Wrapper ref={wrapperRef} hideScroll={hideScroll} paddingTop={!!paddingTop} checkOutPopUp={checkOutPopUp} height={height} setHeight={unsetHeight}>
          <div onClick={() => handleClose()} className="popup-content-svg-holder cross_section" data-testid='jest-popup-close-btn-id'>
            <img src='https://assets.eargo.com/account/insuranceform/close.svg' alt="Close" />
          </div>
          {children}
        </Wrapper>
      </SubContainer>
    </div>
  )
}

PopUp.propTypes = {
  height: PropTypes.any,
  width: PropTypes.any,
  handleClose: PropTypes.func,
  className: PropTypes.string,
  closeOnEsc: PropTypes.bool,
  closeOnOutClick: PropTypes.bool,
  unsetHeight: PropTypes.bool,
  children: PropTypes.any,
  hideScroll: PropTypes.bool,
  paddingTop: PropTypes.bool,
  checkOutPopUp: PropTypes.bool
}

export default PopUp
