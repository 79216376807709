import { requestSetPassword } from '../util/auth'
import { checkAndSetAutoOut } from './auth'

export const SET_PASSWORD_HANDLE_PROCESSING = 'SET_PASSWORD_HANDLE_PROCESSING'
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR'
export const REMOVE_SET_PASSWORD_ERROR_MSG = 'REMOVE_SET_PASSWORD_ERROR_MSG'
export const SUCCESS_SET_PASSWORD = 'SUCCESS_SET_PASSWORD'
export const SF_USER_UPDATED = 'SF_USER_UPDATED'

const handleProcessing = status => ({
  type: SET_PASSWORD_HANDLE_PROCESSING,
  payload: {
    isProcessing: status
  }
})

export const setPasswordError = errMsg => ({
  type: SET_PASSWORD_ERROR,
  payload: {
    errMsg,
    isProcessing: false
  }
})

export const removeFieldError = fieldName => ({
  type: REMOVE_SET_PASSWORD_ERROR_MSG,
  fieldName
})

export const setUserPasswordUpdate = (status) => ({
  type: SF_USER_UPDATED,
  payload: {
    sfUser: status
  }
})
/**
 * Change Password for
 * Logged In user
 * @param {Object} info
 */
export const setPassword = (info) => async (dispatch) => {
  try {
    dispatch(setPasswordError(null))
    dispatch(handleProcessing(true))
    const resp = await requestSetPassword(info)
    if (!!resp && resp.success) {
      dispatch(handleProcessing(false))
      dispatch(setUserPasswordUpdate(false))
      return true
    } else {
      dispatch(checkAndSetAutoOut(resp))
      const errorBag = !!resp && !!resp.error ? resp.error : { otherError: resp.message }
      dispatch(setPasswordError(errorBag))
      return false
    }
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    console.log('Fetch Auth set Password: ', error)
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})
    dispatch(setPasswordError(errorBag))
    return false
  }
}
