import { helpersFn } from '@eargo/eargo-components'
import { PAYMENT_METHODS } from '../constant'
import {
  fetchAllPaymentMethods,
  addPaymentMethod,
  editPaymentMethod,
  deletePaymentMethod,
  generateCBPublicKey,
  setMethodAsDefault,
  getActivePaymentMethods
} from '../util/new_payment_api_util'
import { checkAndSetAutoOut } from './auth'

const { handleErrOtherKey } = helpersFn

export const HANDLE_PAYMENT_PROCESSING = 'HANDLE_PAYMENT_PROCESSING'
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS'
export const FETCH_PAYMENTS_ERROR = 'FETCH_PAYMENTS_ERROR'
export const REMOVE_PAYMENT_ERROR_MSG = 'REMOVE_PAYMENT_ERROR_MSG'
export const EDIT_PAYMENT_SUCCESS = 'EDIT_PAYMENT_SUCCESS'
export const DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS'
export const SET_DEFAULT_PAYMENT_ID = 'SET_DEFAULT_PAYMENT_ID'
export const SET_CYBERSOURCE_KEY = 'SET_CYBERSOURCE_KEY'
export const SET_CYBERSOURCE_KEY_STATUS = 'SET_CYBERSOURCE_KEY_STATUS'
export const SET_IS_CBS = 'SET_IS_CBS'
export const SET_LOADED_METHOD = 'SET_LOADED_METHOD'

export const handlePaymentProcessing = status => ({
  type: HANDLE_PAYMENT_PROCESSING,
  payload: {
    isLoading: status
  }
})

export const setCyberSourceKey = cyberSourceKey => ({
  type: SET_CYBERSOURCE_KEY,
  payload: {
    cyberSourceKey
  }
})

const setCyberSourceKeyStatus = cyberSourceKeyStatus => ({
  type: SET_CYBERSOURCE_KEY_STATUS,
  payload: {
    cyberSourceKeyStatus
  }
})

const fetchPaymentsSuccess = res => ({
  type: FETCH_PAYMENTS_SUCCESS,
  payload: {
    isLoading: false,
    methods: (res.data).sort((a, b) => (a.default === b.default) ? 0 : a.default ? -1 : 1),
    total: res.total || 0
  }
})

export const paymentsError = errMsg => ({
  type: FETCH_PAYMENTS_ERROR,
  payload: {
    isLoading: false,
    errMsg
  }
})

export const removeFieldError = fieldName => ({
  type: REMOVE_PAYMENT_ERROR_MSG,
  fieldName
})

const setDefaultPaymentId = defaultId => ({
  type: SET_DEFAULT_PAYMENT_ID,
  defaultId
})

/**
* Fetch Payment Methods
*/
export const fetchPayments = () => async (dispatch) => {
  try {
    dispatch(handlePaymentProcessing(true))
    dispatch(getPaymentMethods())
    const resp = await fetchAllPaymentMethods()
    if (!!resp && !!resp.success) {
      dispatch(fetchPaymentsSuccess(resp))
      return true
    } else {
      const errorBag = !!resp && !!resp.error ? resp.error : { otherError: resp.message }
      dispatch(paymentsError(errorBag))
      return false
    }
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})
    dispatch(paymentsError(errorBag))
    return false
  }
}

/**
 * Add New Payment Method
 * @param {Object} info
 */
export const addNewPayment = (info) => async (dispatch, getState) => {
  dispatch(handlePaymentProcessing(true))
  try {
    const response = await addPaymentMethod(info)
    let returnMsg = false
    if (!!response && !!response.success) {
      dispatch(fetchPayments())
      returnMsg = true
    } else {
      const errorBag = !!response && !!response.error
        ? handleErrOtherKey(response.error)
        : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(paymentsError(errorBag))
      returnMsg = false
    }
    dispatch(handlePaymentProcessing(false))
    return returnMsg
  } catch (error) {
    const errJson = error.responseJSON
    if (errJson) {
      dispatch(checkAndSetAutoOut(errJson))
    }
    const errorBag = !!errJson && !!errJson.error
      ? handleErrOtherKey(errJson.error)
      : (!!errJson && !!errJson[0] ? { otherError: errJson[0] } : {})

    const isCBS = getState().myInfoPaymentMethod.isCBS
    if (!!isCBS && !!errorBag && !errorBag.otherError &&
      (!!errorBag.last_digits)) {
      errorBag.otherError = 'Something went wrong, Please refresh the page and try again.'
    } else if (!isCBS && !!errorBag && !errorBag.otherError &&
      !!errorBag.transient_token) {
      errorBag.otherError = 'Something went wrong, Please refresh the page and try again.'
    }

    dispatch(handlePaymentProcessing(false))
    dispatch(paymentsError(errorBag))
    return false
  }
}

/**
 * Edit Payment Method
 * @param {*} id
 * @param {*} info
 */
export const editPaymentMethoInfo = (id, info) => async (dispatch, getState) => {
  dispatch(handlePaymentProcessing(true))
  try {
    const response = await editPaymentMethod(id, info)
    let returnMsg = false
    if (!!response && !!response.success) {
      dispatch(fetchPayments())
      returnMsg = true
    } else {
      const errorBag = !!response && !!response.error ? handleErrOtherKey(response.error) : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(paymentsError(errorBag))
      returnMsg = false
    }
    dispatch(handlePaymentProcessing(false))
    return returnMsg
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? handleErrOtherKey(error.responseJSON.error)
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})

    const isCBS = getState().myInfoPaymentMethod.isCBS
    if (!!isCBS && !!errorBag && !errorBag.otherError &&
      (!!errorBag.last_digits)) {
      errorBag.otherError = 'Something went wrong, Please refresh the page and try again.'
    } else if (!isCBS && !!errorBag && !errorBag.otherError &&
      !!errorBag.transient_token) {
      errorBag.otherError = 'Something went wrong, Please refresh the page and try again.'
    }

    dispatch(handlePaymentProcessing(false))
    dispatch(paymentsError(errorBag))
    return false
  }
}

/**
 * Set Default Payment Method
 * @param {Number} id
 */
export const setDefaultPaymentMethod = id => async (dispatch) => {
  dispatch(handlePaymentProcessing(true))
  try {
    const response = await setMethodAsDefault(id)
    let returnMsg = false
    if (!!response && !!response.success) {
      dispatch(setDefaultPaymentId(id))
      returnMsg = true
    } else {
      const errorBag = !!response && !!response.error ? response.error : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(paymentsError(errorBag))
      returnMsg = false
    }
    dispatch(handlePaymentProcessing(false))
    return returnMsg
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})
    dispatch(handlePaymentProcessing(false))
    dispatch(paymentsError(errorBag))
    return false
  }
}

/**
 * Delete Payment Method
 * @param {Number} id
 */
export const deletePaymentInfo = id => async (dispatch) => {
  dispatch(handlePaymentProcessing(true))
  try {
    const response = await deletePaymentMethod(id)
    let returnMsg = false
    if (!!response && !!response.success) {
      dispatch(fetchPayments())
      returnMsg = true
    } else {
      const errorBag = !!response && !!response.error
        ? response.error
        : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(paymentsError(errorBag))
      returnMsg = false
    }
    dispatch(handlePaymentProcessing(false))
    return returnMsg
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})
    dispatch(handlePaymentProcessing(false))
    dispatch(paymentsError(errorBag))
    return false
  }
}

/**
 * Generate CyberSource
 * Public Key
 */
export const generateCyberSourceKey = () => async dispatch => {
  dispatch(setCyberSourceKeyStatus('LOADING'))
  try {
    const resp = await generateCBPublicKey()
    if (!!resp && !!resp.success && !!resp.data && !!resp.data.keyId) {
      dispatch(setCyberSourceKey(resp.data.keyId))
      dispatch(setCyberSourceKeyStatus('SUCCESS'))
      return resp.data.keyId
    }
    return false
  } catch (e) {
    dispatch(setCyberSourceKeyStatus('FAIL'))
    console.log('fetchKeyInfo Error: ', e)
    return false
  }
}

const setIsCBS = isCBS => ({
  type: SET_IS_CBS,
  payload: {
    isCBS
  }
})

const setMethodLoaded = methodLoaded => ({
  type: SET_LOADED_METHOD,
  payload: {
    methodLoaded
  }
})

export const getPaymentMethods = () => async (dispatch) => {
  try {
    const res = await getActivePaymentMethods()
    if (!!res && !!res.length) {
      const cbsMethod = res.find(r => (r.description === PAYMENT_METHODS.CBS))
      if (cbsMethod) {
        dispatch(setIsCBS(cbsMethod))
        dispatch(setMethodLoaded('CBS'))
      } else {
        window.alert('Something went wrong. Please refresh window')
      }
    }
  } catch (e) {
    console.log('Error In getPaymentMethods')
  }
}
