import { addSupportApi, getActiveSupportCaseApi, getCaseListApi, getCaseListDetailApi, getProductDetailsApi, getSupportCaseAccountInfoApi } from '../util/support_api_util'
import { checkAndSetAutoOut } from './auth'
export const HANDLE_SUPPORT_PROCESSING = 'HANDLE_SUPPORT_PROCESSING'
export const POST_SUPPORT_SUCCESS = 'POST_SUPPORT_SUCCESS'
export const HANDLE_SUPPORT_ERROR = 'HANDLE_SUPPORT_ERROR'
export const GET_CASE_LIST_SUCCESS = 'GET_CASE_LIST_SUCCESS'
export const GET_CASE_LIST_DETAIL_SUCCESS = 'GET_CASE_LIST_DETAIL_SUCCESS'
export const HANDLE_SERVER_DOWN = 'HANDLE_SERVER_DOWN'
export const GET_SUPPORT_CASE_ACCOUNT = 'GET_SUPPORT_CASE_ACCOUNT'
export const HANDLE_ACTIVE_SUPPORT_CASE = 'HANDLE_ACTIVE_SUPPORT_CASE'
export const CLEAR_CASE_LIST_DATA = 'CLEAR_CASE_LIST_DATA'
export const GET_PRODUCT_DETAILS_SUCCESS = 'GET_PRODUCT_DETAILS'
export const GET_PRODUCT_DETAILS_ERROR = 'GET_PRODUCT_DETAILS_ERROR'
export const CLEAR_SUPPORT_REQUEST_DETAILS = 'CLEAR_SUPPORT_REQUEST_DETAILS'

const handleProcessing = isLoading => ({
  type: HANDLE_SUPPORT_PROCESSING,
  payload: { isLoading }
})

const handleActiveSupportCase = active => ({
  type: HANDLE_ACTIVE_SUPPORT_CASE,
  payload: { active }
})

const postSupportSucess = data => ({
  type: POST_SUPPORT_SUCCESS,
  payload: {
    support: data,
    isLoading: false
  }
})

const handleSupportError = errMsg => ({
  type: HANDLE_SUPPORT_ERROR,
  payload: {
    errMsg,
    isLoading: false
  }
})

export const getProductDetailsInfo = info => ({
  type: GET_PRODUCT_DETAILS_SUCCESS,
  payload: {
    lineItems: info
  }
})

const getCaseListSuccess = caseDetail => ({
  type: GET_CASE_LIST_SUCCESS,
  payload: {
    caseDetail: caseDetail.data,
    isLoading: false,
    total: caseDetail.count,
    singleCaseDetail: {}
  }
})

const getCaseListDetailSuccess = caseDetail => ({
  type: GET_CASE_LIST_DETAIL_SUCCESS,
  payload: {
    singleCaseDetail: caseDetail.data,
    caseDetail: [],
    isLoading: false
  }
})

const getSupportCaseAccount = accountDetail => ({
  type: GET_SUPPORT_CASE_ACCOUNT,
  payload: {
    isAllowed: accountDetail,
    notFound: false,
    isLoading: false
  }
})

const handleServerDown = notFound => ({
  type: HANDLE_SERVER_DOWN,
  payload: { notFound }
})

export const clearCaseListData = () => ({
  type: CLEAR_CASE_LIST_DATA,
  payload: {
    caseDetail: []
  }
})

export const clearSupportDetails = () => ({
  type: CLEAR_SUPPORT_REQUEST_DETAILS
})

export const getProductDetails = () => async (dispatch) => {
  dispatch(handleProcessing(true))
  dispatch(handleServerDown(false))
  try {
    const resp = await getProductDetailsApi()
    if (!!resp && !!resp.success && !!resp.orders.length) {
      dispatch(getProductDetailsInfo(!!resp && !!resp.orders && resp.orders))
    } else {
      dispatch(getProductDetailsInfo(['eargo_7']))
      const errorBag = !!resp && !!resp.error ? resp.error : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(handleSupportError(errorBag))
    }
    dispatch(handleProcessing(false))
    return resp
  } catch (error) {
    dispatch(getProductDetailsInfo(['eargo_7']))
    dispatch(checkAndSetAutoOut(error.responseJSON))
    dispatch(handleSupportError(error.responseJSON))
  }
}

export const getSupportCaseAccountInfo = () => async (dispatch) => {
  dispatch(handleProcessing(true))
  dispatch(handleServerDown(false))
  try {
    const resp = await getSupportCaseAccountInfoApi()
    if (!!resp && !!resp.success) {
      dispatch(getSupportCaseAccount(!!resp && !!resp.data && !!resp.data.account && !!resp.data.account.entity && !!resp.data.account.entity.iscase_support))
    } else {
      const errorBag = !!resp && !!resp.error ? resp.error : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(handleSupportError(errorBag))
    }
    dispatch(handleProcessing(false))
    return resp
  } catch (error) {
    dispatch(checkAndSetAutoOut(error.responseJSON))
    dispatch(handleSupportError(error.responseJSON))
  }
}

export const addNewSupportRequest = (info) => async (dispatch) => {
  dispatch(handleProcessing(true))
  try {
    const resp = await addSupportApi(info)
    if (!!resp && !!resp.success) {
      dispatch(postSupportSucess(resp))
      return resp
    } else {
      const errorBag = !!resp && !!resp.error ? resp.error : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(handleSupportError(errorBag))
    }
    dispatch(handleProcessing(false))
    return resp
  } catch (error) {
    dispatch(checkAndSetAutoOut(error.responseJSON))
    dispatch(handleSupportError(error.responseJSON))
  }
}

export const getCaseList = (info = {}) => async (dispatch) => {
  dispatch(handleProcessing(true))
  dispatch(handleServerDown(false))
  try {
    const resp = await getCaseListApi(info)
    if (!!resp && !!resp.success) {
      dispatch(getCaseListSuccess(resp))
    } else {
      const errorBag = !!resp && !!resp.error ? resp.error : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(handleSupportError(errorBag))
    }
    dispatch(handleProcessing(false))
    return resp
  } catch (error) {
    if (
      (!!error.responseJSON && !error.responseJSON.success && !!error.responseJSON.code && ((error.responseJSON.code === 'unauthenticated') || (error.responseJSON.code === 'unauthorized_access'))) ||
      (!!error.responseJSON && !error.responseJSON.success && !!error.responseJSON.statusText && error.responseJSON.statusText === 'Unauthorized') // axios response format
    ) {
      dispatch(handleServerDown(false))
      dispatch(checkAndSetAutoOut(error.responseJSON))
      dispatch(handleSupportError(error.responseJSON))
    }
    dispatch(handleServerDown(true))
    dispatch(handleSupportError(error.responseJSON))
  }
}

// GET CASE LIST DETAIL
export const getCaseListDetail = ({ sf_case_id }) => async (dispatch) => {
  dispatch(handleProcessing(true))
  dispatch(handleServerDown(false))
  try {
    const resp = await getCaseListDetailApi(sf_case_id)
    if (!!resp && !!resp.success) {
      dispatch(getCaseListDetailSuccess(resp))
    } else {
      const errorBag = !!resp && !!resp.error ? resp.error : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(handleSupportError(errorBag))
    }
    dispatch(handleProcessing(false))
    return resp
  } catch (error) {
    dispatch(checkAndSetAutoOut(error.responseJSON))
    dispatch(handleSupportError(error.responseJSON))
  }
}

export const getActiveSupportCase = () => async (dispatch) => {
  dispatch(handleProcessing(true))
  dispatch(handleServerDown(false))
  try {
    const resp = await getActiveSupportCaseApi()
    if (!!resp && !!resp.success) {
      dispatch(handleActiveSupportCase(resp.active))
    } else {
      const errorBag = !!resp && !!resp.error ? resp.error : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(handleSupportError(errorBag))
    }
    dispatch(handleProcessing(false))
    return resp
  } catch (error) {
    dispatch(checkAndSetAutoOut(error.responseJSON))
    dispatch(handleSupportError(error.responseJSON))
  }
}
