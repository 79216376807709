import { helpersFn } from '@eargo/eargo-components'
import { SERVER_URL } from '../constant'

const { prepareRequest } = helpersFn

export const fetchRecentOrder = () => {
  const reqUrl = `${SERVER_URL}v2/api/users/recent_order`
  return new Promise((resolve, reject) => {
    prepareRequest('GET', reqUrl)
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}
