import { ROUTES } from '../../constant'

const { EARGO_NEO, EARGO_MAX, EARGO_NEO_HIFI, ACCESSORIES, ADDRESS, REVIEW, CONFIRMATION, EARGO_5, EARGO_6, EARGO_7 } = ROUTES

export const metaTags = {
  [EARGO_NEO]: [
    { name: 'title', content: 'Eargo Neo | Hear Life Clearly' },
    { name: 'description', content: 'Eargo Neo is our latest innovation: invisible, comfortable, and rechargeable and now, customizable. Free shipping and financing available.' },
    { property: 'og:title', content: 'Eargo Neo Hearing Aids | A sound revival' },
    { property: 'og:description', content: 'Hearing aids for a sound revival. Fully connected. Completely customizable. Completely you.' },
    { property: 'og:image', content: 'https://assets.eargo.com/pdp/meta/Eargo_Neo_og_image.jpg' },
    { property: 'og:url', content: window.location.origin + EARGO_NEO }
  ],
  [EARGO_MAX]: [
    { name: 'title', content: 'Shop Eargo Max Hearing Aids | Eargo' },
    { name: 'description', content: 'Advanced sound quality and noise reduction technology so you can hear life better. Learn more about Eargo Max hearing aids.' },
    { property: 'og:title', content: 'Eargo Max | Give Your Ears A Sound Boost' },
    { property: 'og:description', content: "Eargo Max offers better audio fidelity in our patented and award-winning design that's invisible, comfortable and rechargeable. 0 % APR financing available." },
    { property: 'og:image', content: 'https://assets.eargo.com/pdp/meta/Eargo_Neo_og_image.jpg' },
    { property: 'og:url', content: window.location.origin + EARGO_MAX }
  ],
  [EARGO_NEO_HIFI]: [
    { name: 'title', content: 'Shop Eargo Neo HiFi Hearing Aids | Eargo' },
    { name: 'description', content: 'Bring your world to life with our award-winning hearing aid, packed with outstanding sound fidelity. Learn more about Eargo Neo HiFi hearing aids.' },
    { property: 'og:title', content: 'Eargo Neo HiFi Hearing Aids | Hear Life in Surround Sound' },
    { property: 'og:description', content: 'Top-of-the-line hearing solution packed with our best sound fidelity ever and finely tuned for a full-bodied, natural listening experience.' },
    { property: 'og:image', content: 'https://assets.eargo.com/pdp/meta/Eargo_Neo_Hifi_og_image.jpg' },
    { property: 'og:url', content: window.location.origin + EARGO_NEO_HIFI }
  ],
  [EARGO_5]: [
    { name: 'title', content: 'Shop Eargo 5 Hearing Aids | Eargo' },
    { name: 'description', content: 'Shop the Eargo 5 hearing aid, with Sound Match technology for personalization to your unique hearing preferences. Learn more about our FDA-cleared, OTC self-fitting hearing aids.' },
    { property: 'og:title', content: 'Eargo 5 | Hearing Aids Tuned to You by You' },
    { property: 'og:description', content: 'Now the power to improve your hearing is in your hands—delivered directly to you, supported professionally by us.' },
    { property: 'og:image', content: 'https://assets.eargo.com/pdp/meta/Eargo_5_og_image.jpg' },
    { property: 'og:url', content: window.location.origin + EARGO_5 }
  ],
  [EARGO_6]: [
    { name: 'title', content: 'Shop Eargo 6 Hearing Aids | Eargo' },
    { name: 'description', content: 'Shop the Eargo 6 hearing aid, with Sound Match and Sound Adjust technology for sound that adjusts to your world. Learn more about our FDA-cleared, OTC self-fitting hearing aids.' },
    { property: 'og:title', content: 'Eargo 6 Hearing Aids | Sound that Adjust to Your World' },
    { property: 'og:description', content: 'Better hearing made effortless, now with Sound Adjust and Sound Match.' },
    { property: 'og:image', content: 'https://assets.eargo.com/pdp/meta/Eargo_6_og_image.jpg' },
    { property: 'og:url', content: window.location.origin + EARGO_6 }
  ],
  [EARGO_7]: [
    { name: 'title', content: 'Shop Eargo 7 Hearing Aids | Eargo' },
    { name: 'description', content: 'Shop the Eargo 7 hearing aid, with Sound Match and Sound Adjust+ with Clarity Mode technology. Learn more about our latest FDA-cleared, OTC self-fitting hearing aids.' },
    { property: 'og:title', content: 'Eargo 7 Hearing Aids | Hear. There. Everywhere.' },
    { property: 'og:description', content: 'Hear more of what you love, automatically. Now with Sound Adjust+ with Clarity Mode.' },
    { property: 'og:image', content: 'https://assets.eargo.com/pdp/meta/eargo_7_og_image.jpg' },
    { property: 'og:url', content: window.location.origin + EARGO_7 }
  ],
  [ACCESSORIES]: [
    { name: 'title', content: 'Accessories for Eargos | Eargo Shop' },
    { name: 'description', content: 'Ensure your Eargo hearing aids stay in peak condition. Here you can order replacement Flexis, Wax Guards, or an additional case for charging your Eargos.' },
    { property: 'og:title', content: 'Eargo Hearing Aids | A Sound Revival' },
    { property: 'og:description', content: 'Hearing aids for a sound revival. Fully connected. Completely customizable. Completely you.' },
    { property: 'og:image', content: 'https://assets.eargo.com/pdp/meta/Eargo_Neo_og_image.jpg' },
    { property: 'og:url', content: window.location.origin + ACCESSORIES }
  ],
  [ADDRESS]: [
    { name: 'title', content: 'Check Out Page - Buy Eargo | Eargo Shop' },
    { property: 'og:image', content: 'https://assets.eargo.com/pdp/meta/Eargo_Neo_og_image.jpg' },
    { property: 'og:url', content: window.location.origin }
  ],
  [REVIEW]: [
    { property: 'og:image', content: 'https://assets.eargo.com/pdp/meta/Eargo_Neo_og_image.jpg' },
    { property: 'og:url', content: window.location.origin }
  ],
  [CONFIRMATION]: [
    { property: 'og:image', content: 'https://assets.eargo.com/pdp/meta/Eargo_Neo_og_image.jpg' },
    { property: 'og:url', content: window.location.origin }
  ]
}

metaTags[EARGO_NEO].h1 = 'Eargo Neo Hearing Aids | Fully connected. Completely customizable. Completely you.'
metaTags[EARGO_MAX].h1 = 'Eargo Max Hearing Aids.'
metaTags[EARGO_NEO_HIFI].h1 = 'Eargo Neo Hifi Hearing Aids.'
metaTags[ACCESSORIES].h1 = 'Eargo Accessories for Hearing Aids.'
metaTags[EARGO_5].h1 = 'Eargo 5 Hearing Aid: OTC Self-fitting, Virtually Invisible Hearing Aids.'
metaTags[EARGO_6].h1 = 'Eargo 6 Hearing Aid: OTC Self-fitting, Virtually Invisible Hearing Aids.'
metaTags[EARGO_7].h1 = 'Eargo 7 Hearing Aid: OTC Self-fitting, Virtually Invisible Hearing Aids.'
