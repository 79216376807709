import React from 'react'
import PropTypes from 'prop-types'
import './CommanHeading.scss'

const CommanHeading = ({ heading, className }) => {
  return (
    <h5 className={'typographyV2 account_title ' + className}>{ heading }</h5>
  )
}

CommanHeading.propTypes = {
  heading: PropTypes.string,
  className: PropTypes.string
}

export default React.memo(CommanHeading)
