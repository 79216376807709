import auth0 from 'auth0-js'
import { AUTH0CLIENTID, AUTH0DOMAIN, AUTH0CONNECTION, SERVER_URL } from '../constant'

export const webAuth = new auth0.WebAuth({
  domain: AUTH0DOMAIN,
  clientID: AUTH0CLIENTID,
  scope: 'openid',
  responseType: 'id_token'
})

const customizeError = (err) => {
  const error = {}
  error.code = err && err.code ? err.code : 'access_denied'
  error.error = err && err.description ? err.description : "Your email/password don't match an Eargo account. Please review and try again."
  return error
}

const requestActivationCode = (email) => {
  return new Promise((resolve, reject) => {
    fetch(`${SERVER_URL}v2/api/auth/send-createaccount-otp`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    })
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

export const fetchUserSession = (token) => {
  return new Promise((resolve, reject) => {
    fetch(`${SERVER_URL}v2/api/session/call-back`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token })
    })
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

export const handleLogin = async ({ email, password }) => {
  const customError = {
    success: false,
    message: "Your email/password don't match an Eargo account. Please review and try again."
  }
  try {
    const auth0Login = await handlAuth0Login(email, password)
    if (auth0Login.idToken) {
      const userData = await fetchUserSession(auth0Login.idToken)
      return userData
    } else {
      if (auth0Login.error.includes('Please verify your email')) {
        const activationCode = await requestActivationCode(email)
        const response = activationCode.success ? activationCode : customError
        return response
      } else return auth0Login
    }
  } catch (error) {
    return customError
  }
}

export const handlAuth0Login = async (email, password) => {
  return new Promise((resolve, reject) => {
    webAuth.client.login({ realm: AUTH0CONNECTION, username: email, password: password }, (err, res) => {
      if (err) {
        const error = customizeError(err)
        resolve(error)
      } else {
        resolve(res)
      }
    }
    )
  })
}

// export const triggerMagicLink = ({ email }) => {
//   return new Promise((resolve, reject) => {
//     fetch(`${SERVER_URL}api/send-magicLink`, {
//       method: 'POST',
//       credentials: 'include',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({ email })
//     })
//       .then(res => res.json())
//       .then(result => resolve(result))
//       .catch(error => reject(error))
//   })
// }

// export const handleSignup = async (fields) => {
//   try {
//     return new Promise((resolve, reject) => {
//       webAuth.signup({ connection: AUTH0CONNECTION, ...fields }, (err, res) => {
//         if (err) {
//           reject(err)
//         } else {
//           resolve(res)
//         }
//       }
//       )
//     })
//   } catch (error) {
//     return error
//   }
// }

// export const sendLoginAuth0Otp = async (auth0ClientId, auth0Domain, { email }) => {
//   try {
//     return new Promise((resolve, reject) => {
//       auth0Client(auth0ClientId, auth0Domain, 'token', '/callback').passwordlessStart(
//         {
//           connection: 'email', // Use 'sms' for SMS-based passwordless login
//           email: email,
//           send: 'link' // Use 'link' to send a magic link instead of a code
//         },
//         (err, res) => {
//           if (err) {
//             reject(err)
//           } else {
//             resolve(res)
//           }
//         }
//       )
//     })
//   } catch (error) {
//     return error
//   }
// }

// const auth0Client = (auth0ClientId, auth0Domain, responseType, redirectUri, scope) => {
//   const webAuth = new auth0.WebAuth({
//     domain: auth0Domain,
//     clientID: auth0ClientId,
//     ...(responseType && { responseType: responseType }),
//     ...(redirectUri && { redirectUri: redirectUri }),
//     ...(scope && { scope: scope })
//   })
//   return webAuth
// }
