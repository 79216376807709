import React from 'react'
import PropTypes from 'prop-types'
import { ReactCheckBox } from '@eargo/eargo-components'

export const ReduxCheckboxInput = ({
  input, label, className = ''
}) => (
  <ReactCheckBox
    label={label}
    className={className}
    checked={input.value}
    handleOnChange={input.onChange}
    {...input}
  />
)

ReduxCheckboxInput.propTypes = {
  input: PropTypes.any,
  label: PropTypes.any,
  className: PropTypes.string
}
