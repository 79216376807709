import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { parse } from 'query-string'
import * as EmailValidator from 'email-validator'
import { PrimaryInput, DotSpinLoading, helpersFn, EargoButtonV2, AuthWrapper, HeaderSection, CommonUnderline } from '@eargo/eargo-components'

import { fetchAuth, requestForgotPassword } from '../../util/auth'
import MessageContainer from '../common/MessageContainer'
import './index.scss'

const { createQueryParams } = helpersFn

const ForgotPassword = ({ location, history }) => {
  const [email, setEmail] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [errors, setErrors] = useState({})
  const preventSubmit = !!Object.keys(errors).length || !email
  const queryParams = parse(location.search)
  const errorList = errors.otherError

  useEffect(() => {
    fetchAuthInfo()
    handleRedirectUrl()
  }, [])

  const handleRedirectUrl = () => {
    const queryParams = parse(window.location.search)
    if (!queryParams.redirect_uri) {
      history.replace('/forgot-password?redirect_uri=/')
    }
  }

  const fetchAuthInfo = async () => {
    try {
      const response = await fetchAuth()
      const queryParams = parse(window.location.search)
      if (!!response && !!response.data && !!response.data.id) {
        if (!!queryParams && !!queryParams.redirect_uri) {
          window.location.href = queryParams.redirect_uri
        } else {
          history.push('/')
        }
      }
    } catch (error) {
      console.log('Fetch Auth Error: ', error)
    }
  }

  const handleFocus = e => {
    const { name } = e.target
    if (errors) {
      !!errors[name] && delete errors[name]
      !!errors.otherError && delete errors.otherError
    }
    setErrors({})
  }

  const requestForgot = async (errors) => {
    try {
      const resp = await requestForgotPassword(email)
      if (!!resp && !!resp.type &&
        (resp.type === 'success') &&
        !!resp.req_id) {
        setErrors({})
        setIsProcessing(false)
        return resp.req_id
      } else {
        const errorBag = !!resp && !!resp.error ? resp.error : (!!resp && !!resp[0] ? resp[0] : resp.message)
        errors.otherError = errorBag
        setErrors(errors)
        setIsProcessing(false)
        return false
      }
    } catch (error) {
      const errorBag = !!error.responseJSON && !!error.responseJSON.error
        ? error.responseJSON.error
        : (
            !!error.responseJSON && !!error.responseJSON[0] ? error.responseJSON[0] : 'Something went wrong, Please try again.'
          )
      errors.otherError = errorBag
      setErrors(errors)
      setIsProcessing(false)
      return false
    }
  }

  const validateForm = () => {
    const formErrors = {}
    let formIsValid = true

    if (!email) {
      formIsValid = false
      formErrors.email = 'Please enter your email'
    } else {
      if (!EmailValidator.validate(email)) {
        formIsValid = false
        formErrors.email = 'Please enter valid email'
      }
    }
    setErrors(formErrors)
    return formIsValid
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const errors = {}
    setIsProcessing(true)
    if (validateForm()) {
      const resp = await requestForgot(errors)
      if (resp) {
        const queryParams = parse(location.search)
        delete queryParams.email
        history.push('/verify-otp?' + createQueryParams({ ...queryParams, req_id: resp }))
        return true
      }
    }
    setErrors(errors)
    setIsProcessing(false)
  }

  return (
    <AuthWrapper className="forgot_password_main_container" >
        {!!isProcessing && <DotSpinLoading />}
        <HeaderSection heading= 'Forgot Password' label={!!queryParams && !!queryParams.email ? 'Your Activation Code Has Expired' : 'Enter your email and we will send you an email with instructions to reset your password.'} />
        <form className='form_container' onSubmit={handleSubmit}>
          <div className="input_field">
            <PrimaryInput
              name="email"
              value={email || ''}
              handleOnBlur={validateForm}
              handleOnFocus={handleFocus}
              handleOnChange={e => {
                setEmail((e.target.value).trim())
                setErrors({})
              }}
              errClass={!!errors && !!errors.email ? 'error_border' : ''}
              label={!!errors && !!errors.email ? errors.email : 'Email'}
            />
          </div>
          {!!errors && !!errors.otherError && <div className='message_container'><MessageContainer messageList={errorList} /></div>}
          <div className='send_button'>
            <EargoButtonV2
              label={isProcessing ? 'Loading...' : 'Send reset instructions'}
              type="submit"
              disabled={preventSubmit}
              className={`primary ${preventSubmit ? 'disabled' : ''}`} />
          </div>
        </form>
        <CommonUnderline />
        <div className='body_bold typographyV2 back_to_login_page' onClick={() => history.push('/login')} >Back to login</div>
      </AuthWrapper>
  )
}

ForgotPassword.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
}

export default withRouter(ForgotPassword)
