import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import PrimaryTextArea from '../PrimaryTextArea'

const ReduxPrimaryTextArea = ({
  input, id, disabled, label, handleOnFocus, handleOnBlur, placeholder, maxLength,
  meta: { touched, error, active, visited }, errResp, inputMode, classess = ''
}) => (

  <Fragment>
    <PrimaryTextArea
      id={id}
      classess={classess}
      disabled={disabled}
      active={active}
      placeholder={placeholder}
      maxLength={maxLength}
      inputMode={inputMode}
      errClass={(((!active && (visited || touched)) && error) || !!errResp) ? 'error_border' : ''}
      label={(((!active && (visited || touched)) && error) || !!errResp) ? (error || errResp) : label}
      handleOnChange={param => input.onChange(param.target.value)}
      handleOnFocus={e => {
        input.onFocus(e)
        !!handleOnFocus && handleOnFocus(e)
      }}
      handleOnBlur={e => {
        input.onBlur(e)
        input.onChange((e.target.value).trim())
        !!handleOnBlur && handleOnBlur(e)
      }}
      {...input}
    />
  </Fragment>
)

ReduxPrimaryTextArea.propTypes = {
  input: PropTypes.any,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  handleOnFocus: PropTypes.func,
  handleOnBlur: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.any,
  meta: PropTypes.any,
  errResp: PropTypes.any,
  inputMode: PropTypes.any,
  classess: PropTypes.string
}

export default ReduxPrimaryTextArea
