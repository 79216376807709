import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'

import { CBS_API_JS } from '../../constant'
import { colorCodes } from '../../constant/colors'
import { removeAllErrors } from '../../actions/error_actions'
import { requiredField } from '../common/redux-field/validation'
import ReduxNumberInput from '../common/redux-field/ReduxNumberInput'
import { removeAllReviewErrors } from '../../actions/review_error_actions'
import { generateCyberSourceKey } from '../../actions/info_payment_method'
import { addScript, removeElement, waitForScript } from '../../common/helpers'
import './CyberSourceForm.scss'

const expirationRequired = requiredField('Expiration')
const { ERROR_RED, GREY_1, GREEN_2 } = colorCodes

// Custom styles that will be applied to each field we create using Microform
const myStyles = {
  input: {
    'font-family': 'Inter, sans-serif',
    'font-style': 'normal',
    'font-size': '16px',
    'font-weight': '400',
    'line-height': '18px',
    color: GREY_1
  },
  ':disabled': { cursor: 'not-allowed' },
  valid: { color: GREEN_2 },
  invalid: { color: ERROR_RED }
}

const isExpirationValid = value => {
  if (!value) return 'Expiration is invalid'

  const newDate = value.split('/')
  if (!!newDate && newDate.length === 2) {
    const dateInstance = new Date()
    const currentMonth = dateInstance.getMonth().toString()
    const currentYear = dateInstance.getFullYear().toString()

    const monthVal = (Number(newDate[0]) - 1).toString()
    const yearVal = `20${newDate[1]}`
    const maxYear = (yearVal - currentYear) * 12 + parseInt(newDate[0])
    if (maxYear <= 120) {
      return new Date(yearVal, monthVal) >= new Date(currentYear, currentMonth)
        ? undefined
        : 'Expiration is invalid'
    }
  }
  return 'Expiration is invalid'
}

const ElementWrapper = ({ label, children, labelClass, isValue }) => (
  <label className={`card-containers inputContainer ${labelClass}`}>
    <h1 className={`typographyV2 ${isValue ? 'floating_label' : 'label'}`}>
      {label}
    </h1>
    {children}
  </label>
)

ElementWrapper.propTypes = {
  label: PropTypes.any,
  children: PropTypes.any,
  labelClass: PropTypes.string,
  isValue: PropTypes.any
}

const cyberSourceState = (state, retry) => {
  switch (state) {
    case 'LOADING':
      return <p className="center-flex cyber_source_status_label">{'Processing...'}</p>
    case 'FAIL':
      return <p className="center-flex cyber_source_status_label" onClick={retry}>{'Failed. Retry!'}</p>
    default:
      return null
  }
}

const CyberSourceForm = ({
  identifier = 1,
  isEdit, initialValues, setCardBrand, setCardCode,
  handleMicroformIns, setCardState, cardState
}) => {
  const dispatch = useDispatch()
  const { cyberSourceKey, cyberSourceKeyStatus } = useSelector(state => state.userPaymentMethods)
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const ref = useRef(false)
  useEffect(() => {
    ref.current = true
    return () => {
      ref.current = false
    }
  }, [])

  const numberRef = useRef()
  const cvcRef = useRef()

  const handleCSFocus = type => {
    dispatch(removeAllErrors())
    dispatch(removeAllReviewErrors())
    setCardState((prevState) => {
      return ({
        ...prevState,
        [type + 'Focus']: true,
        ...(type === 'exp' && { [type + 'Message']: false }),
        ...(type === 'exp' && { [type + 'Error']: false })
      })
    })
  }

  const handleCSBlur = (type) => {
    setCardState((prevState) => {
      return ({
        ...prevState,
        [type + 'Focus']: false
      })
    })
  }

  const handleFieldChange = (type, data) => {
    if ((type === 'number') && !!data && !!data.card && !!data.card[0]) {
      // HINT: Set default value visa
      if (data.card.length === 2) {
        const cardInfo = data.card.filter(c => c.name !== 'maestro')
        setCardBrand(!!cardInfo && !!cardInfo[0] ? cardInfo[0].name : 'visa')
        setCardCode(!!cardInfo && !!cardInfo[0] ? cardInfo[0].cybsCardType : false)
      } else {
        setCardBrand(data.card[0].name || 'visa')
        setCardCode(data.card[0].cybsCardType || false)
      }
    }
    const { empty, valid } = data
    setCardState((prevState) => {
      return ({
        ...prevState,
        [type + 'Error']: empty ? true : !valid,
        [type + 'Empty']: empty,
        [type + 'Message']: empty ? 'is required' : 'is invalid'
      })
    })
  }

  const applyCyberSource = async () => {
    if (!window.Flex) {
      addScript(CBS_API_JS, 'cyber-source-script')
    }

    try {
      const res = await waitForScript('Flex', 10)
      if (!!res && !!cyberSourceKey && !!ref.current) {
        setScriptLoaded(true)
        return true
      }
    } catch (e) {
      console.log('Handling applyCyberSource: ', e)
      return false
    }
  }

  useEffect(() => {
    if (scriptLoaded) {
      const { Flex } = window
      const flex = new Flex(cyberSourceKey)
      const microform = flex.microform({ styles: myStyles })

      if (!!numberRef && !!numberRef.current) {
        const numberField = microform.createField('number', { placeholder: '' })
        numberField.on('focus', () => handleCSFocus('number'))
        numberField.on('blur', (data) => handleCSBlur('number', data))
        numberField.on('change', (data) => handleFieldChange('number', data))
        numberField.load(`#number-container-${identifier}`)
      }

      if (!!cvcRef && !!cvcRef.current) {
        const securityCode = microform.createField('securityCode', { placeholder: '' })
        securityCode.load(`#securityCode-container-${identifier}`)
        securityCode.on('focus', () => handleCSFocus('cvc'))
        securityCode.on('blur', (data) => handleCSBlur('cvc', data))
        securityCode.on('change', (data) => handleFieldChange('cvc', data))
      }

      handleMicroformIns(microform)
    }
  }, [scriptLoaded])

  useEffect(() => {
    applyCyberSource()
    return () => {
      removeElement(['cyber-source-script'])
      setScriptLoaded(false)
    }
    // eslint-disable-next-line
  }, [cyberSourceKey])

  const {
    numberEmpty, numberError, numberFocus, numberMessage,
    expError, expMessage,
    cvcEmpty, cvcError, cvcFocus, cvcMessage
  } = cardState

  return (
    <Fragment>
      {
        (!!scriptLoaded && !!cyberSourceKey)
          ? <Fragment>
          <ElementWrapper label={(!numberFocus && numberError) ? `Card Number ${numberMessage}` : 'Card Number'}
            isValue={numberFocus || !numberEmpty}
            labelClass={(!numberFocus && numberError) ? 'error_border' : ''}>
            <div className='cyber-source-card-inputs' id={`number-container-${identifier}`} ref={numberRef} />
          </ElementWrapper>
          {
            !!isEdit && !!initialValues && !!initialValues.last_digits &&
            <div className='body typographyV2 save_card'>XXXX XXXX XXXX {initialValues.last_digits}</div>
          }
          <div className="flex-container for-cbs">
            <Field
              name={`expiration_${identifier}`}
              label="Expiration"
              component={ReduxNumberInput}
              onFocus={() => handleCSFocus('exp')}
              expError={!!expError && !!expMessage ? expMessage : false}
              validate={[expirationRequired, isExpirationValid]}
            />
            <ElementWrapper label={(!cvcFocus && cvcError) ? `CVC ${cvcMessage}` : 'CVC'}
              isValue={cvcFocus || !cvcEmpty}
              labelClass={(!cvcFocus && cvcError) ? 'error_border' : ''}>
              <div className='cyber-source-card-cvc-input' id={`securityCode-container-${identifier}`} ref={cvcRef} />
            </ElementWrapper>
          </div>
        </Fragment>
          : cyberSourceState(cyberSourceKeyStatus, dispatch(generateCyberSourceKey))
      }
    </Fragment>
  )
}

CyberSourceForm.propTypes = {
  identifier: PropTypes.any,
  removeAllErrors: PropTypes.func,
  cyberSourceKeyStatus: PropTypes.any,
  isEdit: PropTypes.any,
  initialValues: PropTypes.any,
  cyberSourceKey: PropTypes.any,
  setCardBrand: PropTypes.func,
  setCardCode: PropTypes.func,
  removeAllReviewErrors: PropTypes.func,
  handleMicroformIns: PropTypes.func,
  setCardState: PropTypes.func,
  cardState: PropTypes.any
}

export default CyberSourceForm
