import React from 'react'

const PlayButton = () => (
    <>
        <img src={'https://assets.eargo.com/corp/guides_page/play_button.svg'} alt="Play Arrow" className="thumb-playbtn play-btn" />
        <img src={'https://assets.eargo.com/corp/guides_page/play_button_hover.png'} alt="Play Arrow" className="thumb-playbtn play-btn-hover" />
    </>
)

export default PlayButton
