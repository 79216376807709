import { SET_WIDTH_FOR_MOBILE } from '../constant'
import { fetchAuth } from '../util/global'
import { setAddressListPopUp, setAddressPopUp } from './user_address'
import { setAddPaymentPopUp, setPaymentListPopUp } from './user_payment'

export const DETECT_IS_MOBILE = 'DETECT_IS_MOBILE'
export const PAYPAL_IS_LOADING = 'PAYPAL_IS_LOADING'
export const SET_LOGIN_FORM = 'SET_LOGIN_FORM'
export const SET_ORDER_FOUND = 'SET_ORDER_FOUND'
export const SET_COUPON_RESPONSE_MESSAGE = 'SET_COUPON_RESPONSE_MESSAGE'
export const SET_CART_STATUS = 'SET_CART_STATUS'
export const SET_BREAD_MODAL = 'SET_BREAD_MODAL'
export const SET_OPEN_REVIEW = 'SET_OPEN_REVIEW'
export const LOGIN_AUTH_SUCCESS = 'LOGIN_AUTH_SUCCESS'
export const LOGIN_AUTH_FALSE = 'LOGIN_AUTH_FALSE'
export const SET_AUTH_RESP = 'SET_AUTH_RESP'
export const SET_AUTO_OUT = 'SET_AUTO_OUT'
export const CLEAR_ON_AUTO_OUT = 'CLEAR_ON_AUTO_OUT'
export const SET_IS_CBS = 'SET_IS_CBS'
export const SET_LOADED_METHOD = 'SET_LOADED_METHOD'
export const OPEN_MY_INFO_COLLAPSE = 'OPEN_MY_INFO_COLLAPSE'
export const DISABLED_OTHER_SECTION = 'DISABLED_OTHER_SECTION'

const loginAuthSuccess = user => ({
  type: LOGIN_AUTH_SUCCESS,
  payload: {
    isLoggedIn: true,
    autoOut: false,
    user
  }
})

export const openMyInfoCollapse = isOpen => ({
  type: OPEN_MY_INFO_COLLAPSE,
  payload: isOpen
})

export const disabledOtherSection = isDisabled => {
  return ({
    type: DISABLED_OTHER_SECTION,
    payload: isDisabled
  })
}

const loginAuthFalse = () => ({
  type: LOGIN_AUTH_FALSE,
  payload: {
    isLoggedIn: false,
    autoOut: false,
    user: {}
  }
})

const loginAuthPending = () => ({
  type: LOGIN_AUTH_FALSE,
  payload: {
    isLoggedIn: null,
    autoOut: false,
    user: {}
  }
})

const setAuthResp = payload => ({
  type: SET_AUTH_RESP,
  payload
})

export const setIsCBS = isCBS => ({
  type: SET_IS_CBS,
  payload: {
    isCBS
  }
})

export const setMethodLoaded = methodLoaded => ({
  type: SET_LOADED_METHOD,
  payload: {
    methodLoaded
  }
})

export const setOrderFound = orderFound => ({
  type: SET_ORDER_FOUND,
  payload: {
    orderFound
  }
})

export const setCartStatus = openCart => ({
  type: SET_CART_STATUS,
  payload: {
    openCart
  }
})

export const setOpenReview = openReview => ({
  type: SET_OPEN_REVIEW,
  payload: {
    openReview
  }
})

export const setBreadModal = openBreadModal => ({
  type: SET_BREAD_MODAL,
  payload: {
    openBreadModal
  }
})

export const detectIsMobile = () => ({
  type: DETECT_IS_MOBILE,
  payload: {
    isMobile: window.innerWidth <= SET_WIDTH_FOR_MOBILE,
    windowWidth: window.innerWidth
  }
})

export const paypalIsLoading = isLoading => ({
  type: PAYPAL_IS_LOADING,
  payload: {
    isLoading
  }
})

export const setLoginForm = showLogin => ({
  type: SET_LOGIN_FORM,
  payload: {
    showLogin
  }
})

export const setCouponResponseMessage = resp => ({
  type: SET_COUPON_RESPONSE_MESSAGE,
  payload: {
    couponRespMessage: {
      error: (!!resp && !!resp.error) ? resp.error : false,
      message: (!!resp && !!resp.message) ? resp.message : false
    }
  }
})

/**
 * Fetch logged in
 * user's info
 */
export const fetchAuthInfo = () => async (dispatch) => {
  try {
    dispatch(loginAuthPending())
    const response = await fetchAuth()
    if (!!response && response.success && !!response.data && !!response.data.id) {
      dispatch(loginAuthSuccess(response.data))
    } else {
      dispatch(loginAuthFalse())
    }
  } catch (error) {
    console.log('Fetch Auth Error: ', error)
  }
}

export const setAuthState = authResp => dispatch => {
  dispatch(setAuthResp({
    isLoggedIn: !!authResp && !!authResp.id,
    autoOut: false,
    user: authResp
  }))
}

export const setAutoOut = autoOut => ({
  type: SET_AUTO_OUT,
  payload: { autoOut }
})

export const clearUserOnAutoOut = () => ({
  type: CLEAR_ON_AUTO_OUT,
  payload: {
    isLoggedIn: false,
    autoOut: true,
    user: {}
  }
})

export const checkAndSetAutoOut = res => dispatch => {
  if (!!res && !res.success && !!res.code &&
    ((res.code === 'unauthenticated') || (res.code === 'unauthorized_access'))) {
    dispatch(clearUserOnAutoOut())
  }
}

/**
 * Set status for all
 * active popups
 * @param {Boolean} status
 */
export const setActivePopUpStatus = status => dispatch => {
  dispatch(setAddressListPopUp(status))
  dispatch(setPaymentListPopUp(status))
  dispatch(setAddressPopUp(status))
  dispatch(setAddPaymentPopUp(status))
  dispatch(setCartStatus(false))
}

export const checkForUser = () => async (dispatch) => {
  try {
    const response = await fetchAuth()
    if (!!response && response.success && !!response.data && !!response.data.id) {
      dispatch(loginAuthSuccess(response.data))
    }
  } catch (error) {
    console.log('Fetch Auth Error: ', error)
  }
}
