import { fetchAllAddresses, addAddress, setAddressAsDefault, validateTax } from '../util/address_api_util'
import { checkAndSetAutoOut } from './global'
import { COUNTRY_CODE } from '../constant'
import { getStates } from '../util/states_api_util'
import { handleUPMethodsProcessing } from './user_payment'
import { updateSyncErrors } from 'redux-form'
import { submitFailure } from '../common/helpers'

export const RECEIVE_ADDRESS = 'RECEIVE_ADDRESS'
export const HANDLE_UA_PROCESSING = 'HANDLE_UA_PROCESSING'
export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS'
export const ADD_USER_ADDRESS_SUCCESS = 'ADD_USER_ADDRESS_SUCCESS'
export const FETCH_ADDRESSES_ERROR = 'FETCH_ADDRESSES_ERROR'
export const FETCH_STATES_SUCCESS = 'FETCH_STATES_SUCCESS'
export const REMOVE_ADDRESS_ERROR_MSG = 'REMOVE_ADDRESS_ERROR_MSG'
export const SET_ADDRESS_DEFAULT_ID = 'SET_ADDRESS_DEFAULT_ID'
export const SET_SAVED_ADDRESS_ID = 'SET_SAVED_ADDRESS_ID'
export const SET_SAVED_BILL_ADDRESS_ID = 'SET_SAVED_BILL_ADDRESS_ID'
export const SET_ADDRESS_LIST_POPUP = 'SET_ADDRESS_LIST_POPUP'
export const SET_ADDRESS_POPUP = 'SET_ADDRESS_POPUP'

export const setAddressListPopUp = addressListPopUp => ({
  type: SET_ADDRESS_LIST_POPUP,
  payload: {
    addressListPopUp
  }
})

export const handleUAProcessing = isLoading => ({
  type: HANDLE_UA_PROCESSING,
  payload: {
    isLoading
  }
})

export const setAddressPopUp = addressPopUp => ({
  type: SET_ADDRESS_POPUP,
  payload: {
    addressPopUp
  }
})

export const setSavedAddressId = savedAddressId => ({
  type: SET_SAVED_ADDRESS_ID,
  payload: {
    savedAddressId
  }
})

export const setSavedBillAddressId = savedBillAddressId => ({
  type: SET_SAVED_BILL_ADDRESS_ID,
  payload: {
    savedBillAddressId
  }
})

const fetchUserAddressesSuccess = res => ({
  type: FETCH_ADDRESSES_SUCCESS,
  payload: {
    isLoading: false,
    addresses: (res.rows).sort((a, b) => (a.default === b.default) ? 0 : a.default ? -1 : 1)
  }
})

// HINT: For now, not in use
export const addUserAddressesSuccess = address => ({
  type: ADD_USER_ADDRESS_SUCCESS,
  address
})

const fetchStatesSuccess = states => ({
  type: FETCH_STATES_SUCCESS,
  payload: {
    states
  }
})

export const removeFieldError = fieldName => ({
  type: REMOVE_ADDRESS_ERROR_MSG,
  fieldName
})

export const addressesError = errMsg => ({
  type: FETCH_ADDRESSES_ERROR,
  payload: {
    isLoading: false,
    errMsg
  }
})

const setDefaultId = defaultId => ({
  type: SET_ADDRESS_DEFAULT_ID,
  defaultId
})

/**
* Fetch Addresses
*/
export const fetchUserAddresses = () => async (dispatch) => {
  try {
    dispatch(handleUAProcessing(true))
    const resp = await fetchAllAddresses()
    if (!!resp && !!resp.success) {
      dispatch(fetchUserAddressesSuccess(resp))
      return true
    } else {
      dispatch(checkAndSetAutoOut(resp))
      const errorBag = !!resp && !!resp.error ? resp.error : { otherError: resp.message }
      dispatch(addressesError(errorBag))
      return false
    }
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (
          !!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {}
        )
    dispatch(addressesError(errorBag))
    return false
  }
}

/**
 * Add New Address
 * @param {Object} info
 */
export const addNewAddress = info => async (dispatch, getState) => {
  dispatch(handleUAProcessing(true))
  try {
    const response = await addAddress(info)
    let returnMsg = false
    if (!!response && !!response.success) {
      if (response.data) {
        dispatch(fetchUserAddresses())
        const { cart, userAddresses } = getState()
        const userAddressList = userAddresses.addresses
        const cartAddressID = !!cart && !!cart.shipAddress && !!cart.shipAddress.id ? cart.shipAddress.id : false
        const isInList = !!cartAddressID && !!userAddressList && !!userAddressList.length && userAddressList.find(u => u.id === cartAddressID)
        !!isInList && dispatch(setSavedAddressId(response.data.id))
      }
      returnMsg = true
    } else {
      dispatch(checkAndSetAutoOut(response))
      const errorBag = !!response && !!response.error ? response.error : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(addressesError(errorBag))
      returnMsg = false
    }
    dispatch(handleUAProcessing(false))
    return returnMsg
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorMsg = !!error.responseJSON && !!error.responseJSON.error && error.responseJSON.error
    const errorBag = errorMsg
      ? typeof errorMsg === 'string' ? { otherError: errorMsg } : errorMsg
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})

    dispatch(handleUAProcessing(false))
    dispatch(addressesError(errorBag))
    return false
  }
}

/**
 * Set Default Address
 * @param {Number} id
 */
export const setDefaultAddress = id => async (dispatch) => {
  dispatch(handleUAProcessing(true))
  try {
    const response = await setAddressAsDefault(id)
    let returnMsg = false
    if (!!response && !!response.success) {
      dispatch(setDefaultId(id))
      returnMsg = true
    } else {
      const errorBag = !!response && !!response.error ? response.error : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(addressesError(errorBag))
      returnMsg = false
    }
    dispatch(checkAndSetAutoOut(response))
    dispatch(handleUAProcessing(false))
    return returnMsg
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (
          !!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {}
        )
    dispatch(handleUAProcessing(false))
    dispatch(addressesError(errorBag))
    return false
  }
}

/**
 * Fetch All States
 * List according to
 * countryId
 * @param {*} countryId
 */
export const fetchStates = (countryId = COUNTRY_CODE) => async (dispatch) => {
  try {
    const resp = await getStates(countryId)
    if (!!resp && !!resp.length) {
      dispatch(fetchStatesSuccess(resp))
      return true
    } else {
      const errorBag = !!resp && !!resp.error ? resp.error : { otherError: resp.message }
      dispatch(addressesError(errorBag))
      return false
    }
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (
          !!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {}
        )
    dispatch(addressesError(errorBag))
    return false
  }
}

/**
 * Validate Zipcode
 * @param {*} param
 * @param {String} FORM_NAME
 */

export const validateZipCode = ({ zipcode, state_id, city = null }, FORM_NAME) => async (dispatch) => {
  // HINT: I'll optimize it later
  try {
    if (!zipcode || !state_id) return

    dispatch(handleUAProcessing(true))
    dispatch(handleUPMethodsProcessing(true))
    const response = await validateTax({ zipcode, state_id, ...(!!city && { city }) })
    if (!!response && !!response.success) {
      dispatch(handleUAProcessing(false))
      dispatch(handleUPMethodsProcessing(false))
      return true
    }
    dispatch(checkAndSetAutoOut(response))
    dispatch(handleUAProcessing(false))
    dispatch(handleUPMethodsProcessing(false))
    dispatch(updateSyncErrors(FORM_NAME, {
      zipcode: 'Zipcode combo is incorrect'
    }))
    submitFailure()
    return false
  } catch (error) {
    dispatch(handleUAProcessing(false))
    dispatch(handleUPMethodsProcessing(false))
    dispatch(updateSyncErrors(FORM_NAME, {
      zipcode: 'Zipcode combo is incorrect'
    }))
    submitFailure()
    return false
  }
}
