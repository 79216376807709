import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { DotSpinLoading } from '@eargo/eargo-components'

import AddressForm from './AddressSection/AddressForm'
import { editAddressInfo, validateZipCode } from '../../actions/address'
import { submitFailure } from '../../common/helpers'
import { disabledOtherSection } from '../../actions/global'
import './EditAddressForm.scss'

const FORM_NAME = 'editAddressForm'

const EditAddressSectionForm = ({ submitting, addressId, handleSubmit, handleOnCancel, initialize }) => {
  // Here we are setting current address data ( which user want to edit )
  // in initial value
  // In useEffect we are Checking Address Data is Initialize or not
  const dispatch = useDispatch()
  const [id, setId] = useState()
  const isLoading = useSelector(state => state.address.isLoading)
  const initialValues = useSelector(state => ({
    ...(state.address.addresses).find(add => add.id === addressId)
  }))
  useEffect(() => {
    if (initialValues.id !== id) {
      initialize(initialValues)
      setId(initialValues.id)
    }
  }, [initialValues])

  // This method is use to make smooth scroll behaviour
  const scrollToEditAddress = () => {
    setTimeout(() => {
      const element = document.getElementById('eargo-edit-address-container')
      !!element && element.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }
  const submitForm = async (data) => {
    const isValid = await dispatch(validateZipCode(data, FORM_NAME)).then(result => result)
    if (isValid) {
      const resp = await dispatch(editAddressInfo(addressId, {
        ...(!!data.firstname && { firstname: data.firstname }),
        ...(!!data.lastname && { lastname: data.lastname }),
        ...(!!data.address1 && { address1: data.address1 }),
        address2: data.address2 ? data.address2 : null,
        ...(!!data.city && { city: data.city }),
        ...(!!data.zipcode && { zipcode: data.zipcode }),
        ...(!!data.state_id && { state_id: data.state_id }),
        ...(!!data.country_id && { country_id: data.country_id }),
        default: !!data.default
      }))
      if (resp) {
        scrollToEditAddress()
        handleOnCancel()
      }
    }
    dispatch(disabledOtherSection(false))
  }

  return (
    <div id="eargo-edit-address-container">
      {!!isLoading && <DotSpinLoading />}
      <AddressForm
        isEdit={true}
        handleOnCancel={() => {
          scrollToEditAddress()
          handleOnCancel()
        }}
        isDisable={submitting}
        handleSubmit={handleSubmit(submitForm)} />
      <div className='edit_address_form_line' data-testid='line-testId' />
    </div>
  )
}

EditAddressSectionForm.propTypes = {
  submitting: PropTypes.bool,
  addressId: PropTypes.number,
  handleSubmit: PropTypes.func,
  handleOnCancel: PropTypes.func,
  initialize: PropTypes.func
}

const EditAddressForm = reduxForm({
  form: FORM_NAME,
  onSubmitFail: () => submitFailure()
})(EditAddressSectionForm)

export default EditAddressForm
