import * as PromoBannerApiUtil from '../util/promo_banner_api_util'

export const RECEIVE_PROMO_BANNER = 'RECEIVE_PROMO_BANNER'

export const receivePromoBanner = promoBanner => {
  return {
    type: RECEIVE_PROMO_BANNER,
    promoBanner
  }
}

export const fetchPromoBanner = () => dispatch => (
  PromoBannerApiUtil.fetchPromoBanner().then(
    res => dispatch(receivePromoBanner(res)),
    err => console.log('FETCH PROMO BANNER', err))
)
