import { RECEIVE_INSURANCE_PROVIDERS } from '../actions/insurance_provider'

const InsuranceProviderReducer = (state = [], action) => {
  Object.freeze(state)
  switch (action.type) {
    case RECEIVE_INSURANCE_PROVIDERS:
      return action.insuranceProviders
    default:
      return state
  }
}

export default InsuranceProviderReducer
