import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formValueSelector, reduxForm } from 'redux-form'
import { DotSpinLoading } from '@eargo/eargo-components'
import CardForm from './CardForm'
import { editPaymentMethoInfo } from '../../../actions/info_payment_method'
import { submitFailure } from '../../../common/helpers'
import { validateZipCode } from '../../../actions/address'
import './EditCreditCard.scss'

const FORM_NAME = 'EditCardForm'

const EditCreditCard = ({
  isCBS, submitting, validateZipCode, expiration = false,
  handleSubmit, onCancel, change,
  initialize, initialValues, addresses, name,
  states, methodId, address_id, editPaymentMethod, isLoading
}) => {
  const [microform, handleMicroformIns] = useState(false)
  const [cardBrand, setCardBrand] = useState('')

  const handleOnIdChange = addId => {
    let formInstance = {}
    if (addId) {
      const addressDetails = addresses.find(add => add.id === addId)
      formInstance = {
        ...initialValues,
        name,
        address_id: addId,
        ...(!!addressDetails && !!addressDetails.address1 && { address1: addressDetails.address1 }),
        ...(!!addressDetails && !!addressDetails.address2 && { address2: addressDetails.address2 }),
        ...(!!addressDetails && !!addressDetails.city && { city: addressDetails.city }),
        ...(!!addressDetails && !!addressDetails.zipcode && { zipcode: addressDetails.zipcode }),
        ...(!!addressDetails && !!addressDetails.state_id && { state_id: addressDetails.state_id }),
        ...(!!addressDetails && !!addressDetails.country_id && { country_id: addressDetails.country_id })
      }
    } else {
      formInstance = {
        ...initialValues,
        name,
        address_id: addId,
        address1: '',
        address2: '',
        city: '',
        zipcode: '',
        state_id: ''
      }
    }
    initialize(formInstance)
  }

  const submitForm = async (data) => {
    delete data.address_id
    console.log('OnEditCreditCard ', data)

    const isValid = await validateZipCode(data, FORM_NAME)
    if (!isValid) return false

    try {
      if (!!states && !!states.length && !!data.state_id) {
        data.country_id = states[0].country_id
        if (data.state_id) {
          const stateId = states.find(st => st.abbr === data.state_id)
          if (stateId) {
            data.state_id = stateId.id
          }
        }
      }

      const fullName = data.name ? (data.name).split(' ') : false
      const firstName = !!fullName && !!fullName[0] ? fullName[0] : ''
      const lastName = fullName ? (data.name).substring(fullName[0].length).trim() : ''

      let cardInfo = {}
      const basicInfo = {
        name: data.name,
        type: 'update',
        default: !!data.default, // HINT: We can't write without quote, because it is reserve keyword
        address: {
          ...(!!firstName && { firstname: firstName }),
          ...(!!lastName && { lastname: lastName }),
          ...(!!data.address1 && { address1: data.address1 }),
          address2: data.address2 ? data.address2 : null,
          ...(!!data.city && { city: data.city }),
          ...(!!data.zipcode && { zipcode: data.zipcode }),
          ...(!!data.state_id && { state_id: data.state_id }),
          ...(!!data.country_id && { country_id: data.country_id })
        }
      }

      if (!!isCBS && !!microform) {
        if (data.transient_token) {
          const expirationVal = !!expiration && expiration.split('/')
          cardInfo = {
            transient_token: data.transient_token,
            cc_type: cardBrand,
            month: expirationVal[0],
            year: `20${expirationVal[1]}`,
            last_digits: null
          }
        } else {
          console.log('CYBER TOKEN NOT FOUND ')
        }
      }
      const resp = await editPaymentMethod(methodId, { ...basicInfo, ...cardInfo })
      !!resp && onCancel()
    } catch (e) {
      console.log('CHECK ERROR ', e)
    }
  }
  return (
    <div className='edit_credit_card_container' id="eargo-edit-card-container">
      { !!isLoading && <DotSpinLoading />}
      <CardForm
        handleMicroformIns={handleMicroformIns}
        microform={microform}
        expiration={expiration}
        setCardBrand={setCardBrand}
        change={change}
        isEdit={true}
        addressId={address_id}
        onCancel={onCancel}
        initialValues={initialValues}
        isDisable={submitting}
        handleOnIdChange={handleOnIdChange}
        onSubmit={handleSubmit(submitForm)} />
      <div className='edit_credit_card_line' />
    </div>
  )
}

EditCreditCard.propTypes = {
  isCBS: PropTypes.any,
  validateZipCode: PropTypes.func,
  submitting: PropTypes.any,
  handleSubmit: PropTypes.func,
  expiration: PropTypes.any,
  onCancel: PropTypes.func,
  initialValues: PropTypes.any,
  change: PropTypes.any,
  initialize: PropTypes.func,
  addresses: PropTypes.any,
  name: PropTypes.any,
  states: PropTypes.any,
  methodId: PropTypes.any,
  address_id: PropTypes.any,
  editPaymentMethod: PropTypes.func,
  isLoading: PropTypes.any
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, ownProp) => {
  const addressDetails = (state.myInfoPaymentMethod.methods).find(method => method.id === ownProp.methodId)
  delete addressDetails.address_id
  const name = selector(state, 'name')
  const isCBS = state.myInfoPaymentMethod.isCBS
  return ({
    isCBS,
    states: state.address.states,
    addresses: state.address.addresses,
    name,
    isLoading: state.myInfoPaymentMethod.isLoading,
    address_id: selector(state, 'address_id'),
    ...(!!isCBS && { expiration: selector(state, 'expiration') }),
    initialValues: {
      name,
      ...addressDetails,
      ...addressDetails.address,
      default: addressDetails.default
    }
  })
}

const mapDispatchToProps = dispatch => ({
  editPaymentMethod: (id, info) => dispatch(editPaymentMethoInfo(id, info)),
  validateZipCode: (info, FORM_NAME) => dispatch(validateZipCode(info, FORM_NAME))
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmitFail: () => submitFailure()
})(EditCreditCard))
