import { RECEIVE_CREDIT_CARD, SET_SPLIT_CHECK_STATUS, LOGGED_IN_CARDS } from '../actions/credit_card_actions'

const initialState = {
  cardsArr: [],
  splitCardChecked: false,
  loggedInCard: ['', '']
}

const CreditCardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_CREDIT_CARD: {
      const newCreditCard = []
      !!action && !!action.creditCard && !!action.creditCard.length && action.creditCard.map((c) => {
        const credit = c.card
        let yearVal = credit.year
        let monthVal = credit.month
        if (!!monthVal && (monthVal.length === 1)) {
          monthVal = `0${monthVal}`
        }
        // Set last two digit of year (In Case of CyberSource)
        if (!!yearVal && (yearVal.length === 4)) {
          yearVal = yearVal.substring(2, 4)
        }
        const sepratorSlash = (!!monthVal || !!yearVal) ? '/' : ''
        newCreditCard.push({
          name: credit.name,
          ccNumber: credit.last_digits,
          expiration: monthVal + sepratorSlash + yearVal,
          brand: credit.cc_type,
          customer_id: credit.customer_id,
          address: credit.address,
          amount: credit.amount
        })
        return c
      })

      return {
        ...state,
        cardsArr: newCreditCard
      }
    }
    case SET_SPLIT_CHECK_STATUS:
    case LOGGED_IN_CARDS: {
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}

export default CreditCardsReducer
