import * as passwordApiUtil from '../util/password_api_util'
import { receiveSessionErrors } from './error_actions'

export const SET_FORGOT_PASSWORD_MESSAGE = 'SET_FORGOT_PASSWORD_MESSAGE'
export const SET_RESET_PASSWORD_MESSAGE = 'SET_RESET_PASSWORD_MESSAGE'

export const setForgotPasswordMessage = (forgotMessage = null) => ({
  type: SET_FORGOT_PASSWORD_MESSAGE,
  forgotMessage
})

export const forgotPasswordAct = email => dispatch => (
  passwordApiUtil.forgotPassword(email).then(res => {
    if (res.type === 'success') {
      dispatch(setForgotPasswordMessage(res.message))
    }
  },
  err => dispatch(receiveSessionErrors(err.responseJSON)))
)

export const setResetPasswordMessage = (resetMessage = null) => ({
  type: SET_RESET_PASSWORD_MESSAGE,
  resetMessage
})

export const resetPassword = (passwordDetail, history) => dispatch => (
  passwordApiUtil.resetPasswordApi(passwordDetail).then(res => {
    if (res.type === 'success') {
      dispatch(setResetPasswordMessage(res.message))
      setTimeout(() => history.push('/'), 1000)
    }
  },
  err => dispatch(receiveSessionErrors(err.responseJSON)))
)
