import React from 'react'
import PropTypes from 'prop-types'

import './CommonHeaderSection.scss'

const CommonHeaderSection = ({ heading, subHeading, isImage = false, isBorderBottom = false, Products = [], appHeading = '', isBorderTop = false, eargoHifiImage = false, className, isSubHeading = true }) => {
  let str = ''
  if (typeof Products === 'string') {
    str = Products?.toUpperCase().replace(/_/g, ' ')
  }
  return (
    <div className={`container ${isBorderBottom ? 'style_border_bottom' : ''} ${isBorderTop ? 'style_border_top' : ''} ${className}`}>
      <div className='subContainer'>
        {!eargoHifiImage
          ? <div className={`setImage_${isImage ? 'visible' : 'hidden'}`}><img className='InnerImage' src='https://assets.eargo.com/new_account/eargo_app.png' width='100%' height='100%' /></div>
          : <div className={`setImage_${isImage ? 'visible' : 'hidden'}`}><img className='InnerImage' src={eargoHifiImage} width='100%' height='100%' /></div>
        }
        <div className={`heading_container ${className}`}>
          <h7 className='typographyV2 heading'>{`${heading} ${Products?.length ? str : appHeading}`}</h7>
          {isSubHeading && <div className='subHeading typographyV2 body'>{subHeading}</div>}
        </div>
      </div>
      <div className='border-bottom' />
    </div>
  )
}

CommonHeaderSection.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  isImage: PropTypes.bool,
  isBorderBottom: PropTypes.bool,
  Products: PropTypes.any,
  isBorderTop: PropTypes.bool,
  eargoHifiImage: PropTypes.image || PropTypes.bool,
  appHeading: PropTypes.string,
  className: PropTypes.string,
  isSubHeading: PropTypes.bool
}

export default CommonHeaderSection
