import { fetchOrderTracking } from '../util/order_track'
import { checkAndSetAutoOut } from './auth'

export const HANDLE_ORDERS_PROCESSING = 'HANDLE_ORDERS_PROCESSING'
export const FETCH_ORDERS_TRACK_SUCCESS = 'FETCH_ORDERS_TRACK_SUCCESS'
export const RESET_ORDERS_TRACK_DATA = 'RESET_ORDERS_TRACK_DATA'

const handleProcessing = status => ({
  type: HANDLE_ORDERS_PROCESSING,
  payload: {
    isProcessing: status
  }
})

const fetchOrderTrackSuccess = res => {
  return ({
    type: FETCH_ORDERS_TRACK_SUCCESS,
    payload: {
      isProcessing: false,
      data: res.data
    }
  })
}

export const resetOrderTrackData = () => {
  return ({
    type: RESET_ORDERS_TRACK_DATA,
    payload: {
      isProcessing: false,
      data: []
    }
  })
}

export const fetchOrderTrack = (orderNumber) => async (dispatch) => {
  dispatch(handleProcessing(true))
  try {
    const resp = await fetchOrderTracking(orderNumber)
    // Adding scroll to Top
    window.scrollTo(0, 0)
    if (!!resp && !!resp.data) {
      dispatch(fetchOrderTrackSuccess(resp))
      dispatch(handleProcessing(false))
      return resp
    } else {
      dispatch(checkAndSetAutoOut(resp))
      dispatch(handleProcessing(false))
      return false
    }
  } catch (error) {
    console.log('error ---> ', error)
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})
    dispatch(checkAndSetAutoOut(errorBag))
    return false
  }
}
