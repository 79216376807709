import React from 'react'
import PropTypes from 'prop-types'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const LazyImage = ({
  src,
  id = '',
  onClick,
  alt = 'Image',
  effect = 'blur',
  className = '',
  width = 'auto',
  height = 'auto',
  position,
  style,
  wrapperClassName = ''
}) => (
  <LazyLoadImage
    className={className}
    id={id}
    alt={alt}
    src={src}
    onClick={onClick}
    effect={effect}
    width={width}
    height={height}
    style = {style}
    position = {position}
    wrapperClassName = {wrapperClassName}
  />
)

LazyImage.propTypes = {
  src: PropTypes.any,
  id: PropTypes.string,
  onClick: PropTypes.func,
  alt: PropTypes.string,
  effect: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.any,
  position: PropTypes.any,
  wrapperClassName: PropTypes.string
}

export default React.memo(LazyImage)
