import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import AllShipAddress from '../AllShipAddress'
import AddNewAddress from './AddNewAddress'
import { setAddressPopUp, setAddressListPopUp } from '../../../actions/user_address'
import PopUp from '../../common/PopUp'
import './index.scss'

const AuthAddress = ({ addressCount, updateShipAddress }) => {
  const { states, addresses, savedAddressId, addressListPopUp, errMsg, isLoading, addressPopUp } = useSelector(state => state.userAddresses)
  const cart = useSelector(state => state.cart)
  const dispatch = useDispatch()
  return (
    <Fragment>
      {!!addressListPopUp &&
        <PopUp handleClose={() => dispatch(setAddressListPopUp(false))} width={640} height={593} paddingTop={true}>
          <AllShipAddress
            cart={cart}
            states={states}
            addresses={addresses}
            defaultAddrId={savedAddressId}
            updateShipAddress={updateShipAddress}
            toggleAddressList={(value) => { dispatch(setAddressListPopUp(value)) }}
          />
        </PopUp>}
      {!!addressPopUp && <div className='auth_address_container'>
        <PopUp
          width={640}
          height={593}
          paddingTop={true}
          handleClose={() => dispatch(setAddressPopUp(false))} unsetHeight={true}>
          <AddNewAddress
            errMsg={errMsg}
            states={states}
            isLoading={isLoading}
            addressCount={addressCount}
            closePopUp={() => dispatch(setAddressPopUp(false))} />
        </PopUp></div>
      }
    </Fragment>
  )
}

AuthAddress.propTypes = {
  addressCount: PropTypes.any,
  cart: PropTypes.object,
  states: PropTypes.object,
  addresses: PropTypes.object,
  isLoading: PropTypes.bool,
  updateShipAddress: PropTypes.any,
  savedAddressId: PropTypes.number,
  addressListPopUp: PropTypes.bool,
  setAddressListPopUp: PropTypes.func,
  addressPopUp: PropTypes.bool,
  setAddressPopUp: PropTypes.func,
  errMsg: PropTypes.object
}

export default AuthAddress
