import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import AccountSection from './AccountSection'
import ResetPassword from './ResetPassword'
import AddressSection from './AddressSection'
import { fetchStates } from '../../actions/address'
import PaymentSection from './PaymentSection'
import { disabledOtherSection } from '../../actions/global'
import { checkIfExist } from '../../util/auth'
import SetPassword from './SetPassword'
import { setUserPasswordUpdate } from '../../actions/set_password'
import './MyInformation.scss'

const MyInformation = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)
  const sfUser = useSelector((state) => state.setPassword.sfUser)
  useEffect(() => {
    scrollToId()
    dispatch(fetchStates())
    return () => {
      dispatch(disabledOtherSection(false))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    async function fetchData () {
      if (user?.email?.length) {
        const response = await checkIfExist(user.email)
        if (!!response && !!response.sf_user) {
          dispatch(setUserPasswordUpdate(true))
        }
      }
    }
    fetchData()
    // eslint-disable-next-line
  }, [user.email])

  const scrollToId = () => {
    const value = (window.location.href.indexOf('#') > -1) ? window.location.href.split('#')[1] : null
    setTimeout(() => scrollTo(getOffsetTopById(value)), 1000)
  }
  // cross browser schinanigans
  const getScrollTop = () => {
    return document.documentElement.scrollTop || document.body.scrollTop
  }

  const getOffsetTopById = (id) => {
    try {
      const el = document.getElementById(id)
      const top = getOffsetTop(el)
      return top
      // return top - getHeaderHeight() - 5
    } catch (e) {
    }
  }
  const getOffsetTop = (element) => {
    if (!element) {
      return 0
    }
    const { top } = element.getBoundingClientRect()
    const scrollTop = getScrollTop()
    return top + scrollTop - 200
  }

  const scrollTo = (top) => {
    window.scroll({
      top,
      left: 0,
      behavior: 'smooth'
    })
  }

  return (
    <div className='my_info_container' data-testid='MyInformation-container-testid'>
      <div className='my_info_sub_container' data-testid='SubContainer-testid'>
        <div className='my_info_child_container'>
          <div className='my_info_account_container RoundedCorner' data-testid='AccountInfoContainer-testid'>
            <AccountSection user={user} className='RoundedCorner' style={{ marginBottom: '10px', backgroundColor: 'white' }} />
            <AddressSection anchorClassName='address'/>
            <PaymentSection anchorClassName='payment'/>
            {
              // HINT: There was no other way to block, This section till first loading
              !!user && Object.keys(user).length > 0 &&
              <>
              <div className='my_info_password_container' id='login'>
                <h5 className='typographyV2 my_info_account_title my-account-line'>Manage Password</h5>
              {sfUser
                ? <div className='my_info_password_section' id='managePassword'>
                <SetPassword anchorClassName='login'/>
              </div>
                : <div className='my_info_password_section' id='managePassword'>
                <ResetPassword anchorClassName='login'/>
              </div> }
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

MyInformation.propTypes = {
  getStateList: PropTypes.func,
  user: PropTypes.any
}

export default MyInformation
