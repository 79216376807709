import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { EargoButtonV2, RoundRadioInput, DotSpinLoading } from '@eargo/eargo-components'

import ReduxPrimaryTextArea from '../../common/redux-form/ReduxPrimaryTextArea'
import ThankYouPopup from './ThankYouPopup'
import { requiredField } from '../../common/redux-form/validation'
import { submitFailure } from '../../../common/helpers'
import PrimarySelectBox from '../../common/PrimarySelectBox'
import { addNewSupportRequest, getSupportCaseAccountInfo } from '../../../actions/support'
import './supportForm.scss'

const FORM_NAME = 'SupportIssue'
const subjectRequire = requiredField('Subject')
const descRequire = requiredField('Description')
const radioRequire = requiredField('Contact')
const topicList = [{
  id: 'My devices or charger are not working properly',
  label: 'My devices or charger are not working properly'
}, {
  id: 'I’m having trouble with the Eargo App',
  label: 'I’m having trouble with the Eargo App'
}, {
  id: 'My devices are lost or damaged',
  label: 'My devices are lost or damaged'
}, {
  id: 'I’d like to buy another device',
  label: 'I’d like to buy another device'
}, {
  id: 'I have a question regarding shipping',
  label: 'I have a question regarding shipping'
}, {
  id: 'I have a question about my bill',
  label: 'I have a question about my bill'
}, {
  id: 'I’d like to check on a warranty repair',
  label: 'I’d like to check on a warranty repair'
}, {
  id: 'I have a question about insurance',
  label: 'I have a question about insurance'
}, {
  id: 'I want to return my Eargos',
  label: 'I want to return my Eargos'
}, {
  id: 'I have a question about the status of my refund',
  label: 'I have a question about the status of my refund'
}, {
  id: 'I have another question or issue',
  label: 'I have another question or issue'
}
]
// Radiobutton group is used to show multiple radio inputs
const RoundButtonGroup = ({ name, optionList, errResp }) => (
  <div>
    <Field name={name}
      validate={[radioRequire]}
      component={({ input, options, meta: { active, touched, visited, error } }) => (
        <>
          <span className="typographyV2 body support-form-messagespan d-flex mb-20 mt-50">Best way to contact you?
        {((!active && (visited || touched) && error) || !!errResp) && <span className="errorSpan">&nbsp;{error || errResp}</span>}
          </span>
          <div className='support-form-optionholder'>
            {options.map((option, index) =>
              <div key={index} className="d-flex w-100 mt-8 mb-8 radio-button-holder">
                <RoundRadioInput
                  value={option.value}
                  label={option.label}
                  checked={option.value === input.value}
                  handleOnClick={option.handleOnClick}
                  handleOnFocus={input.onFocus}
                  handleOnBlur={input.onBlur}
                  handleOnChange={param => input.onChange(param.target.value)}
                />
              </div>
            )}
          </div>
        </>
      )}
      options={optionList}
    />
  </div>
)

RoundButtonGroup.propTypes = {
  name: PropTypes.any,
  optionList: PropTypes.any,
  errResp: PropTypes.any
}

const SupportReduxForm = ({ handleOnFocus, errMsg, removeFieldError, handleSubmit, invalid, submitting, pristine, initialize }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [resp, setResp] = React.useState(false)
  const [isReturnType, setIsReturnType] = useState(false)
  const isLoading = useSelector(state => state.support.isLoading)
  const preferred_contact_method = useSelector(state => state?.form?.SupportIssue?.values?.preferred_contact_method)
  const user = useSelector(state => state.auth.user)
  const [textAreaCount, setTextAreaCount] = useState(0)
  const userPhone = !!user && !!user.phone && user.phone.slice(9, 14)
  const userEmail = !!user && !!user.email && user.email.split('@')
  const emailStart = !!userEmail[0] && userEmail[0].slice(0, 3)
  const emailEnd = !!userEmail[1] && userEmail[1].toString()
  const finalEmail = !!emailStart && emailStart.concat('*******@', emailEnd)
  const query = new URLSearchParams(history.location.search)

  const setInitialValue = (order_number) => {
    return {
      subject: '',
      description: `Requesting support regarding Order #${order_number} please include additional comments below:`,
      preferred_contact_method: 'Email'
    }
  }

  useEffect(() => {
    if (query.get('type') && query.get('order_id')) {
      setIsReturnType(true)
      initialize(setInitialValue(query.get('order_id')))
    }
  }, [])

  useEffect(() => {
    dispatch(getSupportCaseAccountInfo())
  }, [])

  // submit form function is used to add a new support ticket on that particular account
  const submitForm = async (data) => {
    const { description, preferred_contact_method, subject } = data
    const resp = await dispatch(addNewSupportRequest({
      description: description,
      preferred_contact_method: preferred_contact_method,
      subject: subject
    }))
    setResp(resp)
  }

  const handleDescriptionChange = (e) => {
    if (e.length <= 1000) {
      setTextAreaCount(e.length)
    }
  }

  return (
    <div className='support-form-container'>
       <div className="support-form-div">
      {!!isLoading && <DotSpinLoading />}
      <div className="support-form">
          <h7 className="typographyV2 back_button" onClick={() => history.goBack()}>
            <img
              className="arrow_icon"
              src="https://assets.eargo.com/new_account/arrow_back.png"
            />
            Back
          </h7>
      <div className='support-form-wrapper'>
        <h7 className="typographyV2 support-form-heading"> Support Request</h7>
        </div>
      <div role='support-form'>
        <form className='support-form-form' onSubmit={handleSubmit(submitForm)}>
          <div className="typographyV2 body support-form-topiclabel">Please Choose a Subject</div>
          <Field
            id="subject"
            name="subject"
            label="Subject"
            searchable={false}
            topicList={topicList}
            disabled={false}
            component={PrimarySelectBox}
            validate={[subjectRequire]}
            removeFieldError={removeFieldError}
            errResp={!!errMsg && !!errMsg.subject ? errMsg.subject : false}
          />
          <div className='support-form-decholder'>
            <Field
              id="description"
              name="description"
              value="description"
              classess='support_description'
              component={ReduxPrimaryTextArea}
              errResp={!!errMsg && !!errMsg.description ? errMsg.description : false}
              validate={descRequire}
              maxLength={1000}
              onFocus={handleOnFocus}
              label="How can we help?"
              onChange={e => handleDescriptionChange(e)}
            />
          </div>
          <div className="typographyV2 body_small text_area">{`${textAreaCount} /${1000}`}</div>
          <RoundButtonGroup name="preferred_contact_method"
            optionList={[
              { label: 'Email', value: 'Email' },
              { label: 'Phone', value: 'Phone' }
            ]} errResp={!!errMsg && !!errMsg.preferred_contact_method ? errMsg.preferred_contact_method : false}
          />
          {!!preferred_contact_method && <Fragment>
            <div className='typographyV2 body support-form-checkcontact'>{`Your ${preferred_contact_method === 'Phone' ? 'phone number' : 'email address'}:`}</div>
            <div className='typographyV2 body support-form-contactinfo'>{preferred_contact_method === 'Phone' ? ` ${userPhone ? `(***) ***${userPhone}` : 'N/A'}` : finalEmail || 'N/A'}</div>
          </Fragment>}

          <div className='support-form-buttonholder'>
            <EargoButtonV2
              className='primary support-form-submitbutton'
              label="SEND"
              type="submit"
              disabled={invalid || submitting || (pristine && !isReturnType)}
            />
          </div>
       </form>
        {!!resp && <ThankYouPopup isContact={preferred_contact_method} setResp={(resp) => setResp(resp)} />}
      </div>
      </div>
      </div>
    </div>
  )
}
SupportReduxForm.propTypes = {
  isLoading: PropTypes.bool,
  handleOnFocus: PropTypes.any,
  errMsg: PropTypes.any,
  removeFieldError: PropTypes.any,
  handleSubmit: PropTypes.func,
  user: PropTypes.any,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  pristine: PropTypes.any,
  initialize: PropTypes.object
}

const SupportForm = reduxForm({
  form: FORM_NAME,
  onSubmitFail: () => submitFailure()
})(SupportReduxForm)

export default React.memo(SupportForm)
