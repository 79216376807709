import merge from 'lodash/merge'
import {
  CART_PROCESSING_START,
  RECEIVE_CART,
  RECEIVE_TAX,
  SET_CARD_IN_CART,
  SET_SHIP_ADD_IN_CART,
  UPDATE_ORDER_STATE
} from '../actions/cart_actions'

const defaultState = {
  buttonStatus: false
}

const CartReducer = (state = defaultState, action) => {
  Object.freeze(state)
  let newCart
  if (state !== null) {
    newCart = merge({}, state)
  }
  switch (action.type) {
    case RECEIVE_CART: {
      return merge({}, action.cart)
    }
    case UPDATE_ORDER_STATE: {
      return {
        ...state,
        state: action.orderState
      }
    }
    case SET_SHIP_ADD_IN_CART: {
      return {
        ...state,
        shipAddress: action.shipAddress
      }
    }
    case SET_CARD_IN_CART: {
      return {
        ...state,
        card: action.card
      }
    }
    case RECEIVE_TAX: {
      const taxVal = !!action.tax && !!action.tax.tax ? action.tax.tax : '0.0' // HINT: Error prevention for eargo-neo-hifi (It should be remove - Temp)
      newCart.additionalTaxTotal = taxVal
      newCart.total = (parseFloat(state.itemTotal) + parseFloat(state.shipmentTotal) + parseFloat(state.promoTotal) + parseFloat(taxVal)).toString()
      return newCart
    }
    case CART_PROCESSING_START: {
      return {
        ...state,
        buttonStatus: action.status
      }
    }
    default:
      return state
  }
}

export default CartReducer
