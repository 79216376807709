import { RECEIVE_CURRENT_USER } from '../actions/session_actions'

const SessionReducer = (state = null, action) => {
  Object.freeze(state)
  switch (action.type) {
    case RECEIVE_CURRENT_USER:
      return { ...state, ...action.currentUser }
    default:
      return state
  }
}

export default SessionReducer
