import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { EargoButtonV2, DotSpinLoading } from '@eargo/eargo-components'

import { removeFieldError, updateUserError, updateUserInfo } from '../../../actions/auth'
import { email, noOnlyNumber, onlyOneSpecialCharDash, requiredField, USPhone, doubleSpace } from '../../common/redux-form/validation'
import ReduxPrimaryInput from '../../common/redux-form/ReduxPrimaryInput'
import { submitFailure } from '../../../common/helpers'
import { disabledOtherSection } from '../../../actions/global'
import './EditForm.scss'

const fNameRequire = requiredField('First Name')
const lNameRequire = requiredField('Last Name')
const emailRequire = requiredField('Email')
const phoneRequire = requiredField('Phone')

let EditForm = ({ handleSubmit, onCancel, initialize }) => {
  // Here we are setting current user data ( which user want to edit )
  // in initial value
  // In useEffect we are Checking Address Data is Initialize or not
  const dispatch = useDispatch()
  const errMsg = useSelector((state) => state.auth.errMsg)
  const isLoading = useSelector((state) => state.auth.isLoading)
  const initialValues = useSelector((state) => state.auth.user)

  useEffect(() => {
    initialize(initialValues)
  }, [initialValues])
  // Handle Unmount logic
  useEffect(() => () => dispatch(updateUserError(null)), [updateUserError])
  const handleOnFocus = e => {
    const fieldName = e.target.name
    if (!!errMsg && !!errMsg.otherError) dispatch(removeFieldError('otherError'))
    if (!!errMsg && errMsg[fieldName]) dispatch(removeFieldError(fieldName))
  }
  const submitForm = async (data) => {
    const { firstName, lastName, phone, zipcode } = data
    const resp = await dispatch(updateUserInfo({ first_name: firstName, last_name: lastName, phone, zipcode }))
    dispatch(disabledOtherSection(false))
    !!resp && onCancel()
  }

  return (
    <Fragment>
      {!!isLoading && <DotSpinLoading />}
      <form className='edit_account_form' onSubmit={handleSubmit(submitForm)} data-testid='EditForm-testid'>
        <div className='edit_account_one_line_section' data-testid='oneLIne'>
          <Field
            id="firstName"
            name="firstName"
            label="First Name"
            errResp={!!errMsg && !!errMsg.firstName ? errMsg.firstName : false}
            handleOnFocus={handleOnFocus}
            component={ReduxPrimaryInput}
            capitalize={true}
            validate={[fNameRequire, doubleSpace, onlyOneSpecialCharDash, noOnlyNumber]}
          />
          <Field
            id="lastNane"
            name="lastName"
            label="Last Name"
            errResp={!!errMsg && !!errMsg.lastName ? errMsg.lastName : false}
            handleOnFocus={handleOnFocus}
            component={ReduxPrimaryInput}
            capitalize={true}
            validate={[lNameRequire, doubleSpace, onlyOneSpecialCharDash, noOnlyNumber]}
          />
        </div>
        <Field
          id="email"
          name="email"
          label="Email"
          disabled={true}
          errResp={!!errMsg && !!errMsg.email ? errMsg.email : false}
          handleOnFocus={handleOnFocus}
          component={ReduxPrimaryInput}
          validate={[emailRequire, email]}
        />
        <Field
          id="phone"
          name="phone"
          label="Phone"
          type="tel"
          errResp={!!errMsg && !!errMsg.phone ? errMsg.phone : false}
          handleOnFocus={handleOnFocus}
          component={ReduxPrimaryInput}
          validate={[phoneRequire, USPhone]}
        />
        {!!errMsg && !!errMsg.otherError && <div className='typographyV2 err_regular edit_account_error_message'>{errMsg.otherError}</div>}
        <div className='edit_account_button_holder'>
          <EargoButtonV2
            data-testid='saveBtn-testid'
            className="primary"
            name="button"
            type="submit"
            label={isLoading ? 'LOADING...' : 'Save'}
          />
          <span className='typographyV2 body edit_account_cancel_button' data-testid='cancelBtn-testid' onClick={() => {
            dispatch(disabledOtherSection(false))
            onCancel()
          }}>Cancel</span>
        </div>
      </form>
    </Fragment>
  )
}

EditForm.propTypes = {
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  initialize: PropTypes.object
}

EditForm = reduxForm({
  form: 'AccountInfoForm',
  onSubmitFail: () => submitFailure()
})(EditForm)

export default EditForm
