import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { colorCodes } from '../../constant/colors'
import { devices } from '../../constant/devices'
import ReduxSelectBox from './redux-form/ReduxSelectBox'
import './PrimarySelectBox.scss'

const requestTypeSelectStyle = {
  container: (provided, { isDisabled }) => ({
    ...provided,
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    width: '100%',
    height: '100%',
    background: isDisabled ? colorCodes.BRIGHT_GREY : 'transparent'
  }),
  indicatorsContainer: () => ({
    marginRight: 0
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    color: colorCodes.GREY_1,
    svg: {
      width: 40,
      height: 27
    }
  }),
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    color: colorCodes.GREY_1
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 14,
    overflow: 'visible',
    [`@media ${devices.mobile}`]: {
      paddingLeft: 13
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'none'
  }),
  menu: () => ({
    flexDirection: 'column',
    minHeight: 257,
    overflow: 'hidden',
    zIndex: 2,
    width: '100%',
    position: 'absolute',
    top: 58,
    left: 0,
    borderRadius: 3
  }),
  menuList: () => ({
    // maxHeight: 257,
    overflowY: 'auto',
    background: colorCodes.WHITE,
    border: `1px solid ${colorCodes.GREY_6}`,
    position: 'relative'
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    cursor: 'pointer',
    paddingTop: 8,
    background: (isFocused && !isSelected) && colorCodes.GREY_13,
    paddingBottom: 8,
    paddingLeft: 24,
    fontSize: 18,
    lineHeight: '26px',
    fontFamily: 'Inter,sans-serif'
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'none'
  })
}

const PrimarySelectBox = ({
  input, meta: { active, visited, touched, error },
  handleOnFocus, handleOnBlur, errResp, removeFieldError, topicList, disabled = false,
  searchable = true
}) => {
  // In this we are just showing a dropdown with passed option list and
  // store input value in our state so that we
  const [searchableData, setSearchableData] = useState('')
  const statePlaceHolder = input.value

  return (
    <label className={`primary-select-ipholder2 ${(((touched && error) && !active) || !!errResp) ? 'error_border state_error_border' : ''}`}>
      {<span className={`typographyV2 ${(((touched && error) && !active) || !!errResp) ? 'err_regular' : 'body'} primary-select-statelabel`}>
        {(((!active && (visited || touched)) && error) || !!errResp) ? (error || errResp) : input.value ? <span className='typographyV2 body primary-select-statespan'>{!searchableData && statePlaceHolder}</span> : 'Subject'}
      </span>}
      <ReduxSelectBox
        selectBoxStyle={requestTypeSelectStyle}
        input={input}
        options={topicList.map((option) => ({
          ...option,
          id: option.id,
          label: option.label,
          value: option.id
        }))}
        isSearchable={searchable}
        handleOnFocus={handleOnFocus}
        handleOnBlur={handleOnBlur}
        errResp={errResp}
        disabled={disabled}
        removeFieldError={removeFieldError}
        onInputChange={value => setSearchableData(value)}
      />
    </label>
  )
}

PrimarySelectBox.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  handleOnFocus: PropTypes.func,
  handleOnBlur: PropTypes.func,
  errResp: PropTypes.string,
  removeFieldError: PropTypes.func,
  topicList: PropTypes.array,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool
}

export default PrimarySelectBox
