import {
  UP_METHODS_PROCESSING,
  USER_PAYMENTS_SUCCESS,
  USER_PAYMENTS_ERROR,
  SET_DEFAULT_PAYMENT_ID,
  SET_ADD_PAYMENT_POPUP,
  SET_SAVED_METHOD_ID,
  SET_PAYMENT_LIST_POPUP,
  ADD_UP_METHOD_SUCCESS,
  REMOVE_PAYMENT_ERROR_MSG,
  SET_CYBERSOURCE_KEY,
  SET_CYBERSOURCE_KEY_STATUS,
  SET_SPLIT_CYBERSOURCE_KEY,
  SET_SPLIT_CYBERSOURCE_KEY_STATUS,
  SET_SAVED_PAYMENT_ID,
  SET_ALL_PAYMENT_METHODS
} from '../actions/user_payment'

const defaultState = {
  paymentListPopUp: false,
  addPaymentPopUp: false,
  cyberSourceKey: false,
  splitCyberSourceKey: false,
  splitCyberSourceKeyStatus: false,
  cyberSourceKeyStatus: false,
  savedMethodId: [],
  defaultSavedId: [],
  userSavedId: [],
  isLoading: false,
  methods: [],
  total: 0,
  errMsg: {}
}

const UserPayment = (state = defaultState, action) => {
  switch (action.type) {
    case UP_METHODS_PROCESSING:
    case USER_PAYMENTS_SUCCESS:
    case SET_SAVED_METHOD_ID:
    case USER_PAYMENTS_ERROR:
    case SET_PAYMENT_LIST_POPUP:
    case SET_ADD_PAYMENT_POPUP:
    case SET_CYBERSOURCE_KEY:
    case SET_CYBERSOURCE_KEY_STATUS:
    case SET_SPLIT_CYBERSOURCE_KEY_STATUS:
    case SET_SPLIT_CYBERSOURCE_KEY:
      return {
        ...state,
        ...action.payload
      }
    case ADD_UP_METHOD_SUCCESS: {
      const methods = state.methods
      let defaults = ''
      if (action.method) {
        action.methods.map((i) => {
          if (i.default === 1) {
            defaults = i.id
          }
          return i
        })
        methods.push(action.method)
      }
      return {
        ...state,
        ...methods,
        defaultSavedId: [defaults]
      }
    }
    case REMOVE_PAYMENT_ERROR_MSG: {
      const errMessages = state.errMsg
      if (!!errMessages && !!errMessages.otherError) {
        delete errMessages.otherError
      }
      if (!!action.fieldName && !!errMessages && !!errMessages[action.fieldName]) {
        delete errMessages[action.fieldName]
      }
      return {
        ...state,
        ...errMessages
      }
    }
    case SET_SAVED_PAYMENT_ID: {
      const newMethod = state.savedMethodId
      if (newMethod.indexOf(action.id) === -1 && newMethod.length < 2) {
        newMethod.push(action.id)
      } else if (newMethod.indexOf(action.id) !== -1 && newMethod.length < 2) {
        newMethod.splice(newMethod.indexOf(action.id), 1)
      } else if (newMethod.indexOf(action.id) !== -1) {
        newMethod.splice(newMethod.indexOf(action.id), 1)
      } else {
        newMethod.splice(newMethod.length - 1, 1)
        newMethod.push(action.id)
      }
      return {
        ...state,
        savedMethodId: newMethod
      }
    }

    case SET_ALL_PAYMENT_METHODS: {
      const newMethods = action.ids
      return {
        ...state,
        savedMethodId: newMethods
      }
    }
    case SET_DEFAULT_PAYMENT_ID: {
      const methodArray = state.methods
      const updatedMethod = !!methodArray && !!methodArray.length && methodArray.map(value => value.id === action.defaultId ? { ...value, default: true } : { ...value, default: false })
      return {
        ...state,
        methods: updatedMethod
      }
    }
    default: {
      return state
    }
  }
}

export default UserPayment
