import { ajax } from 'jquery'
import { FEEDBACK_SERVER } from '../constant'

/**
 * Get Survey Questions
 * @param {*} user_id
 */
export const getQuestions = user_id => {
  return ajax({
    method: 'GET',
    url: `${FEEDBACK_SERVER}api/survey-questions`,
    data: {
      user_id
    }
  })
}

/**
 * Save user answers
 * @param {*} feedback
 */
export const saveFeedback = feedback => {
  return ajax({
    method: 'POST',
    url: `${FEEDBACK_SERVER}api/survey-questions`,
    data: { feedback }
  })
}
