import { ajax } from 'jquery'
import { prepareRequest } from '../common/helpers'

export const submitLeadProcess = info => ajax(prepareRequest('POST', 'api/lead-process', info))

export const submitLead = info => ajax(prepareRequest('POST', 'api/lead', info))

export const submitSFLeadId = lead_id => ajax(prepareRequest('POST', 'api/send_lead_and_prequal', lead_id))

export const productsForInsurance = (info, signal) => ajax(prepareRequest('POST', 'api/products_for_insurance', info, false, false, signal))

export const fetchLeadInfo = leadId => ajax(prepareRequest('GET', `api/lead/${leadId}`))
