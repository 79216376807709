import React, { Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LoginForm, DotSpinLoading, helpersFn } from '@eargo/eargo-components'

import FooterWrapper from '../common/Footer.js'
import { checkLoggedIn } from '../../actions/auth'
import { setBreadModal } from '../../actions/global'
import { fetchCart } from '../../actions/cart_actions'
import { addressUrl, BASE_URL, SERVER_URL } from '../../constant'
import LoaderPlaceholder from '../common/LoaderPlaceholder/index.jsx'
import BreadModal from '../pdp/bread_modal/bread_modal.jsx'
import { handleLogin } from '../../util/auth0_api'
import Header from '../common/Header'

const LoginNew = ({ history, location }) => {
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart)
  const headerJson = useSelector(state => state.headerJson)
  const { openBreadModal } = useSelector(state => state.global)
  const variant = useSelector(state => state.variant.variant)

  const id = useParams()
  const handleGtm = () => {
    const gtmOptions = {
      event: 'VirtualPageView',
      virtualPageURL: location.pathname.toLowerCase(),
      virtualPageTitle: 'Log In'
    }
    helpersFn.gtmSend(gtmOptions)
  }
  useEffect(() => {
    dispatch(checkLoggedIn(history))
    dispatch(fetchCart())
    handleGtm()
  }, [])

  return (
    <>
     { headerJson.isLoading
       ? <DotSpinLoading />
       : <Header history={history} />
     }
      <LoginForm
      autoOut={false}
      isShop={true}
      history={history}
      shopDomain={BASE_URL}
      corpDomain={addressUrl}
      serverUrl={SERVER_URL}
      isDeviceId={id.deviceId}
      closePopUp={null}
      showSignUp={() => { history.push('/signup') }}
      handleLogin= {handleLogin} />
      <FooterWrapper history={history} />
      {
        !!openBreadModal &&
        <Suspense fallback={<LoaderPlaceholder />}>
          <BreadModal
            id={Math.random()}
            open={openBreadModal}
            updateState={() => dispatch(setBreadModal())}
            cart={cart}
            variant={variant}
          />
        </Suspense>
      }
    </>
  )
}

LoginNew.propTypes = {
  history: PropTypes.object,
  checkLoggedIn: PropTypes.func,
  cart: PropTypes.object,
  openCart: PropTypes.bool,
  setAuthState: PropTypes.func,
  autoOut: PropTypes.bool,
  userInfo: PropTypes.object,
  addToCart: PropTypes.func,
  fetchCart: PropTypes.func,
  location: PropTypes.any,
  headerJson: PropTypes.object,
  openBreadModal: PropTypes.func
}

export default withRouter(LoginNew)
