import { RECIEVE_CATEGORIES, RECEIVE_VARIANTS, GET_PRODUCTS, RECEIVE_VARIANT, MAKE_PRODUCTS_NULL } from '../actions/variant_actions'

const defaultState = {
  categories: {},
  variants: [],
  singleProduct: {},
  variant: {}
}

const VariantReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_VARIANT:
    case RECIEVE_CATEGORIES:
    case RECEIVE_VARIANTS: {
      return {
        ...state,
        ...action.payload
      }
    }
    case MAKE_PRODUCTS_NULL: {
      return {
        ...state,
        singleProduct: {}
      }
    }
    case GET_PRODUCTS: {
      const id = action.id
      const variant = state.variants
      const tempList = []
      !!id && variant.forEach(f => f.childCategories.forEach(j => {
        if (j.name.toString() === id) {
          tempList.push(j)
        }
      }))
      return {
        ...state,
        singleProduct: tempList.length > 0 ? tempList[0] : {}
      }
    }
    default:
      return state
  }
}

export default VariantReducer
