import React, { lazy, Suspense } from 'react'
import LoaderPlaceholder from '../common/LoaderPlaceholder'

const load = (Component) => {
  const MyComp = (props) => (
    <Suspense fallback={<LoaderPlaceholder />}>
      <Component {...props} />
    </Suspense>
  )

  MyComp.displayName = 'MyComp'
  return MyComp
}

const CartContainer = load(lazy(() => import('../cart/cart_container')))
const ThreeTraitsModule = load(lazy(() => import('../pdp/three_traits_module/three_traits_module')))
const BestAudioModule = load(lazy(() => import('../pdp/best_audio_module/best_audio_module')))
const PressRibbonModule = load(lazy(() => import('../pdp/press_ribbon_module/press_ribbon_module')))
const ThreeBlocksModule = load(lazy(() => import('../pdp/three_blocks_module/three_blocks_module')))
const YourEarsOnlyModule = load(lazy(() => import('../pdp/your_ears_only_module/your_ears_only_module')))
const FourProfilesModule = load(lazy(() => import('../pdp/four_profiles_module/four_profiles_module')))
const SupportModule = load(lazy(() => import('../pdp/support_module/support_module')))
const ReviewsModule = load(lazy(() => import('../pdp/reviews_module/reviews_module')))
const WhatsInTheBoxModule = load(lazy(() => import('../pdp/whats_in_the_box_module/whats_in_the_box_module')))
const GuaranteeModule = load(lazy(() => import('../pdp/guarantee_module/guarantee_module')))
const MicrosizeModule = load(lazy(() => import('../pdp/microsize_module/microsize_module')))
const TechnicaSpecificationsModule = load(lazy(() => import('../pdp/technical_specifications_module/technical_specifications_module')))
const FAQModule = load(lazy(() => import('../pdp/faq_module/faq_module')))
const BreadModal = load(lazy(() => import('../pdp/bread_modal/bread_modal')))
const ThreeHifiImages = load(lazy(() => import('../pdp/three_hifi_images/three_hifi_images')))
const Eargo5Features = load(lazy(() => import('../Eargo5/Eargo5Features/Eargo5Features')))
const Eargo5GetStarted = load(lazy(() => import('../Eargo5/Eargo5GetStarted/Eargo5GetStarted')))
const Reviews = load(lazy(() => import('../Reviews/Reviews')))
const Eargo5Comparison = load(lazy(() => import('../Eargo5/Eargo5Comparison/Eargo5Comparison')))
const Eargo5WhatsIncluded = load(lazy(() => import('../Eargo5/Eargo5WhatsIncluded/Eargo5WhatsIncluded')))
const MoreProducts = load(lazy(() => import('../MoreProducts/MoreProducts')))
const Sound_adapt = load(lazy(() => import('../Eargo6/Sound_adapt/Sound_adapt')))
const NoiseReduction = load(lazy(() => import('../Eargo6/NoiseReduction/NoiseReduction')))
const VoiceIndicators = load(lazy(() => import('../Eargo6/VoiceIndicators/VoiceIndicators')))
const SoundMatch = load(lazy(() => import('../Eargo6/SoundMatch/SoundMatch')))
const Performance = load(lazy(() => import('../Eargo6/Performance/Performance')))
const Telehealth = load(lazy(() => import('../Eargo6/Telehealth/Telehealth')))
const EargoDifference = load(lazy(() => import('../Eargo6/EargoDifference/EargoDifference')))
const DeviceIllustrationSpecs = load(lazy(() => import('../Eargo7/DeviceIllustrationSpecs')))
const DesignedToBeHeard = load(lazy(() => import('../Eargo7/DesignedToBeHeard')))
const Eargo7Newfeatures = load(lazy(() => import('../Eargo7/Eargo7Newfeatures')))
const NaturalSound = load(lazy(() => import('../Eargo7/NaturalSound')))
const RechargeableBattery = load(lazy(() => import('../Eargo7/RechargeableBattery')))
const Pricing = load(lazy(() => import('../Eargo7/Pricing')))
const WaterResistance = load(lazy(() => import('../Eargo7/WaterResistance')))
const SelfFitting = load(lazy(() => import('../Eargo7/SelfFitting')))
const PromiseText = load(lazy(() => import('../Eargo7/Promise')))
const WhatsInTheBox = load(lazy(() => import('../Eargo7/WhatsInTheBox')))
const SetupCleaningCare = load(lazy(() => import('../Eargo7/SetupCleaningCare')))
const PopularQuestions = load(lazy(() => import('../Eargo7/PopularQuestions')))
const SoundAdjust = load(lazy(() => import('../Eargo7/SoundAdjust')))

export {
  CartContainer,
  ThreeTraitsModule,
  BestAudioModule,
  PressRibbonModule,
  ThreeBlocksModule,
  YourEarsOnlyModule,
  FourProfilesModule,
  SupportModule,
  ReviewsModule,
  WhatsInTheBoxModule,
  GuaranteeModule,
  MicrosizeModule,
  TechnicaSpecificationsModule,
  FAQModule,
  BreadModal,
  ThreeHifiImages,
  Eargo5Features,
  Eargo5GetStarted,
  Reviews,
  Eargo5Comparison,
  Eargo5WhatsIncluded,
  MoreProducts,
  Sound_adapt,
  NoiseReduction,
  VoiceIndicators,
  SoundMatch,
  Performance,
  Telehealth,
  EargoDifference,
  DeviceIllustrationSpecs,
  DesignedToBeHeard,
  Eargo7Newfeatures,
  NaturalSound,
  RechargeableBattery,
  Pricing,
  SelfFitting,
  WaterResistance,
  PromiseText,
  WhatsInTheBox,
  PopularQuestions,
  SoundAdjust,
  SetupCleaningCare
}
