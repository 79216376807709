import React from 'react'
import PropTypes from 'prop-types'
import './PrimaryTextArea.scss'

const PrimaryTextArea = ({
  id, name, value, active, disabled = false, errClass = '', className = '', label = 'Label', maxLength,
  handleOnClick, handleOnChange, handleOnFocus, handleOnBlur, oneline = false, inputRef, readOnly = false, placeholder,
  classess = ''
}) => {
  const [inputActive, setInputActive] = React.useState(false)
  return (<label
    id={id}
    className={`primary-text-area-label ${errClass} ${oneline ? 'primary-text-area-one-line-style' : ''}`}
    onClick={() => !!handleOnClick && handleOnClick()}>
    {
      (errClass !== 'error_border') ? <h1 className={`${(!!inputActive || !!active || !!value) ? 'typographyV2 floating_label' : 'typographyV2 body un-floating-label'}`}>{label}</h1> : <span className='typographyV2 err_regular un-floating-label'>{label}</span>
    }
    <textarea
      defaultValue={value || ''}
      name={name}
      placeholder={placeholder}
      className={`typographyV2 body primary-text-area-field ${classess} ${className} ${(!!inputActive || !!active || !!value) ? 'paddingtop1' : ''}`}
      onChange={handleOnChange}
      ref={inputRef}
      rows={5}
      maxLength={maxLength}
      disabled={disabled}
      readOnly={readOnly}
      onFocus={e => {
        setInputActive(true)
        !!handleOnFocus && handleOnFocus(e)
      }}
      onBlur={e => {
        setInputActive(false)
        !!handleOnBlur && handleOnBlur(e)
      }}
    />
  </label>
  )
}

PrimaryTextArea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  active: PropTypes.bool,
  errClass: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.any,
  handleOnFocus: PropTypes.func,
  handleOnBlur: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleOnClick: PropTypes.func,
  value: PropTypes.any,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  oneline: PropTypes.bool,
  inputRef: PropTypes.any,
  height: PropTypes.any,
  width: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  classess: PropTypes.string
}

export default PrimaryTextArea
