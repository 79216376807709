import React, { Suspense, useEffect, useReducer, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withRouter, Switch, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { parse } from 'query-string'

import { helpersFn } from '@eargo/eargo-components'

// eslint-disable-next-line no-unused-vars
import { BottomNav, TopActionBar } from '@eargo/ecomm-component-library'

import { metaTags } from '../pdp/meta'
import { ROUTES, SKU } from '../../constant'
import { colorCodes } from '../../constant/colors'
import { fetchVariant } from '../../actions/variant_actions'
import { createCart, fetchCart, applyCouponWithoutError, applyCoupon } from '../../actions/cart_actions'
import {
  removeElement,
  styleElement,
  addMetaTags,
  removeMetaTags,
  productBaseMetaJson,
  isCBSMethod,
  isSupportRoute
} from '../../common/helpers'
import { BreadModal } from '../lazyComponents'
import { setBreadModal, setCartStatus } from '../../actions/global'
import LoaderPlaceholder from '../common/LoaderPlaceholder'
import SupportForm from '../my_info/SupportSection/SupportForm'
import SupportRequestDetail from '../my_info/SupportSection/SupportRequestDetail'
import Header from '../common/Header'
// import FooterWrapper from '../common/Footer.js'

import usePrevious from '../../common/usePrevious'
import { MyInfoRoutesWrapper } from '../my_info'
import Sidebar from '../my_info/Sidebar'

const PDP = React.lazy(() => import('../pdp/pdp'))
const EARGO5 = React.lazy(() => import('../Eargo5/Eargo5Page/eargo_5_container'))
const EARGO6 = React.lazy(() => import('../Eargo6/Eargo6Page/eargo6_container'))
const EARGO7 = React.lazy(() => import('../Eargo7'))

const { WHITE } = colorCodes
const { EARGO_NEO, EARGO_MAX, EARGO_NEO_HIFI, EARGO_5, EARGO_6, EARGO_7 } = ROUTES
let URLCouponAutoApplied = false

const Wrapper = props => {
  const { location: { pathname }, history } = props
  const dispatch = useDispatch()
  const { autoOut, openReview, openBreadModal } = useSelector(state => state.global)
  const { cart, paymentMethods } = useSelector(state => state)
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const variant = useSelector(state => state.variant.variant)
  const pathNameVal = location.pathname.toLowerCase()
  const prevOpenReview = usePrevious(openReview)
  const prevAutoOut = usePrevious(autoOut)
  const prevVariant = usePrevious(variant)
  const prevPathname = usePrevious(pathname)

  // eslint-disable-next-line no-unused-vars
  const [productSku, setProductSku] = useState(null)
  const [productActionBarLogo, setProductActionBarLogo] = useState(null)

  useEffect(() => {
    const htmlElem = document.getElementsByTagName('html')[0]
    if (htmlElem) {
      htmlElem.removeAttribute('style')
    }
    window.addEventListener('scroll', handleScroll)
    handleData()
    return () => {
      dispatch(setCartStatus(false))
      window.removeEventListener('scroll', handleScroll)
      removeMetaTags()
      removeElement('yotpo-js')
    }
  }, [])

  useEffect(() => {
    let header_height
    if (document.getElementById('header')) {
      header_height = document.getElementById('header').offsetHeight
    }
    if (prevOpenReview !== openReview && !!openReview) {
      const productReview = (document.getElementById('see_more_reviews_title').offsetTop - header_height)
      window.scrollTo(0, productReview)
    }
    if ((prevAutoOut !== autoOut) && !!autoOut) {
      dispatch(setCartStatus(false))
    }
    if (prevVariant !== variant) {
      if (!!variant && !!variant.product) {
        productBaseMetaJson(variant)
      }
    }
    if (prevPathname && prevPathname?.toLowerCase() !== pathname.toLowerCase()) {
      handleData()
    } else {
      if (typeof window.yotpo !== 'undefined') {
        setTimeout(() => window.yotpo.initWidgets(), 1200)
      }
    }
  }, [openReview, variant, autoOut, pathname])

  // Apply URL coupon code
  useEffect(() => {
    const params = parse(location.search)

    if (URLCouponAutoApplied === false && !!params.coupon_code && cart?.itemCount > 0 && (cart.couponCode?.includes(params.coupon_code) === false || cart.couponCode === null)) {
      const couponParams = {
        order_id: cart.id,
        coupon_code: params.coupon_code
      }
      URLCouponAutoApplied = true
      dispatch(applyCoupon(couponParams))
    }
  }, [cart])

  const handleData = () => {
    window.top.scrollTo(0, 0)

    console.log(location.pathname.toLowerCase())
    let sku
    switch (location.pathname.toLowerCase()) {
      case EARGO_NEO:
        sku = SKU.EARGO_NEO
        break
      case EARGO_MAX:
        sku = SKU.EARGO_MAX
        break
      case EARGO_5:
        sku = SKU.EARGO_5
        break
      case EARGO_6:
        sku = SKU.EARGO_6
        setProductSku(SKU.EARGO_6)
        setProductActionBarLogo('https://assets.eargo.com/plp/Eargo+6.svg')
        break

      case EARGO_7:
        sku = SKU.EARGO_7
        setProductSku(SKU.EARGO_7)
        setProductActionBarLogo('https://assets.eargo.com/plp/Eargo+7.svg')
        break

      case EARGO_NEO_HIFI:
      default:
        sku = SKU.EARGO_NEO_HIFI
        break
    }

    dispatch(fetchVariant(sku))
    dispatch(fetchCart())
    addMetaTags(metaTags[location.pathname.toLowerCase()])

    const params = parse(location.search)

    if (params.cart === '1') {
      dispatch(setCartStatus(true))
    }

    handleGtm()
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', forceUpdate)
    return () => window.removeEventListener('resize', forceUpdate)
  }, [])

  const addToCart = async (e) => {
    e.preventDefault()
    const paymentMethod = isCBSMethod(paymentMethods)

    if (paymentMethod) {
      const params = parse(location.search)
      const cartParams = {
        ...(!!cart && !!cart.id && { orderId: cart.id }),
        quantity: 1,
        variant_id: variant.id,
        payment_method_id: paymentMethod.id
      }

      await dispatch(createCart(cartParams)).then(async res => {
        if (params.coupon_code) {
          const couponParams = {
            order_id: res.cart.id,
            coupon_code: params.coupon_code
          }
          await dispatch(applyCouponWithoutError(couponParams))
        }
        window.location.href = ROUTES.NEW_CHECKOUT
        // dispatch(setCartStatus(true))
      })
    } else {
      console.log('Payment Method Not Found')
    }
  }

  const handleScroll = () => {
    if (isScrolledIntoView('.bsAdo') === true) {
      styleElement('.bsAdo div', 'opacity: 1; top: 20%;')
    }
    if (isScrolledIntoView('.your_ears_are_in_good_company') === true) {
      styleElement('.your_ears_are_in_good_company', 'opacity: 1; margin-top: 5%;')
    }
  }

  const handleGtm = () => {
    const url = pathname.toLowerCase()
    const virtualPageTitle = /\/my-info\/support\/./.test(url) ? 'My Support Form' : 'PDP'
    helpersFn.gtmSend({
      event: 'VirtualPageView',
      virtualPageURL: url,
      virtualPageTitle: virtualPageTitle
    })
  }

  const isScrolledIntoView = elem => {
    const { scrollY, innerHeight } = window
    const docViewTop = scrollY
    const docViewBottom = docViewTop + innerHeight

    if (document.querySelectorAll(elem).length === 0) return null

    const selectedElem = document.querySelector(elem)
    const elemTop = selectedElem.offsetTop
    const elemBottom = elemTop + selectedElem.offsetHeight
    return (((elemBottom - 200) <= docViewBottom) && (elemTop >= docViewTop))
  }

  return (
    <>
      {isSupportRoute(location)
        ? <>
          {
            <MyInfoRoutesWrapper>
              <Sidebar />

              <Switch>
                <Route exact path='/my-info/support/form' component={SupportForm} />
                <Route exact strict path='/my-info/support/:id' component={SupportRequestDetail} />
              </Switch>

              </MyInfoRoutesWrapper>
          }
        </>
        : Object.keys(variant).length > 0 && <>
        <div>

          <h1 type='hidden' style={{ height: 0, color: WHITE, display: 'none' }}>
            {!!metaTags[pathNameVal] && metaTags[pathNameVal].h1}
          </h1>

          <Helmet>
            <link href={`https://shop.eargo.com${pathNameVal}`} rel="canonical" />
          </Helmet>

          {
            (pathNameVal === EARGO_6 || pathNameVal === EARGO_7) && (
              <TopActionBar productSku={productSku} cartData={cart} buyButtonHandler={addToCart} productLogo={productActionBarLogo} isLowStock={pathNameVal === EARGO_6}/>
            )
          }

          <Suspense fallback={<LoaderPlaceholder />}>
            <Header history={history} addToCart={addToCart} />
          </Suspense>

          <Switch>
            <Route exact strict path={[EARGO_MAX, EARGO_NEO_HIFI]} component={PDP} />
            <Route exact path={EARGO_5} component={EARGO5} />
            <Route exact path={EARGO_6} component={EARGO6} />
            <Route exact path={EARGO_7} component={EARGO7} />
          </Switch>

          <BottomNav productSku={productSku} cartData={cart} buyButtonHandler={addToCart} isLowStock={pathNameVal === EARGO_6}/>

          {
            !!openBreadModal &&
            <Suspense fallback={<LoaderPlaceholder />}>

              <BreadModal
                id={Math.random()}
                open={openBreadModal}
                updateState={() => dispatch(setBreadModal())}
                cart={cart}
                variant={variant}
              />

            </Suspense>
          }
        </div>
      </>}
    </>
  )
}

Wrapper.propTypes = {
  location: PropTypes.object,
  setCartStatus: PropTypes.func,
  fetchVariant: PropTypes.func,
  fetchCart: PropTypes.func,
  createCart: PropTypes.func,
  history: PropTypes.object,
  setBreadModal: PropTypes.func
}

export default withRouter(Wrapper)
