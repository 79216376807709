import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colorCodes } from '../../../constant/colors'
import { devices } from '../../../constant/devices'
import { convertStringToAmount, isWebPSupported, isJ2Supported, getSkuImage } from '../../../common/helpers'

const { tablet, mobile } = devices
const { GREY_1, GREY_3 } = colorCodes

const ProductQuantity = styled.div`
font-size: 18px;
line-height: 21px;
font-family: unset;
color: ${props => props.isChild ? GREY_1 : GREY_3};
@media ${tablet} {
    padding-top: 0px!important;
}
`

const TotalPrice = styled.div`
font-size: 18px;
line-height: 26px;
color: ${GREY_1};
@media ${tablet} {
    padding-top: 2px!important;
}
`

const ProductName = styled.h2`
    font-size: 18px;
    line-height: 26px;
    color: ${GREY_1};
`

const ImageContainer = styled.img`
    width: 100px;
    @media ${tablet} {
        width: 50px;
    }
`

const ProductDetail = styled.div`
    padding-left ${props => props.isChild ? 0 : 26}px;
    @media ${tablet} {
       padding-left ${props => props.isChild ? 0 : 20}px;
    }
`

const ProductContainer = styled.div`
    display: flex;
    align-items: center;
    padding-top: 40px;
    @media ${mobile} {
        padding-top: 54px;
    }
`

const isWebp = isWebPSupported()
const isJp2 = isJ2Supported()

const setImageName = imageObj => {
  if (imageObj) {
    return isWebp ? imageObj.webp : (!!isJp2 && imageObj.jp2 ? imageObj.jp2 : imageObj.png)
  }
  return 'https://assets.eargo.com/pdp/my_orders/404.png'
}

const LineItem = ({ lItem: { quantity = 0, price = 0, name = '', images, sku }, isChild = false, displayPrice }) => (
  <ProductContainer data-testid='ProductContainer-testid'>
    {!isChild && <ImageContainer src={images ? setImageName(images) : getSkuImage(sku)} alt={name} />}
    <ProductDetail isChild={!!isChild} data-testid='ProductDetail-testid'>
      {!!name && <ProductName className='bold'>{name}</ProductName>}
      <ProductQuantity isChild={isChild} className='light pt-8' data-testid='ProductQuantity-testid'>
        Qty: {quantity} {displayPrice === 'Eargo' && (<>x ${convertStringToAmount(Math.abs(parseFloat(price, 10)).toFixed(2))}</>)}
      </ProductQuantity>
      {displayPrice === 'Eargo' && (<TotalPrice className="bold pt-8" data-testid='TotalPrice-testid'>${convertStringToAmount(Math.abs(parseFloat((quantity * price), 10)).toFixed(2))}</TotalPrice>)}
    </ProductDetail>
  </ProductContainer>
)

LineItem.propTypes = {
  lItem: PropTypes.object,
  isChild: PropTypes.bool,
  displayPrice: PropTypes.string
}

export default LineItem
