import screen from '../assets/scss/common.module.scss'

export const devices = {
  minMobile: `(max-width: ${screen.minMobile})`,
  mobile: `(max-width: ${screen.mobile})`,
  mobile_minWidth: `(min-width: ${screen.mobile})`,
  maxMobile: `(max-width): ${screen.maxMobile})`,
  minTablet: `(max-width: ${screen.minTablet})`,
  tablet: `(max-width: ${screen.tablet})`,
  tabletWidth_1: `(max-width: ${screen.tabletWidth1})`,
  tabletWidth_2: `(max-width: ${screen.tabletWidth2})`,
  tabletWidthMin1: `(max-width: ${screen.tabletWidthMin1})`,
  desktop: `(min-width: ${screen.desktop})`,
  desktopWidth1: `(min-width: ${screen.desktopWidth1})`,
  desktopWidth2: `(max-width: ${screen.desktopWidth2})`,
  desktopWidth3: `(max-width: ${screen.desktopWidth3})`
}
