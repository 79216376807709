import React from 'react'
import PropTypes from 'prop-types'
import './SectionTitle.scss'

const SectionTitle = ({ title, children, id = '', className = '', isReview = false }) => (
    <h5 id={id} className={`typographyV2 section_title ${className}`}>{children || title}</h5>
)

SectionTitle.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
  id: PropTypes.string,
  className: PropTypes.any,
  isReview: PropTypes.any
}

export default SectionTitle
