import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import './index.scss'
import BoxV2 from '../../../common/BoxV2'
import LatestSupportTicket from './LatestSupportTicket'
import CreateNewSupportTicket from './CreateNewSupportTicket'
import { getCaseList } from '../../../../actions/support'

const SupportSection = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const caseDetail = useSelector(state => state.support.caseDetail)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getCaseList())
  }, [])

  return (
    <div className='support-main-block'>
        <BoxV2 className='full_size custom_height h-210'>
            <CreateNewSupportTicket history={history} />
        </BoxV2>
        <BoxV2 className='full_size custom_height h-210'>
            <LatestSupportTicket caseDetail={caseDetail} history={history} />
        </BoxV2>
    </div>
  )
}

export default SupportSection
