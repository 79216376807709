import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

function BoxV2 ({ children, className, isClass = true }) {
  return (
    <div className={`box-design-shadow ${isClass && 'new-account-box-design'} ${className}`}>
        {children}
    </div>
  )
}

BoxV2.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isClass: PropTypes.bool
}

export default BoxV2
