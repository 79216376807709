import * as CartApiUtil from '../util/credit_card_api_util'
import { fetchCart } from './cart_actions'
import { receiveCreditCardError, receiveSplitCardError } from './error_actions'
import { checkAndSetAutoOut } from './global'

export const RECEIVE_CREDIT_CARD = 'RECEIVE_CREDIT_CARD'
export const SET_SPLIT_CHECK_STATUS = 'SET_SPLIT_CHECK_STATUS'
export const LOGGED_IN_CARDS = 'LOGGED_IN_CARDS'

export const receiveCreditCard = creditCard => ({
  type: RECEIVE_CREDIT_CARD,
  creditCard
})

export const setSplitCheckStatus = splitCardChecked => ({
  type: SET_SPLIT_CHECK_STATUS,
  payload: { splitCardChecked }
})

export const setLoggedInCard = (loggedInCard) => ({
  type: LOGGED_IN_CARDS,
  payload: {
    loggedInCard
  }
})

export const createCreditCard = (creditCard, callback, shouldFetch = true) => (dispatch) => {
  return (
    CartApiUtil.createSplitCreditCard(creditCard).then(
      res => {
        !!shouldFetch && dispatch(fetchCart({}, true))
        dispatch(receiveCreditCard(res.data ? res.data : []))
        return res
      },
      error => {
        !!callback && callback()
        const errJson = error.responseJSON
        dispatch(receiveCreditCard({ last_digits: '', month: '', year: '', cc_type: '' }))
        if (!!errJson && typeof errJson.error === 'string') {
          return dispatch(receiveCreditCardError([errJson.error]))
        } else if (!!errJson && errJson.error) {
          return dispatch(receiveCreditCardError([errJson.error[Object.keys(errJson.error)[0]]]))
        } else if (!!errJson && errJson.data && errJson.data.length) {
          return dispatch(receiveSplitCardError(errJson.data))
        }
        // const errorBag = !!errJson && !!errJson.error
        //   ? errJson.error
        //   : (!!errJson && !!errJson.data && errJson.data.length ? errJson.data : ['Something went wrong, Please try again'])
        // if (errorBag.length < 2) {
        //   return dispatch(receiveSplitCardError(['error in handling']))
        // } else {
        //   return dispatch(receiveCreditCardError([...errorBag]))
        // }
      })
  )
}

export const updateCreditCard = (creditCard, callback) => (dispatch) => {
  return (
    CartApiUtil.updateCreditCard(creditCard).then(
      res => {
        dispatch(checkAndSetAutoOut(res))
        dispatch(receiveCreditCard(res))
        dispatch(fetchCart({}, true))
        return true
      },
      error => {
        !!callback && callback()

        const errJson = error.responseJSON
        if (errJson) {
          dispatch(checkAndSetAutoOut(errJson))
        }
        dispatch(receiveCreditCard({ last4: '', expMonth: '', expYear: '', brand: '' }))
        if (!!errJson.error && !!errJson.errorKey &&
          (errJson.errorKey === 'card')) {
          dispatch(receiveCreditCardError([errJson.error]))
          return false
        }
        const errorBag = !!errJson && !!errJson.error
          ? errJson.error
          : (!!errJson && !!errJson[0] ? errJson[0] : 'Something went wrong, Please try again')
        return dispatch(receiveCreditCardError([errorBag]))
      })
  )
}

export const fetchCreditCard = cardToken => dispatch => {
  return (
    CartApiUtil.fetchCreditCard(cardToken)
      .then(res => dispatch(receiveCreditCard(res.data)))
  )
}
