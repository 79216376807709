import {
  FETCH_ORDERS_ERROR,
  FETCH_ORDERS_SUCCESS,
  HANDLE_ORDERS_PROCESSING,
  REMOVE_ORDERS_IN_A_LIST,
  FETCH_ORDER_BY_ID_SUCCESS,
  VIEW_ORDER_DETAILS_SUCCESS,
  VIEW_ORDER_DETAILS_PROCESSING,
  VIEW_ORDER_DETAILS_ERROR
} from '../actions/orders'

const defaultState = {
  total: 0,
  orders: [],
  pageCount: 3,
  orderDetail: {},
  isProcessing: false
}

const Orders = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ORDERS_SUCCESS:
    case FETCH_ORDERS_ERROR:
    case HANDLE_ORDERS_PROCESSING:
    case VIEW_ORDER_DETAILS_ERROR:
    case VIEW_ORDER_DETAILS_PROCESSING:
      return {
        ...state,
        ...action.payload
      }
    case VIEW_ORDER_DETAILS_SUCCESS: {
      const { parentSfId, viewDetails } = action.payload
      const { orderDetail } = state
      if (parentSfId) {
        const newOrderDetail = Object.keys(orderDetail) && orderDetail.rmaOrders.map((rma) => {
          if (rma.number === viewDetails.number) {
            return { ...rma, ...viewDetails }
          }
          return rma
        })
        return {
          ...state,
          isProcessing: action.payload.isProcessing,
          orderDetail: { ...orderDetail, rmaOrders: newOrderDetail }
        }
      }
      return {
        ...state,
        isProcessing: action.payload.isProcessing,
        orderDetail: action.payload.viewDetails
      }
    }

    case FETCH_ORDER_BY_ID_SUCCESS: {
      const { parentSfId, sfOrderId, order, isProcessing } = action.payload
      const oredersArray = state.orders
      const updatedOrders = !!oredersArray && oredersArray.length > 0 && oredersArray.map(value => {
        if (parentSfId) {
          if (value.sf_order_id === parentSfId) {
            const infoVal = !!value && value.rmaOrders
            const updatedRmaOrders = !!infoVal && infoVal.length > 0 && infoVal.map(v => {
              if (v.sf_order_id === sfOrderId) {
                // Hint: For now, we're not getting rmaData to BE
                delete order.rmaOrders
                delete order.number
                delete order.order_number

                return { ...v, ...order }
              }
              return v
            })
            value.rmaOrders = updatedRmaOrders
            return value
          }
        } else if (value.sf_order_id === sfOrderId) {
          return { ...value, ...order }
        }
        return value
      })
      return {
        ...state,
        isProcessing,
        orders: updatedOrders
      }
    }
    case REMOVE_ORDERS_IN_A_LIST:
      return {
        ...state,
        orders: []
      }
    default:
      return state
  }
}

export default Orders
