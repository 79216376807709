import React from 'react'
import PropTypes from 'prop-types'
import Feedback from './Feedback'
import Cross from './Cross'
import ThankYou from './ThankYou'
import Star from './Star'
import DownArrow from './DownArrow'
import UpArrow from './UpArrow'

const Icon = props => {
  switch (props.name) {
    case 'feedback':
      return <Feedback {...props} />
    case 'cross':
      return <Cross {...props} />
    case 'thankyou':
      return <ThankYou {...props} />
    case 'star':
      return <Star {...props} />
    case 'down-arrow':
      return <DownArrow {...props} />
    case 'up-arrow':
      return <UpArrow {...props} />
    default:
      return <div />
  }
}

Icon.propTypes = {
  name: PropTypes.string
}

export default Icon
