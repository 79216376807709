import { helpersFn } from '@eargo/eargo-components'
import { handleAjax } from '../common/helpers'
import { SERVER_URL } from '../constant'

const { createQueryParams, prepareRequest } = helpersFn

/**
 * Fetch Orders for
 * logged in user
 * @param {Object} info
 */
export const fetchUserAllOrders = query => {
  let reqUrl = `${SERVER_URL}v2/api/users/order_list`
  if (Object.keys(query).length > 0) {
    reqUrl = reqUrl + '?' + createQueryParams(query)
  }
  return new Promise((resolve, reject) => {
    prepareRequest('GET', reqUrl)
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}

/**
 * Fetch Order info
 * by sfOrderId
 * @param {String} sfOrderId
 * @returns
 */
export const fetchOrderById = sfOrderId => handleAjax('GET', `api/users/order-info/${sfOrderId}`)

/**
 * Download Invoice
 * @param {*} sfOrderId
 * @returns
 */
export const downloadInvoice = sfOrderId => handleAjax('GET', `api/invoice/${sfOrderId}`, false, false, false, false, true)

export const downloadReturnLabel = (sfOrderId, type) => handleAjax('GET', `api/returnLabelInfo/${sfOrderId}?type=${type}`, false, false, false, false, true)

/**
 * Download SuperBill
 * @param {*} sfOrderId
 * @returns
 */
export const downloadSBInvoice = sfOrderId => handleAjax('GET', `api/superbill/${sfOrderId}`, false, false, false, false, true)

export const downloadDocument = (type) => handleAjax('GET', `api/insurance-documents/download/${type}`, false, false, false, false, true)

/**
 * Fetch welcome call Data
 */
export const fetchOrientationBannerData = sfOrderId => handleAjax('GET', 'api/users/orientation_banner')
