import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import './SupportNotification.scss'

const SupportNotification = ({ returnNotification, activeNumber }) => {
  const history = useHistory()
  useEffect(() => {
    returnNotification(true)
  })

  return (
    <div className='support_notification_container' data-testid='support-notification'>
      <div className='support_notification_holder' onClick={() => history.push('/my-info/support')}>
        <img className='support_notification_icon' src='https://assets.eargo.com/account/support/notification.svg' alt='notification icon' />
        <div className='typographyV2 body support-notification-text'>You have {activeNumber} active support ticket.</div>
      </div>
      <img className='support_notification_icon' src='https://assets.eargo.com/account/insuranceform/close.svg' alt='cross icon' onClick={() => returnNotification(false)} />
    </div>
  )
}

SupportNotification.propTypes = {
  activeNumber: PropTypes.number,
  returnNotification: PropTypes.func
}

export default SupportNotification
