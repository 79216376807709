import {
  RECEIVE_LEAD_PROCESS,
  LEAD_PROCESS_LOADING,
  LEAD_PROCESS_ERROR_MSG,
  REMOVE_LEAD_PROCESS_ERROR_MSG
} from '../actions/lead_process_action'

const defaultState = {
  isLoading: false,
  errMsg: {},
  applicantInfo: {}
}

const LeadProcessReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LEAD_PROCESS_LOADING:
    case RECEIVE_LEAD_PROCESS:
    case LEAD_PROCESS_ERROR_MSG:
      return {
        ...state,
        ...action.payload
      }
    case REMOVE_LEAD_PROCESS_ERROR_MSG: {
      const errMessages = state.errMsg
      if (!!action.fieldName && !!errMessages && !!errMessages[action.fieldName]) {
        delete errMessages[action.fieldName]
      }
      const newState = { ...state, errMsg: errMessages }
      return {
        newState
      }
    }
    default:
      return state
  }
}

export default LeadProcessReducer
