import React from 'react'
import PropTypes from 'prop-types'
import { colorCodes } from '../../constant/colors'

const { ORANGE_1 } = colorCodes
const Star = ({
  style = {},
  fill = ORANGE_1,
  borderColor = ORANGE_1,
  width = 13,
  height = 13,
  className = '',
  onClick,
  viewBox = '0 0 24 24'
}) => (
    <svg
      fill={fill}
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      className={className}
      onClick={() => !!onClick && onClick()}
      data-testid='jest-star-id'
    >
      <path stroke={borderColor} strokeWidth="1" d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
    </svg>
)

Star.propTypes = {
  style: PropTypes.any,
  fill: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  viewBox: PropTypes.string,
  borderColor: PropTypes.string
}

export default Star
