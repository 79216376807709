import React from 'react'
import PropTypes from 'prop-types'
import { EargoButtonV2 } from '@eargo/eargo-components'
import Documents from '../../../icons/Documents'
import Calendar from '../../../icons/Calendar'
import './index.scss'
import colors from '../../../../assets/scss/common.module.scss'
import ReactHtmlParser from 'react-html-parser'
/**
 * Dashboard banner
 * @param {string} bannerType Sets accent color.
 * @param {string} icon Sets banner icon.
 * @param {string} copy Sets banner copy.
 * @param {string} buttonLabel Sets banner button label.
 * @param {string} action Sets banner button action.
 * @param {string} link If action = link, sets the link.
 * @param {string} scrollToElement If action = scroll, sets the destination element.
 */

const DashboardBanner = ({ bannerType = null, icon = null, copy, buttonLabel, action, scrollToElement, link, openInNewTab = false, customCss, phoneNumber }) => {
  let accentColor = null
  let bannerIcon = null

  switch (bannerType) {
    case 'info':
      accentColor = colors.info
      break
    case 'warning':
      accentColor = colors.warning
      break
    case 'error':
      accentColor = colors.errorRed
      break
    case 'success':
      accentColor = colors.green1
      break

    default:
      accentColor = colors.grey1
      break
  }

  switch (icon) {
    case 'documents':
      bannerIcon = <Documents fill={accentColor}/>
      break
    case 'calendar':
      bannerIcon = <Calendar fill={accentColor}/>
      break

    default:
      bannerIcon = <Documents fill={accentColor}/>
      break
  }
  return (
    <div className="dashboard-banner__container">
      <div>
        {bannerType &&
          <div className={`dashboard-banner__type-indicator dashboard-banner__type-indicator--${bannerType}`}></div>
        }
        <div className="dashboard-banner__content">
            {icon &&
              <div className='dashboard-banner__icon'>
                {bannerIcon}
              </div>
            }
          <div className={`dashboard-banner__copy ${customCss || ''}`}>{ReactHtmlParser(copy)}</div>
        </div>
        {action && action === 'link' && link &&
          <a href={link} {...(openInNewTab && { target: '_blank' })} >
            <EargoButtonV2 className='primary dashboard-banner__button small' handleOnClick={() => {}} label={buttonLabel}/>
          </a>
        }
        {action && action === 'scroll' && scrollToElement &&
          <a href={`#${scrollToElement}`}>
            <EargoButtonV2 className='primary dashboard-banner__button small' handleOnClick={() => {}} label={buttonLabel}/>
          </a>
        }
        {action && action === 'call' && phoneNumber &&
          <a href={`tel:${phoneNumber}`}>
            <div className='button-reschedule-call'>Reschedule call</div>
          </a>
        }
      </div>
    </div>
  )
}

DashboardBanner.propTypes = {
  bannerType: PropTypes.string,
  icon: PropTypes.string,
  copy: PropTypes.string,
  buttonLabel: PropTypes.string,
  link: PropTypes.string,
  action: PropTypes.string,
  scrollToElement: PropTypes.string,
  openInNewTab: PropTypes.bool,
  customCss: PropTypes.string,
  phoneNumber: PropTypes.string
}

export default DashboardBanner
