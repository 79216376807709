import {
  SET_PASSWORD_ERROR,
  SET_PASSWORD_HANDLE_PROCESSING,
  REMOVE_SET_PASSWORD_ERROR_MSG,
  SF_USER_UPDATED
} from '../actions/set_password'

const defaultState = {
  errMsg: {},
  isProcessing: false,
  sfUser: false
}

const SetPassword = (state = defaultState, action) => {
  switch (action.type) {
    case SET_PASSWORD_HANDLE_PROCESSING:
    case SET_PASSWORD_ERROR:
      return {
        ...state,
        ...action.payload
      }
    case SF_USER_UPDATED:
      return {
        ...state,
        ...action.payload
      }
    case REMOVE_SET_PASSWORD_ERROR_MSG: {
      const errMessages = state.errMsg
      if (
        !!action &&
        !!action.fieldName &&
        !!errMessages &&
        !!errMessages[action.fieldName]
      ) {
        delete errMessages[action.fieldName]
      }
      return {
        ...state,
        ...errMessages
      }
    }
    default:
      return state
  }
}

export default SetPassword
