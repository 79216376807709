import * as InsuranceProviderApiUtil from '../util/insurance_provider_api_util'

export const RECEIVE_INSURANCE_PROVIDERS = 'RECEIVE_INSURANCE_PROVIDERS'

export const receiveInsuranceProviders = insuranceProviders => ({
  type: RECEIVE_INSURANCE_PROVIDERS,
  insuranceProviders
})

/**
 * Fetch Insurance
 * Providers
 * @param {*} page
 */
export const fetchInsuranceProviders = (page = 'fedform') => dispatch => (
  InsuranceProviderApiUtil.fetchInsuranceProviders(page)
    .then(res => dispatch(receiveInsuranceProviders(res)))
    .catch(err => { console.log('err --------', err) }))
