import React from 'react'
import PropTypes from 'prop-types'
import { ResetPassword } from '@eargo/eargo-components'
import { BASE_URL } from '../../../constant'
import { withRouter } from 'react-router'

function ResetPasswordMain ({ history }) {
  const { history: { state } } = window
  return (
    <div>
        <ResetPassword shopDomain={BASE_URL} history={history} userInfo={state.state} />
    </div>
  )
}

ResetPasswordMain.propTypes = {
  history: PropTypes.any
}

export default withRouter(ResetPasswordMain)
