import * as CartApiUtil from '../util/cart_api_util'
import { receiveCurrentUser } from './session_actions'
import { receiveCouponError, receiveCartErrors, removeCouponError, removeAllErrors, receiveShipAddressError, receiveCreditCardError, receiveSplitCardError } from './error_actions'
import { removeAllReviewErrors, receiveReviewCreditCardError } from './review_error_actions'
import { ROUTES, ZIP_SERVER_MSG } from '../constant'
import { setCouponResponseMessage, clearUserOnAutoOut, setOrderFound } from './global'
import { setSavedAddressId, setSavedBillAddressId } from './user_address'
import { fetchUPMethods, setSavedMethodId } from './user_payment'
import { receiveCreditCard, setLoggedInCard, setSplitCheckStatus } from './credit_card_actions'

export const RECEIVE_CART = 'RECEIVE_CART'
export const RECEIVE_TAX = 'RECEIVE_TAX'
export const UPDATE_ORDER_STATE = 'UPDATE_ORDER_STATE'
export const SET_CARD_IN_CART = 'SET_CARD_IN_CART'
export const SET_SHIP_ADD_IN_CART = 'SET_SHIP_ADD_IN_CART'

export const CART_PROCESSING_START = 'CART_PROCESSING_START'

export const receiveCart = cart => ({
  type: RECEIVE_CART,
  cart
})

export const updateOrderState = orderState => ({
  type: UPDATE_ORDER_STATE,
  orderState
})

export const receiveTax = tax => ({
  type: RECEIVE_TAX,
  tax
})

export const onlySetCartCouponInfo = res => dispatch => {
  if (!!res && !!res.status && res.status === 'orderRefreshed') {
    dispatch(fetchCart())
  }
  dispatch(setCouponResponseMessage(res))
}

const setCartAndCouponInfo = res => dispatch => {
  dispatch(onlySetCartCouponInfo(res))
  return dispatch(receiveCart(res))
}

const setCartProcessing = (status) => ({
  type: CART_PROCESSING_START,
  status
})

export const fetchCart = (query = false, fetchDefaultInfo = false) => dispatch => (
  CartApiUtil.fetchCart(query).then(res => {
    // NOTE - When order not found, {sucess: true, data: null}, response Object is given from BE
    if (res && res.success && !res.data) {
      return false
    }

    if (res) {
      dispatch(receiveCart(res))
      dispatch(setOrderFound(true))
      dispatch(receiveCurrentUser(res.user))

      if (fetchDefaultInfo) {
        if (!!res && !!res.shipAddress && !!res.shipAddress.id) {
          dispatch(setSavedAddressId(res.shipAddress.id))
        } else {
          dispatch(setSavedAddressId(false))
        }

        if (!!res && !!res.billAddress && !!res.billAddress.id) {
          dispatch(setSavedBillAddressId(res.billAddress.id))
        } else {
          dispatch(setSavedBillAddressId(false))
        }
      }
      if (!!res && !!res.cards && !!res.cards.length) {
        dispatch(setSplitCheckStatus(res.cards.length === 2))
      } else {
        dispatch(setSplitCheckStatus(false))
      }

      if (!!res && !!res.cards) {
        const newState = []
        const amountState = []
        res.cards.forEach((cc) => {
          if (cc.card.id && cc.card.reusable) {
            newState.push(cc.card.id)
            amountState.push(cc.card.amount ? cc.card.amount : '')
          }
        })
        dispatch(setLoggedInCard(amountState))
        dispatch(setSavedMethodId(newState))
      } else {
        dispatch(setSavedMethodId([]))
      }
    }
    return true
  }, err => {
    if (err.responseJSON) {
      dispatch(refreshCartForExpire(err.responseJSON))
    }
    console.log('FETCH CART ERROR')
    return false
  })
)

export const createCart = cart => async dispatch => {
  dispatch(setCartProcessing(true))
  try {
    const res = await CartApiUtil.createCart(cart)
    dispatch(refreshCartForExpire(res))
    dispatch(setCartProcessing(false))
    return dispatch(setCartAndCouponInfo(res))
  } catch (error) {
    dispatch(setCartProcessing(false))
    if (error.responseJSON) {
      dispatch(refreshCartForExpire(error.responseJSON))
    }
    return dispatch(receiveCartErrors(error.responseJSON))
  }
}

export const verifyOrder = (query = false) => dispatch => {
  return CartApiUtil.verifyOrder(query).then(res => {
    if (res && res.success && !res.data) {
      return false
    }

    if (res) {
      dispatch(receiveCart(res))
      dispatch(setCartAndCouponInfo(res))
    }
    return res
  }, error => {
    console.log('Verify Error: ', error)
    return false
  })
}

export const updateCart = (orderInfo, page = 'cart') => dispatch => {
  const updatedOrderinfo = { ...orderInfo, order: { ...orderInfo.order, page } }
  return CartApiUtil.updateCart(updatedOrderinfo).then(res => {
    dispatch(refreshCartForExpire(res))
    // If new message key found remove previous error
    !!res && !!res.message && dispatch(removeCouponError())
    dispatch(setCartAndCouponInfo(res))
    dispatch(receiveCurrentUser(res.user))
    return true
  }, error => {
    const errJson = error.responseJSON
    if (errJson) {
      dispatch(refreshCartForExpire(errJson))
      if (!!errJson.error && !!errJson.errorKey) {
        if (errJson.errorKey === 'address') {
          dispatch(receiveShipAddressError([errJson.error]))
        } else if (errJson.errorKey === 'card') {
          dispatch(receiveCreditCardError([errJson.error]))
        }
        return false
      }
    }
    if (!!errJson && errJson.length > 0) {
      // HINT: We'll replace this part with error_code future functionality
      if ((errJson[0]).startsWith('Promo code ')) {
        dispatch(receiveCouponError(errJson))
      } else if ((errJson).includes('Cannot find order')) {
        dispatch(receiveCart({})) // Set Cart Blank in state
      } else if (errJson[0] === 'Already selected card is deleted from your account. Please check the new card attached to your order.') {
        dispatch(fetchCart(false, true))
        dispatch(fetchUPMethods())
        dispatch(receiveCreditCardError(errJson))
      } else {
        dispatch(receiveCartErrors(errJson))
      }
    }
    return false
  })
}

export const updateInsuCart = (orderInfo) => dispatch => (
  CartApiUtil.updateInsuranceCart(orderInfo).then(res => {
    dispatch(refreshCartForExpire(res))
    // If new message key found remove previous error
    !!res && !!res.message && dispatch(removeCouponError())
    dispatch(setCartAndCouponInfo(res))
    dispatch(receiveCurrentUser(res.user))
    return true
  }, error => {
    const errJson = error.responseJSON
    if (errJson) {
      dispatch(refreshCartForExpire(errJson))
      if (!!errJson.error && !!errJson.errorKey) {
        if (errJson.errorKey === 'address') {
          dispatch(receiveShipAddressError([errJson.error]))
        } else if (errJson.errorKey === 'card') {
          dispatch(receiveCreditCardError([errJson.error]))
        }
        return false
      }
    }
    if (!!errJson && errJson.error && (errJson.error).includes(ZIP_SERVER_MSG)) {
      return { combinationFailed: true }
    }
    if (errJson && (errJson.length > 0)) {
      // HINT: We'll replace this part with error_code future functionality
      if ((errJson[0]).startsWith('Promo code ')) {
        dispatch(receiveCouponError(errJson))
      } else if ((errJson).includes('Cannot find order')) {
        dispatch(receiveCart({})) // Set Cart Blank in state
      } else {
        dispatch(receiveCartErrors(errJson))
      }
    }
    return false
  })
)

export const chooseSelectedProd = (orderInfo) => dispatch => (
  CartApiUtil.chooseSelectedProd(orderInfo).then(res => {
    dispatch(refreshCartForExpire(res))
    // If new message key found remove previous error
    !!res && !!res.message && dispatch(removeCouponError())
    dispatch(setCartAndCouponInfo(res))
    dispatch(receiveCurrentUser(res.user))
    return true
  }, error => {
    const errJson = error.responseJSON
    if (errJson) {
      dispatch(refreshCartForExpire(errJson))
      if (!!errJson.error && !!errJson.errorKey) {
        if (errJson.errorKey === 'address') {
          dispatch(receiveShipAddressError([errJson.error]))
        } else if (errJson.errorKey === 'card') {
          dispatch(receiveCreditCardError([errJson.error]))
        }
        return false
      }
    }
    if (errJson && (errJson.length > 0)) {
      // HINT: We'll replace this part with error_code future functionality
      if ((errJson[0]).startsWith('Promo code ')) {
        dispatch(receiveCouponError(errJson))
      } else if ((errJson).includes('Cannot find order')) {
        dispatch(receiveCart({})) // Set Cart Blank in state
      } else {
        dispatch(receiveCartErrors(errJson))
      }
    }
    return false
  })
)

export const removeLineItem = (orderId, lineItemId) => dispatch => (
  CartApiUtil.removeLineItem(orderId, lineItemId).then(res => {
    dispatch(refreshCartForExpire(res))
    return dispatch(setCartAndCouponInfo(res))
  }, error => {
    const errJson = error.responseJSON
    if (errJson) {
      dispatch(refreshCartForExpire(errJson))
    }
    if (!!errJson && errJson.length > 0 && (errJson).includes('Cannot find order')) {
      dispatch(receiveCart({}))
    } else {
      dispatch(receiveCartErrors(errJson))
    }
  })
)

export const checkoutUpdate = orderInfo => dispatch => (
  CartApiUtil.checkoutUpdate(orderInfo).then(res => {
    dispatch(refreshCartForExpire(res))
    return dispatch(setCartAndCouponInfo(res))
  }, error => {
    if (error.responseJSON) {
      dispatch(refreshCartForExpire(error.responseJSON))
    }
    return dispatch(receiveCartErrors(error.responseJSON))
  })
)

export const applyCoupon = request => dispatch => (
  CartApiUtil.applyCoupon(request).then(res => {
    dispatch(refreshCartForExpire(res))
    dispatch(setCartAndCouponInfo(res))
  }, error => {
    const errJson = error.responseJSON
    if (errJson) {
      dispatch(refreshCartForExpire(errJson))
    }
    if (!!errJson && errJson.length > 0 && (errJson).includes('Cannot find order')) {
      dispatch(receiveCart({}))
    } else {
      dispatch(receiveCouponError(errJson))
    }
  })
)

export const applyCouponWithoutError = request => dispatch => (
  CartApiUtil.applyCoupon(request).then(res => {
    dispatch(refreshCartForExpire(res))
    return dispatch(setCartAndCouponInfo(res))
  }, error => {
    const errJson = error.responseJSON
    return errJson
  })
)

export const removeCoupon = orderId => dispatch => (
  CartApiUtil.removeCoupon(orderId).then(res => {
    dispatch(refreshCartForExpire(res))
    return dispatch(setCartAndCouponInfo(res))
  }, error => {
    const errJson = error.responseJSON
    if (errJson) {
      dispatch(refreshCartForExpire(errJson))
    }
    if (!!errJson && errJson.length > 0 && (errJson).includes('Cannot find order')) {
      dispatch(receiveCart({}))
    } else {
      dispatch(receiveCouponError(error.responseJSON))
    }
  })
)

export const CBSCompleteOrder = orderInfo => async dispatch => {
  try {
    const resp = await CartApiUtil.completeOrder(orderInfo)
    if (!!resp && !!resp.success && !!resp.data) {
      return resp.data
    } else if (!!resp && !!resp.success && !!resp.order) {
      return {
        success: true,
        renderUrl: resp.renderUrl
      }
    } else if (!!resp && !resp.success && !!resp.error) {
      return resp
    }
    return false
  } catch (error) {
    const errJson = error.responseJSON
    if (errJson) {
      dispatch(refreshCartForExpire(errJson))
    }
    if (!!errJson && !!errJson[0] && !!errJson[0].status &&
      (errJson[0].status === 'orderRefreshed')) {
      dispatch(fetchCart())
      dispatch(setCouponResponseMessage(errJson[0]))
    } else {
      const errMsg = !!errJson && !!errJson.error
        ? errJson.error
        : (!!errJson && !!errJson.data ? errJson.data : 'Something went wrong, Please try again')
      if (!!orderInfo && (!!orderInfo.stripePaymentMethodId || !!orderInfo.paymentIntentId)) {
        if (!!errJson && errJson.length > 0 && (errJson).includes('Cannot find order')) {
          dispatch(receiveCart({}))
        } else if (!!errJson && errJson.length > 0 && (errJson[0]).startsWith('Promo code ')) { // HINT: Handled error for .includes("Coupon Expired!")
          dispatch(receiveCouponError(errJson))
        } else {
          dispatch(receiveReviewCreditCardError([errMsg]))
        }
      } else if (errMsg.length > 1) {
        dispatch(receiveSplitCardError(errMsg))
      } else {
        dispatch(receiveCreditCardError([errMsg[0].error]))
        if (!!errMsg && !!errMsg[0].renderUrl) {
          return errMsg[0].error
        }
      }
    }
    return errJson
  }
}

export const federalCompleteOrder = orderInfo => dispatch => (
  CartApiUtil.federalCompleteOrder(orderInfo).then(res => {
    if (!res.error) {
      dispatch(refreshCartForExpire(res))
      return dispatch(receiveCart(res))
    }
    if (res.error) {
      return dispatch(receiveCartErrors([res.error]))
    }

    return res
  }, error => {
    if (error.responseJSON) {
      dispatch(refreshCartForExpire(error.responseJSON))
    }
    if (!!error && !!error.responseJSON &&
      !!error.responseJSON[0] && !!error.responseJSON[0].status &&
      (error.responseJSON[0].status === 'orderRefreshed')) {
      dispatch(fetchCart())
      dispatch(setCouponResponseMessage(error.responseJSON[0]))
    } else {
      const errMsg = !!error && !!error.responseJSON && error.responseJSON[0]
        ? `${error.responseJSON[0]} Please double check your information.`
        : 'Something went wrong, Please try again'
      const errJson = error.responseJSON
      if (!!errJson && errJson.length > 0 && (errJson).includes('Cannot find order')) {
        dispatch(receiveCart({}))
      } else {
        dispatch(receiveCartErrors([errMsg]))
      }
    }
  }
  )
)

export const reviseCartOrderInfo = orderId => dispatch => (
  CartApiUtil.reviseCartOrder(orderId).then(res => {
    dispatch(refreshCartForExpire(res))
    if (!!res && !!res.success) {
      if (!!res && !!res.status &&
        (res.status === 'orderRefreshed')) {
        dispatch(fetchCart())
        dispatch(setCouponResponseMessage(res))
        return false
      }
      return true
    }
    return false
  }, error => {
    if (error.responseJSON) {
      dispatch(refreshCartForExpire(error.responseJSON))
    }
    if (!!error && !!error.responseJSON &&
      !!error.responseJSON[0] && !!error.responseJSON[0].status &&
      (error.responseJSON[0].status === 'orderRefreshed')) {
      dispatch(fetchCart())
      dispatch(setCouponResponseMessage(error.responseJSON[0]))
    }
    return false
  }
  )
)

export const breadCompleteOrder = (orderInfo, history, handleDisableState) => dispatch => (
  CartApiUtil.breadCompleteOrder(orderInfo).then(res => {
    dispatch(refreshCartForExpire(res))
    handleDisableState(false)
    if (!res.error) {
      dispatch(receiveCart(res))
      if (!!res.state && (res.state === 'complete' || res.state === 'pending-approval')) {
        history.push(`${ROUTES.CONFIRMATION}/${res.number}`)
      } else {
        dispatch(receiveCartErrors(['Something went wrong, Please try again']))
      }
    }
    if (res.error) {
      if (res.error.responseJSON) {
        dispatch(refreshCartForExpire(res.error.responseJSON))
      }
      return dispatch(receiveCartErrors([res.error]))
    }
    return res
  }, error => {
    const errJson = error.responseJSON
    handleDisableState(false)
    if (!!error && !!error.responseJSON &&
      !!error.responseJSON[0] && !!error.responseJSON[0].status &&
      (error.responseJSON[0].status === 'orderRefreshed')) {
      dispatch(fetchCart())
      dispatch(setCouponResponseMessage(error.responseJSON[0]))
    } else {
      if (!!errJson && !!errJson.error) {
        if ((errJson.error).includes('Cannot find order')) {
          dispatch(receiveCart({}))
        } else {
          dispatch(receiveCartErrors([errJson.error]))
        }
      } else if (!!errJson && !!errJson.length && (errJson).includes('Cannot find order')) {
        dispatch(receiveCart({}))
      } else {
        dispatch(receiveCartErrors([`${error.responseJSON[0]}, Bread Submission Failed!`]))
      }
    }
  })
)

export const updateTax = tax => dispatch => (
  CartApiUtil.updateTaxApi(tax).then(res => {
    dispatch(refreshCartForExpire(res))
    if (res) {
      dispatch(receiveCart(res))
    }
    // if (!!res && !!res.refreshOrder) {
    //   dispatch(fetchCart({
    //     ...(!!tax && !!tax.orderId && { orderId: tax.orderId }),
    //     ...(!!tax && !!tax.isInsured && { isInsured: tax.isInsured })
    //   }))
    // }
    // return dispatch(receiveTax(res))
  })
)

export const productForIns = (info, signal) => async (dispatch) => {
  const resp = await CartApiUtil.productsForInsurance(info, signal)
  if (!!resp && !!resp.success) {
    const products = resp.products ? resp.products : {}
    const allTaxes = []
    !!Object.values(resp.products).length &&
      Object.keys(resp.products).map(k =>
        allTaxes.push(resp.products[k].eargo_tax))

    return { products, allTaxes }
  }
  return false
}

export const setShipAddressInCart = shipAddress => ({
  type: SET_SHIP_ADD_IN_CART,
  shipAddress
})

export const setCardInCart = card => ({
  type: SET_CARD_IN_CART,
  card
})

export const refreshCartForExpire = res => dispatch => {
  if (!!res && !!res.errorCode) {
    if (res.errorCode === 'cart_expired') {
      dispatch(removeAllErrors())
      dispatch(removeAllReviewErrors())
      dispatch(clearUserOnAutoOut())
    } else if (res.errorCode === 'not_found') {
      dispatch(setOrderFound(false))
      dispatch(receiveCart({}))
    }
  }
}

export const AttachPaymentMethod = (id, orderId) => dispatch => {
  CartApiUtil.attachPaymentMethod({ payment_method_id: id, orderId }).then(res => {
    dispatch(refreshCartForExpire(res))
    if (!!res && !!res.success) {
      dispatch(receiveCart(res.data))
      return true
    }
    return false
  }, error => {
    if (error.responseJSON.errorType === 'validationError') {
      dispatch(receiveCartErrors([error.responseJSON.error]))
    } else if (error.responseJSON.errorType === 'simpleError') {
      dispatch(receiveCartErrors([error.responseJSON.error]))
    }
    if (!!error && !!error.responseJSON &&
      !!error.responseJSON[0] && !!error.responseJSON[0].status &&
      (error.responseJSON[0].status === 'orderRefreshed')) {
      dispatch(fetchCart())
      dispatch(setCouponResponseMessage(error.responseJSON[0]))
    }
    return false
  }
  )
}

export const setSavedIDInfoFalse = () => (dispatch) => {
  dispatch(setSavedAddressId(false))
  dispatch(setSavedBillAddressId(false))
  dispatch(setSavedMethodId([]))

  // HINT: Reset CC info
  dispatch(receiveCreditCard({ name: '', last_digits: '', month: '', year: '', brand: '' }))
}
