import React from 'react'
import PropTypes from 'prop-types'

const Documents = ({
  style = {},
  fill = '#333333',
  width = 28,
  height = 30,
  className = '',
  onClick,
  viewBox = '0 0 28 30'
}) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      className={className}
      onClick={() => !!onClick && onClick()}
      fill="none"
    >
      <path stroke={fill} d="M1.45361 27.1621V6.0697C1.45361 4.96513 2.34905 4.0697 3.45362 4.0697H24.546C25.6505 4.0697 26.546 4.96513 26.546 6.0697V27.1621C26.546 28.2666 25.6505 29.1621 24.546 29.1621H3.45361C2.34904 29.1621 1.45361 28.2666 1.45361 27.1621Z" />
      <path d="M6.44434 4.06973V0.837769" stroke={fill} />
      <path d="M21.5435 4.06973V0.837769" stroke={fill} />
      <rect x="1.45361" y="10.1287" width="25.3333" height="1.33333" stroke={fill} />
    </svg>
)

Documents.propTypes = {
  style: PropTypes.any,
  fill: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  viewBox: PropTypes.string
}

export default Documents
