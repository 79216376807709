import React from 'react'
import PropTypes from 'prop-types'
import { colorCodes } from '../../constant/colors'

const ThankYou = ({
  style = {},
  fill = colorCodes.BLACK,
  width = 40,
  height = 40,
  className = '',
  onClick,
  viewBox = '0 0 368 368'
}) => (
    <svg
      fill={fill}
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      className={className}
      onClick={() => !!onClick && onClick()}
      data-testid='jest-thank-you-id'
    >
      <path d="m328 0h-288c-22.054688 0-40 17.945312-40 40v224c0 22.054688 17.945312 40 40 40h24v56c0 3.230469 1.945312 6.160156 4.9375 7.390625.992188.417969 2.03125.609375 3.0625.609375 2.078125 0 4.128906-.816406 5.65625-2.34375l61.65625-61.65625h188.6875c22.054688 0 40-17.945312 40-40v-224c0-22.054688-17.945312-40-40-40zm24 264c0 13.230469-10.769531 24-24 24h-192c-2.128906 0-4.160156.839844-5.65625 2.34375l-50.34375 50.34375v-44.6875c0-4.425781-3.574219-8-8-8h-32c-13.230469 0-24-10.769531-24-24v-224c0-13.230469 10.769531-24 24-24h288c13.230469 0 24 10.769531 24 24zm0 0" />
      <path d="m136 144c4.425781 0 8-3.574219 8-8v-64c0-4.425781-3.574219-8-8-8s-8 3.574219-8 8v64c0 4.425781 3.574219 8 8 8zm0 0" />
      <path d="m232 144c4.425781 0 8-3.574219 8-8v-64c0-4.425781-3.574219-8-8-8s-8 3.574219-8 8v64c0 4.425781 3.574219 8 8 8zm0 0" />
      <path d="m296 160c-4.425781 0-8 3.574219-8 8 0 30.871094-25.128906 56-56 56h-96c-30.871094 0-56-25.128906-56-56 0-4.425781-3.574219-8-8-8s-8 3.574219-8 8c0 39.703125 32.296875 72 72 72h96c39.703125 0 72-32.296875 72-72 0-4.425781-3.574219-8-8-8zm0 0" />
    </svg>
)

ThankYou.propTypes = {
  style: PropTypes.any,
  fill: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  viewBox: PropTypes.string
}

export default ThankYou
