import React, { useEffect } from 'react'
import { DotSpinLoading } from '@eargo/eargo-components'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
// import Sidebar from '../Sidebar'
import './index.scss'
import DocumentCard from './DocumentCard'
import DocumentRowCard from './DocumentRowCard'
import { emptyDocuments, fetchAlluserDocuments } from '../../../actions/documents'
import { totalDocuments } from '../../../common/helpers'
import { checkLeadId } from '../../../util/document'

const Documents = ({ history }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.documents.isLoading)
  const documentsList = useSelector(state => state.documents.totalDocuments)
  const uploadedDocuments = useSelector(state => state.documents?.uploadedDocuments)

  useEffect(() => {
    async function fetchData () {
      const response = await checkLeadId()
      if (!response.success) {
        history.push('/')
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchDocuments = () => {
      dispatch(fetchAlluserDocuments())
    }
    fetchDocuments()
    return () => {
      dispatch(emptyDocuments([]))
    }
  }, [])

  if (isLoading) {
    return <DotSpinLoading />
  }

  const checkInsuranceType = (type) => {
    return totalDocuments.filter(doc => (doc.id === type) || ((doc.altId === type)))[0]?.heading || ''
  }

  return (
    <div className='uploadDocumentContainer' id='uploadDocument'>
      <div className='uploadDocumentRightContainer'>
        { documentsList.length >= 1 &&
          <div className='upperBox'>
            <div className='title'>Documents To Upload ({documentsList.length})</div>
            <div className='sub_title'>Maximum file size 10MB</div>
            <div className='divider_line'></div>
            <div className='card_wrapper'>
              <div className='cardContainer'>
                {
                  documentsList.map((document) => (
                    <DocumentCard key={document.id} id={document.id} heading={document.heading} subHeading={document.subHeading} documentsList={documentsList} />
                  ))
                }
              </div>
            </div>
          </div>
        }
        { uploadedDocuments.length >= 1 &&
          <div className='lowerBox'>
            <div className='title'>Documents</div>
            <div className='divider_line'></div>
            <div className='documentRowContainer'>
              {
                uploadedDocuments.map((document) => (
                  <DocumentRowCard key={document.id} id={document.id} heading={checkInsuranceType(document.type)} docName={document.file_name} uploadDate={document.created_at} type={document.type} />
                ))
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}

Documents.propTypes = {
  history: PropTypes.any
}

export default Documents
