import React from 'react'
import './SupportNotFound.scss'

const SupportNotFound = () => (
  <div className='support-not-found-container' data-testid='support-not-found' role='support-not-found'>
    <div className='error-circle'>
      <img className='error-image' src='https://assets.eargo.com/account/support/error.svg' alt="Not Found image" />
    </div>
    <h5 className='typographyV2 sorry-text'>SORRY</h5>
    <div className='typographyV2 body body-text'>It looks like Support is currently down.  We are working on the issue. If you need to submit a Support Request right away, you can still reach us via email or phone.</div>
    <div className='help-line-holder'>
      <div className='help-line' data-testid='support-not-found-email'><img className='email-icon' src='https://assets.eargo.com/account/support/email.svg' alt="Email Icon" /> <h6 className='typographyV2 help-line-text'> support@eargo.com</h6></div>
      <div className='help-line padd-right' data-testid='support-not-found-phone'><img className='phone-icon' src='https://assets.eargo.com/account/phone.svg' alt="Phone Icon" /><h6 className='typographyV2 help-line-text'> (800) 615-9000</h6></div>
    </div>
  </div>
)

export default SupportNotFound
