import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { DotSpinLoading } from '@eargo/eargo-components'

import CardForm from './CardForm'
import { ROUTES } from '../../constant'
import PopUp from '../../components/common/PopUp'
import { submitFailure } from '../../common/helpers'
import { validateZipCode } from '../../actions/user_address'
import { removeFieldError } from '../../actions/info_payment_method'
import './AddNewCreditCard.scss'

const FORM_NAME = 'addNewCreditCardForm'

let AddNewCreditCard = ({
  change, splitCardChecked = false, setSplitCheckStatus,
  submitting, handleSubmit, addNewPayment, onCancel, methods,
  setPaymentListPopUp, initialize
}) => {
  const dispatch = useDispatch()
  const [isProcessing, setIsProcessing] = useState(false)
  const [microform, handleMicroformIns] = useState(false)
  const [cardBrand, setCardBrand] = useState('')
  const [addressId, setAddressId] = useState('')
  const isAddress = location.pathname === ROUTES.ADDRESS

  const states = useSelector(state => state.userAddresses.states)
  const isLoading = useSelector(state => state.userPaymentMethods.isLoading)
  const isLoggedIn = useSelector(state => state.global.isLoggedIn)
  const address_id = useSelector(state => state.form?.addNewCreditCardForm?.values?.address_id)
  const name = useSelector(state => state.form?.addNewCreditCardForm?.values?.name)
  const addressDetails = useSelector(state => ({
    ...(state.userAddresses.addresses).find(add => add.id === address_id)
  }))
  const expiration = useSelector(state => state.form?.addNewCreditCardForm?.values?.expiration_1 || state.form?.addNewCreditCardForm?.values?.expiration)

  const initialValues = {
    name,
    address_id,
    ...(!!addressDetails && !!addressDetails.address1 && { address1: addressDetails.address1 }),
    ...(!!addressDetails && !!addressDetails.address2 && { address2: addressDetails.address2 }),
    ...(!!addressDetails && !!addressDetails.city && { city: addressDetails.city }),
    ...(!!addressDetails && !!addressDetails.zipcode && { zipcode: addressDetails.zipcode }),
    ...(!!addressDetails && !!addressDetails.state_id && { state_id: addressDetails.state_id }),
    ...(!!addressDetails && !!addressDetails.country_id && { country_id: addressDetails.country_id })
  }

  useEffect(() => {
    if (address_id !== addressId) {
      initialize(initialValues)
      setAddressId(address_id)
    }
  }, [initialValues])

  useEffect(() => {
    !!setPaymentListPopUp && dispatch(setPaymentListPopUp(false))
    // eslint-disable-next-line
    }, [])

  // Handle Unmount logic
  useEffect(() => () => {
    if (!!methods && !!methods.length) {
      !splitCardChecked && !!setPaymentListPopUp && dispatch(setPaymentListPopUp(true))
    }
  }, [setPaymentListPopUp, methods])

  const submitForm = async data => {
    const isValid = await dispatch(validateZipCode(data, FORM_NAME))
    if (!isValid) return false

    try {
      setIsProcessing(true)

      const fullName = data.name ? (data.name).split(' ') : false
      const firstName = fullName && !!fullName[0] ? fullName[0] : ''
      const lastName = fullName ? (data.name).substring(fullName[0].length).trim() : ''

      const countryId = data.country_id
        ? data.country_id
        : (!!states && !!states.length && !!data.state_id ? states[0].country_id : false)
      if (microform) {
        if (data.transient_token) {
          const expirationVal = !!expiration && expiration.split('/')

          const resp = await dispatch(addNewPayment({
            transient_token: data.transient_token,
            name: data.name,
            type: 'create',
            cc_type: cardBrand,
            month: expirationVal[0],
            year: `20${expirationVal[1]}`,
            last_digits: null,
            default: !!data.default, // HINT: We can't write without quote, because it is reserve keyword
            address: {
              ...(!!firstName && { firstname: firstName }),
              ...(!!lastName && { lastname: lastName }),
              ...(!!data.address1 && { address1: data.address1 }),
              ...(!!data.address2 && { address2: data.address2 }),
              ...(!!data.city && { city: data.city }),
              ...(!!data.zipcode && { zipcode: data.zipcode }),
              ...(!!data.state_id && { state_id: data.state_id }),
              ...(!!countryId && { country_id: countryId })
            }
          }))
          setIsProcessing(false)
          !!resp && onCancel()
        } else {
          setIsProcessing(false)
          console.log('CYBER TOKEN NOT FOUND ')
        }
      } else {
        console.log('Something wrong, CBS or microform not found')
      }
    } catch (e) {
      setIsProcessing(false)
      console.log('CHECK ERROR ', e)
    }
  }

  return (
        <Fragment>
          <PopUp
          width={640}
          height={650}
          paddingTop={true}
          handleClose={() => {
            dispatch(removeFieldError())
            if (methods && methods.length < 2) {
              !!setSplitCheckStatus && dispatch(setSplitCheckStatus(false))
            }
            onCancel(false)
          }} unsetHeight={true}>
            {(!!isLoading || !!isProcessing) && <DotSpinLoading />}
            <div className='add_new_card_heading_section'>
                <h5 className='typographyV2 heading'>Add New Credit Card</h5>
            </div>
            {splitCardChecked && methods.length && methods.length < 2 && isLoggedIn && isAddress && <div className='description_section'>
                <div className='body typographyV2 description'>You have selected to split payment between two cards, but have only one card on file.  Please use the form below to create a second card.</div>
            </div>}
            <CardForm
                formId='eargo-add-new-card-form'
                handleMicroformIns={handleMicroformIns}
                microform={microform}
                expiration={expiration}
                setCardBrand={setCardBrand}
                change={change}
                addressId={address_id}
                isDisable={submitting || !!isLoading}
                onSubmit={handleSubmit(submitForm)}
            />
            </PopUp>
        </Fragment>
  )
}

AddNewCreditCard.propTypes = {
  expiration: PropTypes.any,
  change: PropTypes.any,
  validateZipCode: PropTypes.func,
  submitting: PropTypes.any,
  handleSubmit: PropTypes.func,
  addNewPayment: PropTypes.func,
  onCancel: PropTypes.func,
  methods: PropTypes.any,
  setPaymentListPopUp: PropTypes.func,
  states: PropTypes.any,
  address_id: PropTypes.any,
  isLoading: PropTypes.any,
  isLoggedIn: PropTypes.any,
  setSplitCheckStatus: PropTypes.func,
  initialize: PropTypes.func,
  splitCardChecked: PropTypes.any
}

AddNewCreditCard = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmitFail: () => submitFailure()
})(AddNewCreditCard)

export default AddNewCreditCard
