import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import global from './global'
import CartReducer from './cart_reducer'
import VariantReducer from './variant_reducer'
import SessionReducer from './session_reducer'
import ErrorsReducer from './errors_reducer'
import ReviewErrorsReducer from './review_errors_reducer'
import ShipmentTypesReducer from './shipment_types_reducer'
import CreditCardsReducer from './credit_card_reducer'
import PaymentMethodsReducer from './payment_methods_reducer'
import PromoBannersReducer from './promo_banners_reducer'
import PasswordReducer from './password_reducer'
import feedback from './feedback'
import userAddresses from './user_address'
import userPaymentMethods from './user_payment'
import auth from './auth'
import support from './support'
import orders from './orders'
import address from './address'
import changePassword from './change_password'
import MyInfoPaymentMethodsReducer from './info_payment_method'
import insurancePrequal from './insurance_prequal_reducer'
import InsuranceProvidersReducer from './insurance_provider_reducer'
import InsCart from './iinsurance_cart_reducer'
import LeadProcessReducer from './lead_process_reducer'
import orderTrack from './order_track'
import recentOrder from './recentOrder_reducer'
import { headerJson, footerJson } from './aem_data_reducer'
import setPassword from './set_password'
import documents from './documents'
// NOTE - paymentMethod is changed to firstPaymentMethod, because Corp FE payment method having same reducer name.
const rootReducer = combineReducers({
  global,
  currentUser: SessionReducer,
  cart: CartReducer,
  variant: VariantReducer,
  errors: ErrorsReducer,
  reviewErrors: ReviewErrorsReducer,
  shipmentTypes: ShipmentTypesReducer,
  creditCard: CreditCardsReducer,
  paymentMethods: PaymentMethodsReducer,
  promoBanner: PromoBannersReducer,
  password: PasswordReducer,
  feedback: feedback,
  userAddresses,
  userPaymentMethods,
  support,
  auth,
  orders,
  address,
  myInfoPaymentMethod: MyInfoPaymentMethodsReducer,
  changePassword,
  insurancePrequal,
  insuranceProviders: InsuranceProvidersReducer,
  insuranceCart: InsCart,
  leadProcess: LeadProcessReducer,
  order_track: orderTrack,
  recent_order: recentOrder,
  form: formReducer,
  headerJson,
  footerJson,
  setPassword,
  documents
})

export default rootReducer
