import React from 'react'
import { createRoot } from 'react-dom/client'

import { BrowserRouter } from 'react-router-dom'

import configureStore from './store/store'
import { setTrackingCookie } from './general_methods'
import { unregister } from './serviceWorker'

import Root from './components/root'

import './index.scss'

const container = document.getElementById('root')
const root = createRoot(container)

const store = configureStore()

window.store = store
setTrackingCookie()

root.render(
  <BrowserRouter>
    <Root store={store} />
  </BrowserRouter>
)

unregister()
