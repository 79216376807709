import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_HANDLE_PROCESSING,
  REMOVE_CHANGE_PASSWORD_ERROR_MSG
} from '../actions/change_password'

const defaultState = {
  errMsg: {},
  isProcessing: false
}

const ChangePassword = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_HANDLE_PROCESSING:
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        ...action.payload
      }
    case REMOVE_CHANGE_PASSWORD_ERROR_MSG: {
      const errMessages = state.errMsg
      if (!!action && !!action.fieldName && !!errMessages && !!errMessages[action.fieldName]) {
        delete errMessages[action.fieldName]
      }
      return {
        ...state,
        ...errMessages
      }
    }
    default:
      return state
  }
}

export default ChangePassword
