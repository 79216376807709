import { helpersFn } from '@eargo/eargo-components'
import { SERVER_URL } from '../constant'

/**
 * Fetch logged in
 * auth info
 */
export const fetchAuth = async () => {
  return new Promise((resolve, reject) => {
    helpersFn.prepareRequest('GET', `${SERVER_URL}v2/api/auth`)
      .then(res => res.json())
      .then(result => resolve(result))
      .catch(error => reject(error))
  })
}
