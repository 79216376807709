import React, { useEffect } from 'react'
import { PrimaryInput, helpersFn } from '@eargo/eargo-components'
import PropTypes from 'prop-types'
import { onlyDigit } from '../../../common/helpers'

const { capitalizeOnBlur, formatPhoneNumber } = helpersFn

// HINT: WEB-4261

const ReduxPrimaryInput = ({
  input, id, type, disabled, label, handleOnFocus, handleOnBlur, showPassword = false, capitalize = false,
  autoComplete = true, meta: { asyncValidating, touched, error, active, visited }, errResp, inputMode, handleOnChange,
  passwordConfirmed = false
}) => {
  const beforeSubmitBlur = (e) => {
    if ((e.keyCode === 13) || (e.key === 'Enter')) {
      document.activeElement.blur()
    }
  }

  useEffect(() => {
    document.addEventListener('keypress', beforeSubmitBlur)
    return () => {
      document.removeEventListener('keypress', beforeSubmitBlur)
    }
  }, [])

  return (
    <div className="redux-primary-input-holder" data-testid='jest-redux-primary-input-testid'>
      <PrimaryInput
        type={type}
        id={id}
        disabled={disabled}
        active={active}
        inputMode={inputMode}
        showPassword={showPassword}
        autoComplete={autoComplete}
        errClass={passwordConfirmed ? errResp : (((!active && (visited || touched)) && error) || !!errResp) ? 'error_border' : ''}
        label={passwordConfirmed ? label : (((!active && (visited || touched)) && error) || !!errResp) ? (asyncValidating ? 'Validating...' : (error || errResp)) : (asyncValidating ? 'Validating...' : label)}
        handleOnChange={param => {
          const val = (type === 'tel') ? (param.target.value).replace(/\D/g, '') : param.target.value
          input.onChange(((input.name === 'patient_phone') || (input.name === 'payer_phone') || (input.name === 'phone'))
            ? onlyDigit(val)
            : val)
          !!handleOnChange && handleOnChange(param)
        }}
        handleOnFocus={e => {
          const val = (type === 'tel') ? (e.target.value).replace(/\D/g, '') : e.target.value
          input.onFocus(e)
          !!handleOnFocus && handleOnFocus(e)
          input.onChange(((input.name === 'patient_phone') || (input.name === 'payer_phone') || (input.name === 'phone'))
            ? onlyDigit(val)
            : val)
        }}
        handleOnBlur={e => {
          const trimmedValue = (e.target.value).trim()
          const val = (type === 'tel') ? (e.target.value).replace(/\D/g, '') : e.target.value
          input.onChange(capitalize ? capitalizeOnBlur(trimmedValue) : trimmedValue)

          // HINT: capitalizeOnBlur function taking some time in calculation that's why we added setTimeOut
          capitalize ? setTimeout(() => input.onBlur(), 50) : input.onBlur()
          !!handleOnBlur && handleOnBlur(e)
          input.onBlur(((input.name === 'patient_phone') || (input.name === 'payer_phone') || (input.name === 'phone'))
            ? formatPhoneNumber(val)
            : val)
        }}
        {...input}
      />
    </div>
  )
}

ReduxPrimaryInput.propTypes = {
  input: PropTypes.any,
  id: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  handleOnFocus: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  showPassword: PropTypes.bool,
  capitalize: PropTypes.bool,
  autoComplete: PropTypes.bool,
  meta: PropTypes.any,
  errResp: PropTypes.any,
  inputMode: PropTypes.any,
  passwordConfirmed: PropTypes.bool
}

export default ReduxPrimaryInput
