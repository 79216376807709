import {
  RECEIVE_PROMO_BANNER
} from '../actions/promo_banner_actions'

const PromoBannersReducer = (state = null, action) => {
  Object.freeze(state)
  switch (action.type) {
    case RECEIVE_PROMO_BANNER: {
      const promoBannerResp = Object.keys(action.promoBanner).length > 0 ? action.promoBanner : null
      return promoBannerResp
    }
    default:
      return state
  }
}

export default PromoBannersReducer
