import React from 'react'
import PropTypes from 'prop-types'
import { addressUrl, SKU } from '../../../../constant'
import './index.scss'
import { getSkuImage } from '../../../../common/helpers'

const UserGuides = ({ itemList: { product_name = 'Eargo 7', sku = SKU.EARGO_7 } }) => {
  const userGuides = [
    { title: 'Eargo 5', pdfDownloadLink: `${addressUrl}content/dam/eargo/pdf/EARGO_5_FLUG.pdf` },
    { title: 'Eargo 6', pdfDownloadLink: `${addressUrl}content/dam/eargo/pdf/EARGO_6_FLUG.pdf` },
    { title: 'Eargo Max', pdfDownloadLink: `${addressUrl}content/dam/eargo/pdf/EARGO_Max_User_Guide.pdf` },
    { title: 'Eargo Neo', pdfDownloadLink: `${addressUrl}content/dam/eargo/pdf/EARGO_Neo_User_Guide.pdf` },
    { title: 'Eargo Neo Hifi', pdfDownloadLink: `${addressUrl}content/dam/eargo/pdf/Eargo-Neo-HiFi_FLUG_LBL0251_REV_C.pdf` },
    { title: 'Eargo 7', pdfDownloadLink: `${addressUrl}content/dam/eargo/pdf/EARGO_5_6_7_Use_Care-Guide.pdf` },
    { title: 'Eargo Se', pdfDownloadLink: `${addressUrl}/content/dam/eargo/pdf/EARGO_SE_Use_Care_Guide.pdf` },
    { title: 'Link By Eargo', pdfDownloadLink: `${addressUrl}content/dam/eargo/pdf/LINK_by_Eargo_Use_Care_Guide.pdf` }
  ]

  const handleOnClick = () => {
    userGuides.forEach(item => {
      if (item.title === product_name) {
        window.open(item.pdfDownloadLink, '_blank')
        // const link = document.createElement('a')
        // link.download = item.pdfDownloadLink
        // document.body.appendChild(link)
        // link.click()
        // document.body.removeChild(link)
      }
    })
  }
  return (
    <div className='user_guides_container'>
      <div className='user_guides_sub_container'>
        <div className='user_guides_inner_container'>
          <img src={getSkuImage(sku)} className='user_guieds_image' />
          <div className='user_guides_heading_container'>
            <div className='user_guides_heading'>
              <h6 className='typographyV2 product_heading'>Your products: </h6>
              <h5 className='typographyV2 product_title'>{product_name}</h5>
            </div>
            <div className='view_link' onClick={handleOnClick}><img className='new_page_icon' src='https://assets.eargo.com/account/support/new_page_icon.svg' /><span className='typographyV2 append_icon user_guides_link'>VIEW GUIDE</span></div>
          </div>
        </div>
        <div className='typographyV2 body description'>To view guides for other Eargo products, <span><a className='guides_link' target='_blank' rel='noreferrer' href={`${addressUrl}guides`}>click here</a></span> and select the product of your choice.</div>
      </div>
    </div>
  )
}

UserGuides.propTypes = {
  itemList: PropTypes.object,
  key: PropTypes.any
}

export default UserGuides
