import React from 'react'
import PropTypes from 'prop-types'
import { devices } from '../../../constant/devices'
import { colorCodes } from '../../../constant/colors'
import ReduxSelectBox from '../../common/redux-form/ReduxSelectBox'
import { createAddressLine, getStateAbbr } from '../../../common/helpers'
import './AddressSelectBox.scss'

const { GREY_1, GREY_6, WHITE, ORANGE_5, BRIGHT_GREY } = colorCodes
const { mobile } = devices

const selectBoxStyle = {
  container: (provided, { isDisabled }) => ({
    ...provided,
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    width: '100%',
    height: '100%',
    background: isDisabled ? BRIGHT_GREY : 'transparent'
  }),
  indicatorsContainer: () => ({
    marginRight: 7
  }),
  dropdownIndicator: () => ({
    color: ORANGE_5
  }),
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    color: GREY_1
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 18,
    overflow: 'visible'
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'none'
  }),
  menu: () => ({
    flexDirection: 'column',
    maxHeight: 300,
    width: '100%',
    zIndex: 2,
    overflowY: 'scroll',
    position: 'absolute',
    top: 75,
    left: 0,
    background: WHITE,
    border: `1px solid ${GREY_6}`,
    borderRadius: 3,
    [`@media ${mobile}`]: {
      top: 60
    }
  }),
  menuList: () => ({
    maxHeight: 300,
    overflowY: 'auto',
    position: 'relative'
  }),
  option: (provided) => ({
    ...provided,
    cursor: 'pointer',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 24,
    fontSize: '18px',
    fontFamily: 'Inter, sans-serif'
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'none'
  })
}

const AddressSelectBox = ({
  input, label, handleOnIdChange, meta: { active, visited, touched, error },
  handleOnFocus, handleOnBlur, errResp, removeFieldError, states, addressList, fetchMore
}) => {
  let placeholderVal = input.value

  if (!!addressList.length && input.value) {
    const record = addressList.find(el => el.id === parseInt(input.value))
    if (record) {
      placeholderVal = [record.address1 ? [record.address1].concat('') : '', record.address2 ? [record.address2].concat('') : '', getStateAbbr(states, record.state_id) + ',', record.zipcode].join(' ')
    }
  }

  if (!!addressList && !!addressList[0] && !!addressList[0].id) {
    addressList.unshift({
      id: false,
      label: 'Select An Address',
      value: ''
    })
  }

  return (
    <label className={`address_select_box_ip_holder_2 ${((touched && error) || !!errResp) ? 'error_border' : ''}`}>
      {<h1 className={`typographyV2 address_select_box_label ${(!!active || !!input.value) ? 'floating_label' : 'label'}`}>
        {(((!active && (visited || touched)) && error) || !!errResp) ? (error || errResp) : label}
      </h1>}
      {!!placeholderVal && <span className='typographyV2 input_field_text address_select_box_state_span'>{placeholderVal}</span>}
      <ReduxSelectBox
        input={input}
        selectBoxStyle={selectBoxStyle}
        options={addressList.map((option) => {
          const customLabel = option.id
            ? createAddressLine(states, option)
            : option.label
          return ({
            ...option,
            id: option.id,
            label: customLabel,
            value: option.id
          })
        })}
        handleOnChange={handleOnIdChange}
        handleOnFocus={handleOnFocus}
        handleOnBlur={handleOnBlur}
        errResp={errResp}
        isSearchable={false}
        onMenuScrollToBottom={() => fetchMore()}
        removeFieldError={removeFieldError}
      />
    </label>
  )
}

AddressSelectBox.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  handleOnIdChange: PropTypes.any,
  meta: PropTypes.any,
  handleOnFocus: PropTypes.func,
  handleOnBlur: PropTypes.func,
  errResp: PropTypes.any,
  removeFieldError: PropTypes.func,
  states: PropTypes.any,
  addressList: PropTypes.any,
  fetchMore: PropTypes.func
}

export default AddressSelectBox
