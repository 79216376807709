export const RECEIVE_SESSION_ERRORS = 'RECEIVE_SESSION_ERRORS'
export const RECEIVE_COUPON_ERROR = 'RECEIVE_COUPON_ERROR'
export const RECEIVE_CART_ERROR = 'RECEIVE_CART_ERROR'
export const RECEIVE_CREDIT_CARD_ERROR = 'RECEIVE_CREDIT_CARD_ERROR'
export const RECEIVE_SPLIT_CARD_ERROR = 'RECEIVE_SPLIT_CARD_ERROR'
export const RECEIVE_SHIP_ADDRESS_ERROR = 'RECEIVE_SHIP_ADDRESS_ERROR'
export const REMOVE_ALL_ERRORS = 'REMOVE_ALL_ERRORS'
export const REMOVE_COUPON_ERROR = 'REMOVE_COUPON_ERROR'
export const REMOVE_CREDIT_CARD_ERROR = 'REMOVE_CREDIT_CARD_ERROR'
export const REMOVE_SESSION_ERROR = 'REMOVE_SESSION_ERROR'
export const REMOVE_SHIP_ADDRESS_ERROR = 'REMOVE_SHIP_ADDRESS_ERROR'

export const receiveSessionErrors = error => ({
  type: RECEIVE_SESSION_ERRORS,
  error
})

export const receiveCartErrors = error => ({
  type: RECEIVE_CART_ERROR,
  error
})

export const receiveCouponError = error => ({
  type: RECEIVE_COUPON_ERROR,
  error
})

export const receiveCreditCardError = error => ({
  type: RECEIVE_CREDIT_CARD_ERROR,
  error
})

export const receiveSplitCardError = error => ({
  type: RECEIVE_SPLIT_CARD_ERROR,
  error
})

export const receiveShipAddressError = error => ({
  type: RECEIVE_SHIP_ADDRESS_ERROR,
  error
})

export const removeAllErrors = () => ({
  type: REMOVE_ALL_ERRORS
})

export const removeCouponError = () => ({
  type: REMOVE_COUPON_ERROR
})

export const removeCreditCardError = () => ({
  type: REMOVE_CREDIT_CARD_ERROR
})

export const removeSessionError = () => ({
  type: REMOVE_SESSION_ERROR
})

export const removeShipAddressError = () => ({
  type: REMOVE_SHIP_ADDRESS_ERROR
})
