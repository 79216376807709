import {
  LOGIN_AUTH_SUCCESS,
  LOGIN_AUTH_FALSE,
  UPDATE_USER_ERROR,
  REMOVE_UPDATE_USER_ERROR_MSG,
  UPDATE_USER_SUCCESS,
  SET_AUTH_RESP,
  SET_AUTO_OUT,
  HANDLE_UPDATE_USER_PROCESSING
} from '../actions/auth'

const defaultState = {
  user: {},
  errMsg: {},
  autoOut: false,
  isLoading: false,
  loginAuthSuccess: false
}

const Auth = (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_AUTH_SUCCESS:
    case LOGIN_AUTH_FALSE:
    case UPDATE_USER_SUCCESS:
    case UPDATE_USER_ERROR:
    case SET_AUTH_RESP:
    case SET_AUTO_OUT:
    case HANDLE_UPDATE_USER_PROCESSING:
      return {
        ...state,
        ...action.payload
      }
    case REMOVE_UPDATE_USER_ERROR_MSG: {
      const errMessages = state.errMsg
      if (!!errMessages && !!errMessages.otherError) {
        delete errMessages.otherError
      }
      if (!!action.fieldName && !!errMessages && !!errMessages[action.fieldName]) {
        delete errMessages[action.fieldName]
      }
      return {
        ...state,
        ...errMessages
      }
    }
    default:
      return state
  }
}

export default Auth
