import React, { useState } from 'react'
import styled from 'styled-components'
import { devices } from '../../../constant/devices'
import { colorCodes } from '../../../constant/colors'
import ResetPasswordReduxForm from './Form'
import { useDispatch, useSelector } from 'react-redux'
import { disabledOtherSection } from '../../../actions/global'

const Container = styled.div`
padding-top: 23px;
width: 493px;
@media ${devices.tablet} {
    width: 100%;
}
`

const ResetPasswordLabel = styled.div`
width: 153px;
cursor: pointer;
// font-weight: 700;
// font-size: 16px;
// line-height: 20px;
color: ${colorCodes.ORANGE_1};
`

const ResetPassword = () => {
  const dispatch = useDispatch()
  const isDisabled = useSelector(state => state.global.isDisabled)
  const [isEdit, setIsEdit] = useState(false)

  const scrollToMyPassword = () => {
    setTimeout(() => {
      const element = document.getElementsByClassName('eargo-my-password-container')
      !!element && element[0] && element[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
    }, 100)
  }

  return (
    <Container className="eargo-my-password-container" id="myPassword">
      {!isEdit
        ? <ResetPasswordLabel className={`typographyV2 body_bold ${isDisabled ? 'isDisabled' : ''}`}
          onClick={() => {
            dispatch(disabledOtherSection(true))
            scrollToMyPassword()
            setIsEdit(true)
          }}
        >Reset password</ResetPasswordLabel>
        : <ResetPasswordReduxForm onCancel={() => {
          setIsEdit(false)
          scrollToMyPassword()
        }} />
      }
    </Container>
  )
}

export default ResetPassword
