import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { EargoButtonV2, RoundRadioInput, AuthWrapper, HeaderSection, DotSpinLoading, CommonUnderline } from '@eargo/eargo-components'
import { fetchAuth, sendOtp } from '../../util/auth'
import { Field, reduxForm } from 'redux-form'
import { requiredField } from '../common/redux-form/validation'
import { maskedEmail, submitFailure } from '../../common/helpers'
import './index.scss'

const FORM_NAME = 'OTPChoice'

const radioRequire = requiredField('Contact')

const RadioButtonHolder = styled.div`
  margin-bottom: 32px;
    label.errl:after{
        background-color: ${({ theme: { color } }) => color.DARK_BLUE_3};
    }
    input.erri:checked ~ label.errl:before {
        border: ${({ theme: { color } }) => `2px Solid ${color.DARK_BLUE_3}`};
    }
`

const CodeRequest = ({ history }) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const email = history.location?.state?.email || history.location?.state?.detail?.data?.email
  const [OTPMethod, setOTPMethod] = useState('Email')
  const path = history.location?.state?.redirect_to
  useEffect(() => {
    window.scroll(0, 0)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    async function checkUser () {
      if (!email) {
        return history.push('/login')
      }
      const response = await fetchAuth()
      if (!!response && !!response.data && !!response.data.id) {
        return history.push('/')
      }
    }
    checkUser()
    // eslint-disable-next-line
  }, [])

  const sendOtpForLogin = async () => {
    try {
      setIsProcessing(true)
      const error = {}
      const resp = await sendOtp(email)
      if (!!resp && !!resp.success) {
        history.push({
          pathname: `/verify-login-otp/${resp.login_code_request_id}`,
          state: { redirect_to: path }
        })
        return resp
      } else {
        const errorBag = !!resp && !!resp.error && !!resp.message ? resp.message : (!!resp && !!resp.error ? resp.error : '')
        error.otherError = errorBag === 'Cannot find user' ? 'Something went wrong, Please try again.' : errorBag
        setIsProcessing(false)
        return false
      }
    } catch (error) {
      const errorBag = !!error.responseJSON && !!error.responseJSON.error
        ? error.responseJSON.error
        : (!!error.responseJSON && !!error.responseJSON[0] ? error.responseJSON[0] : 'Something went wrong, Please try again.')
      error.otherError = errorBag
      setIsProcessing(false)
      return false
    }
  }

  const disablePhone = OTPMethod === 'Phone'
  let RoundButtonGroup = ({ name, optionList, radioFunc, radioVar }) => (
    <>
    <div className="send_otp_option interFontBold">
      <Field name={name}
        validate={[radioRequire]}
        component={({ input, options, meta: { active, touched, visited, error } }) => (
          <>
          {((!active && (visited || touched) && error)) && <span className="errorSpan">&nbsp;{error}</span>}
            <div className="option_holder">
              {options.map((option, index) =>
                <RadioButtonHolder key={index} className="d-flex w-100">
                  {options.length > 1 && <RoundRadioInput
                    value={option.value}
                    label={option.label}
                    checked={option.value === radioVar}
                    handleOnClick={option.handleOnClick}
                    handleOnFocus={input.onFocus}
                    handleOnBlur={input.onBlur}
                    handleOnChange={e => {
                      input.onChange(e.target.value)
                      radioFunc(e.target.value)
                    }}
                  />}
                    <span className= "send_otp_value">{option.data}</span>
                </RadioButtonHolder>
              )}
            </div>
          </>
        )}
        options={optionList}
      />
    </div>
    </>
  )

  RoundButtonGroup.propTypes = {
    name: PropTypes.any,
    optionList: PropTypes.any,
    radioFunc: PropTypes.any,
    radioVar: PropTypes.any
  }

  RoundButtonGroup = reduxForm({
    form: FORM_NAME,
    onSubmitFail: () => submitFailure()
  })(RoundButtonGroup)

  return (
   <AuthWrapper className="main_container" >
      {!!isProcessing && <DotSpinLoading />}
      <HeaderSection heading='Code Request' label='We will send the code to the email we have on file. It may take up to 5 minutes to get the code' />
        <div>
        <RoundButtonGroup name="preferred_otp_method"
              optionList={[{ label: 'Email :', value: 'Email', data: email ? maskedEmail(email) : 'N/A' }]}
              radioFunc={setOTPMethod}
              radioVar = {OTPMethod}
          />
            <EargoButtonV2
              label= {isProcessing ? 'Loading...' : 'Send code'}
              disabled={disablePhone}
              onClick={sendOtpForLogin}
              className={'primary bold send_code_button'} />
        </div>
        <CommonUnderline />
        <div className='back_to_login_page interFontBold' onClick={() => history.push('/login')} >Back to login</div>
   </AuthWrapper>

  )
}

CodeRequest.propTypes = {
  history: PropTypes.any
}

export default withRouter(CodeRequest)
