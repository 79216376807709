import { HEADER_AEM_ENDPOINT, FOOTER_AEM_ENDPOINT, footerMockData, headerMockData } from '../constant/index.js'

export const SET_AEM_HEADER_DATA = 'SET_AEM_HEADER_DATA'
export const SET_AEM_FOOTER_DATA = 'SET_AEM_HEADE_FOOTER_DATA'

export const setHeaderJson = json => ({
  type: SET_AEM_HEADER_DATA,
  payload: {
    isLoading: false,
    headerData: json
  }
})

export const setFooterJson = json => ({
  type: SET_AEM_FOOTER_DATA,
  payload: {
    isLoading: false,
    footerData: json
  }
})

export const fetchFooterJson = () => async (dispatch) => {
  fetch(FOOTER_AEM_ENDPOINT)
    .then(response => response.json())
    .then(data => {
      data && data.model ? dispatch(setFooterJson(data.model)) : dispatch(setFooterJson(footerMockData))
    })
    .catch((error) => {
      console.log('Failure in fetching footer data', error)
      dispatch(setFooterJson(footerMockData))
    })
}

export const fetchHeaderJson = () => async (dispatch) => {
  fetch(HEADER_AEM_ENDPOINT)
    .then(response => response.json())
    .then(data => {
      data && data.model ? dispatch(setHeaderJson(data.model)) : dispatch(setHeaderJson(headerMockData))
    })
    .catch((error) => {
      console.log('Failure in fetching header data', error)
      dispatch(setHeaderJson(headerMockData))
    })
}
