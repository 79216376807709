import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import './device-video-content.scss'
import LazyImage from '../../common/LazyImage'
import PlayButton from '../../common/PlayButton'
import SupportHeader from './SupportHeader/SupportHeader'
import { addressUrl, supportVideoHeading, supportVideoSubHeading, newGuidesLinks } from '../../../constant'
import usePrevious from '../../../common/usePrevious'
import { EargoButtonV2 } from '@eargo/eargo-components'

const DeviceVideoContent = (props) => {
  const [key, setKey] = useState([])
  const { content, product_class } = props.deviceContent
  const { deviceContent } = props
  const prevLocation = usePrevious(props.location)

  useEffect(() => {
    if (deviceContent) {
      if (!!content && content.length > 0) {
        if (product_class === 'mobile_app') {
          setKey([product_class + 0, product_class + 1])
        } else {
          setKey([product_class + 0])
        }
      }
    }
  }, [])

  useEffect(() => {
    const { location: { hash, pathname } } = window
    if ((!!prevLocation && prevLocation.pathname !== pathname) ||
      (!!prevLocation && prevLocation.hash !== hash)) {
      const element = !!hash && document.querySelector(hash)
      element ? element.scrollIntoView() : window.scrollTo(0, 0)
    }
  }, [window])

  const removeElement = elementIds => {
    if (!!elementIds && elementIds.length > 0) {
      elementIds.map(elementId => {
        const element = document.getElementById(elementId)
        return !!element && element.parentNode.removeChild(element)
      })
    }
  }

  const closeVideo = () => removeElement(['close-btn', 'dark-overlay', 'testimonial-modal-movie'])

  const showVideo = source => {
    const darkOverlay = document.createElement('div')
    darkOverlay.onclick = () => closeVideo()
    darkOverlay.id = 'dark-overlay'

    const closeBtn = document.createElement('div')
    closeBtn.className = 'close-btn'
    closeBtn.id = 'close-btn'
    const cross = document.createTextNode('X')
    closeBtn.appendChild(cross)
    closeBtn.onclick = () => closeVideo()

    document.body.appendChild(darkOverlay)
    document.body.appendChild(closeBtn)

    const sourceTag = document.createElement('iframe')
    sourceTag.src = source
    sourceTag.id = 'testimonial-modal-movie'
    sourceTag.width = '640'
    sourceTag.height = '360'
    sourceTag.setAttribute('frameborder', '0')
    sourceTag.setAttribute('allow', 'autoplay')
    sourceTag.setAttribute('allowFullScreen', '')
    sourceTag.setAttribute('webkitAllowFullscreen', '')
    sourceTag.setAttribute('fullscreen', '')
    document.body.appendChild(sourceTag)
  }

  const videoItem = (data, index, product) => {
    const { video, image, img_alt, title } = data
    return (<li className={`video_item ${product === 'eargo_neo' ? 'neo' : ''}`} key={index + Math.random()}
    >
      <div className="video_thumbnail"
        onClick={() => showVideo(video)}>
        <LazyImage src={image} alt={img_alt} className="thumbnail_img" />
        <PlayButton />
      </div>
      <div className="typographyV2 body_bold video-title">{title}</div>
    </li>)
  }

  const contentBody = (type, props, open, product) => {
    const { info, class3 } = props
    switch (type) {
      default: {
        return (
          <div className='video-info-holder'>
            <ul id={open} className={class3}>
              {!!info && info.length > 0 &&
                info.map((key, index) => videoItem(key, index, product))}
            </ul>
          </div>
        )
      }
    }
  }
  const handleOnClick = () => {
    const link = newGuidesLinks[product_class] ? newGuidesLinks[product_class] : '/guides'
    window.location.href = `${addressUrl}${link}`
  }

  return (
    <div className='device_video_content'>
      <div id='selected' className={`guides_section ${product_class}`}>
        {<SupportHeader heading={supportVideoHeading} subHeading={supportVideoSubHeading} isBorderBottom={true} Products={product_class} />}
        {
          !!content && content.length > 0 && content.map((contentProps, i) => {
            const { type, class1 } = contentProps
            const listIndex = product_class + i
            const index = key.indexOf(listIndex)
            const open = index < 0 ? 'closed' : 'open'
            return <section id={index === 0 ? 'video_guides' : ''} className={`${class1} ${type === 'video' ? product_class : ''}`} key={i}>
              {contentBody(type, contentProps, open, product_class)}
            </section>
          })
        }
        {
          <div className='eargo_buttonV2'>
            <EargoButtonV2
              label='View all guides'
              className='primary'
              id='eargo-acc-wax-add-to-cart'
              handleOnClick={() => handleOnClick()}
            />
          </div>
        }
      </div>
    </div>
  )
}

DeviceVideoContent.propTypes = {
  videosContent: PropTypes.any,
  deviceContent: PropTypes.any,
  location: PropTypes.object,
  info: PropTypes.object,
  class3: PropTypes.string,
  Products: PropTypes.any,
  productCount: PropTypes.string,
  totalProductCount: PropTypes.number,
  handleOnChange: PropTypes.func,
  key: PropTypes.number
}

export default withRouter(DeviceVideoContent)
