import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import CreateSupportRequest from './CreateSupportRequest'
import { DotSpinLoading } from '@eargo/eargo-components'

import SupportNotFound from './SupportNotFound'
import SupportRequestLayout from './SupportRequestLayout'
import './SupportRequest.scss'

const SupportRequest = ({ handleLoadMoreTickets, notFound }) => {
  let [caseDetail, isLoading, isAllowed, total] = useSelector((state) => [
    state.support.caseDetail,
    state.support.isLoading,
    state.support.isAllowed,
    state.support.total
  ])

  if (isLoading) {
    caseDetail = []
  }
  return (
    <>
      {notFound
        ? <SupportNotFound />
        : <div className='support-request-container' data-testid='support-request-list' >
          {!!isLoading && <DotSpinLoading />}
          <CreateSupportRequest isAllowed={isAllowed} />
          {!!isAllowed && !!caseDetail && caseDetail.length > 0 && <>
            <div className='support-request-line'></div>
            <h6 className='typographyV2 support-req'>Support Requests</h6>
          </>
          }
          {!!isLoading && <footer className='typographyV2 body support-request-footer'>Loading...</footer>}
          {/* {!isLoading && !isAllowed && <HelpText>You havn&apos;t placed any order yet.</HelpText>} */}
          {!!isAllowed && !!caseDetail && caseDetail.length > 0 && <SupportRequestLayout caseDetail={caseDetail} />}
          {
            !!isAllowed && !!caseDetail && caseDetail.length > 0 &&
            <h7 className = {`typographyV2 more-tickets ${total <= caseDetail.length ? 'no-more-tickets' : ''}`} onClick={() => (total > caseDetail.length) && handleLoadMoreTickets()}>
              {total > caseDetail.length ? 'Load older tickets' : 'No more tickets'}
            </h7>
          }
        </div>
      }
    </>
  )
}

SupportRequest.propTypes = {
  handleLoadMoreTickets: PropTypes.func,
  notFound: PropTypes.any
}

export default SupportRequest
