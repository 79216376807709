import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { EargoButtonV2, DotSpinLoading } from '@eargo/eargo-components'

import CommanHeading from '../CommanHeading'
import AddNewAddress from '../../order_checkout_forms/AuthAddress/index'
import DeletePopup from './../DeletePopup'
import RenderAddresses from './RenderAddresses'
import { addressSectionError, deleteAddressInfo, fetchAddresses } from '../../../actions/address'
import { setAddressPopUp } from '../../../actions/user_address'
import usePrevious from '../../../common/usePrevious'
import './index.scss'

const ExpandArrow = styled.img`
  width: 8px;
  margin-left: 10px;
  transform: rotate(270deg);
  display: inline-block;
  ${props => props.showAll && `
  transform: rotate(90deg);
  `}
`

const AddressSection = ({ anchorClassName }) => {
  const dispatch = useDispatch()
  const [showAllAddress, setShowAllAddress] = useState(false)
  const [editAddress, setEditAddress] = useState(false)
  const [addAddress, setAddAddress] = useState(false)
  const [deleteAddress, setDeleteAddress] = useState(false)
  const [checkIsPopUp, setCheckIsPopUp] = useState(false)
  const [editId, setEditId] = useState(false)
  const [addressCount, setAddressCount] = useState(0)
  const autoOut = useSelector(state => state.auth.autoOut)
  const isLoading = useSelector(state => state.address.isLoading)
  const errMsg = useSelector(state => state.address.errMsg)
  const addresses = useSelector(state => state.address.addresses)
  const isDisabled = useSelector(state => state.global.isDisabled)
  const prevAutoOut = usePrevious(autoOut)
  // This variable is use to set initial address When user come on my info page
  const defaultAddressMethod = !!addresses && addresses.filter(obj => !!obj.default)

  // This useEffect is fetch Addresses on mount component and get all Addresses through useSelector
  // Remove address section error on unmount component
  useEffect(() => {
    dispatch(fetchAddresses())
    return () => {
      dispatch(addressSectionError({}))
    }
  }, [])
  useEffect(() => {
    setAddressCount(addresses.length)
  }, [addresses])
  // Set address id and Make editable to user
  useEffect(() => {
    if (!editAddress && !!editId) {
      setEditAddress(editId)
      setEditId(false)
    }
  }, [editId])
  // Check user session is end or not where autoOut and prevAutoOut give a bool value to check
  useEffect(() => {
    if ((prevAutoOut !== autoOut) && !!autoOut) {
      setAddAddress(false)
      setDeleteAddress(false)
    }
  }, [prevAutoOut, autoOut])

  // This useEffect is use to edit Address according to address id
  const setAnotherEdit = (id) => {
    if (editAddress) {
      setEditAddress(false)
      setEditId(id)
    } else {
      setEditAddress(id)
    }
  }
  // Below function is use to add new Address When user fill all fields in PopUp view form
  const addedSuccessfully = (fetchAddress = false) => {
    setAddAddress(false)
    !!fetchAddress && dispatch(fetchAddresses())
  }
  const deleteUserAddressInfo = async (id) => {
    const resp = await dispatch(deleteAddressInfo(id))
    !!resp && setDeleteAddress(false)
  }
  const removeError = () => {
    dispatch(addressSectionError({}))
    setDeleteAddress(false)
  }
  const ShowAndEditAddress = () => {
    setShowAllAddress(!showAllAddress)
    setEditAddress(false)
  }
  const closeEditAndAddNewAddress = () => {
    dispatch(setAddressPopUp(true))
    setCheckIsPopUp(true)
    setEditAddress(false)
  }
  // This is use to enable to PopUp form (Add New Address) after clicked on (Add New Address) Button
  useEffect(() => {
    if (checkIsPopUp) {
      setAddAddress(true)
      setCheckIsPopUp(false)
    }
  }, [checkIsPopUp])

  if ((!addressCount && !!isLoading)) return <DotSpinLoading />
  return (
    <Fragment>
      <div className='address_section_container' id={anchorClassName}>
        {(!editAddress && !!isLoading) && <DotSpinLoading />}
        <div className='address_section_header_container'>
          <CommanHeading heading='Address' />
          {!!addressCount && (addressCount > 1) && <div className={`address_section_show_section ${isDisabled ? 'isDisabled' : ''} ${editAddress ? 'isActive' : ''}`} onClick={() => !editAddress && ShowAndEditAddress()}>
            {showAllAddress
              ? <label className={`typographyV2 caption address_section_show_label ${isDisabled ? 'isDisabled' : ''} ${editAddress ? 'isActive' : ''}`}>Show less  <ExpandArrow showAll={showAllAddress} src='https://assets.eargo.com/new_account/arrow_back.png' /></label>
              : (!!addressCount && <label className={`typographyV2 caption address_section_show_label ${isDisabled ? 'isDisabled' : ''} ${editAddress ? 'isActive' : ''}`}>Show all ({addressCount})  <ExpandArrow showAll={showAllAddress} src='https://assets.eargo.com/new_account/arrow_back.png' /></label>)
            }
          </div>}
        </div>
        <div className='address_details_section_container'>
          <div id='eargo-my-acc-address-container'>
            {addressCount
              ? <RenderAddresses
              showAllAddress={showAllAddress}
              showAddresses={ !showAllAddress ? (!!defaultAddressMethod && !!defaultAddressMethod[0] ? [defaultAddressMethod[0]] : [addresses[0]]) : addresses }
              setAnotherEdit={setAnotherEdit}
              setDeleteAddress={setDeleteAddress}
              setEditId={setEditId}
              editAddress={editAddress}
              setEditAddress={setEditAddress}
              />
              : <div className='typographyV2 body address_details_no_info'>You don&#39;t have any shipping addresses yet</div>
            }
            {
              !!addresses.length &&
              !addresses.some(a => !!a.default) &&
              <div className='typographyV2 err_regular address_details_warning_message'>Please select an address as the Default.</div>}
            <div className='address_details_add_button_section'>
              <EargoButtonV2
                disabled={isDisabled}
                className='primary'
                label="Add New Address"
                handleOnClick={closeEditAndAddNewAddress} />
            </div>
          </div>
          {addAddress ? <AddNewAddress handleClose={addedSuccessfully} /> : ''}
          {!!deleteAddress && <DeletePopup
            isLoading={isLoading}
            errMsg={(!!errMsg && !!errMsg.otherError) ? errMsg.otherError : false}
            onCancel={removeError}
            onDelete={() => deleteUserAddressInfo(deleteAddress)}
            title="Delete Address?"
            subTitle="Are you sure you want to delete this address? This action cannot be reverted."
          />}
        </div>
      </div>
    </Fragment>
  )
}

AddressSection.propTypes = {
  anchorClassName: PropTypes.string
}
export default React.memo(AddressSection)
