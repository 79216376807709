import { handleAjax } from '../common/helpers'

export const signup = user => handleAjax('POST', 'api/signup', user)

export const login = user => handleAjax('POST', 'api/login', user, false, false)

export const inlineLogin = user => handleAjax('POST', 'api/login', user)

export const logout = () => handleAjax('POST', 'api/logout')

export const update = user => handleAjax('PATCH', 'api/users', user)

export const clearCache = () => handleAjax('GET', 'api/clear')
