import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { devices } from '../constant/devices'
import { colorCodes } from '../constant/colors'

import App from './app'

const themeObj = {
  media: devices,
  color: colorCodes
}

const Root = ({ store }) => (
  <Provider store={store}>
    <ThemeProvider theme={themeObj}>
      <App store={store} />
    </ThemeProvider>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.any
}

export default Root
