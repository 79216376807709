import {
  HANDLE_UA_PROCESSING,
  FETCH_ADDRESSES_SUCCESS,
  FETCH_ADDRESSES_ERROR,
  SET_ADDRESS_DEFAULT_ID,
  REMOVE_ADDRESS_ERROR_MSG,
  SET_SAVED_ADDRESS_ID,
  SET_ADDRESS_LIST_POPUP,
  SET_ADDRESS_POPUP,
  FETCH_STATES_SUCCESS,
  ADD_USER_ADDRESS_SUCCESS,
  SET_SAVED_BILL_ADDRESS_ID
} from '../actions/user_address'

const defaultState = {
  savedAddressId: null,
  savedBillAddressId: false,
  addressListPopUp: false,
  addressPopUp: false,
  isLoading: false,
  addresses: [],
  states: [],
  errMsg: {}
}

const UserAddress = (state = defaultState, action) => {
  switch (action.type) {
    case HANDLE_UA_PROCESSING:
    case SET_ADDRESS_LIST_POPUP:
    case FETCH_ADDRESSES_SUCCESS:
    case FETCH_ADDRESSES_ERROR:
    case SET_SAVED_ADDRESS_ID:
    case SET_SAVED_BILL_ADDRESS_ID:
    case SET_ADDRESS_POPUP:
    case FETCH_STATES_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }
    case ADD_USER_ADDRESS_SUCCESS: {
      const addresses = state.addresses
      if (action.address) {
        addresses.push(action.address)
      }
      return {
        ...state,
        ...addresses
      }
    }
    case REMOVE_ADDRESS_ERROR_MSG: {
      const errMessages = state.errMsg
      if (!!errMessages && !!errMessages.otherError) {
        delete errMessages.otherError
      }
      if (!!action.fieldName && !!errMessages && !!errMessages[action.fieldName]) {
        delete errMessages[action.fieldName]
      }
      return {
        ...state,
        ...errMessages
      }
    }
    case SET_ADDRESS_DEFAULT_ID: {
      const addressesArray = state.addresses
      const updatedAddresses = !!addressesArray && addressesArray.length > 0 && addressesArray.map(value => value.id === action.defaultId ? { ...value, default: true } : { ...value, default: false })
      return {
        ...state,
        addresses: updatedAddresses
      }
    }
    default: {
      return state
    }
  }
}

export default UserAddress
