import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { EargoButtonV2 } from '@eargo/eargo-components'

import './index.scss'
import { uploadDocuments } from '../../../../actions/auth'
import PieSpinLoader from '../../../common/PieSpinLoader'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserDocuments } from '../../../../actions/documents'
import SplitErrorContainer from '../../../order_checkout_forms/SplitErrorContainer'

const allowedExtensions = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'image/gif']

function DocumentCard ({ heading, subHeading, id, documentsList }) {
  let isRequired = false
  if (id === 'AudiogramHearingTestDoc') {
    isRequired = true
  }
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [formData, setFormData] = useState()
  const [downloadProgress, setDownloadProgress] = useState('')
  const uploadInProgress = useSelector(state => state.documents.uploadInProgress)

  const clickHandler = () => {
    const browseButton = document.getElementById('browse_button_main')
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = allowedExtensions.join(', ')
    input.style.display = 'none'
    input.onchange = _ => {
      const files = Array.from(input.files)
      setFormData(files[0])
    }
    browseButton.appendChild(input)
    input.click()
  }

  const onDragEnter = (e) => {
    e.preventDefault()
  }

  const onDrop = (e) => {
    e.preventDefault()
    if (uploadInProgress) {
      setError(true)
      setErrorMessage('Upload in progress, please wait')
    } else {
      setFormData(e.dataTransfer.files[0])
    }
  }

  const onDragOver = (e) => {
    e.preventDefault()
  }

  const onDragLeave = (e) => {
    e.preventDefault()
  }

  const handleOnClick = () => {
    setError(false)
    setErrorMessage('')
  }

  useEffect(() => {
    (async () => {
      if (formData) {
        const sizeBytes = formData?.size
        if (sizeBytes >= 10485760) {
          setError(true)
          setErrorMessage('File is too large (Max 10MB)')
          return
        } else if (!allowedExtensions.includes(formData?.type)) {
          setError(true)
          setErrorMessage('Unsupported file type')
          return
        } else {
          setError(false)
          setErrorMessage('')
        }
        const totalSize = Math.round((sizeBytes) / 1000, 1)
        setDownloadProgress('0kb / ' + totalSize + 'kb')
        setLoading(true)
        const info = new FormData()
        info.set('image', formData)
        info.set('type', id)
        const res = await uploadDocuments(info, setDownloadProgress, dispatch)
        setLoading(false)
        if (res.success) {
          setUploaded(true)
          setTimeout(() => {
            dispatch(updateUserDocuments(documentsList, id))
            setUploaded(false)
          }, 2000)
        } else {
          setError(true)
          if (res.error) {
            setErrorMessage(res.error)
          } else {
            setErrorMessage('Oops! Something went wrong. Please refresh the page and try uploading your document again.')
          }
        }
      }
    })()
  }, [formData])

  const showIcon = (loading) => {
    if (loading) {
      return (
        <div className='loader_container'>
          <PieSpinLoader />
          <div className='image_name_holder interFontRegular'>{formData?.name}</div>
          <div className='image_size_holder interFontRegular'>{downloadProgress}</div>
        </div>
      )
    } else if (uploaded) {
      return (
        <div>
          <img src='https://assets.eargo.com/account/insuranceform/success.png' className='success_image' />
          <div className='image_name_holder interFontRegular'>{formData?.name}</div>
          <div className='image_size_holder interFontRegular'>{downloadProgress}</div>
        </div>
      )
    }
    return (
      <>
        <div className='box_main' onDragEnter={onDragEnter} onDrop={onDrop} onDragLeave={onDragLeave} onDragOver={onDragOver}>
          <img src='https://assets.eargo.com/account/insuranceform/Upload_Icon.png' className="upload-icon" />
          <div className='upload-description'>Drag and Drop Files</div>
        </div>
        <div className='normal-text'>or</div>
        <div id="browse_button_main" className='button'>
          <EargoButtonV2
            label="Browse"
            type="button"
            disabled={uploadInProgress}
            handleOnClick={clickHandler}
            className='ghost black browse_button small interFontBold' />
        </div>
      </>
    )
  }

  return (
      <div className='main_document_container'>
          { error && <div className='sizeError'>
              <SplitErrorContainer errHeader={errorMessage} isError={false} uploadError= {true} handleClick = {handleOnClick}/>
            </div>
          }
          <div className= 'box_container' >
              {
                isRequired
                  ? <div className= {`full_width ${error && 'box_blur'}`}>
                    <div className= 'required'>*Required</div>
                    <div className='header'>{heading}</div>
                    <div className='sub-header'>{subHeading}</div>
                    {showIcon(loading)}
                  </div>
                  : <div className= {`normal_width ${error && 'box_blur'}`}>
                    <div className='header'>{heading}</div>
                    <div className='sub-header'>{subHeading}</div>
                    {showIcon(loading)}
                  </div>
              }
        </div>
      </div>
  )
}

DocumentCard.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  id: PropTypes.string,
  documentsList: PropTypes.array
}

export default DocumentCard
