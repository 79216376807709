import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { parse } from 'query-string'
import { DotSpinLoading } from '@eargo/eargo-components'
import { colorCodes } from '../../constant/colors'
import { BASE_URL } from '../../constant'

const { BLACK, WHITE } = colorCodes

const Container = styled.div`
height: auto;
padding: 6px 0;
background-color: ${WHITE};
.edsllc {
    position: unset !important;
}
`

const Title = styled.div`
left: 0px;
top: 131px;
font-size: 36px;
line-height: 60px;
text-align: center;
color: ${BLACK};
`

const Description = styled.div`
font-size: 24px;
line-height: 36px;
text-align: center;
color: ${BLACK};
`

const CompletedMessage = ({ location, history }) => {
  const queryParams = parse(location.search)
  React.useEffect(() => {
    setTimeout(() => (window.location.href = !!queryParams && !!queryParams.redirect_uri
      ? queryParams.redirect_uri
      : BASE_URL), 2000)

    return () => {
      if (!!location && !!location.state &&
        ((!!location.state.title) || (!!location.state.desc))) {
        const state = { ...location.state }
        delete state.title
        delete state.desc
        history.replace({ ...location, state })
        window.location.href = !!queryParams && !!queryParams.redirect_uri
          ? queryParams.redirect_uri
          : BASE_URL
      }
    }
  }, [history, location, queryParams])
  const { state } = location
  return (
    <Container>
      {!!state && !!state.title && <Title className="bold">{state.title}</Title>}
      {!!state && !!state.desc && <Description className="bold">{state.desc}</Description>}
      <DotSpinLoading bgEffect={'transparent'} />
    </Container>
  )
}

CompletedMessage.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any
}

export default withRouter(CompletedMessage)
