import * as SessionApiUtil from '../util/session_api_util'
import { receiveSessionErrors } from './error_actions'

export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER'

export const receiveCurrentUser = currentUser => ({
  type: RECEIVE_CURRENT_USER,
  currentUser
})

export const signup = user => dispatch => (
  SessionApiUtil.signup(user).then(res => dispatch(receiveCurrentUser(res)),
    err => dispatch(receiveSessionErrors(err.responseJSON)))
)

export const login = user => dispatch => (
  SessionApiUtil.login(user).then(res => dispatch(receiveCurrentUser(res)),
    err => dispatch(receiveSessionErrors(err.responseJSON)))
)

export const inlineLogin = user => dispatch => (
  SessionApiUtil.inlineLogin(user).then(res => dispatch(receiveCurrentUser(res)),
    err => dispatch(receiveSessionErrors(err.responseJSON)))
)

export const logout = () => dispatch => (
  SessionApiUtil.logout().then(res => dispatch(receiveCurrentUser(null)),
    err => dispatch(receiveSessionErrors(err.responseJSON)))
)

export const updateUser = user => dispatch => (
  SessionApiUtil.update(user).then(res => dispatch(receiveCurrentUser(res)),
    err => dispatch(receiveSessionErrors(err.responseJSON)))
)

export const clearCache = () => (
  SessionApiUtil.clearCache()
)
