import React, { useEffect, useState } from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { EargoButtonV2 } from '@eargo/eargo-components'

import CyberSourceForm from './CyberSourceForm'
import { devices } from '../../constant/devices'
import { colorCodes } from '../../constant/colors'
import CommonAddressField from './CommonAddressField'
import ReduxSelectBox from '../common/redux-field/ReduxSelectBox'
import { createAddressLine, getStateAbbr } from '../../common/helpers'
import ReduxPrimaryInput from '../common/redux-field/ReduxPrimaryInput'
import { getPaymentMethods } from '../../actions/payment_methods_actions'
import { ReduxCheckboxInput } from '../common/redux-field/ReduxCheckboxInput'
import { CardFormContext, CardFormProvider } from '../order_checkout_forms/CardFormContext'
import { paymentsError, removeFieldError, generateCyberSourceKey } from '../../actions/user_payment'
import { minLength, noOnlyNumber, onlyOneSpecialCharDash, requiredField, whiteSpace, doubleSpace } from '../common/redux-field/validation'
import './CardForm.scss'

const { mobile } = devices
const { GREY_6, WHITE, GREY_10, ORANGE_5, BRIGHT_GREY } = colorCodes
const minChar4 = minLength(4, 'Too Short')
const fullNameRequire = requiredField('Name')
const selectBoxStyle = {
  container: (provided, { isDisabled }) => ({
    ...provided,
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    width: '100%',
    height: '100%',
    background: isDisabled ? BRIGHT_GREY : 'transparent'
  }),
  indicatorsContainer: () => ({
    marginRight: 7
  }),
  dropdownIndicator: () => ({
    color: ORANGE_5
  }),
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    color: colorCodes.GREY_1
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 18,
    overflow: 'visible'
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'none'
  }),
  menu: () => ({
    flexDirection: 'column',
    maxHeight: 300,
    width: '100%',
    zIndex: 2,
    overflowY: 'scroll',
    position: 'absolute',
    top: 75,
    left: 0,
    background: WHITE,
    border: `1px solid ${GREY_6}`,
    borderRadius: 3,
    [`@media ${mobile}`]: {
      top: 60
    }
  }),
  menuList: () => ({
    maxHeight: 300,
    overflowY: 'auto',
    position: 'relative'
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    cursor: 'pointer',
    paddingTop: 8,
    background: (isFocused && !isSelected) && GREY_10,
    paddingBottom: 8,
    paddingLeft: 24,
    fontSize: '1.125rem',
    fontFamily: 'Inter, sans-serif'
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'none'
  })
}

const handleFullName = value => {
  const fullName = value ? value.split(' ') : false
  const lastName = fullName ? (value).substring(fullName[0].length).trim() : ''
  return lastName && (/^\d+$/.test(lastName) || !(/[a-zA-Z]/.test(lastName))) ? 'Only Numbers not allowed' : undefined
}

const AddressSelectBox = ({
  input, label, handleOnIdChange, meta: { active, visited, touched, error },
  handleOnFocus, handleOnBlur, errResp, removeFieldError, states, addressList
}) => {
  let placeholderVal = input.value

  if (addressList.length && input.value) {
    const record = addressList.find(el => el.id === parseInt(input.value))
    if (record) {
      placeholderVal = [record.address1 ? [record.address1].concat('') : '', record.address2 ? [record.address2].concat('') : '', getStateAbbr(states, record.state_id) + ',', record.zipcode].join(' ')
    }
  }

  const newAddressList = [{
    id: false,
    label: 'Select An Address',
    value: ''
  }, ...addressList]
  return (
    <label className={`${((touched && error) || !!errResp) ? 'error_border' : ''} address_box_container`}>
      {<h1 className={`typographyV2 ${(!!active || !!input.value) ? 'floating_label' : 'label'} select_box_label`}>
        {(((!active && (visited || touched)) && error) || !!errResp) ? (error || errResp) : label}
      </h1>}
      {!!placeholderVal && <span className='input_field_text typographyV2 state_span'>{placeholderVal}</span>}
      <ReduxSelectBox
        input={input}
        selectBoxStyle={selectBoxStyle}
        options={newAddressList.map((option) => {
          const customLabel = option.id
            ? createAddressLine(states, option)
            : option.label
          return ({
            ...option,
            id: option.id,
            label: customLabel,
            value: option.id
          })
        })}
        handleOnChange={handleOnIdChange}
        handleOnFocus={handleOnFocus}
        handleOnBlur={handleOnBlur}
        errResp={errResp}
        isSearchable={false}
        removeFieldError={removeFieldError}
      />
    </label>
  )
}

AddressSelectBox.propTypes = {
  input: PropTypes.any,
  label: PropTypes.any,
  handleOnIdChange: PropTypes.any,
  meta: PropTypes.object,
  handleOnFocus: PropTypes.any,
  handleOnBlur: PropTypes.any,
  errResp: PropTypes.any,
  removeFieldError: PropTypes.func,
  states: PropTypes.any,
  addressList: PropTypes.any
}

const ElementWrapperLabel = styled.label`
&.error_border span {
    font-size: 14px !important;
}
`

const ElementWrapper = ({ label, children, labelClass, isValue }) => (
  <ElementWrapperLabel className={`card-containers inputContainer cardholder-name padding-left-0 ${labelClass}`}>
    <h1 className={`typographyV2 ${isValue ? 'floating_label padding-left-24' : 'label'}`}>
      {label}
    </h1>
    {children}
  </ElementWrapperLabel>
)

ElementWrapper.propTypes = {
  label: PropTypes.any,
  children: PropTypes.any,
  labelClass: PropTypes.string,
  isValue: PropTypes.any
}

const CardFormData = ({
  formId = '', onSubmit, isDisable = false,
  handleMicroformIns, microform, expiration, setCardBrand, change,
  handleOnIdChange, initialValues = {}, cardState,
  setCardState
}) => {
  const dispatch = useDispatch()
  const isCBS = useSelector(state => state.global.isCBS || state.myInfoPaymentMethod.isCBS)
  const errMsg = useSelector(state => state.userPaymentMethods.errMsg.otherError ? state.userPaymentMethods.errMsg : state.myInfoPaymentMethod.errMsg)
  const { states, addresses } = useSelector(state => state.userAddresses)

  useEffect(() => {
    dispatch(getPaymentMethods())
    setCardState({
      ...cardState,
      numberEmpty: true,
      expEmpty: true,
      cvcEmpty: true
    })

    // eslint-disable-next-line
  }, [])

  const [cardCode, setCardCode] = useState(false)

  useEffect(() => {
    paymentsError()
    setCardState(prevState => {
      return ({
        ...prevState,
        numberFocus: false,
        numberError: false,
        numberMessage: true,
        expFocus: false,
        expError: false,
        expMessage: true,
        cvcFocus: false,
        cvcError: false,
        cvcMessage: true
      })
    })

    // eslint-disable-next-line
  }, [paymentsError])

  const handleOnFocus = e => {
    const fieldName = e.target.name
    paymentsError() // HINT: Added Temporary
    if (errMsg && errMsg.otherError) dispatch(removeFieldError('otherError'))
    if (errMsg && errMsg[fieldName]) dispatch(removeFieldError(fieldName))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!!isCBS && !!microform) {
      const { fields: { number, securityCode } } = microform
      if (!number || !securityCode) {
        return false
      }

      const expirationVal = !!expiration && expiration.split('/')
      microform.createToken({
        expirationMonth: expirationVal[0],
        expirationYear: `20${expirationVal[1]}`,
        type: cardCode
      }, async function (err, token) {
        if (err) {
          onSubmit()
          console.log('check Error ', err)
          if (!!err && !!err.reason && (err.reason === 'CREATE_TOKEN_VALIDATION_SERVERSIDE')) {
            handleMicroformIns(false)
            dispatch(generateCyberSourceKey())
            paymentsError({
              ...errMsg,
              otherError: 'Card session time out, Please enter again.'
            })
          }

          if (!!err && !!err.reason && (
            (err.reason === 'CREATE_TOKEN_UNABLE_TO_START') ||
            (err.reason === 'FIELD_LOAD_CONTAINER_SELECTOR') ||
            (err.reason === 'CREATE_TOKEN_CAPTURE_CONTEXT_USED_TOO_MANY_TIMES'))) {
            // TODO: I'll update it
            handleMicroformIns(false)
            dispatch(generateCyberSourceKey())
            paymentsError({
              ...errMsg,
              otherError: err.message
            })
          }
          if (!!err && !!err.details && !!err.details.length) {
            (err.details).map(({ location, message }) => {
              if (!!location && location === 'number') {
                const numberMsgVal = ((message === 'Validation error') ||
                  (message === 'Invalid card number format'))
                  ? 'is invalid'
                  : message
                setCardState((prevState) => {
                  return ({
                    ...prevState,
                    [location + 'Error']: true,
                    [location + 'Message']: numberMsgVal
                  })
                })
              }

              if (!!location && ((location === 'expirationMonth') ||
                (location === 'expirationYear') ||
                (location === 'captureData.expirationYear') ||
                (location === 'captureData.expirationMonth'))) {
                setCardState((prevState) => {
                  return ({
                    ...prevState,
                    expError: true,
                    expMessage: message
                  })
                })
              }

              if (!!location && location === 'securityCode') {
                const cvcMsgVal = (message === 'Validation error') ? 'is invalid' : message
                setCardState((prevState) => {
                  return ({
                    ...prevState,
                    cvcError: true,
                    cvcMessage: cvcMsgVal
                  })
                })
              }
              return true
            })
          }
          return false
        } else {
          change('transient_token', token)
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(onSubmit()), 500))
          return true
        }
      })
    }
  }

  return (
    <form onSubmit={handleSubmit} id={formId} className='card_form'>
      <div className='card_form_container' >
        <Field
          id="name"
          name="name"
          label="Name"
          errResp={!!errMsg && !!errMsg.name ? errMsg.name : false}
          autoComplete={false}
          capitalize={true}
          validate={[fullNameRequire, doubleSpace, onlyOneSpecialCharDash, noOnlyNumber, handleFullName, whiteSpace, minChar4]}
          component={ReduxPrimaryInput}
          handleOnFocus={handleOnFocus}
        />
        <Field
          id="address_id"
          name="address_id"
          label="Choose an Address (optional)"
          addressList={addresses}
          states={states}
          component={AddressSelectBox}
          handleOnIdChange={handleOnIdChange}
          removeFieldError={removeFieldError}
          errResp={!!errMsg && !!errMsg.address_id ? errMsg.address_id : false}
        />
        <CommonAddressField
          errMsg={errMsg}
          states={states}
          handleOnFocus={handleOnFocus} />
        <CyberSourceForm
            setCardState={setCardState}
            cardState={cardState}
            setCardBrand={setCardBrand}
            setCardCode={setCardCode}
            handleMicroformIns={handleMicroformIns}
            initialValues={initialValues} />
      </div>
      <div className='check_box_holder'>
        <Field
          name="default"
          label="Make default payment"
          component={ReduxCheckboxInput}
        />
      </div>
      {errMsg && errMsg.otherError && <div className='err_regular typographyV2 error_message'>{errMsg.otherError}</div>}
      <div className='button_holder'>
        <EargoButtonV2 label={isDisable ? 'Loading...' : 'Save'}
          disabled={!!isDisable} className="primary stretch" type="submit" />
      </div>
    </form>
  )
}

CardFormData.propTypes = {
  formId: PropTypes.string,
  addresses: PropTypes.any,
  errMsg: PropTypes.any,
  onSubmit: PropTypes.func,
  isDisable: PropTypes.bool,
  isCBS: PropTypes.any,
  getPaymentMethods: PropTypes.func,
  handleMicroformIns: PropTypes.func,
  microform: PropTypes.any,
  expiration: PropTypes.any,
  setCardBrand: PropTypes.any,
  change: PropTypes.any,
  generateCyberSourceKey: PropTypes.func,
  handleOnIdChange: PropTypes.any,
  states: PropTypes.any,
  removeFieldError: PropTypes.func,
  paymentsError: PropTypes.func,
  initialValues: PropTypes.object,
  cardState: PropTypes.any,
  setCardState: PropTypes.any
}

const CardForm = props => {
  return (
      <CardFormProvider>
        <CardFormContext.Consumer>
          {([cardState, setCardState, splitCardState1, setSplitCardState1, splitCardState2, setSplitCardState2]) => <CardFormData
            cardState={cardState}
            splitCardState1={splitCardState1}
            setSplitCardState1={setSplitCardState1}
            splitCardState2={splitCardState2}
            setSplitCardState2={setSplitCardState2}
            setCardState={setCardState}
            {...props} />}
        </CardFormContext.Consumer>
      </CardFormProvider>
  )
}

export default CardForm
