import React from 'react'
import PropTypes from 'prop-types'

import './SplitErrorContainer.scss'

// HINT: \n was not working here
const SplitErrorContainer = ({ errMsg, errHeader = 'Card Error', isError = true, uploadError = false, handleClick }) => {
  console.log('isError--->', uploadError)
  errMsg = !!errMsg && errMsg.split(/\n/)
  return (
      <div className='split_error_container' id='split_card_container'>
          <div className='left_error_container'></div>
          <div className='right_error_container'>
              <div className={`inside_right  ${uploadError && 'inside_right_sub'}`}>
                  { isError ? <img src='https://assets.eargo.com/brand/icon/split_error_icon.svg' className='right_img_error' /> : <img onClick={handleClick} src='https://assets.eargo.com/account/insuranceform/cross.png' className={`right_img_error ${uploadError && 'right_img_error_sub'}`} />}
                  <div className={`right_inside_right ${uploadError && 'right_inside_right_sub'}`}>
                      <div className={`body_bold typographyV2 ${uploadError && 'body typographyV2'}`}>{errHeader}</div>
                      <div className='caption typographyV2 right_inside_right_error'>
                      {
                       errMsg && errMsg.map((d, k) => <div key={k}> <span>{d}</span><br /> </div>)
                      }
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

SplitErrorContainer.propTypes = {
  errMsg: PropTypes.string,
  isError: PropTypes.bool,
  errHeader: PropTypes.any,
  uploadError: PropTypes.bool,
  handleClick: PropTypes.func
}

export default SplitErrorContainer
