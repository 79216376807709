import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DotSpinLoading } from '@eargo/eargo-components'
import './index.scss'
// import { useDispatch } from 'react-redux'
// import { deleteDocument } from '../../../../actions/auth'
// import { fetchAlluserDocuments } from '../../../../actions/documents'
import { downloadDocument } from '../../../../util/orders'

function DocumentRowCard ({ heading, docName, uploadDate, id, type }) {
  const extension_main = (docName || '').split('.')
  const extension = extension_main.length ? extension_main[extension_main.length - 1] : ''
  const date = new Date(uploadDate)
  const day = date.toLocaleString('default', { day: '2-digit' })
  const month = date.toLocaleString('default', { month: 'long' })
  const year = date.toLocaleString('default', { year: 'numeric' })

  const date_str = month + ' ' + day + ', ' + year
  // const dispatch = useDispatch()
  // const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)

  // const onDelete = async (fileName, docType, id) => {
  //   setLoading(true)
  //   const resp = await deleteDocument({ docType, fileName, id })
  //   if (resp.success) {
  //     dispatch(fetchAlluserDocuments())
  //   }
  //   setLoading(false)
  // }

  const downloadDocuments = async () => {
    setLoading(true)
    try {
      const res = await downloadDocument(type)
      if (res) {
        let file = ''
        if (extension?.toLowerCase() === 'pdf') {
          file = new Blob([res], { type: 'application/pdf' })
        } else {
          file = new Blob([res], { type: `image/${extension?.toLowerCase()}` })
        }
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL, '_blank')
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.log('Download Document Failed', error)
    }
  }

  return (
    <>
     {(loading) && <DotSpinLoading />}
      <div className='doc_row_container'>
        <div className='normal_view'>
          <div className='left_section'>
            <div className='extension'>{extension}</div>
            <div className='file_details_container'>
              <div className='heading'>{heading}</div>
              <div className='filename'>{docName}</div>
            </div>
          </div>
          <div className='right_section'>
            <div className='date'>{date_str}</div>
            <div className='download' onClick={() => { downloadDocuments() } } >Download</div>
            {/* <div className='delete' onClick={() => { setDeleteConfirmation(~deleteConfirmation) }}>Delete</div> */}
          </div>
        </div>
        <div className='mobile_view'>
          {/* {!deleteConfirmation && */}
            <>
              <div className='extension'>{extension}</div>
              <div className='file_details_container'>
                <div className='heading'>{heading}</div>
                <div className='date'>{date_str}</div>
                <div className='filename'>{docName}</div>
                <div className='download_delete_container'>
                  <div className='download' onClick={() => { downloadDocuments() } } >Download</div>
                  {/* <div className='delete' onClick={() => { setDeleteConfirmation(~deleteConfirmation) } }>Delete</div> */}
                </div>
              </div>
            </>
        </div>
      </div>
      {/* { !!deleteConfirmation && */}
        {/* <div className='delete_confirmation_container'>
          <div className='left_rectangle'></div>
          <div className='center_text_container'>
            <div className='sure'>Are You Sure?</div>
            <div className='button'>
              <EargoButtonV2
                label='Yes, delete'
                type="submit"
                handleOnClick={() => onDelete(docName, type, id)}
                className='primary bold'
              />
            </div>
            <div>
            <EargoButtonV2
                label='No, keep document'
                type="submit"
                handleOnClick={() => setDeleteConfirmation(~deleteConfirmation)}
                className='primary keep-document-button bold'
              />
            </div>
            <div className='no' onClick={() => { setDeleteConfirmation(~deleteConfirmation) } }>No, keep document</div>
          </div>
        </div> */}

      <div className='seperator'></div>
    </>
  )
}

DocumentRowCard.propTypes = {
  heading: PropTypes.string,
  docName: PropTypes.string,
  uploadDate: PropTypes.string,
  id: PropTypes.any,
  type: PropTypes.any
}

export default DocumentRowCard
