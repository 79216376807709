import React from 'react'
import PropTypes from 'prop-types'
import { colorCodes } from '../../constant/colors'

const DownArrow = ({
  style = {},
  fill = colorCodes.DARK_BLUE_1,
  width = 16,
  height = 11,
  className = '',
  onClick,
  viewBox = '0 0  16 11'
}) => (
    <svg
      fill='none'
      style={style}
      height={height}
      viewBox={viewBox}
      width={width}
      onClick={() => !!onClick && onClick}
      className={className}
      data-testid='jest-down-arrow-id'
    >
      <path d='M1.89373 0.868713L7.63123 6.59371L13.3687 0.868713L15.1312 2.63121L7.63123 10.1312L0.131226 2.63121L1.89373 0.868713Z' fill={fill} />
    </svg>
)

DownArrow.propTypes = {
  style: PropTypes.any,
  fill: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  viewBox: PropTypes.string
}

export default DownArrow
