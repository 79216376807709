import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { DotSpinLoading } from '@eargo/eargo-components'

import './RelatedOrders.scss'
import { isWebPSupported } from '../../../../../common/helpers'
import { downloadReturnLabel } from '../../../../../util/orders'
import { convertStringToAmount } from '../../../../../general_methods'
import { DATE_FORMAT_1, SET_WIDTH_FOR_TABLET } from '../../../../../constant'
import { fetchViewDetailsBySFId } from '../../../../../actions/orders'
import { useDispatch } from 'react-redux'

const RelatedOrders = ({ number, createdDate, status, total, sfOrderrId, rmaData }) => {
  const dispatch = useDispatch()
  const [expandDetails, setExpandDetails] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [returnLabelErrorMsg, setReturnLabelErrorMsg] = useState('')
  const isReturnOrder = (number).startsWith('RC')
  const isExchangeOrder = (number).startsWith('RE')
  const setReturnOrExchangeText = isReturnOrder ? 'Return' : 'Exchange'
  let rmaHeaderMessage = ''
  if (rmaData && !rmaData.lineItems) {
    rmaData.lineItmes = []
  }

  const ReturnExpandDetails = async () => {
    if (expandDetails) {
      setExpandDetails(!expandDetails)
      return
    }
    setIsLoading(true)
    const resp = await dispatch(fetchViewDetailsBySFId(sfOrderrId, true, false))
    if (resp) {
      setExpandDetails(!expandDetails)
    }
    setIsLoading(false)
  }

  const downloadInvoiceFn = async (sfOrderId, type) => {
    const setErrorMsg = type === 'pdf' ? 'Download Failed' : 'QR failed'
    try {
      setIsLoading(true)
      const res = await downloadReturnLabel(sfOrderId, type === 'pdf' ? 'pdf' : 'image')
      if (res) {
        let file = ''
        if (type === 'pdf') {
          file = new Blob([res], { type: 'application/pdf' })
        } else {
          file = new Blob([res], { type: 'image/png' })
        }
        const fileURL = URL.createObjectURL(file)
        if (((window.innerWidth > SET_WIDTH_FOR_TABLET) || !!isWebPSupported()) && (type === 'pdf' || type === 'png')) {
          window.open(fileURL, '_blank')
        }
      } else {
        setReturnLabelErrorMsg(setErrorMsg)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setReturnLabelErrorMsg(setErrorMsg)
    }
  }
  if (status === 'Delivered') {
    const refundMessage = isReturnOrder ? 'Refund issued' : 'Exchanged device delivered'
    rmaHeaderMessage = refundMessage
  } else {
    rmaHeaderMessage = `${setReturnOrExchangeText} request created | Awaiting device return`
  }
  return (
    <div className='related_container'>
      {isLoading && <DotSpinLoading />}
      <div className='related_orders_container'>
        <div className='related_orders_header'>
          <p className='typographyV2 body d-flex related_header'>
            <img
              className='information_icon'
              src='https://assets.eargo.com/new_account/information.png'
              alt='information.png'
            />
            {rmaHeaderMessage}
          </p>
          <span
            onClick={ReturnExpandDetails}
            className={`${expandDetails ? '' : 'arrow_bottom_icon'} arrow_icon`}
          >
            <img src='https://assets.eargo.com/new_account/arrow_up.png' />
          </span>
        </div>
        {expandDetails
          ? <div className='related_order_wrapper'>
            <div className='open_related_order inner-flex'>
              <p className='typographyV2 body_bold'>{isReturnOrder ? 'Return' : 'Exchange'} #{number}</p>
              <p className='typographyV2 body related_order_date'>{moment(createdDate).format(DATE_FORMAT_1)}</p>
            </div>
            {(rmaData.returnShippingLabelUrl || rmaData.returnShippingLabelBarcode) && <><p className='typographyV2 body_bold return_label'>Return label: </p>
            <div className='print_wrapper'>
              <div className='print_container'>
                <div>
                  {
                  rmaData.returnShippingLabelUrl && <div className='print_label_container'><span className='typographyV2 caption_bold print_label_text' onClick={() => downloadInvoiceFn(sfOrderrId, 'pdf') }>
                    <img className='related_order_print' src='https://assets.eargo.com/new_account/print.png' />PRINT RETURN LABEL</span>
                    <p className='typographyV2 caption expire'>Label is valid for 30 days</p>
                    </div>
                  }
                  {
                    rmaData.returnShippingLabelBarcode &&
                    <p className='typographyV2 caption_bold download_qr' onClick={() => { downloadInvoiceFn(sfOrderrId, 'png') }}><img src='https://assets.eargo.com/cart/eye.png' className='eye_img' />VIEW QR CODE</p>
                  }
                  {returnLabelErrorMsg && <p className='typographyV2 caption return_label_msg'>{returnLabelErrorMsg}</p>}
                </div>
              </div>
            </div></>}
            {isReturnOrder && <div className='open_related_order'>
              <p className='typographyV2 body_bold'>
                {(isReturnOrder && status === 'Delivered')
                  ? 'Amount credited'
                  : 'Amount to be credited'}:
                ${convertStringToAmount(Math.abs(parseFloat(rmaData.original_return_refund_total, 10)).toFixed(2))}
              </p>
            </div>}

            {isExchangeOrder && <div className='open_related_order'>
              <span className='interFontBold'>
                {isExchangeOrder && status === 'Delivered' ? 'Device exchanged:' : 'Device to exchange:'}
              </span>
            </div>}

            {isReturnOrder && status !== 'Delivered' && <p className='typographyV2 body instrument'>
              Your refunded order cost will be credited to you through the payment method you used to make the purchase.
            </p>}
            <p className='typographyV2 body instrument'>
              {isExchangeOrder && `${rmaData.lineItems.map(e => e.name).join(', ')} ${rmaData.lineItems.length > 1 ? 'devices.' : 'device.'}`}
            </p>
          </div>
          : (
          <div className='related_order_wrapper related_data'>
            <div className='related_order_number'>
            <p className='typographyV2 body_bold'>{isReturnOrder ? 'Return' : 'Exchange'} #{number}</p>
            </div>
            <p className='typographyV2 body related_order_date'>{moment(createdDate).format(DATE_FORMAT_1)}</p>
          </div>
            )}
      </div>
    </div>
  )
}

RelatedOrders.propTypes = {
  number: PropTypes.string,
  total: PropTypes.number,
  rmaData: PropTypes.object,
  sfOrderrId: PropTypes.string,
  createdDate: PropTypes.string,
  status: PropTypes.string
}

export default RelatedOrders
