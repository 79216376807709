import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { EargoButtonV2 } from '@eargo/eargo-components'

import { devices } from '../../../../../constant/devices'
import { colorCodes } from '../../../../../constant/colors'
import { addressUrl } from '../../../../../constant'

const { mobile, tablet } = devices
const { GREY_2 } = colorCodes

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
  justify-content: center;
  button {
    .sentence_case {
      bottom: -7px;
    }
  }
  @media ${mobile} {
    min-height: 100vh;
  }
`

const ChargerImage = styled.img`
    width: 210px;
    height: 195px;
    margin-bottom: 16px;
`

const ShowEmptyMsg = styled.div`
    width: 30%;
    text-align: center;
    color: ${GREY_2};
    font-weight: 400;
    font-size: 18px;
    line-height: 148%;
    margin-bottom: 33px;
    @media ${tablet} {
        width: 65%;
    }
    @media ${mobile} {
        width: 65%;
    }
`

const EmptyOrder = ({ selectedOption }) => {
  const redirectHandler = () => {
    window.location.href = `${addressUrl}products-hearing-aids`
  }

  return (
    <Container>
      <ChargerImage src='https://assets.eargo.com/pdp/my_orders/empty_order.png' />
      <ShowEmptyMsg className='show_message'>
        {(selectedOption.value === 'return' || selectedOption.value === 'exchange')
          ? <p className='typographyV2 body'>You have no returns or exchanges.</p>
          : <p className='typographyV2 body'>Your purchased orders, returns, and exchanges will show up here.</p>
        }
      </ShowEmptyMsg>
      <EargoButtonV2 className='primary tyChe center small' onClick={redirectHandler}>Shop Now</EargoButtonV2>
    </Container>
  )
}

EmptyOrder.propTypes = {
  selectedOption: PropTypes.object
}

export default EmptyOrder
