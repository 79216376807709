import {
  HANDLE_ADDRESS_PROCESSING,
  FETCH_ADDRESSES_SUCCESS,
  FETCH_STATES_SUCCESS,
  FETCH_ADDRESSES_ERROR,
  REMOVE_ADDRESS_ERROR_MSG,
  EDIT_ADDRESS_SUCCESS,
  SET_ADDRESS_DEFAULT_ID,
  DELETE_ADDRESS_SUCCESS,
  ADDRESS_SECTION_ERROR
} from '../actions/address'

const defaultState = {
  isLoading: false,
  states: [],
  addresses: [],
  total: 0,
  errMsg: {}
}

const Address = (state = defaultState, action) => {
  switch (action.type) {
    case HANDLE_ADDRESS_PROCESSING:
    case FETCH_ADDRESSES_SUCCESS:
    case FETCH_STATES_SUCCESS:
    case FETCH_ADDRESSES_ERROR:
    case ADDRESS_SECTION_ERROR:
      return {
        ...state,
        ...action.payload
      }
    // HINT: For now not in use
    case EDIT_ADDRESS_SUCCESS: {
      let addresses = state.addresses
      const { addressId, address } = action.payload
      addresses = addresses.map(a => {
        if (a.id === addressId) {
          a = address
        }
        return a
      })
      return {
        ...state,
        addresses: addresses
      }
    }
    case REMOVE_ADDRESS_ERROR_MSG: {
      const errMessages = state.errMsg
      if (!!errMessages && !!errMessages.otherError) {
        delete errMessages.otherError
      }
      if (!!action.fieldName && !!errMessages && !!errMessages[action.fieldName]) {
        delete errMessages[action.fieldName]
      }
      return {
        ...state,
        ...errMessages
      }
    }
    case DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        addresses: state.addresses.filter(a => a.id !== action.id)
      }
    case SET_ADDRESS_DEFAULT_ID: {
      const addressesArray = state.addresses
      const updatedAddresses = !!addressesArray && addressesArray.length > 0 && addressesArray.map(value => value.id === action.defaultId ? { ...value, default: true } : { ...value, default: false })
      return {
        ...state,
        addresses: updatedAddresses
      }
    }
    default:
      return state
  }
}

export default Address
