import { requestChangePassword } from '../util/auth'
import { checkAndSetAutoOut } from './auth'

export const CHANGE_PASSWORD_HANDLE_PROCESSING = 'CHANGE_PASSWORD_HANDLE_PROCESSING'
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'
export const REMOVE_CHANGE_PASSWORD_ERROR_MSG = 'REMOVE_CHANGE_PASSWORD_ERROR_MSG'

const handleProcessing = status => ({
  type: CHANGE_PASSWORD_HANDLE_PROCESSING,
  payload: {
    isProcessing: status
  }
})

export const changePasswordError = errMsg => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: {
    errMsg,
    isProcessing: false
  }
})

export const removeFieldError = fieldName => ({
  type: REMOVE_CHANGE_PASSWORD_ERROR_MSG,
  fieldName
})

/**
 * Change Password for
 * Logged In user
 * @param {Object} info
 */
export const changePassword = (info) => async (dispatch) => {
  try {
    dispatch(changePasswordError(null))
    dispatch(handleProcessing(true))
    const resp = await requestChangePassword(info)
    if (!!resp && resp.success) {
      dispatch(handleProcessing(false))
      return true
    } else {
      dispatch(checkAndSetAutoOut(resp))
      const errorBag = !!resp && !!resp.error ? resp.error : { otherError: resp.message }
      dispatch(changePasswordError(errorBag))
      return false
    }
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    console.log('Fetch Auth Change Password: ', error)
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})
    dispatch(changePasswordError(errorBag))
    return false
  }
}
