
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ProgressBar, Step } from 'react-step-progress-bar'
import { useDispatch, useSelector } from 'react-redux'
import { DotSpinLoading } from '@eargo/eargo-components'
import 'react-step-progress-bar/styles.css'
import styled from 'styled-components'

import { UPDATE_PROGRESS_STATE_PERCENTAGE } from './content'
import { fetchOrderTrack, resetOrderTrackData } from '../../actions/order_track'
import LineItem from '../my_info/MyOrder/LineItem'
import { devices } from '../../constant/devices'
import { colorCodes } from '../../constant/colors'
import usePrevious from '../../common/usePrevious'
import { stepsArray } from '../../constant'

const { tablet } = devices
const { GREY_1, ORANGE_1, GREY_7, GREY_6, GREY_14, DARK_BLUE_3, GREY_24 } = colorCodes

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 70%;
margin: auto;
color: ${GREY_1};
.capitalize {
    text-transform: capitalize;
}
.completed_step {
    background-image: url('https://assets.eargo.com/account/completed_step.svg');
    width: 21px;
    height: 21px;
    background-repeat: no-repeat;
}
.future_step {
    background-image: url('https://assets.eargo.com/account/future_step.svg');
    width: 21px;
    height: 21px;
    background-repeat: no-repeat;
}
.current_step {
    width: 38px;
    height: 35px;
    background-image: url(https://assets.eargo.com/account/current_step.svg);
}
.RSPBprogressBar {
    background: repeating-linear-gradient(to right,#848484 0,#848484 5px,transparent 5px,transparent 7px);
  }
  .RSPBprogression {
    -webkit-transition: width 5s ease;
    transition: width 5s ease;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
  }
`

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media ${tablet} {
        margin-top: 36px;
    }
    .title {
        font-size: 26px;
    }
    .order_number {
        color: ${GREY_7}
    }
`

const TwoColumns = styled.div`
    margin-top: 75px;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 100px;
    @media ${tablet}{
      flex-direction: column;
      align-items: center;
    }
    .left_column, .right_column {
        display: flex;
        flex-direction: column;
        width: 40%;
        align-items: center;
        @media ${tablet}{
            width: 100%;
        }
    }
    .column_title {
        color: ${GREY_7};
        width: 100%;
        text-align: center;
        line-height: 26px;
        padding-bottom: 5px;
        border-bottom: 1px solid ${GREY_6};
    }
    .weekday {
        font-size: 24px;
        line-height: 28px;
        margin: 10px 0;
    }
    .day {
        font-size: 110px;
        line-height: 129px;
        color: ${ORANGE_1};
    }
    .status {
        font-size: 34px;
        line-height: 40px;
        text-transform: capitalize;
        padding-bottom: 40px;
    }
`

const LeftColumn = styled.div`
    margin-right: 20%;
    @media ${tablet}{
        margin-right: unset;
    }
    .soon {
        margin: 50px 0;
    }
`

const RightColumn = styled.div`
    @media ${tablet}{
        margin-top: 50px;
    }
`

const TrackingDetails = styled.div``

const TrackingContainer = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: ${GREY_7};
    padding: 20px 0;
    border-bottom: 1px solid ${GREY_14};
    .&:nth-child(1){
        border-top: 1px solid ${GREY_14};
    }
`

const DateTime = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 20px;
`

const ProgressBarContainer = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 100px;
    @media ${tablet} {
        display: none;
    }
`

const UpdateLabel = styled.div`
    color: ${GREY_24};
    position: absolute;
    top: -50px;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    white-space: nowrap;
    display: flex;
    flex-direction: column;
`

const DateTimeStepContainer = styled.div`
    display: flex;
    flex-direction: row;
    span {
        font-size: 14px;
        color: ${GREY_7};
        margin-top: 10px;
    }
    span:nth-child(1) {
        margin-right: 3px;
    }
`

const BackButton = styled.div`
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    margin-top: 45px;
    cursor: pointer;
    color: ${DARK_BLUE_3};
    img {
        width:10px;
        margin-right: 10px;
        margin-bottom: 4px;
    }
    span {
        padding-bottom: 3px;
        border-bottom: 2px solid white;
    }
    &:hover span{
        border-bottom: 2px solid ${DARK_BLUE_3};
    }
`

const TrackOrder = ({ history }) => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.order_track.data)
  const isProcessing = useSelector(state => state.orders.isProcessing)
  const prevData = usePrevious(data)
  let itemList = null
  let trackingInfo = null
  let weekday = null
  let month = null
  let day = null
  let deliveryDate = null
  let number
  let status = 'Processing'
  let displayPrice = null
  const stepsTime = []

  useEffect(() => {
    async function getOrders () {
      window.scrollTo(0, 0)
      const number = window.location.pathname.split('/track-order/')[1]
      const res = await dispatch(fetchOrderTrack(number))
      if (!Object.keys(res.data).length) {
        history.push('/my-info')
      }
    }
    getOrders()
  }, [])

  useEffect(() => {
    let trackingStatus
    let currentIndex = 0
    if (prevData !== data && data.tracking_info) {
      if (data.tracking_info.tracking_status === 'unknown') {
        trackingStatus = 'pre_transit'
      } else if (data.tracking_info.tracking_status === 'return_to_sender' || data.tracking_info.tracking_status === 'available_for_pickup') {
        trackingStatus = 'delivered'
      } else if (data.tracking_info.tracking_status === 'failure') {
        trackingStatus = 'in_transit'
      } else {
        trackingStatus = data.tracking_info.tracking_status
      }
      currentIndex = (UPDATE_PROGRESS_STATE_PERCENTAGE[(trackingStatus)] / 33.33)
      currentIndex = Math.floor(currentIndex)
      if (document.getElementsByClassName('bar_point')[currentIndex]) {
        document.getElementsByClassName('bar_point')[currentIndex].className += ' current_step'
      }
    }
  }, [data])

  const renderProgressPercent = () => {
    let percent
    let trackingStatus
    if (data.tracking_info) {
      if (data.tracking_info.tracking_status === 'unknown') {
        trackingStatus = 'pre_transit'
      } else if (data.tracking_info.tracking_status === 'return_to_sender' || data.tracking_info.tracking_status === 'available_for_pickup') {
        trackingStatus = 'delivered'
      } else if (data.tracking_info.tracking_status === 'failure') {
        trackingStatus = 'in_transit'
      } else {
        trackingStatus = data.tracking_info.tracking_status
      }
      percent = UPDATE_PROGRESS_STATE_PERCENTAGE[trackingStatus]
    }
    if (!percent) percent = 0
    return percent
  }

  const renderData = () => {
    if (data.order) {
      itemList = data.order.lineItems
      displayPrice = data.order.order_channel
    }
    if (data.tracking_info) {
      trackingInfo = data.tracking_info
    }
    number = window.location.pathname.split('/track-order/')[1]

    if (!!trackingInfo && !!trackingInfo.tracking_details) {
      for (let i = 0; i < (trackingInfo.tracking_details).length; i++) {
        if (trackingInfo.tracking_details[i].status === 'pre_transit') {
          stepsTime[0] = trackingInfo.tracking_details[i].datetime
        } else if (trackingInfo.tracking_details[i].status === 'in_transit') {
          stepsTime[1] = trackingInfo.tracking_details[i].datetime
        } else if (trackingInfo.tracking_details[i].status === 'out_for_delivery') {
          stepsTime[2] = trackingInfo.tracking_details[i].datetime
        } else if (trackingInfo.tracking_details[i].status === 'delivered') {
          stepsTime[3] = trackingInfo.tracking_details[i].datetime
        }
      }
      deliveryDate = (trackingInfo.tracking_status === 'delivered')
        ? stepsTime[3]
        : (trackingInfo.estimated_delivery_date ? trackingInfo.estimated_delivery_date : null)
    }

    if (!!trackingInfo && !!trackingInfo.tracking_status) {
      status = trackingInfo.tracking_status.replace(/_/g, ' ')
      if (status === 'unknown') {
        status = 'Processing'
      } else if (status === 'failure') {
        status = 'Delivery Failed'
      } else if (status === 'available for pickup') {
        status = 'Available for Pickup'
      }
    }

    if (deliveryDate) {
      weekday = new Date(deliveryDate).toLocaleDateString('en-US', { weekday: 'long' })
      month = new Date(deliveryDate).toLocaleDateString('en-US', { month: 'long' })
      day = new Date(deliveryDate).toLocaleDateString('en-US', { day: 'numeric' })
    }
  }

  return (
    <>
      {renderData()}
      <Container>
        {!!isProcessing && <DotSpinLoading />}
        <BackButton className="back_button" onClick={() => {
          dispatch(resetOrderTrackData())
          history.goBack()
        }}>
          <img src='https://assets.eargo.com/account/back_button.svg' alt="back button" />
          <span>Back to My Orders</span>
        </BackButton>
        <TitleContainer>
          <span className="title">Order Tracking</span>
          <span className="order_number">Order # {number}</span>
        </TitleContainer>
        <ProgressBarContainer className={'progress_bar_container'}>
          <ProgressBar
            percent={renderProgressPercent()}
            filledBackground="#848484"
            width="75%"
            height="2px"
            margin="0"
            opacity="0.75">
            {stepsArray.map((step, index) =>
              <Step key={index} >
                {({ accomplished, index }) => {
                  return (
                    <div
                      className={`bar_point ${accomplished ? 'completed_step' : 'future_step'
                        }`}>
                      <UpdateLabel className="update_label">
                        <span>{step.name}</span>
                        {!!stepsTime[index] &&
                          <DateTimeStepContainer className="date_time_step_container">
                            <span>{new Date(stepsTime[index]).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}</span>
                            <span>{new Date(stepsTime[index]).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}</span>
                          </DateTimeStepContainer>}
                      </UpdateLabel>
                    </div>
                  )
                }}
              </Step>)}
          </ProgressBar>
        </ProgressBarContainer>

        <TwoColumns>
          <LeftColumn className="left_column">
            <span className="column_title bold">{status === 'delivered' || status === 'Available for Pickup' ? 'DELIVERED DATE' : 'ESTIMATED DELIVERY DATE'}</span>
            {deliveryDate === null
              ? <span className="bold soon">Tracking information will be updated soon</span>
              : <>
                <span className="weekday bold">{weekday === 'Invalid Date' ? '' : weekday}</span>
                <span className="month">{month === 'Invalid Date' ? '' : month}</span>
                <span className="day">{day === 'Invalid Date' ? '' : day}</span>
              </>}
            {trackingInfo && trackingInfo.tracking_status && <span className="status bold">{status.replace('_', ' ')}</span>}
            {(trackingInfo) && trackingInfo.tracking_details && deliveryDate != null && <TrackingDetails>
              {(trackingInfo.tracking_details).map((trackingDetail, index) => {
                return (
                  <TrackingContainer key={index} >
                    <DateTime>
                      <span>{new Date(trackingDetail.datetime).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}</span>
                      <span>{new Date(trackingDetail.datetime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}</span>
                    </DateTime>
                    <div className="status_location">
                      <span className="capitalize">{trackingDetail.status.replace(/_/g, ' ')}</span>
                      <div>
                        {trackingDetail.tracking_location.city &&
                          <span>{trackingDetail.tracking_location.city} , </span>}
                        {trackingDetail.tracking_location.state &&
                          <span>{trackingDetail.tracking_location.state}</span>}
                      </div>
                    </div>
                  </TrackingContainer>)
              }
              )}
            </TrackingDetails>}
          </LeftColumn>
          <RightColumn className="right_column">
            <span className="column_title bold">ORDER DETAILS</span>
            <div className="ordered_items_container">
              {!!itemList && !!itemList.length &&
                (itemList).map((lItem, l) =>
                  <LineItem lItem={lItem} key={l} displayPrice = {displayPrice} />)
              }
            </div>

          </RightColumn>
        </TwoColumns>
      </Container>
    </>
  )
}

TrackOrder.propTypes = {
  fetchOrderTrack: PropTypes.func,
  resetOrderTrackData: PropTypes.func,
  history: PropTypes.object
}

export default TrackOrder
