import * as PaymentMethodsApiUtil from '../util/payment_method_api_util'
import { PAYMENT_METHODS } from '../constant'
import { setIsCBS, setMethodLoaded } from './global'

export const RECEIVE_PAYMENT_METHODS = 'RECEIVE_PAYMENT_METHODS'

export const receivePaymentMethods = paymentMethods => {
  return {
    type: RECEIVE_PAYMENT_METHODS,
    paymentMethods
  }
}

export const getPaymentMethods = () => dispatch => (
  PaymentMethodsApiUtil.getPaymentMethods().then(
    res => {
      if (!!res && !!res.length) {
        const cbsMethod = res.find(r => (r.description === PAYMENT_METHODS.CBS))
        dispatch(setIsCBS(!!cbsMethod))
        dispatch(setMethodLoaded(!cbsMethod ? false : 'CBS'))
      }
      return dispatch(receivePaymentMethods(res))
    },
    err => console.log('Error In getPaymentMethods', err))
)
