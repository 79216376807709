import { getQuestions, saveFeedback } from '../util/feedback'

export const SET_HELP_DESK = 'SET_HELP_DESK'
export const RECEIVE_QUESTIONS = 'RECEIVE_QUESTIONS'
export const ANSWER_SUBMITTED = 'ANSWER_SUBMITTED'

export const setShowHelpDesk = showHelpDesk => ({
  type: SET_HELP_DESK,
  payload: {
    showHelpDesk
  }
})

const receiveQuestions = payload => ({
  type: RECEIVE_QUESTIONS,
  payload
})

const answerSubmitted = payload => ({
  type: ANSWER_SUBMITTED,
  payload
})

const logErrorMessage = response => {
  console.log(response)
}

export const fetchQuestions = userId => dispatch => (
  getQuestions(userId).then(res => {
    // TODO: We'll improve this condition later
    if (!!res && !!res.message &&
      (res.message === 'Form already submitted')) {
      dispatch(setShowHelpDesk(false))
    }
    return dispatch(receiveQuestions(res))
  },
  error => dispatch(logErrorMessage(error.responseJSON)))
)

export const saveAnswers = feedback => dispatch => (
  saveFeedback(feedback).then(
    () => dispatch(answerSubmitted(true)),
    error => dispatch(logErrorMessage(error.responseJSON)))
)
