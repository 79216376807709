import { totalDocuments } from '../common/helpers'
import { fetchUserDocumetsApi } from '../util/document'
import { checkAndSetAutoOut } from './global'

export const FETCH_USER_DOCUMETS = 'FETCH_USER_DOCUMETS'
export const UPDATE_TOTAL_DOCUMENTS = 'UPDATE_TOTAL_DOCUMENTS'
export const HANDLE_ID_LOADING = 'HANDLE_ID_LOADING'
export const EMPTY_TOTAL_DOCUMENTS = 'EMPTY_TOTAL_DOCUMENTS'
export const HANDLE_UPLOADING = 'HANDLE_UPLOADING'

const fetchDocuments = (updatedDocuments) => {
  return {
    type: UPDATE_TOTAL_DOCUMENTS,
    payload: {
      total: updatedDocuments
    }
  }
}

export const emptyDocuments = (updatedDocuments) => {
  return {
    type: EMPTY_TOTAL_DOCUMENTS,
    payload: {
      total: updatedDocuments
    }
  }
}

const fetchUserDocuments = (updatedDocuments) => {
  return {
    type: FETCH_USER_DOCUMETS,
    payload: {
      documents: updatedDocuments
    }
  }
}

const handleDocumentProcessing = (isLoading) => {
  return {
    type: HANDLE_ID_LOADING,
    payload: {
      isLoading
    }
  }
}

export const handleUploadInProgress = (uploadInProgress) => {
  return {
    type: HANDLE_UPLOADING,
    payload: {
      uploadInProgress
    }
  }
}

export const updateUserDocuments = (documents, key) => dispatch => {
  const leftOverDocuments = documents.filter(i => i.id !== key)
  dispatch(fetchDocuments(leftOverDocuments))
  dispatch(fetchAlluserDocuments())
}

export const fetchAlluserDocuments = () => async dispatch => {
  try {
    dispatch(handleDocumentProcessing(true))
    const resp = await fetchUserDocumetsApi()
    const docs = (resp?.data || []).map(i => i.type)
    const documents = totalDocuments.filter(doc => !(docs.includes(doc.id) || docs.includes(doc.altId)))
    dispatch(fetchDocuments(documents))
    dispatch(fetchUserDocuments(resp.data))
    dispatch(handleDocumentProcessing(false))
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (
          !!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {}
        )
    console.log('error', errorBag)
    dispatch(handleDocumentProcessing(false))
    return false
  }
}
