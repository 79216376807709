import React from 'react'
import PropTypes from 'prop-types'
import { EargoButtonV2, DotSpinLoading } from '@eargo/eargo-components'
import PopUp from './PopUp'
import closeSvg from './AddressSection/closeSvg.svg'
import './DeletePopup.scss'

const DeletePopup = ({ onDelete, onCancel, errMsg = false, title, subTitle, isLoading }) => (
    <div data-testid='DeletePopUp-container-testid' className='delete_pop_up_container PopUpRoundedCorner'>
        <PopUp preventClose={!!isLoading} handleClose={onCancel} closeSvg={closeSvg} hideScroll={true}>
            {!!isLoading && <DotSpinLoading />}
            <div className='delete_pop_up_content' data-testid='content-testid'>
                <h5 className='typographyV2 delete_pop_up_heading'>{errMsg ? 'Error!' : title}</h5>
                <div className='typographyV2 body delete_pop_up_subheading'>{errMsg || subTitle}</div>
                <div data-testid='ButtonContainer-testid' className={`delete_pop_up_button_container ${errMsg ? 'center-flex' : 'between-flex'}`}>
                    {!errMsg && <EargoButtonV2 className="delete_pop_up_action_button primary" handleOnClick={onDelete} label={!isLoading ? 'YES, DELETE IT' : 'Loading...'} />}
                    <EargoButtonV2 className="delete_pop_up_action_button ghost black" handleOnClick={onCancel} label={errMsg ? 'OK' : 'NO, KEEP IT'} />
                </div>
            </div>
        </PopUp>
    </div>
)

DeletePopup.propTypes = {
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  errMsg: PropTypes.any,
  title: PropTypes.string,
  subTitle: PropTypes.any,
  isLoading: PropTypes.bool
}

export default DeletePopup
