import {
  RECEIVE_REVIEW_CREDIT_CARD_ERROR,
  REMOVE_ALL_REVIEW_ERRORS
} from '../actions/review_error_actions'
import merge from 'lodash/merge'

const ReviewErrorsReducer = (state = {}, action) => {
  Object.freeze(state)
  const newState = merge({}, state)
  switch (action.type) {
    case RECEIVE_REVIEW_CREDIT_CARD_ERROR:
      newState.creditCard = action.error[0]
      return newState
    case REMOVE_ALL_REVIEW_ERRORS:
      return {}
    default:
      return state
  }
}

export default ReviewErrorsReducer
