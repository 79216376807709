import { fetchAllAddresses, fetchAllStates, addAddress, editAddress, deleteAddress, setAddressAsDefault, validateTax } from '../util/address_api_util'
import { COUNTRY_CODE } from '../constant'
import { checkAndSetAutoOut } from './auth'
import { handlePaymentProcessing } from './info_payment_method'
import { updateSyncErrors } from 'redux-form'
import { submitFailure } from '../common/helpers'

export const HANDLE_ADDRESS_PROCESSING = 'HANDLE_ADDRESS_PROCESSING'
export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS'
export const FETCH_ADDRESSES_ERROR = 'FETCH_ADDRESSES_ERROR'
export const FETCH_STATES_SUCCESS = 'FETCH_STATES_SUCCESS'
export const ADDRESS_SECTION_ERROR = 'ADDRESS_SECTION_ERROR'
export const REMOVE_ADDRESS_ERROR_MSG = 'REMOVE_ADDRESS_ERROR_MSG'
export const EDIT_ADDRESS_SUCCESS = 'EDIT_ADDRESS_SUCCESS'
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS'
export const SET_ADDRESS_DEFAULT_ID = 'SET_ADDRESS_DEFAULT_ID'

const handleProcessing = status => ({
  type: HANDLE_ADDRESS_PROCESSING,
  payload: {
    isLoading: status
  }
})

const fetchAddressesSuccess = res => ({
  type: FETCH_ADDRESSES_SUCCESS,
  payload: {
    isLoading: false,
    addresses: (res.rows).sort((a, b) => (a.default === b.default) ? 0 : a.default ? -1 : 1),
    total: res.count || 0
  }
})

export const addressesError = errMsg => ({
  type: FETCH_ADDRESSES_ERROR,
  payload: {
    isLoading: false,
    errMsg
  }
})

const fetchStatesSuccess = states => ({
  type: FETCH_STATES_SUCCESS,
  payload: {
    states
  }
})

export const addressSectionError = errMsg => ({
  type: ADDRESS_SECTION_ERROR,
  payload: {
    isLoading: false,
    errMsg
  }
})

export const removeFieldError = fieldName => ({
  type: REMOVE_ADDRESS_ERROR_MSG,
  fieldName
})

const setDefaultId = defaultId => ({
  type: SET_ADDRESS_DEFAULT_ID,
  defaultId
})

/**
* Fetch Addresses
*/
export const fetchAddresses = () => async (dispatch) => {
  try {
    dispatch(handleProcessing(true))
    const resp = await fetchAllAddresses()
    if (!!resp && !!resp.success) {
      dispatch(fetchAddressesSuccess(resp))
      return true
    } else {
      const errorBag = !!resp && !!resp.error ? resp.error : { otherError: resp.message }
      dispatch(addressesError(errorBag))
      return false
    }
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})
    dispatch(addressesError(errorBag))
    return false
  }
}

/**
 * Fetch All States
 * List accordint to
 * coutnryId
 * @param {*} countryId
 */
export const fetchStates = (countryId = COUNTRY_CODE) => async (dispatch) => {
  try {
    const resp = await fetchAllStates(countryId)
    if (!!resp && !!resp.length) {
      dispatch(fetchStatesSuccess(resp))
      return true
    } else {
      const errorBag = !!resp && !!resp.error ? resp.error : { otherError: resp.message }
      dispatch(addressesError(errorBag))
      return false
    }
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})
    dispatch(addressesError(errorBag))
    return false
  }
}

/**
 * Add New Address
 * @param {Object} info
 */
export const addNewAddress = (info) => async (dispatch) => {
  dispatch(handleProcessing(true))
  try {
    const response = await addAddress(info)
    let returnMsg = false
    if (!!response && !!response.success) {
      returnMsg = true
    } else {
      const errorBag = !!response && !!response.error ? response.error : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(addressesError(errorBag))
      returnMsg = false
    }
    dispatch(handleProcessing(false))
    return returnMsg
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorMsg = !!error.responseJSON && !!error.responseJSON.error && error.responseJSON.error
    const errorBag = errorMsg
      ? (typeof errorMsg === 'string' ? { otherError: errorMsg } : errorMsg)
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})

    dispatch(handleProcessing(false))
    dispatch(addressesError(errorBag))
    return false
  }
}

/**
 * Edit Address
 * @param {*} id
 * @param {*} info
 */
export const editAddressInfo = (id, info) => async (dispatch) => {
  dispatch(handleProcessing(true))
  try {
    const response = await editAddress(id, info)
    let returnMsg = false
    if (!!response && !!response.success) {
      dispatch(fetchAddresses())
      returnMsg = true
    } else {
      const errorBag = !!response && !!response.error ? response.error : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(addressesError(errorBag))
      returnMsg = false
    }
    dispatch(handleProcessing(false))
    return returnMsg
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})
    dispatch(handleProcessing(false))
    dispatch(addressesError(errorBag))
    return false
  }
}

/**
 * Set Default Address
 * @param {Number} id
 */
export const setDefaultAddress = id => async (dispatch) => {
  dispatch(handleProcessing(true))
  try {
    const response = await setAddressAsDefault(id)
    let returnMsg = false
    if (!!response && !!response.success) {
      dispatch(setDefaultId(id))
      returnMsg = true
    } else {
      const errorBag = !!response && !!response.error ? response.error : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(addressesError(errorBag))
      returnMsg = false
    }
    dispatch(handleProcessing(false))
    return returnMsg
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})
    dispatch(handleProcessing(false))
    dispatch(addressesError(errorBag))
    return false
  }
}

/**
 * Delete Address
 * @param {Number} id
 */
export const deleteAddressInfo = id => async (dispatch) => {
  dispatch(handleProcessing(true))
  try {
    const response = await deleteAddress(id)
    let returnMsg = false
    if (!!response && !!response.success) {
      dispatch(fetchAddresses())
      returnMsg = true
    } else {
      const errorBag = !!response && !!response.error ? response.error : ({ otherError: 'Something went wrong, Please try again' })
      dispatch(addressesError(errorBag))
      returnMsg = false
    }
    dispatch(handleProcessing(false))
    return returnMsg
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})
    dispatch(handleProcessing(false))
    dispatch(addressesError(errorBag))
    return false
  }
}

/**
 * Validate Zipcode
 * @param {*} param
 * @param {String} FORM_NAME
 */
export const validateZipCode = (info, FORM_NAME, forAddress = false) => async (dispatch) => {
  // HINT: I'll optimize it later
  try {
    if (!info.zipcode || !info.state_id) return

    forAddress
      ? dispatch(handleProcessing(true))
      : dispatch(handlePaymentProcessing(true))
    const response = await validateTax({
      zipcode: info.zipcode,
      state_id: info.state_id,
      ...(info.city && { city: info.city })
    })
    if (!!response && !!response.success) {
      forAddress
        ? dispatch(handleProcessing(false))
        : dispatch(handlePaymentProcessing(false))
      return true
    }
    forAddress
      ? dispatch(handleProcessing(false))
      : dispatch(handlePaymentProcessing(false))
    dispatch(updateSyncErrors(FORM_NAME, {
      zipcode: 'Zipcode combo is incorrect'
    }))

    submitFailure()
    return false
  } catch (error) {
    forAddress
      ? dispatch(handleProcessing(false))
      : dispatch(handlePaymentProcessing(false))
    dispatch(updateSyncErrors(FORM_NAME, {
      zipcode: 'Zipcode combo is incorrect'
    }))

    submitFailure()
    return false
  }
}
