import { BREAD2_API_JS, BREAD_INTEGRATION_KEY } from './constant'

const loadBread = async () => {
  const breadElem = document.getElementById('eargo-bread-js')
  if (breadElem) return true

  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    // script.defer = true

    script.src = BREAD2_API_JS
    script.id = 'eargo-bread-js'
    script.async = true
    document.getElementsByTagName('body')[0].appendChild(script)
    script.addEventListener('load', () => {
      resolve(script)
    })

    // Catch any errors while loading the script
    script.addEventListener('error', () => {
      reject(new Error(`${this.src} failed to load.`))
    })
  })
}

const initializeBread = async (breadData) => {
  const {
    placements,
    buyer,
    onCheckout = () => {},
    onDecisioned = () => {},
    onClose = () => {}
  } = breadData
  let breadExists = false

  if (window.BreadPayments) {
    breadExists = true
  }

  await loadBread()
  const { BreadPayments } = window

  if (!breadExists) {
    BreadPayments.setup({
      integrationKey: BREAD_INTEGRATION_KEY,
      buyer: buyer
    })
  } else {
    BreadPayments.__internal__.setBuyer(buyer)
  }

  BreadPayments.registerPlacements(placements)

  BreadPayments.on('INSTALLMENT:APPLICATION_CHECKOUT', (installmentResult) => {
    onCheckout(installmentResult)
  })

  BreadPayments.on('INSTALLMENT:APPLICATION_DECISIONED', (applicationResult) => {
    onDecisioned(applicationResult)
  })

  BreadPayments.on('INSTALLMENT:CUSTOMER_CLOSE', (opts) => {
    onClose(opts)
    const BreadElem = document.querySelector('[id*="zoid-checkout-component"]')

    if (BreadElem) {
      BreadElem.remove()
    }
  })

  if (!breadExists) {
    await BreadPayments.init()
  }
}

export default initializeBread
