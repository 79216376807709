import { fetchOrderById, fetchUserAllOrders } from '../util/orders'
import { checkAndSetAutoOut } from './auth'

export const HANDLE_ORDERS_PROCESSING = 'HANDLE_ORDERS_PROCESSING'
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'
export const FETCH_ORDER_BY_ID_SUCCESS = 'FETCH_ORDER_BY_ID_SUCCESS'
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR'
export const REMOVE_ORDERS_IN_A_LIST = 'REMOVE_ORDERS_IN_A_LIST'

export const VIEW_ORDER_DETAILS_PROCESSING = 'VIEW_ORDER_DETAILS_PROCESSING'
export const VIEW_ORDER_DETAILS_SUCCESS = 'VIEW_ORDER_DETAILS_SUCCESS'
export const VIEW_ORDER_DETAILS_ERROR = 'VIEW_ORDER_DETAILS_ERROR'

const viewOrderDetailsStart = status => ({
  type: VIEW_ORDER_DETAILS_PROCESSING,
  payload: {
    isProcessing: status
  }
})

const viewOrderDetailsSuccess = (viewDetails, parentSfId = false) => ({
  type: VIEW_ORDER_DETAILS_SUCCESS,
  payload: {
    isProcessing: false,
    viewDetails,
    parentSfId
  }
})

const viewOrderDetailsError = error => ({
  type: VIEW_ORDER_DETAILS_ERROR,
  payload: {
    isProcessing: false,
    error
  }
})

const handleProcessing = status => ({
  type: HANDLE_ORDERS_PROCESSING,
  payload: {
    isProcessing: status
  }
})

const fetchOrderSuccess = res => ({
  type: FETCH_ORDERS_SUCCESS,
  payload: {
    isProcessing: false,
    orders: res.data.orders,
    total: res.data.total_count || 0,
    pageCount: res.data.page_count || 0
  }
})

const fetchOrderByIdSuccess = (order, sfOrderId, parentSfId = false) => ({
  type: FETCH_ORDER_BY_ID_SUCCESS,
  payload: {
    parentSfId,
    order,
    sfOrderId,
    isProcessing: false
  }
})

const fetchOrderError = error => ({
  type: FETCH_ORDERS_ERROR,
  payload: {
    isProcessing: false,
    error
  }
})

export const removeOrdersList = () => ({
  type: REMOVE_ORDERS_IN_A_LIST
})

/**
 * Fetch All Orders
 * @param {Object} query
 */
export const fetchUserOrders = (query = {}) => async (dispatch) => {
  try {
    dispatch(handleProcessing(true))
    const resp = await fetchUserAllOrders(query)
    // Adding scroll to Top
    window.scrollTo(0, 0)
    if (!!resp && !!resp.data) {
      dispatch(fetchOrderSuccess(resp))
      return resp
    } else {
      dispatch(checkAndSetAutoOut(resp))
      const errorBag = !!resp && !!resp.error ? resp.error : { otherError: resp.message }
      dispatch(fetchOrderError(errorBag))
      return false
    }
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})
    dispatch(fetchOrderError(errorBag))
    return false
  }
}

/**
 * Fetch Order info
 * by sfOrderId
 * @param {String} sfOrderId
 * @param {String} parentSfId
 * @returns
 */
export const fetchUserOrderById = (sfOrderId, parentSfId = false) => async (dispatch) => {
  try {
    dispatch(handleProcessing(true))
    const resp = await fetchOrderById(sfOrderId)
    if (!!resp && !!resp.data) {
      dispatch(fetchOrderByIdSuccess(resp.data, sfOrderId, parentSfId))
      return resp
    } else {
      dispatch(checkAndSetAutoOut(resp))
      const errorBag = !!resp && !!resp.error ? resp.error : { otherError: resp.message }
      dispatch(fetchOrderError(errorBag))
      return false
    }
  } catch (error) {
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    const errorBag = !!error.responseJSON && !!error.responseJSON.error
      ? error.responseJSON.error
      : (!!error.responseJSON && !!error.responseJSON[0] ? { otherError: error.responseJSON[0] } : {})
    dispatch(fetchOrderError(errorBag))
    return false
  }
}

/**
 * Fetch Order info
 * by sfOrderId
 * @param {String} sfOrderId
 * @param {String} parentSfId
 * @returns
 */
export const fetchViewDetailsBySFId = (sfOrderId, parentSfId = false, isLoading = true) => async (dispatch) => {
  try {
    dispatch(viewOrderDetailsStart(isLoading))
    const resp = await fetchOrderById(sfOrderId)
    if (resp && resp.data) {
      dispatch(viewOrderDetailsSuccess(resp.data, parentSfId))
      return resp
    } else {
      dispatch(checkAndSetAutoOut(resp))
      dispatch(viewOrderDetailsStart(false))
      return false
    }
  } catch (error) {
    dispatch(viewOrderDetailsStart(false))
    if (error.responseJSON) {
      dispatch(checkAndSetAutoOut(error.responseJSON))
    }
    dispatch(viewOrderDetailsError(error))
    return false
  }
}
