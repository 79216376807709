import {
  RECEIVE_SESSION_ERRORS,
  REMOVE_ALL_ERRORS,
  RECEIVE_COUPON_ERROR,
  RECEIVE_CART_ERROR,
  RECEIVE_CREDIT_CARD_ERROR,
  RECEIVE_SHIP_ADDRESS_ERROR,
  REMOVE_COUPON_ERROR,
  REMOVE_CREDIT_CARD_ERROR,
  REMOVE_SESSION_ERROR,
  REMOVE_SHIP_ADDRESS_ERROR,
  RECEIVE_SPLIT_CARD_ERROR
} from '../actions/error_actions'
import merge from 'lodash/merge'

const ErrorsReducer = (state = {}, action) => {
  Object.freeze(state)
  const newState = merge({}, state)
  switch (action.type) {
    case RECEIVE_SESSION_ERRORS:
      newState.session = action.error[0]
      return newState
    case RECEIVE_COUPON_ERROR:
      newState.coupon = action.error[0]
      return newState
    case RECEIVE_CART_ERROR:
      newState.cart = action.error[0]
      return newState
    case RECEIVE_CREDIT_CARD_ERROR:
      newState.creditCard = action.error[0]
      return newState
    case RECEIVE_SHIP_ADDRESS_ERROR:
      newState.shipAddress = action.error[0]
      return newState
    case REMOVE_COUPON_ERROR:
      delete newState.coupon
      return newState
    case REMOVE_CREDIT_CARD_ERROR:
      delete newState.creditCard
      return newState
    case REMOVE_SESSION_ERROR:
      delete newState.session
      return newState
    case REMOVE_SHIP_ADDRESS_ERROR:
      delete newState.shipAddress
      return newState
    case RECEIVE_SPLIT_CARD_ERROR:
      newState.splitCard = action.error
      return newState
    case REMOVE_ALL_ERRORS:
      return {}
    default:
      return state
  }
}

export default ErrorsReducer
