import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { RoundRadioInput } from '@eargo/eargo-components'

import EditCreditCard from './EditCreditCard'
import { setDefaultPaymentMethod } from '../../../actions/info_payment_method'
import { getCardImage, createAddressLine } from '../../../common/helpers'
import { disabledOtherSection } from '../../../actions/global'
import './PaymentInformation.scss'

// This method is use to make smooth scroll behaviour
const scrollToCredit = (methodId) => {
  setTimeout(() => {
    const element = !!methodId && document.getElementsByClassName('eargo-payment-container-' + methodId)
    !!element && !!element[0] && element[0].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })
  }, 100)
}

const PaymentInformation = ({ PaymentMethods, isEdit, showAll, setAnotherEdit, setDeleteMethod, setIsEdit, setIsEditId }) => {
  const dispatch = useDispatch()
  const methods = useSelector(state => state.myInfoPaymentMethod.methods)
  const isDisabled = useSelector(state => state.global.isDisabled)
  const states = useSelector(state => state.address.states)
  let isCardExpired = false
  // Here assign no. of paymentMethods to methodCount variable
  const methodCount = methods.length
  const checkCardExpiration = (cardInfo) => {
    const currentDate = new Date()
    const maxYear = (cardInfo?.year - currentDate.getFullYear().toString()) * 12 + parseInt(cardInfo?.month)
    const cardExpiryDate = new Date(cardInfo?.year, (Number(cardInfo?.month)))
    if (maxYear <= 120) {
      if (currentDate >= cardExpiryDate) {
        isCardExpired = true
      } else {
        isCardExpired = false
      }
    } else {
      isCardExpired = true
    }
  }

  return <div>
  { PaymentMethods.map((method, i) => (
      <div className='payment_information_wrapper' key={i}>
          {isEdit !== method.id
            ? <div className={`payment_information_container ${!!showAll && i !== 0 ? 'isCheck' : ''}`}>
                  {checkCardExpiration(method)}
                  {isCardExpired && <span className='typographyV2 err_regular payment_information_card_expired_message'>{isCardExpired ? 'Card Expired.' : ''}</span>}
                  <div className={'payment_information_details_container eargo-payment-container-' + method.id} key={i}>
                    <div className={`payment_information_holder_details ${isCardExpired ? 'expiredCard' : ''}`}>
                        <label className='typographyV2 body payment_information_account_label eargo-capitalize'>
                          {method.name}
                        </label >
                        <div className='payment_information_card_info'>
                            <div className='payment_information_card_logo'>
                                <img src={getCardImage(method.cc_type)} alt='Card logo' />
                            </div>
                            <div className='typographyV2 body payment_information_card_number'>XXXX {method.last_digits}</div>
                            <div className='typographyV2 body payment_information_expiry_date'>{method.month}/{(method.year).substr(-2)}</div>
                        </div>
                        {!!method.address && <div className='payment_information_card_info'>
                            <span className='typographyV2 body'>{createAddressLine(states, method.address)}</span>
                        </div>}
                        <div className={`payment_information_default_field ${methodCount > 1 ? 'isVisible' : ''}`}>
                        {(!!methodCount && (methodCount > 1)) && !isCardExpired && <RoundRadioInput
                                className=''
                                label={method.default ? 'Default' : 'Select as default'}
                                value={i}
                                checked={!!method.default}
                                id='my-payment'
                                name='my-payment'
                                handleOnChange={() => dispatch(setDefaultPaymentMethod(method.id))}
                            />}
                        </div>
                    </div>
                    <div className={`payment_information_edit_section_line ${showAll ? 'isCheck' : ''}`}>
                        <div className='payment_information_edit_section'>
                            <div className={`typographyV2 body payment_information_edit_delete_button ${isDisabled ? 'isDisabled' : ''}`} onClick={() => {
                              dispatch(disabledOtherSection(true))
                              setAnotherEdit(method.id)
                            }}>Edit</div>
                            {(!!methodCount && (methodCount > 1)) && <div className={`typographyV2 body payment_information_edit_delete_button ${isDisabled ? 'isDisabled' : ''}`} onClick={() => setDeleteMethod(method.id)}>Delete</div>}
                        </div>
                    </div>
                  </div>
              </div>
            : <div className='edit_payment_information_section'>
                    <EditCreditCard
                        methodId={isEdit}
                        onCancel={() => {
                          dispatch(disabledOtherSection(false))
                          setIsEdit(false)
                          setIsEditId(false)
                          scrollToCredit(method.id)
                        }} />
              </div>}
      </div>
  ))
  }
  </div>
}

PaymentInformation.propTypes = {
  PaymentMethods: PropTypes.object,
  isEdit: PropTypes.bool,
  showAll: PropTypes.bool,
  setAnotherEdit: PropTypes.func,
  setIsEdit: PropTypes.func,
  setIsEditId: PropTypes.func,
  setDeleteMethod: PropTypes.func
}

export default PaymentInformation
