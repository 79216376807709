import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

import { devices } from '../../constant/devices'
import { colorCodes } from '../../constant/colors'
import ReduxSelectBox from '../common/redux-field/ReduxSelectBox'
import ReduxPrimaryInput from '../common/redux-field/ReduxPrimaryInput'
import { maxLength, minLength, number, requiredField, zipNotZero } from '../common/redux-field/validation'
import './CommonAddressField.scss'

const { mobile } = devices
const { GREY_6, WHITE, GREY_10, ORANGE_5, BRIGHT_GREY } = colorCodes

const address1Require = requiredField('Street Address')
const cityRequire = requiredField('City')
const stateRequire = requiredField('State')
const zipcodeRequire = requiredField('Zipcode')
const minChar5 = minLength(5, 'Too Short')
const maxChar5 = maxLength(5)
const addressMin = minLength(4)

const stateSelectStyle = {
  container: (provided, { isDisabled }) => ({
    ...provided,
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    width: '100%',
    height: '100%',
    background: isDisabled ? BRIGHT_GREY : 'transparent'
  }),
  indicatorsContainer: () => ({
    marginRight: 7
  }),
  dropdownIndicator: () => ({
    color: ORANGE_5
  }),
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    color: colorCodes.GREY_1
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 16,
    paddingTop: 12,
    overflow: 'visible',
    [`@media ${mobile}`]: {
      paddingLeft: 13
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'none'
  }),
  menu: () => ({
    flexDirection: 'column',
    maxHeight: 300,
    minHeight: 300,
    overflow: 'hidden',
    zIndex: 2,
    width: '100%',
    position: 'absolute',
    top: 75,
    left: 0,
    borderRadius: 3,
    [`@media ${mobile}`]: {
      top: 60
    }
  }),
  menuList: () => ({
    minHeight: 290,
    maxHeight: 290,
    overflowY: 'auto',
    background: WHITE,
    border: `1px solid ${GREY_6}`,
    position: 'relative'
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    cursor: 'pointer',
    background: (isFocused && !isSelected) && GREY_10,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 24,
    fontSize: '1.125rem',
    fontFamily: 'Inter, sans-serif'
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'none'
  })
}

const StateSelectBox = ({
  input, meta: { active, visited, touched, error }, isEdit,
  handleOnFocus, handleOnBlur, errResp, removeFieldError, stateList, disabled = false
}) => {
  const [searchableData, setSearchableData] = useState('')
  let statePlaceHolder = input.value

  if ((!!isEdit && !!stateList.length && input.value) ||
    (!!input.value && (typeof input.value === 'number'))) {
    const record = stateList.find(el => el.id === parseInt(input.value))
    if (!!record && !!record.abbr) {
      statePlaceHolder = record.abbr
    }
  }
  return (
    <label className={`${((touched && error) || !!errResp) ? 'error_border state_error_border' : ''} state_select_box_container`}>
      {<h1 className={`typographyV2 ${(!!active || !!input.value) ? 'floating_label' : 'label'} state_label`} >
        {(((!active && (visited || touched)) && error) || !!errResp) ? (error || errResp) : 'State'}
      </h1>}
      {!!input.value && <span className='input_field_text typographyV2 state_span'>{!searchableData && statePlaceHolder}</span>}
      <ReduxSelectBox
        selectBoxStyle={stateSelectStyle}
        input={input}
        options={stateList.map((option) => ({
          ...option,
          id: option.id,
          label: option.name,
          value: option.id
        }))}
        isSearchable={true}
        handleOnFocus={handleOnFocus}
        handleOnBlur={handleOnBlur}
        errResp={errResp}
        disabled={disabled}
        onMenuScrollToBottom={e => e.target.scrollIntoView()}
        removeFieldError={removeFieldError}
        onInputChange={value => setSearchableData(value)}
      />
    </label>
  )
}

StateSelectBox.propTypes = {
  input: PropTypes.any,
  meta: PropTypes.any,
  isEdit: PropTypes.any,
  handleOnFocus: PropTypes.any,
  handleOnBlur: PropTypes.any,
  errResp: PropTypes.any,
  removeFieldError: PropTypes.any,
  stateList: PropTypes.any,
  disabled: PropTypes.bool
}

const CommonAddressField = ({
  handleOnFocus, states, errMsg,
  removeFieldError, addressId = false, isEdit = false
}) => {
  return (
    <Fragment>
      <Field
        id="address1"
        name="address1"
        disabled={!!addressId}
        label="Street Address"
        component={ReduxPrimaryInput}
        onFocus={handleOnFocus}
        validate={[address1Require, addressMin]}
        errResp={!!errMsg && !!errMsg.address1 ? errMsg.address1 : false}
      />
      <Field
        id="address2"
        name="address2"
        disabled={!!addressId}
        label="Apt/Suite (Optional)"
        component={ReduxPrimaryInput}
        onFocus={handleOnFocus}
        errResp={!!errMsg && !!errMsg.address2 ? errMsg.address2 : false}
      />
      <Field
        id="city"
        name="city"
        label="City"
        disabled={!!addressId}
        component={ReduxPrimaryInput}
        onFocus={handleOnFocus}
        validate={[cityRequire]}
        errResp={!!errMsg && !!errMsg.city ? errMsg.city : false}
      />
      <div className="common-state-zipcode-fields two_line_section_address">
        <Field
          id="state_id"
          name="state_id"
          label="State"
          stateList={states}
          isEdit={isEdit}
          disabled={!!addressId}
          component={StateSelectBox}
          validate={[stateRequire]}
          removeFieldError={removeFieldError}
          errResp={!!errMsg && !!errMsg.state_id ? errMsg.state_id : false}
        />
        <div className='zip_field'>
          <Field
            id="zipcode"
            name="zipcode"
            label="Zipcode"
            disabled={!!addressId}
            validate={[zipcodeRequire, number, minChar5, maxChar5, zipNotZero]}
            component={ReduxPrimaryInput}
            onFocus={handleOnFocus}
            errResp={!!errMsg && !!errMsg.zipcode ? errMsg.zipcode : false}
          />
        </div>
      </div>
    </Fragment>
  )
}

CommonAddressField.propTypes = {
  handleOnFocus: PropTypes.any,
  states: PropTypes.any,
  errMsg: PropTypes.any,
  removeFieldError: PropTypes.any,
  addressId: PropTypes.bool,
  isEdit: PropTypes.bool
}

export default CommonAddressField
