import React from 'react'
import { addressUrl } from '../../../../constant'
import './LongFormInvoice.scss'

function LongFormInvoice () {
  return (
    <a className="long-invoice-form d-flex" target="_blank" href={`${addressUrl}content/dam/eargo/pdf/LBL0140_RECEIPT%20LONG%20FORM_OTC_7x7_Rev_C_r2b.pdf`} rel="noopener noreferrer">
        <img src='https://assets.eargo.com/pdp/my_orders/file.svg' />
        <p className="typographyV2 append_icon long-form-invoice">LONG FORM INVOICE</p>
    </a>
  )
}

export default LongFormInvoice
