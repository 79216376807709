import { RECEIVE_PAYMENT_METHODS } from '../actions/payment_methods_actions'

const PaymentMethodsReducer = (state = null, action) => {
  Object.freeze(state)
  switch (action.type) {
    case RECEIVE_PAYMENT_METHODS:
      return action.paymentMethods
    default:
      return state
  }
}

export default PaymentMethodsReducer
