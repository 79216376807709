import React from 'react'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
import './ReduxNumberInput.scss'

const ElementWrapper = ({ label, children, labelClass, isValue }) => (
  <label className={`card-containers inputContainer cardholder-name ${labelClass}`}>
    <h1 className={`typographyV2 ${isValue ? 'floating_label pl-0' : 'label'}`}>
      {label}
    </h1>
    {children}
  </label>
)

const cardExpiry = (val) => {
  let newval = val.substring(0, 2)
  const max = '12'
  if (newval.length === 1 && newval[0] > max[0]) {
    newval = `0${newval}`
  }
  if (newval.length === 2) {
    if (Number(newval) === 0) {
      newval = '01'
    } else if (newval > max) {
      newval = max
    }
  }
  const month = `${newval}/`
  const year = val.substring(2, 4)
  return month + (year.length ? year : '')
}

const ReduxNumberInput = ({
  input, label, errResp, expError,
  meta: { touched, error, active, visited }
}) => (
  <ElementWrapper label={(((!active && (visited || touched)) && error) || (!!errResp || !!expError)) ? (error || (errResp || expError)) : label}
    isValue={active || (!!input.value)}
    labelClass={`primary-input-label ${(((!active && (visited || touched)) && error) || (!!errResp || !!expError)) ? 'error_border' : ''}`}
  >
    <NumberFormat
      className="typographyV2 redux_form_number_format_input"
      // format="##/##"//{cardExpiry} // TODO: I'll update it
      format={cardExpiry}
      data-testid='jest-numberFormatInput-testid'
      {...input}
    />
  </ElementWrapper>
)

ElementWrapper.propTypes = {
  label: PropTypes.any,
  children: PropTypes.any,
  labelClass: PropTypes.string,
  isValue: PropTypes.bool
}

ReduxNumberInput.propTypes = {
  input: PropTypes.any,
  label: PropTypes.any,
  errResp: PropTypes.any,
  expError: PropTypes.any,
  meta: PropTypes.any
}

export default ReduxNumberInput
