import { helpersFn } from '@eargo/eargo-components'
import { handleAjax } from '../common/helpers'

const { createQueryParams } = helpersFn

// Fetch All States
export const fetchAllStates = code => handleAjax('GET', 'api/states', { country_code: code })
/**
 * Fetch All Address
 * @param {Object} queryParams
 */
export const fetchAllAddresses = (queryParams = {}) => {
  let reqUrl = 'api/users/addresses'
  if (Object.keys(queryParams).length > 0) {
    reqUrl = reqUrl + '?' + createQueryParams(queryParams)
  }
  return handleAjax('GET', reqUrl)
}

/**
 * Add New Address
 * @param {Object} info
 */
export const addAddress = info => handleAjax('POST', 'api/users/addresses', info)

/**
 * Edit Address Info
 * @param {Integer} id
 * @param {Object} info
 */
export const editAddress = (id, info) => handleAjax('PATCH', `api/users/addresses/${id}`, info)

/**
 * Set Address as default
 * @param {Integer} id
 */
export const setAddressAsDefault = id => handleAjax('PATCH', `api/users/addresses/set-default/${id}`)

/**
 * Delete Address Info
 * @param {Integer} id
 */
export const deleteAddress = id => handleAjax('DELETE', `api/users/addresses/${id}`)

/**
 * Validate Tax
 * @param {Object} info
 */
export const validateTax = info => handleAjax('POST', 'api/validate-zipcode', info)
