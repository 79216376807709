import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import moment from 'moment'
import { parse } from 'query-string'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { EargoButtonV2, DotSpinLoading } from '@eargo/eargo-components'

import './Orders.scss'
import Pagination from '../Pagination'
import EmptyOrder from './EmptyOrder/EmptyOrder'
import { colorCodes } from '../../../../constant/colors'
import { DATE_FORMAT_1, orderOptions } from '../../../../constant'
import { fetchViewDetailsBySFId, fetchUserOrders } from '../../../../actions/orders'
import SmallLoader from '../../../common/smallLoader/SmallLoader'
import { convertStringToAmount, getSkuImage } from '../../../../common/helpers'
import styled from 'styled-components'
import LongFormInvoice from './LongFormInvoice'

const { WHITE, GREY_1 } = colorCodes

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: WHITE,
    color: GREY_1,
    width: 176,
    borderRadius: 4
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  option: (styles) => ({
    ...styles,
    fontSize: '18px'
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: GREY_1,
    fontSize: '20px',
    backgroundColor: 'none',
    height: '40px',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
    transition: '250ms',
    cursor: 'pointer',
    ':hover': {
      color: GREY_1
    }
  })
}

const Documentation = styled.div`
  .invoice-text{
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    padding-right:20px;
  }
    display: flex;
    padding-top:21px;
    font-style: normal;
    color: ${GREY_1};
`

const OrdersList = () => {
  const url = parse(location.search)
  const page = !!url && url.page > 0 ? parseInt(url.page) : 1
  const history = useHistory()
  const dispatch = useDispatch()
  const [selectedOption, setSelectedOption] = useState(orderOptions[0])
  const TotalOrders = useSelector(state => state.orders.total)
  const loading = useSelector(state => state.orders.isProcessing)
  const [isLoading, setIsLoading] = useState(true)
  const [activePage, setActivePage] = useState(page)
  const [allOrders, setAllOrders] = useState([])

  const showProductDetail = async (sf_order_id) => {
    const resp = await dispatch(fetchViewDetailsBySFId(sf_order_id, false))
    if (resp.data.original_order_id) {
      resp && history.push(`/my-info/order/${resp.data.original_order_id}`)
    } else {
      resp && history.push(`/my-info/order/${sf_order_id}`)
    }
  }

  // Handle pagination
  const handlePageChange = page => {
    setActivePage(page)
    history.push(`${location.pathname}?page=${page}`)
  }

  useEffect(() => {
    setActivePage(page)
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }, 2000)
  }, [])

  useEffect(() => {
    handlePageChange(1)
  }, [selectedOption])

  useEffect(() => {
    async function getOrders () {
      setIsLoading(true)
      const getOrders = await dispatch(fetchUserOrders({ page: page, orders: selectedOption.value }))
      setIsLoading(true)
      if (getOrders.data && getOrders.data.orders && getOrders.data.orders.length === 0) {
        history.push(`${location.pathname}`)
        setActivePage(1)
      }
      setAllOrders(getOrders.data?.orders || [])
      getOrders.success && getOrders.data?.orders.length && handleOnChangeData(getOrders.data?.orders || [])
    }
    getOrders()
  }, [activePage, selectedOption])

  const handleOnChangeData = async (getOrders) => {
    setIsLoading(true)
    const OrdersData = await Promise.all(getOrders?.map(async (item) => {
      const resp = await dispatch(fetchViewDetailsBySFId(item.sf_order_id, false, false))
      setIsLoading(true)
      if (!resp.success) {
        return { ...item, data: { success: false } }
      }
      return { ...item, data: { ...resp.data, success: true } }
    })
    )
    setAllOrders(OrdersData)
    setIsLoading(false)
  }

  const isShowFilter = (allOrders) => {
    if (!!allOrders?.length && selectedOption.value === 'all') {
      return true
    } else if (!allOrders?.length && selectedOption.value === 'all') {
      return false
    }
    return true
  }

  const getRmaOrders = (details) => {
    if (!details || !details.success || !details.rmaOrders || !details.rmaOrders.length) {
      return <div></div>
    }
    return (
      <div className='return_exchange_order'>
      <div><img src='https://assets.eargo.com/new_account/information.png' />{details.rmaOrders[0].number.includes('RC') ? 'Return' : 'Exchange'} requested on {moment(details.rmaOrders[0].order_created_date).format(DATE_FORMAT_1)}</div>
      <p className='typographyV2 body retun_product_order_id'>Order #{details.rmaOrders[0].number}</p>
    </div>
    )
  }

  const getParentOrder = (details) => {
    if (!details || !details.success || !details.original_order_number || !details.original_order_createddate) {
      return <div></div>
    }
    return (
      <div className='return_exchange_order'>
      <p className='typographyV2 body'><img src='https://assets.eargo.com/new_account/information.png' />Based on order from {moment(details.original_order_createddate).format(DATE_FORMAT_1)}</p>
      <div className='retun_product_order_id'>Order #{details.original_order_number}</div>
    </div>
    )
  }

  const getLineItmes = (details) => {
    if (!details || !details.success) {
      return <p className='typographyV2 body return_exchange_style not_found'>Something went wrong</p>
    }
    if (!details.lineItems.length) {
      return <p className='typographyV2 body return_exchange_style not_found'>No line Items Found</p>
    }
    return (
        <>
        <img className="product_image" src={getSkuImage(!!details.lineItems.length && details.lineItems[0].sku)} />
        <div className='name_quentiry_container'>
          <div className='name_quentity'><p className='typographyV2 body_bold'>{!!details.lineItems.length && details.lineItems[0].name}</p>
            <p className='typographyV2 caption quentity'>Qty: {!!details.lineItems.length && details.lineItems[0].quantity}</p>
          </div>
          <p className='typographyV2 body item_out_of_lenght'>Item 1 of {details.lineItems.length}</p>
        </div>
        </>
    )
  }

  const orderStatus = (status, rest, number) => {
    const deliveryTime = rest?.data.deliveryTime
    const estDeliveryTime = rest?.data.estDeliveryTime

    return (
      <>
        {((number.includes('RC') || number.includes('RE')))
          ? <div>
            {status !== 'Delivered'
              ? <p className='typographyV2 body_bold order_number status_color'>Awaiting device return</p>
              : <div>
                {<div className='order_number status_color'>
                  {deliveryTime?.length
                    ? <span>{number.includes('RC') ? 'Request Issued on ' : 'Exchanged device delivered on '}
                      <span className='order_status'>{moment(deliveryTime).format(DATE_FORMAT_1)}</span>
                    </span>
                    : ''}
                </div>}
              </div>
            }
          </div>
          : <div>
            {number.includes('S') && status !== 'Delivered'
              ? <p className='typographyV2 body_bold order_number status_color'>
                Estimated delivery: <p className='typographyV2 body order_status'>{estDeliveryTime?.length ? moment(estDeliveryTime).format(DATE_FORMAT_1) : rest.data?.estDelivery}</p>
              </p>
              : deliveryTime?.length &&
              <p className='typographyV2 body_bold order_number status_color'>
                Delivered: <p className='typographyV2 body order_status'>{deliveryTime?.length && moment(deliveryTime).format(DATE_FORMAT_1)}</p>
              </p>
            }
          </div>
        }
      </>
    )
  }

  return (
    <div className="order_list_container interFontRegular" >
      <div className="list_wrapper">{loading
        ? <DotSpinLoading />
        : <div className="all_list">
          <div className="list_header">
            <h5 className="typographyV2 order_text">Orders</h5>
            {isShowFilter(allOrders) && <Select
              value={selectedOption}
              styles={customStyles}
              isSearchable={false}
              onChange={setSelectedOption}
              options={orderOptions}
            />}
          </div>
          {!!allOrders && !!allOrders?.length
            ? <div className="list_container">
            {allOrders?.map(
              ({ id, status, number, sf_order_id, total, order_created_date, ...rest }) => {
                return (
                  <div className="list_items" key={id}>
                    <div className="order_list_item">
                      { (number.includes('RC') || number.includes('RE')) &&
                      <p className='typographyV2 body_bold return_exchange_style'>{number.includes('RC') ? 'Return Order' : 'Exchange Order'}</p>
                      }
                      <div className='header_wrapper'>
                        <div className="line_items date_and_order">
                          <h6 className="typographyV2 order_date">{moment(order_created_date).format(DATE_FORMAT_1)}</h6>
                          {(number.includes('RC') || number.includes('RE'))
                            ? <div className="order_number">{number.includes('RC')
                              ? `Return #${number}`
                              : `Exchange #${number}`}</div>
                            : <p className="typographyV2 body order_number">Order #{number}</p>
                          }
                        </div>
                      <div className="line_items">
                      { !isLoading &&
                      ((number.includes('RC') || number.includes('RE'))
                        ? <p className="typographyV2 body_bold order_price">{number.includes('RC') ? (rest?.data?.order_channel && rest.data.order_channel === 'Eargo') && (`To be credited: $${rest?.data?.original_return_refund_total && convertStringToAmount(rest?.data?.original_return_refund_total)}`) : 'FREE'}</p>
                        : (rest?.data?.order_channel && rest.data.order_channel === 'Eargo') && (<p className="typographyV2 body_bold order_price">${convertStringToAmount(total)}</p>)
                      )
                      }
                      {
                        !isLoading && orderStatus(status, rest, number)
                      }
                      </div>
                        <div className='status_and_button'>
                          <p className='typographyV2 body_bold order_number parent_status'>{number.includes('S') && status}</p>
                          <div className='view_button_container'>
                            <EargoButtonV2 className='ghost black bold small' onClick={() => {
                              showProductDetail(sf_order_id)
                            }}>View details</EargoButtonV2>
                          </div>
                        </div>
                      </div>
                      {!isLoading && ((number.includes('RC') || number.includes('RE')) ? getParentOrder(rest.data) : getRmaOrders(rest.data))}
                    </div>
                    <div className="product_details">
                      {!isLoading
                        ? getLineItmes(rest.data)
                        : <SmallLoader/>
                    }
                    </div>
                  </div>
                )
              }
            )}
          </div>
            : <EmptyOrder selectedOption={selectedOption} />}
             {!!allOrders.length && <Documentation data-testid='Dcoumentaition-testid'>
                <p className="typographyV2 body_bold invoice-text">Additional Documentation:</p>
                <LongFormInvoice />
                </Documentation>
             }
              {!!allOrders.length && <Pagination
                total={TotalOrders}
                activePage={activePage}
                perPage={3}
                pageInterval={3}
                handlePageChange={(page) => handlePageChange(page)} />
              }
        </div>
        }
      </div>
    </div>
  )
}

export default OrdersList
