import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colorCodes } from '../../constant/colors'
import { devices } from '../../constant/devices'
import { isJ2Supported, isWebPSupported } from '../../common/helpers'

const { WHITE, GREY_3, GREY_5 } = colorCodes
const { mobile } = devices

const isWebp = isWebPSupported()
const isJP2 = isJ2Supported()

const Container = styled.div`
position: fixed;
top: 0;
bottom: 0;
display: flex;
justify-content: center;
width: 100%;
height: 100%;
left: 0;
transition: opacity 500ms;
background-color: rgba(25, 24, 22, 0.5);
visibility: visible;
opacity: 1;
z-index: 9999;
${props => props.setHeight
? 'overflow-y:auto;'
    : 'align-items: center;'}
`

const SubContainer = styled.div`
width: 600px;
max-width: 80%;
background-color: ${WHITE};
border-radius: ${props => props.topDeskPadding ? 4 : 0}px;
`

const CrossSection = styled.div`
width: 15px;
position: absolute;
top: 26px;
right: 26px;
cursor: pointer;
z-index: 999;
`

const Wrapper = styled.div`
padding: 0px 48px;
padding-top: ${props => props.topDeskPadding ? 44 : 86}px;
position: relative;
@media ${mobile} {
    padding: 0px 15px 0px 15px;
    padding-top: ${props => props.paddingTop ? 24 : 0}px;
  }

${props => props.hideScroll
? `
-ms-overflow-style: none;
scrollbar-width: none;
::-webkit-scrollbar {
    display: none;
  }
`
: ''}

${props => props.setHeight
? 'background-color:inherit'
    : `overflow-y: auto;
 max-height: 90vh;
    // height: ${props.height && props.height}px;`}
`

const Children = styled.div`
 overflow-y: ${props => props.isScrollable ? 'scroll' : 'none'};
 height: ${props => props.height ? props.height : 'fit-content'};
 ::-webkit-scrollbar {
  width: 10px;
  display: ${props => props.isScrollable ? 'block' : 'none'};
}
::-webkit-scrollbar-track {
  background: ${GREY_5};
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: ${GREY_3};
  border-radius: 5px;
}
@media ${mobile} {
  margin-top: 30px;
}
`

class PopUp extends Component {
  static defaultProps = {
    closeSvg: false,
    preventClose: false
  }

  constructor (props) {
    super(props)
    this.wrapperRef = React.createRef()
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
    document.body.classList.add('modal-open')
  }

  componentWillUnmount () {
    document.addEventListener('mousedown', this.handleClickOutside)
    document.body.classList.remove('modal-open')
  }

  handleClickOutside = e => {
    const { preventClose, handleClose } = this.props

    if (preventClose) {
      return false
    }

    const wrapper = this.wrapperRef
    if (!!wrapper && wrapper.current !== null && !wrapper.current.contains(e.target)) {
      handleClose()
    }
  }

  render () {
    const { handleClose, children, closeSvg, hideScroll = false, paddingTop = false, height = 400, topDeskPadding = false, unsetHeight = false, popHeightLimit = '', isScrollable = false } = this.props
    return (
      <Container setHeight={unsetHeight}>
        <SubContainer className="popup-content-holder" topDeskPadding={topDeskPadding}>
          <Wrapper data-testid='PopUp-wrapper-testid' ref={this.wrapperRef} height={height} hideScroll={hideScroll} paddingTop={!!paddingTop} topDeskPadding={topDeskPadding} setHeight={unsetHeight}>
            <CrossSection data-testid='CrossSection-testid' onClick={() => handleClose()} className="popup-content-svg-holder">
              <img src={closeSvg || isWebp
                ? 'https://assets.eargo.com/pdp/new_images/webp/close.webp'
                : isJP2
                  ? 'https://assets.eargo.com/pdp/eargo5/images+3/JP2Files/close.jp2'
                  : 'https://assets.eargo.com/account/insuranceform/close.svg'} alt='close' />
            </CrossSection>
            <Children height={popHeightLimit} isScrollable={isScrollable}>
            {children}
            </Children>
          </Wrapper>
        </SubContainer>
      </Container>
    )
  }
}

PopUp.propTypes = {
  preventClose: PropTypes.any,
  handleClose: PropTypes.func,
  children: PropTypes.any,
  closeSvg: PropTypes.any,
  hideScroll: PropTypes.bool,
  paddingTop: PropTypes.bool,
  height: PropTypes.number,
  topDeskPadding: PropTypes.bool,
  unsetHeight: PropTypes.bool,
  popHeightLimit: PropTypes.string,
  isScrollable: PropTypes.bool
}

export default PopUp
